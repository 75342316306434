import React, { useEffect, useState,useCallback} from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { ThemeColor } from "../theme/color";

import "../datatable/Datatable.css";

const StyledDataGrid = styled(DataGrid)({
  "& .MuiTableHead-root": {
    background: ThemeColor.LIGHTPURPLE, // Customize the background color here
    color: "#ffffff", // Optional: customize the text color
  },
});

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Name", width: 150 },
  { field: "age", headerName: "Age", width: 110 },
  // Add more columns as needed
];

const rows = [
  { id: 1, name: "John Doe", age: 35 },
  { id: 2, name: "Jane Smith", age: 42 },
  { id: 3, name: "Jack Johnson", age: 28 },
  // Add more rows as needed
];

function CustomDataTable(props) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const apiRef = useGridApiRef();

  // Notify parent about visible columns on mount or when visibility changes
  // useEffect(() => {
  //   if (apiRef && props.onVisibleColumnsChange) {
  //     const updateVisibleColumns = () => {
  //       const visibleColumns = apiRef.current
  //         .getVisibleColumns()
  //         .map((col) => col.field);
  //       props.onVisibleColumnsChange(visibleColumns); // Pass visible columns to parent
  //     };

  //     // Initial update
  //     updateVisibleColumns();

  //     // Listen for column visibility changes
  //     apiRef.current.subscribeEvent(
  //       "columnVisibilityChange",
  //       updateVisibleColumns
  //     );
  //   }
  // }, [apiRef, props.onVisibleColumnsChange]);



  const handlePaginationChange = (newPaginationModel) => {
    const direction =
      newPaginationModel.page > paginationModel.page ? "right" : "left";
    setPaginationModel(newPaginationModel);
    // //(object);
    if (props?.onPaginationChange)
      props?.onPaginationChange(newPaginationModel, direction);


  };

  // Notify parent of visible columns
  const notifyVisibleColumns = useCallback(() => {
    if (apiRef.current) {
      const visibleColumns = apiRef.current
        .getVisibleColumns()
        .map((col) => col.field);
      console.log("Initial Visible Columns in Child:", visibleColumns);
      props.onVisibleColumnsChange?.(visibleColumns);
    }
  }, [apiRef, props.onVisibleColumnsChange]);


  const handleColumnVisibilityChange = useCallback((model) => {
    console.log("chl chl",model)
    const visibleColumns = Object.keys(model).filter((key) => model[key] === true);
    console.log(visibleColumns)
    props?.onVisibleColumnsChange?.(visibleColumns);
    // if (props.onVisibleColumnsChange) {
    //   props.onVisibleColumnsChange(visibleColumns); // Notify parent
    // }
  }, [props.onVisibleColumnsChange]);

  useEffect(() => {
    notifyVisibleColumns();
  }, []);

  return (
    <div
      style={{
        height: props?.height?props?.height:"100%",
        width: "100%",
        overflowY: "scroll",
        position: "absolute",
        left:"0px",
        bottom: "0px",
      }}
    >
      <StyledDataGrid
        apiRef={apiRef}
        rows={props.row ? props.row : rows}
        columns={props.columns ? props.columns : columns}
        loading={props.loading}
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 0, pageSize: 5 },
        //   },
        // }}
        sx={{
          width: "100%",
          "& .MuiDataGrid-columnHeader": {
            position: "sticky",
            top: 0,
            zIndex: 1000,
            background: ThemeColor.BLUE, // Set the header background color
            color: ThemeColor.WHITE, // Optional: set the header text color
          },
          "& .MuiDataGrid-panelFooter": {
            background: "yellow",
          },
          "& .MuiDataGrid-main": {
            overflow: "auto",
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
        onSelectionModelChange={props.onSelectionModelChange}
        // autosizeOnMount
        disableRowSelectionOnClick
        // rowHeight={150}
        rowHeight={props?.rowheight}
        getRowClassName={props.getRowClassName}
        onRowSelectionModelChange={props.onSelectionChange}
        // onPaginationModelChange={props.onPaginationModelChange}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        // paginationModel={paginationModel}
        // onPaginationModelChange={handlePaginationChange}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
      />
    </div>
  );
}

export default CustomDataTable;
