import { Box, FormGroup, Grid, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import moment from "moment";
import * as Yup from "yup";
import AttachmentDropzone from "../dropzone/AttachmentDropzone";
import {
  GetStudentChatByIdAction,
  GetStudentReminderByIdAction,
} from "../../Redux/action/ApplicationAction";
import {
  AddReminderService,
  AddStudentChatService,
} from "../../Redux/service/Application";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyDatePicker from "../datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import OutlinedTextAreaField from "../inputfiled/OutlinedTextedArea";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { StudentAllReminderModal } from "./AllReminderModal.component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

const validationReminderSchema = Yup.object({
  text: Yup.string().required("Reminder Text is required."),
  reminderDateTime: Yup.date()
    .required("Reminder date is required")
    .test(
      "is-greater",
      "Reminder date and time must be after the current date time.",
      function (value) {
        const { reminderDateTime } = this.parent;
        return dayjs(value).isAfter(dayjs(), "millisecond");
      }
    ),
  resendDateTime: Yup.date()
    .required("Resend date is required")
    .test(
      "is-greater",
      "Resend date must be after the reminder date",
      function (value) {
        const { reminderDateTime } = this.parent;

        if (!reminderDateTime || !value) {
          return true;
        }

        const reminderDate = dayjs(reminderDateTime);
        const resendDate = dayjs(value);

        // Check if both reminder and resend dates are on the same day
        const isSameDay = reminderDate.isSame(resendDate, "day");

        if (isSameDay) {
          // If the dates are the same, check if resend time is after the reminder time
          return resendDate.isAfter(reminderDate, "minute");
        }

        // If the dates are different, check that resendDateTime is after reminderDateTime
        return resendDate.isAfter(reminderDate, "millisecond");
      }
    ),
});

export const StudentReminderModal = ({
  ReminderModal,
  handleClose,
  stdId,
  Studentlist,
}) => {
  const dispatch = useDispatch();
  const [AllReminderModal, setAllReminderModal] = useState(false);

  const handleCloseAllReminderModal = () =>
    setAllReminderModal(!AllReminderModal);

  return (
    <>
      <Modal
        open={ReminderModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography align="right" onClick={() => handleClose()}>
            <Crossicon width={"20"} height={"20"} />
          </Typography>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.BLACK}
            align="center"
          >
            New Reminder for "
            {
              Studentlist?.filter((data) => data.id === stdId)?.[0]
                ?.studentFirstName
            }
            "
          </Typography>
          <Formik
            initialValues={{
              text: "",
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              values["student"] = stdId;
              //("Form data:", values);

              await AddReminderService(values)
                .then((res) => {
                  if (res.status === 200) {
                    // //(res);
                    // dispatch(GetStudentNotesByIdAction(stdId));
                    handleClose();
                    setAllReminderModal(true);

                    dispatch(GetStudentReminderByIdAction(stdId));

                    dispatch(showSnackbar(res.data.message, "success"));
                    // navigate("/CAE/CatalogueBrochureList");
                    resetForm();
                  }
                })
                .catch((e) => {
                  dispatch(showSnackbar(e.response.data.message, "error"));
                });
            }}
            validationSchema={validationReminderSchema}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="d-flex justify-content-center">
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <OutlinedTextAreaField
                          multiline={true}
                          minRows={4}
                          value={values.text}
                          onChange={handleChange}
                          name={"text"}
                          placeholder="Reminder Message"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.text}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>
                  <Grid item lg={8} md={8} xs={12} sm={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <MyDatePicker
                          minDate={dayjs().startOf("day")}
                          label="Reminder Date & Time"
                          // value={dayjs(values.contactPerson[index].reminder_date).toDate()}
                          onChange={(date) => {
                            setFieldValue(
                              `reminderDateTime`,
                              dayjs(date).format("YYYY-MM-DD HH:MM")
                            );
                          }}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.reminderDateTime}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>
                  <Grid item lg={8} md={8} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <MyDatePicker
                          // ref={datetimepickerRef}
                          minDate={dayjs().startOf("day")}
                          label="Enter Resend date time"
                          // value={dayjs(values.contactPerson[index].reminder_date).toDate()}

                          // value={
                          //   values.reminders[index]?.resendDateTime
                          //     ? dayjs(
                          //         values.reminders[index]?.resendDateTime
                          //       ).isValid()
                          //       ? dayjs(values.rresendDateTime)
                          //       : null
                          //     : null
                          // }
                          onChange={(date) => {
                            setFieldValue(
                              `resendDateTime`,
                              dayjs(date).format("YYYY-MM-DD HH:MM")
                            );
                          }}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.resendDateTime}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>
                  <Grid item lg={4} md={4} xs={12} sm={12}>
                    <div className="d-flex justify-content-center w-100 mt-2">
                      <CustomButton
                        variant="contained"
                        type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        // loading={formLoading}
                        //   padding={"0px"}
                        // onClick={handleClick}
                      >
                        Set Reminder
                      </CustomButton>
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <div className="w-100">
                      <Typography
                        className="w-100"
                        align="center"
                        color={ThemeColor.BLUE}
                        fontSize={fontSize.h4.large}
                        fontWeight={fontWeight.W700}
                        sx={{ textDecoration: "underline" }}
                        onClick={() => {
                          setAllReminderModal(true);
                          handleClose();
                        }}
                      >
                        Show All Reminder
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>

      <StudentAllReminderModal
        AllReminderModal={AllReminderModal}
        handleClose={handleCloseAllReminderModal}
        dispatch={dispatch}
        Studentlist={Studentlist}
        stdId={stdId}
        handleCloseReminderModal={handleClose}
      />
    </>
  );
};
