import { GET_ALL_SUPPORT_TICKET } from "../constant/SupportConstant";
import { getAllSupport } from "../service/SupportService";

export const getAllSupportTicket = () => async (dispatch) => {
    try {
        const res = await getAllSupport();
        dispatch({ type: GET_ALL_SUPPORT_TICKET, payload: res.data.tickets.map((state) => ({ ...state, id: state._id })) })
    } catch {
        //("hey")
    }
}