import React from "react";

function Logout() {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.1 0.699951H3.1C1.9 0.699951 1 1.59995 1 2.79995V13.1C1 14.3 1.9 15.2 3.1 15.2H8.1"
          stroke="white"
          stroke-width="1.3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.2998 5L15.2998 8.04762L12.2998 11"
          stroke="white"
          stroke-width="1.3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.2998 8H7.2998"
          stroke="white"
          stroke-width="1.3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Logout;
