import React from "react";

function Settinglist() {
  return (
    <div>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.1401 20.9399C27.1801 20.6399 27.2001 20.3299 27.2001 19.9999C27.2001 19.6799 27.1801 19.3599 27.1301 19.0599L29.1601 17.4799C29.3401 17.3399 29.3901 17.0699 29.2801 16.8699L27.3601 13.5499C27.2401 13.3299 26.9901 13.2599 26.7701 13.3299L24.3801 14.2899C23.8801 13.9099 23.3501 13.5899 22.7601 13.3499L22.4001 10.8099C22.3601 10.5699 22.1601 10.3999 21.9201 10.3999H18.0801C17.8401 10.3999 17.6501 10.5699 17.6101 10.8099L17.2501 13.3499C16.6601 13.5899 16.1201 13.9199 15.6301 14.2899L13.2401 13.3299C13.0201 13.2499 12.7701 13.3299 12.6501 13.5499L10.7401 16.8699C10.6201 17.0799 10.6601 17.3399 10.8601 17.4799L12.8901 19.0599C12.8401 19.3599 12.8001 19.6899 12.8001 19.9999C12.8001 20.3099 12.8201 20.6399 12.8701 20.9399L10.8401 22.5199C10.6601 22.6599 10.6101 22.9299 10.7201 23.1299L12.6401 26.4499C12.7601 26.6699 13.0101 26.7399 13.2301 26.6699L15.6201 25.7099C16.1201 26.0899 16.6501 26.4099 17.2401 26.6499L17.6001 29.1899C17.6501 29.4299 17.8401 29.5999 18.0801 29.5999H21.9201C22.1601 29.5999 22.3601 29.4299 22.3901 29.1899L22.7501 26.6499C23.3401 26.4099 23.8801 26.0899 24.3701 25.7099L26.7601 26.6699C26.9801 26.7499 27.2301 26.6699 27.3501 26.4499L29.2701 23.1299C29.3901 22.9099 29.3401 22.6599 29.1501 22.5199L27.1401 20.9399ZM20.0001 23.5999C18.0201 23.5999 16.4001 21.9799 16.4001 19.9999C16.4001 18.0199 18.0201 16.3999 20.0001 16.3999C21.9801 16.3999 23.6001 18.0199 23.6001 19.9999C23.6001 21.9799 21.9801 23.5999 20.0001 23.5999Z"
          fill="black"
          fill-opacity="0.56"
        />
      </svg>
    </div>
  );
}

export default Settinglist;
