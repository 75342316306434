import React from "react";

function Message() {
  return (
    <div>
      <svg
        width="31"
        height="24"
        viewBox="0 0 31 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.9 0H3.1C1.395 0 0 1.35 0 3V21C0 22.65 1.395 24 3.1 24H27.9C29.605 24 31 22.65 31 21V3C31 1.35 29.605 0 27.9 0ZM27.28 6.375L17.143 12.51C16.1355 13.125 14.8645 13.125 13.857 12.51L3.72 6.375C3.56458 6.29057 3.42847 6.17649 3.31993 6.03968C3.21138 5.90287 3.13265 5.74617 3.08849 5.57906C3.04434 5.41195 3.03568 5.2379 3.06304 5.06746C3.09041 4.89702 3.15322 4.73372 3.24769 4.58745C3.34215 4.44118 3.4663 4.31498 3.61263 4.21649C3.75895 4.118 3.9244 4.04927 4.09897 4.01445C4.27354 3.97963 4.45359 3.97945 4.62823 4.01392C4.80287 4.04839 4.96847 4.1168 5.115 4.215L15.5 10.5L25.885 4.215C26.0315 4.1168 26.1971 4.04839 26.3718 4.01392C26.5464 3.97945 26.7265 3.97963 26.901 4.01445C27.0756 4.04927 27.2411 4.118 27.3874 4.21649C27.5337 4.31498 27.6578 4.44118 27.7523 4.58745C27.8468 4.73372 27.9096 4.89702 27.937 5.06746C27.9643 5.2379 27.9557 5.41195 27.9115 5.57906C27.8674 5.74617 27.7886 5.90287 27.6801 6.03968C27.5715 6.17649 27.4354 6.29057 27.28 6.375Z"
          fill="#6A4BFF"
        />
      </svg>
    </div>
  );
}

export default Message;
