import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../dropzone/dropzone.css";
import Dropdown from "../../Icon/Dropdown";
import { Box, FormHelperText, Typography } from "@mui/material";
import { FontFamily } from "../theme/fontFamily";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { ThemeColor } from "../theme/color";
import Delete from "../../Icon/Delete";
import { UploadFileService } from "../../Redux/service/FileService";
import Attachment from "../../Icon/Attachment";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  //   marginTop: 16
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid black",
  //   marginTop: 10,
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const AttachmentDropzone=forwardRef((props,ref)=>{
  const [files, setFiles] = useState([]);
  var docsFile = [];

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(acceptedFiles); // Update local state with accepted files
    //console.log(acceptedFiles);

    const docsFile = [];
    try {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      let response = await UploadFileService(formData);

      if (response.status === 200) {
        docsFile.push(response.data.result);
      }
    } catch (err) {
      console.error("uploadPhotoPost : error ", err);
    }

    const updatedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setFiles(updatedFiles); // Store previewed files in state
    props.handleMydropzoneFile(docsFile, updatedFiles);

    // Clear the files after processing (reset acceptedFiles)
    setFiles([]);
  }, [props.handleMydropzoneFile]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  const handleRemove = () => {
    const newFiles = [];
    // newFiles.splice(index, 1);
    setFiles([]);
    props.handleMydropzoneFile(newFiles);
  };

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <button onClick={() => handleRemove(index)}>Remove</button>
      </div>
    </div>
  ));

  const resetDropzone = () => {
    //console.log("ononojjoj")
    docsFile=[]
    setFiles([])
    // props?.handleMydropzoneFile([], []); // This will clear the files in Dropzone
  };

  useImperativeHandle(ref, () => ({
    resetDropzone
  }));

  useEffect(() => {
    //(files, "nonnoon");
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="my-dropzone-container1">
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ display: "flex" }}
      >
        <input {...getInputProps()} />
        <div className="mt-2">
          <Attachment />
          <FormHelperText>{props.helperText}</FormHelperText>
          <Box
            className="w-100"
            sx={
              {
                // height: files.length > 0 ? 200 : 10,
                // overflowY: "scroll",
                // overflowX: "auto",
              }
            }
            // style={{ height: "200px", overflowY: "scroll" }}
          >
            {/* <ul>
              {files?.map((file, index) => (
                <li key={index} className="d-flex">
                  {file.name}
                  <div className="px-1">
                    {" "}
                    <button
                      onClick={() => handleRemove(index)}
                      className="py-1 px-2"
                      style={{
                        background: ThemeColor.RED,
                        borderColor: ThemeColor.RED,
                        borderRadius: "50%",
                        boxShadow: "none",
                      }}
                    >
                      <Delete />
                    </button>
                  </div>
                </li>
              ))}
            </ul> */}
          </Box>
        </div>
      </div>
      <div></div>

      {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
      {/* <div className="h-100 w-100">
        <ul>
          {files.map((file, index) => (
            <li key={index} className="d-flex align-items-center">
              {file.name} - {file.size} bytes
              <div className="px-2">
                <button
                  onClick={() => handleRemove(index)}
                  className=""
                  style={{
                    background: ThemeColor.RED,
                    borderColor: ThemeColor.RED,
                    borderRadius: "50%",
                    boxShadow: "none",
                  }}
                >
                  <Delete />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div> */}
    </section>
  );
})

export default AttachmentDropzone;
