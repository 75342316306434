import React from "react";

function LeftSideArrow() {
  return (
    <div>
      <svg
        width="11"
        height="22"
        viewBox="0 0 11 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.0642 11.0001L9.54595 17.4818L8.24978 18.778L1.11995 11.6482C0.948102 11.4763 0.851562 11.2432 0.851562 11.0001C0.851562 10.757 0.948102 10.5239 1.11995 10.352L8.24978 3.22217L9.54595 4.51833L3.0642 11.0001Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default LeftSideArrow;
