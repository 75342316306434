import React from "react";

function Attachment() {
  return (
    <div>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_255_5752)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4894 18.2971C20.3195 17.4669 20.7859 16.3409 20.7859 15.1669C20.7859 13.9928 20.3195 12.8669 19.4894 12.0367L12.6769 5.22417C12.4815 5.02885 12.3716 4.76388 12.3715 4.48756C12.3714 4.21124 12.4811 3.94619 12.6764 3.75073C12.8717 3.55527 13.1367 3.44541 13.413 3.44531C13.6893 3.44521 13.9544 3.55489 14.1498 3.75021L20.9623 10.5627C22.1834 11.7835 22.8695 13.4394 22.8697 15.1661C22.8699 16.8928 22.1842 18.5489 20.9634 19.77C19.7425 20.9911 18.0866 21.6772 16.3599 21.6774C14.6332 21.6776 12.9772 20.9919 11.7561 19.771L3.47066 11.4846C2.59144 10.6052 2.09756 9.41262 2.09766 8.16911C2.09775 6.92561 2.59183 5.73308 3.47119 4.85386C4.35054 3.97464 5.54316 3.48075 6.78666 3.48085C8.03016 3.48095 9.22269 3.97502 10.1019 4.85438L18.3863 13.1388C18.9109 13.679 19.2018 14.404 19.1962 15.157C19.1906 15.91 18.8889 16.6306 18.3564 17.163C17.8238 17.6954 17.1032 17.9969 16.3502 18.0023C15.5972 18.0077 14.8723 17.7166 14.3321 17.1919L6.78421 9.64292C6.58875 9.4476 6.47888 9.18263 6.47879 8.90631C6.47869 8.62999 6.58836 8.36494 6.78369 8.16948C6.97901 7.97402 7.24397 7.86416 7.5203 7.86406C7.79662 7.86396 8.06166 7.97364 8.25712 8.16896L15.8061 15.7179C15.9528 15.8648 16.1518 15.9473 16.3594 15.9474C16.5669 15.9475 16.766 15.8651 16.9129 15.7184C17.0597 15.5717 17.1422 15.3727 17.1423 15.1652C17.1424 14.9576 17.0601 14.7585 16.9134 14.6117L8.629 6.32729C8.38715 6.08531 8.1 5.89332 7.78396 5.76231C7.46791 5.63129 7.12915 5.56381 6.78703 5.56371C6.4449 5.56362 6.10611 5.63091 5.78999 5.76174C5.47387 5.89258 5.18661 6.0844 4.94462 6.32625C4.45591 6.81469 4.18124 7.47727 4.18104 8.16822C4.18085 8.85918 4.45514 9.52191 4.94358 10.0106L13.229 18.296C14.0592 19.1262 15.1852 19.5925 16.3592 19.5925C17.5332 19.5925 18.6592 19.1262 19.4894 18.296V18.2971Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_255_5752">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default Attachment;
