// snackbarActions.js

import {
  HIDE_SNACKBARConst,
  SHOW_SNACKBARConst,
} from "../constant/SnackbarConstant";

export const showSnackbar = (message, severity) => async (dispatch) => {

  const data = {
    message: message,
    severity: severity,
  };
  dispatch({ type: SHOW_SNACKBARConst, payload: data });
};

export const hideSnackbar = (message, severity) => (dispatch) => {
  dispatch({ type: HIDE_SNACKBARConst });
};
