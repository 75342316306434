import {
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../select field/OutlinedSelectField";
import {
  AddPaymentDetailsAfterVisaApprovedService,
  UpdateStudentTransactionService,
  bulkUpdateTransactionReceivedFromUniversityNotesAPI,
  calcualteAgentCommissionService,
  getDefaultCommissionDetailsService,
  postFilterStdApproveorNotAPI,
} from "../../Redux/service/Application";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import OnlyDatePicker from "../datepicker/MyDatepicker";
import {
  GetAgentTransactionListAction,
  GetStudentAction,
  GetUnsettledListAction,
} from "../../Redux/action/ApplicationAction";
import Previews from "../dropzone/Mydropzone";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import {
  validationCommisionSchema,
  validationTransactionSchema,
  validationUniversityAmountSchema,
} from "./ValidationConstant";
import OutlinedTextAreaField from "../inputfiled/OutlinedTextedArea";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "45vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const FilterExcelUniversity = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [VisaStatus, setVisaStatus] = useState("");
  const [commResdata, setCommResdata] = useState(null);

  const formikRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [jsonData, setJsonData] = useState([]);

  const handleExportForNotApproved = async (notFoundData) => {
    console.log(notFoundData);

    const columns = [
      { header: "First Name", key: "studentFirstName" },
      { header: "Last Name", key: "studentLastName" },
      { header: "Date of Birth", key: "dateOfBirth" },
      { header: "Student ID", key: "universityId" },
      { header: "Program", key: "program.program" },
      { header: "Degree", key: "degreeType.name" },
      {
        header: "Semester",
        key: "intake",
      },
      {
        header: "Payment",
        key: "currentTransactionNumber",
      },
      { header: "University Amount", key: "receivedFromUniversity" },
      // { header: "Eligible", key:"data.commissionStatus" },
      // { header: "Reason", key: "data.commissionStatusNotes" },
    ];

    const transformedData = notFoundData.map((item) => {
      const row = {};
      columns.forEach((col) => {
        row[col.header] =
          col.key.split(".").reduce((o, k) => (o || {})[k], item) || ""; // Handle nested keys
      });
      return row;
    });

    // Create a worksheet for the first dataset
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Apply background styling to headers
    const headers = columns.map((col) => col.header);
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // First row (0), column index
      if (!worksheet[cellAddress]) worksheet[cellAddress] = {}; // Ensure the cell exists
      worksheet[cellAddress].s = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFCCFFCC" }, // Light green background color
        },
        font: {
          bold: true,
          color: { rgb: "FF000000" }, // Black text
        },
      };
    });

    // Create a workbook and append the sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CombinedData");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(dataBlob, `NotFoundTransaction.xlsx`);
  };

  const handleExportForApproved = async (approved) => {
    let studentApproved = approved.map((data) => ({
      studentId: data._id,
      currentTransactionNumber: data.requestFilter.currentTransactionNumber,
      receivedFromUniversity: data.requestFilter.receivedFromUniversity,
      bonusFromUniversity: data.requestFilter?.bonusFromUniversity
        ? data.requestFilter?.bonusFromUniversity
        : "",
      notes: data.requestFilter?.notes ? data.requestFilter?.notes : "",
      commissionStatus: data.requestFilter?.commissionStatus,
      commissionStatusNotes: data.requestFilter?.commissionStatusNotes,
    }));

    console.log(studentApproved);

    await bulkUpdateTransactionReceivedFromUniversityNotesAPI(studentApproved)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          dispatch(showSnackbar(res.data.message, "success"));

          dispatch(GetUnsettledListAction(false));

          onClose();

          // if (res.data?.students !== null) {
          //   handleExportForApproved(res.data?.students);
          // }

          // if (res.data?.notFoundData !== null) {
          //   handleExportForNotApproved(res.data?.notFoundData);
          // }
          // resetForm();
        }
      })
      .catch((e) => {
        dispatch(showSnackbar(e.response.data.message, "error"));
      });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      const formattedData = formatKeys(parsedData);
      console.log(formattedData);
      formikRef.current.setFieldValue("data", formattedData);
      setJsonData(formattedData);
    };
    reader.readAsBinaryString(file);
  };

  // Map of old keys to new keys
  const keyMapping = {
    "First Name": "studentFirstName",
    "Last Name": "studentLastName",
    Semester: "intake",
    degree: "degreeType",
    Payment: "currentTransactionNumber",
    "University Amount": "receivedFromUniversity",
    "Date of Birth": "dateOfBirth",
    Bonus: "bonusFromUniversity",
    "Student ID": "universityId",
    Program: "program",
    Degree: "degreeType",
    "Commission Status Notes": "commissionStatusNotes",
    "Commission Status": "commissionStatus",
    Notes: "notes",
  };

  // const formatKeys = (data) => {
  //   return data.map((row) => {
  //     const formattedRow = {};
  //     for (const key in row) {
  //       // Format key dynamically
  //       const formattedKey = key
  //         .replace(/\s+/g, "") // Remove spaces
  //         .replace(/^[A-Z]/, (c) => c.toLowerCase()); // Lowercase the first letter

  //       formattedRow[formattedKey] = row[key];
  //     }
  //     return formattedRow;
  //   });
  // };

  // Function to format keys in data
  const formatKeys = (data) => {
    return data.map((row) => {
      const formattedRow = {};
      for (const key in row) {
        const newKey = keyMapping[key] || key; // Replace key if it exists in keyMapping
        formattedRow[newKey] = row[key];
      }
      return formattedRow;
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        console.log("hanlde close");
        setCommResdata(null);
        setLoading(true);
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          align="right"
          onClick={() => {
            console.log("hanlde close");
            setCommResdata(null);
            setLoading(true);

            onClose();
          }}
        >
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Import University Transaction
        </Typography>
        <Formik
          innerRef={formikRef}
          initialValues={{ data: jsonData }}
          // validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            //("Form data:", values);
            console.log(values);

            await postFilterStdApproveorNotAPI(values)
              .then((res) => {
                if (res.status === 200) {
                  console.log(res);
                  // onClose();

                  if (res.data?.students !== null) {
                    handleExportForApproved(res.data?.students);
                  }

                  if (res.data?.notFoundData !== null) {
                    handleExportForNotApproved(res.data?.notFoundData);
                  }
                  // dispatch(showSnackbar(res.data.message, "success"));
                  resetForm();
                }
              })
              .catch((e) => {
                dispatch(showSnackbar(e.response.data.message, "error"));
              });
          }}
          validationSchema={Yup.object().shape({
            data: Yup.array().required("The data array is required."),
          })}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="mt-4"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="w-100 d-flex flex-column justify-content-center align-item-center">
                        {/* <Previews
                          ref={childRef}
                          initialValue={values?.documents}
                          handleMydropzoneFile={(updatedFiles) => {
                            //(updatedFiles);
                            setFieldValue("documents", updatedFiles);
                          }}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.documents}
                            </Typography>
                          }
                        /> */}
                        <input
                          style={{
                            padding: "15px",
                            border: "2px dotted black",
                            borderRadius: "5px",
                            backgroundColor: "#f9f9f9",
                            color: "#333",
                            cursor: "pointer",
                          }}
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
                        />
                        <div>
                          <Typography
                            align="left"
                            // className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors && errors?.data}
                          </Typography>
                        </div>
                      </div>
                    </FormGroup>
                  </Grid>

                  {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="w-100">
                        <Typography
                          className="mb-1"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Select Field
                        </Typography>
                        <CustomSelect
                          disabled={true}
                          value={values.fileds}
                          name={`fields`}
                          multiple={true}
                          // option={Programlist}
                          onChange={(e) => {
                            setFieldValue(`fields`, e.target.value);
                          }}
                          label={"Select FIleds"}
                          fullWidth={true}
                        />
                      </div>
                    </FormGroup>
                  </Grid> */}

                  <Grid item lg={12} xs={12}>
                    <Box
                      sx={{
                        flexDirection: {
                          lg: "row",
                          md: "row",
                          sm: "column",
                          xs: "column",
                        },
                      }}
                      className="d-flex justify-content-center align-items-center w-100 mt-2 mb-3"
                    >
                      {" "}
                      <CustomButton
                        variant="contained"
                        type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                      >
                        Save
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
