import { Box, Button, FormGroup, Grid, Modal, TextField } from "@mui/material";

import { Form, Formik, useFormik } from "formik"
import CustomButton from "../button/button";
import { ThemeColor } from "../theme/color";
import { Typography } from "antd";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import { fontSize } from "../theme/fontSize";
import { FontFamily } from "../theme/fontFamily";
import { fontWeight } from "../theme/fontWeight";
import React from "react";
import OutlinedTextAreaField from "../inputfiled/OutlinedTextedArea";
import { saveSupportTicket } from "../../Redux/service/SupportService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch } from "react-redux";
import { getAllSupportTicket } from "../../Redux/action/SupportAction";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
    height: "60vh",
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid grey",
    boxShadow: 24,
    p: 4,
};

export const SupportPopup = ({ open, handleClose }) => {

    const dispatch = useDispatch();

    const handleSubmit = async (values) => {
        try {
            const response = await saveSupportTicket(values);
            if (response.status == 200) {
                dispatch(showSnackbar(response.data.message, "success"));
                handleClose();
            }
        } catch (err) {
            dispatch(showSnackbar(err.response.data.message, "error"));
        }
        dispatch(getAllSupportTicket())

    }

    return <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={style}>
            <Grid container>
                <Grid item xs={12}>

                    <Formik initialValues={{ subject: "", message: "" }} onSubmit={handleSubmit}>
                        {({
                            values,
                            errors,
                            handleBlur,
                            handleChange,
                            touched,
                            handleSubmit,
                            setFieldValue,
                            resetForm,
                        }) => (
                            <Form className="" onSubmit={handleSubmit}>

                                <FormGroup style={{}}>
                                    <div className="">
                                        <Typography
                                            className="mb-2"
                                            fontFamily={FontFamily.Lato}
                                            fontSize={fontSize.h3.medium}
                                            fontWeight={fontWeight.W400}
                                        >
                                            Add your subject
                                        </Typography>
                                        <OutlinedInputField
                                            value={values.subject}
                                            // disable={subject.length == 100}
                                            name={`subject`}
                                            onChange={(e) => { values.subject.length <= 100 ? setFieldValue("subject", e.target.value) : setFieldValue("subject", e.target.value.substring(0, 100)) }}
                                            placeholder="Enter your concern"
                                            fullWidth={true}
                                            helperText={
                                                <Typography
                                                    align="left"
                                                    // className="px-3"
                                                    color={ThemeColor.RED}
                                                    fontSize={fontSize.h4.medium}
                                                    fontFamily={FontFamily.Lato}
                                                    fontWeight={fontWeight.W500}
                                                >
                                                    {errors.subject}
                                                </Typography>
                                            }
                                        />
                                        <Typography
                                            className="mb-2"
                                            align="right"
                                        >
                                            {values.subject.length}/{"100"}
                                        </Typography>
                                    </div>
                                </FormGroup>
                                <FormGroup style={{}}>
                                    <div className="">
                                        <Typography
                                            className="mb-2"
                                            fontFamily={FontFamily.Lato}
                                            fontSize={fontSize.h3.medium}
                                            fontWeight={fontWeight.W400}
                                        >
                                            Explain your Issue
                                        </Typography>
                                        <OutlinedTextAreaField
                                            multiline={true}
                                            minRows={6}
                                            value={values.message}
                                            onChange={handleChange}
                                            name={"message"}
                                            placeholder="Explain in detail"
                                            fullWidth={true}
                                            helperText={
                                                <Typography
                                                    align="left"
                                                    // className="px-3"
                                                    color={ThemeColor.RED}
                                                    fontSize={fontSize.h4.medium}
                                                    fontFamily={FontFamily.Lato}
                                                    fontWeight={fontWeight.W500}
                                                >
                                                    {errors.message}
                                                </Typography>
                                            }
                                        />

                                    </div>
                                </FormGroup>
                                <Box justifyContent={"center"} alignSelf={"center"} display={"flex"} flex={"1"}>
                                    <CustomButton
                                        variant="contained"
                                        type="submit"
                                        backgroundColor={ThemeColor.PURPLE}
                                        color={ThemeColor.WHITE}
                                    // loading={formLoading}
                                    >Create Ticket</CustomButton>
                                </Box>

                            </Form>
                        )}


                    </Formik>
                </Grid>

            </Grid>
        </Box>


    </Modal >
}