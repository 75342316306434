import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import { MenuItem, Select, Checkbox, ListItemText } from "@mui/material";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import OnlyDatePicker from "../../component/datepicker/MyDatepicker";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetProgramAction,
  GetRoleAction,
  GetUniversityAction,
} from "../../Redux/action/SettingAction";
import {
  CreateOrganizationService,
  EditOrganizationService,
} from "../../Redux/service/SettingService";
import moment from "moment";
import { orgvalidationSchema } from "./ValidationConstant";
import { GetDegreeAction } from "../../Redux/action/ApplicationAction";

const icon = createSvgIcon(
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 4.5L6 7.5L3 4.5"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const CreateOrganizationPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const childRef = useRef(null);
  const datetimepickerRef = useRef(null);

  const { id } = useParams();

  const [Edit, setEdit] = useState(id ? true : false);

  const [prefieldData, setPrefieldData] = useState();

  const [reminders, setReminders] = useState([]);

  const [ownerPerson, setOwnerPerson] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [TaxDetailsOption, setTaxDetailsOption] = useState(false);
  const [Universitylist, setUniversitylist] = useState([]);

  const [formLoading, setFormLoading] = useState(false);

  const [Rolelist, setRolelist] = useState([]);

  const [univEditdetail, setUnivEditdetail] = useState([]);

  const [Programlist, setProgramlist] = useState([]);

  const [Degreelist, setDegreelist] = useState([]);

  const DegreelistState = useSelector(
    (state) => state.application?.degree?.data?.degrees
  );

  useEffect(() => {
    dispatch(GetDegreeAction());
  }, []);

  useEffect(() => {
    setDegreelist(
      DegreelistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );
  }, [DegreelistState]);

  const EditOrganzitiondataState = useSelector(
    (state) => state.setting?.organization?.data?.organizations
  );

  useEffect(() => {
    dispatch(GetUniversityAction());
  }, []);

  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );

  useEffect(() => {
    dispatch(GetRoleAction());
    dispatch(GetProgramAction());
  }, []);

  const RolelistState = useSelector(
    (state) => state.setting?.role?.data?.roles
  );

  useEffect(() => {
    setRolelist(RolelistState);
  }, [RolelistState]);

  const ProgramlistState = useSelector(
    (state) => state.setting?.program?.data?.programs
  );

  useEffect(() => {
    const updatedData = ProgramlistState?.map(({ program, ...rest }) => ({
      title: program,
      ...rest,
    }));
    setProgramlist(updatedData);
  }, [ProgramlistState]);

  useEffect(() => {
    setPrefieldData(
      EditOrganzitiondataState?.filter((data) => data._id === id)?.[0]
    );
    setTaxDetailsOption(
      EditOrganzitiondataState?.filter((data) => data._id === id)?.[0]
        ?.textDetails
    );
    setReminders(
      EditOrganzitiondataState?.filter(
        (data) => data._id === id
      )?.[0]?.reminders?.map((data) => {
        let json = {
          _id: data._id,
          text: data.text,
          reminderDateTime: moment.unix(data.reminderDateTime).format("llll"),
          resendDateTime: moment.unix(data.resendDateTime).format("llll"),
          isNew: false,
        };
        return json;
      })
    );
    setOwnerPerson(
      EditOrganzitiondataState?.filter(
        (data) => data._id === id
      )?.[0]?.owners?.map((data) => {
        let json = {
          ownerName: data.firstName,
          email: data.email,
          phone: data.phone,
          role: Edit ? data.role?._id : "",
        };
        return json;
      })
    );
    setUnivEditdetail(
      EditOrganzitiondataState?.filter(
        (data) => data._id === id
      )?.[0]?.universities?.map((data) => ({
        university: data?.university?._id,
        commissionAndPrograms: data.commissionAndPrograms.map((comm) => ({
          degree: comm.degree._id,
          program: comm.program.map((data) => {
            return data._id;
          }),
          commissionType: comm.commissionType,
          value: comm.value,
          duration: comm.duration,
          paymentCycle: comm.paymentCycle,
          termsAndCondition: comm.termsAndCondition,
        })),
      }))
    );
  }, [Edit, EditOrganzitiondataState, id]);

  const handleFormSubmit = async (values, formType) => {
    // //(values, formType, formikRef.current);
    setFormLoading(true);

    if (!Edit) {
      await CreateOrganizationService(values)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/OrganizationList");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          // formikRef.current.resetForm();
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    } else {
      //(values, "edit values");
      values["organizationId"] = id;
      await EditOrganizationService(values)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/OrganizationList");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          // formikRef.current.resetForm();
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    }
  };

  useEffect(() => {
    if (UniversitylistState) {
      //(UniversitylistState, "univ state");

      const updatedData = UniversitylistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }));
      setUniversitylist(updatedData);
    }
  }, [UniversitylistState]);

  if (!prefieldData && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Settings
            </Typography>
            <Typography
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
            >
              {!Edit ? "Create" : "Edit"} Organization
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          {!Edit && (
            <div className="d-flex justify-content-center w-100">
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                //   padding={"0px"}
                onClick={() =>
                  handleFormSubmit(formikRef.current?.values, "reset")
                }
                // onClick={handleClick}
              >
                Add Another Organization
              </CustomButton>
            </div>
          )}
        </Grid>
      </Grid>
      <div className="container">
        <Formik
          innerRef={formikRef}
          initialValues={{
            // organization: Edit ? selectedOrgs : [],
            // orgInputFields: Edit ? EditorgFields : {},
            organizationName: Edit ? prefieldData.organizationName : "",
            additionalOrganizationName: Edit
              ? prefieldData.additionalOrganizationName
              : "",
            textDetails: Edit ? prefieldData.textDetails : false,
            gstName: Edit ? prefieldData.gstName : "",
            gstNumber: Edit ? prefieldData.gstNumber : "",
            address: Edit ? prefieldData.address : "",
            notes: Edit ? prefieldData.notes?.[0].text : "",
            documents: Edit ? prefieldData.documents : [],
            startContractDate: Edit ? prefieldData.startContractDate : null,
            endContractDate: Edit ? prefieldData.endContractDate : null,
            reminders: Edit
              ? reminders
              : [
                  {
                    reminderDateTime: null,
                    text: "",
                    resendDateTime: null,
                    isNew: true,
                  },
                ],
            universities: Edit
              ? univEditdetail
              : [
                  {
                    university: "",
                    commissionAndPrograms: [
                      {
                        degree: "",
                        program: [],
                        commissionType: "",
                        value: "",
                        duration: "",
                        paymentCycle: "",
                        termsAndCondition: "",
                      },
                    ],
                  },
                ],
            owners: Edit
              ? ownerPerson
              : [
                  {
                    ownerName: "",
                    email: "",
                    phone: "",
                    role: "",
                  },
                ],
          }}
          validationSchema={orgvalidationSchema}
          onSubmit={(values) => {
            console.log(values, "payload of org");
            if (values?.reminders?.[0]?.reminderDateTime === null) {
              delete values["reminders"];
            }
            //(values);
            handleFormSubmit(values, "submit");
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form className="" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* grid for right basic content */}
                <Grid item lg={8}>
                  <Typography
                    align="center"
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    {!Edit ? "Create New" : "Edit"} Organization
                  </Typography>
                  <Grid container spacing={2} className="mt-4">
                    {/* <NoPaddingGridItem item lg={2} md={2} sm={12} xs={12}>
                      <div className="agent-logo">
                        <img
                          src="/assest/dropzonelogo.png"
                          className="w-100 h-100"
                          alt="agentlogo"
                        ></img>
                      </div>
                    </NoPaddingGridItem> */}
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Name of the organization *
                          </Typography>
                          <OutlinedInputField
                            disable={Edit ? true : false}
                            value={values.organizationName}
                            name={"organizationName"}
                            onChange={handleChange}
                            placeholder="Enter name of organization"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.organizationName}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Secondary Name of the organization
                          </Typography>
                          <OutlinedInputField
                            value={values.additionalOrganizationName}
                            name={"additionalOrganizationName"}
                            onChange={handleChange}
                            placeholder="Enter name of organization"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.additionalOrganizationName}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="mt-3">
                          <CustomSelect
                            value={values?.textDetails}
                            name={"textDetails"}
                            multiple={false}
                            option={[
                              { _id: true, title: "Yes" },
                              { _id: false, title: "No" },
                            ]}
                            onChange={(e) => {
                              setTaxDetailsOption(e.target.value);
                              setFieldValue("textDetails", e.target.value);
                            }}
                            label={"GST Registered"}
                            fullWidth={true}
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    {TaxDetailsOption === true && (
                      <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <Typography
                              className="mb-2"
                              fontFamily={FontFamily.Lato}
                              fontSize={fontSize.h3.medium}
                              fontWeight={fontWeight.W400}
                            >
                              GST Number
                            </Typography>
                            <OutlinedInputField
                              value={values.gstNumber}
                              onChange={handleChange}
                              name={"gstNumber"}
                              placeholder="Enter your details"
                              fullWidth={true}
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}
                    {TaxDetailsOption === true && (
                      <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <Typography
                              className="mb-2"
                              fontFamily={FontFamily.Lato}
                              fontSize={fontSize.h3.medium}
                              fontWeight={fontWeight.W400}
                            >
                              GST Name
                            </Typography>
                            <OutlinedInputField
                              value={values.gstName}
                              onChange={handleChange}
                              name={"gstName"}
                              placeholder="Enter your details...."
                              fullWidth={true}
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Organization Address
                          </Typography>
                          <OutlinedTextAreaField
                            multiline={true}
                            minRows={4}
                            value={values.address}
                            name={"address"}
                            onChange={handleChange}
                            placeholder="Address here"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.address}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FieldArray name="reminders">
                        {({ push, remove }) => (
                          <div>
                            {values.reminders?.map((reminder, index) => (
                              <Grid
                                container
                                spacing={2}
                                className="mt-4"
                                key={index}
                              >
                                {index > 0 && (
                                  <NoPaddingGridItem
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="d-flex align-items-center"
                                  >
                                    <div className="w-100 mt-2 mb-3">
                                      <Divider>
                                        <Chip
                                          label={`${
                                            index + 1
                                          } Reminder Details `}
                                          size="small"
                                        />
                                      </Divider>
                                    </div>
                                  </NoPaddingGridItem>
                                )}
                                <NoPaddingGridItem
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Reminder Date & Time
                                      </Typography>
                                      <MyDatePicker
                                        ref={datetimepickerRef}
                                        minDate={dayjs().startOf("day")}
                                        // minTime={dayjs().startOf("day")}
                                        label="Enter your details...."
                                        // value={dayjs(values.contactPerson[index].reminder_date).toDate()}

                                        value={
                                          values.reminders[index]
                                            ?.reminderDateTime
                                            ? dayjs(
                                                values.reminders[index]
                                                  ?.reminderDateTime
                                              ).isValid()
                                              ? dayjs(
                                                  values.reminders[index]
                                                    ?.reminderDateTime
                                                )
                                              : null
                                            : null
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            `reminders[${index}].reminderDateTime`,
                                            dayjs(date).format(
                                              "YYYY-MM-DD HH:MM"
                                            )
                                          );
                                        }}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.reminders?.[index]
                                                ?.reminderDateTime
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Resend Date & Time
                                      </Typography>
                                      <MyDatePicker
                                        ref={datetimepickerRef}
                                        minDate={dayjs().startOf("day")}
                                        label="Enter your details...."
                                        // value={dayjs(values.contactPerson[index].reminder_date).toDate()}

                                        value={
                                          values.reminders[index]
                                            ?.resendDateTime
                                            ? dayjs(
                                                values.reminders[index]
                                                  ?.resendDateTime
                                              ).isValid()
                                              ? dayjs(
                                                  values.reminders[index]
                                                    ?.resendDateTime
                                                )
                                              : null
                                            : null
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            `reminders[${index}].resendDateTime`,
                                            dayjs(date).format(
                                              "YYYY-MM-DD HH:MM"
                                            )
                                          );
                                        }}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.reminders?.[index]
                                                ?.resendDateTime
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Reminder Note
                                      </Typography>
                                      <OutlinedInputField
                                        value={values.reminders[index].text}
                                        name={`reminders[${index}].text`}
                                        onChange={handleChange}
                                        placeholder="Enter your details...."
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {errors.reminders?.[index]?.text}
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>
                                {index > 0 && (
                                  <NoPaddingGridItem
                                    item
                                    lg={3}
                                    md={3}
                                    sm={12}
                                    xs={12}
                                    className="d-flex align-items-center"
                                  >
                                    <div className="d-flex justify-content-start w-100 mt-3">
                                      <Typography
                                        className="px-2"
                                        fontSize={fontSize.h3.small}
                                        fontFamily={FontFamily.Lato}
                                        color={ThemeColor.BLACK}
                                        fontWeight={fontWeight.W600}
                                      >
                                        Delete Reminder
                                      </Typography>
                                      <IconButton
                                        className="form-icon"
                                        sx={{
                                          background: ThemeColor.RED,
                                          color: ThemeColor.WHITE,
                                        }}
                                        variant="contained"
                                        onClick={() => remove(index)}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </div>
                                  </NoPaddingGridItem>
                                )}
                              </Grid>
                            ))}
                            <div className="d-flex justify-content-start w-100 mt-2 mb-3">
                              <Typography
                                className="px-2"
                                fontSize={fontSize.h3.small}
                                fontFamily={FontFamily.Lato}
                                color={ThemeColor.BLACK}
                                fontWeight={fontWeight.W600}
                              >
                                Add More Reminder
                              </Typography>
                              <IconButton
                                className="form-icon"
                                sx={{
                                  background: ThemeColor.BLUE,
                                  color: ThemeColor.WHITE,
                                }}
                                onClick={() =>
                                  push({
                                    text: "",
                                    reminderDateTime: null,
                                    resendDateTime: null,
                                    isNew: true,
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </NoPaddingGridItem>
                  </Grid>
                </Grid>

                {/* grid for left docs uploading content */}
                <Grid item lg={4} xs={12}>
                  <div className="w-100 d-flex justify-content-center mt-4">
                    <Previews
                      ref={childRef}
                      initialValue={values?.documents}
                      handleMydropzoneFile={(updatedFiles) => {
                        // //(updatedFiles, "oooyesah");
                        setFieldValue("documents", updatedFiles);
                      }}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.documents}
                        </Typography>
                      }
                    />
                  </div>

                  <FormGroup style={{}}>
                    <div className="mt-4">
                      <OnlyDatePicker
                        views={["year", "month", "day"]}
                        label="Start Contract date"
                        // value={startDate}
                        value={
                          values.startContractDate
                            ? dayjs(values.startContractDate).isValid()
                              ? dayjs(values.startContractDate)
                              : null
                            : null
                        }
                        onChange={(date) => {
                          setStartDate(date);
                          if (endDate && date && endDate < date) {
                            setEndDate(null);
                            dispatch(
                              showSnackbar(
                                "End Date must be later than the Start Date. Please select a valid End Date.",
                                "error"
                              )
                            );
                          }
                          setFieldValue(
                            `startContractDate`,
                            dayjs(date).format("YYYY-MM-DD")
                          );
                        }}
                        helperText={
                          <Typography
                            align="left"
                            // className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.startContractDate}
                          </Typography>
                        }
                      />
                    </div>
                  </FormGroup>

                  <FormGroup style={{}}>
                    <div className="mt-4">
                      <OnlyDatePicker
                        views={["year", "month", "day"]}
                        label="End Contract date"
                        // value={endDate}
                        value={
                          values.endContractDate
                            ? dayjs(values.endContractDate).isValid()
                              ? dayjs(values.endContractDate)
                              : null
                            : null
                        }
                        // value={dayjs(values.contacts[index].reminder_date).toDate()}
                        minDate={startDate}
                        onChange={(date) => {
                          setEndDate(date);
                          setFieldValue(
                            `endContractDate`,
                            dayjs(date).format("YYYY-MM-DD")
                          );
                        }}
                        helperText={
                          <Typography
                            align="left"
                            // className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.endContractDate}
                          </Typography>
                        }
                      />
                    </div>
                  </FormGroup>

                  <FormGroup style={{}} className="mt-3">
                    <div className="">
                      <Typography
                        className=""
                        fontFamily={FontFamily.Lato}
                        fontSize={fontSize.h3.medium}
                        fontWeight={fontWeight.W400}
                      >
                        Notes
                      </Typography>
                      <OutlinedTextAreaField
                        multiline={true}
                        minRows={4}
                        value={values.notes}
                        name={"notes"}
                        onChange={handleChange}
                        placeholder="Enter Notes"
                        fullWidth={true}
                      />
                    </div>
                  </FormGroup>
                </Grid>

                {/* section for owner  */}
                <Grid item lg={12} sm={12} xs={12}>
                  <Typography
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    Owner Details
                  </Typography>
                  <FieldArray name="owners">
                    {({ push, remove }) => (
                      <div>
                        {values?.owners?.map((contact, index) => (
                          <Grid container spacing={2} className="mt-4">
                            {index > 0 && (
                              <NoPaddingGridItem
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="d-flex align-items-center"
                              >
                                <div className="w-100 mt-2 mb-3">
                                  <Divider>
                                    <Chip
                                      label={`${index + 1} Owner Details `}
                                      size="small"
                                    />
                                  </Divider>
                                </div>
                              </NoPaddingGridItem>
                            )}
                            <NoPaddingGridItem
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="">
                                  <Typography
                                    className="mb-2"
                                    fontFamily={FontFamily.Lato}
                                    fontSize={fontSize.h3.medium}
                                    fontWeight={fontWeight.W400}
                                  >
                                    Name of Owner (Admin) *
                                  </Typography>
                                  <OutlinedInputField
                                    value={values.owners[index].ownerName}
                                    name={`owners[${index}].ownerName`}
                                    onChange={handleChange}
                                    placeholder="Enter name"
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {errors.owners?.[index]?.ownerName}
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>
                            <NoPaddingGridItem
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="">
                                  <Typography
                                    className="mb-2"
                                    fontFamily={FontFamily.Lato}
                                    fontSize={fontSize.h3.medium}
                                    fontWeight={fontWeight.W400}
                                  >
                                    Email *
                                  </Typography>
                                  <OutlinedInputField
                                    value={values.owners[index].email}
                                    name={`owners[${index}].email`}
                                    onChange={handleChange}
                                    disable={Edit ? true : false}
                                    placeholder="Enter your email...."
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {errors.owners?.[index]?.email}
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>
                            <NoPaddingGridItem
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="">
                                  <Typography
                                    className="mb-2"
                                    fontFamily={FontFamily.Lato}
                                    fontSize={fontSize.h3.medium}
                                    fontWeight={fontWeight.W400}
                                  >
                                    Phone *
                                  </Typography>
                                  <OutlinedInputField
                                    value={values.owners[index].phone}
                                    name={`owners[${index}].phone`}
                                    onChange={handleChange}
                                    placeholder="Enter your phone...."
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {errors.owners?.[index]?.phone}
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>
                            <NoPaddingGridItem
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="mt-3">
                                  {/* <CustomSelect
                                    value={values?.owners[index]?.role}
                                    name={`owners[${index}].role`}
                                    option={Rolelist}
                                    onChange={(e) => {
                                      // //(e);
                                      setFieldValue(
                                        `owners[${index}].role`,
                                        e.target.value
                                      );
                                    }}
                                    label={"Role in organization"}
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {errors.owners?.[index]?.role}
                                      </Typography>
                                    }
                                  /> */}
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>
                            {index > 0 && (
                              <NoPaddingGridItem
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                                className="d-flex align-items-center"
                              >
                                <div className="d-flex justify-content-start w-100 mt-3">
                                  <Typography
                                    className="px-2"
                                    fontSize={fontSize.h3.small}
                                    fontFamily={FontFamily.Lato}
                                    color={ThemeColor.BLACK}
                                    fontWeight={fontWeight.W600}
                                  >
                                    Delete Owner
                                  </Typography>
                                  <IconButton
                                    className="form-icon"
                                    sx={{
                                      background: ThemeColor.RED,
                                      color: ThemeColor.WHITE,
                                    }}
                                    variant="contained"
                                    onClick={() => remove(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </div>
                              </NoPaddingGridItem>
                            )}
                          </Grid>
                        ))}
                        {!Edit && (
                          <div className="d-flex justify-content-end w-100 mt-3">
                            <Typography
                              className="px-2"
                              fontSize={fontSize.h3.small}
                              fontFamily={FontFamily.Lato}
                              color={ThemeColor.BLACK}
                              fontWeight={fontWeight.W600}
                            >
                              Add More Owner
                            </Typography>
                            <IconButton
                              className="form-icon"
                              sx={{
                                background: ThemeColor.BLUE,
                                color: ThemeColor.WHITE,
                              }}
                              onClick={() =>
                                push({
                                  ownerName: "",
                                  email: "",
                                  phone: "",
                                  role: "",
                                })
                              }
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    )}
                  </FieldArray>
                </Grid>

                {/* grid for bottom university list content */}
                {(window.sessionStorage.getItem("user_role_type") === "admin" ||
                  (window.sessionStorage.getItem("user_role_type") === "user" &&
                    window.sessionStorage.getItem("role") === "accountant") ||
                  (window.sessionStorage.getItem("user_role_type") ===
                    "agent" &&
                    window.sessionStorage.getItem("role") === "admin") ||
                  (window.sessionStorage.getItem("user_role_type") ===
                    "agent" &&
                    window.sessionStorage.getItem("role") ===
                      "agent_accountant")) && (
                  <Grid item lg={12} sm={12} xs={12}>
                    <Typography
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W600}
                      fontSize={fontSize.h3.medium}
                      color={ThemeColor.PURPLE}
                    >
                      University List
                    </Typography>
                    <FieldArray name="universities">
                      {({
                        push: pushUniversity,
                        remove: removeUniversity,
                        form,
                      }) => (
                        <div>
                          {values.universities.map((univ, univIndex) => (
                            <div
                              key={univIndex}
                              style={{ marginBottom: "20px" }}
                            >
                              <div>
                                <CustomSelect
                                  name={`universities[${univIndex}].university`}
                                  fullWidth
                                  value={
                                    Universitylist
                                      ? values.universities[univIndex]
                                          .university
                                      : ""
                                  }
                                  option={Universitylist}
                                  multiple={false}
                                  disabled={
                                    window.sessionStorage.getItem(
                                      "user_role_type"
                                    ) === "agent" &&
                                    window.sessionStorage.getItem("role") ===
                                      "admin"
                                  }
                                  onChange={(e) => {
                                    const selectedUniversity = e.target.value;
                                    setFieldValue(
                                      `universities[${univIndex}].university`,
                                      selectedUniversity
                                    );

                                    // Prefill the program names
                                    setFieldValue(
                                      `universities[${univIndex}].commissionAndPrograms`,
                                      Universitylist?.filter(
                                        (univ) =>
                                          univ._id === selectedUniversity
                                      )?.[0]?.commissionsAndPrograms?.map(
                                        (data) => ({
                                          program: data.program.map((data) => {
                                            return data._id;
                                          }),
                                          degree: data.degree._id,
                                          commissionType: data.commissionType,
                                          value: "80",
                                          duration: data.duration,
                                          paymentCycle: data.paymentCycle,
                                          termsAndCondition:
                                            data.termsAndConditionForOrg,
                                        })
                                      ) || []
                                    );
                                  }}
                                ></CustomSelect>
                              </div>

                              {/* Nested FieldArray for Commission Programs */}
                              <FieldArray
                                name={`universities[${univIndex}].commissionAndPrograms`}
                              >
                                {({
                                  push: pushCommission,
                                  remove: removeCommission,
                                }) => (
                                  <div style={{ marginTop: "10px" }}>
                                    {univ?.commissionAndPrograms?.map(
                                      (comm, commIndex) => (
                                        <Grid
                                          key={commIndex}
                                          container
                                          spacing={2}
                                        >
                                          {commIndex > 0 && (
                                            <NoPaddingGridItem
                                              item
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              className="d-flex align-items-center"
                                            >
                                              <div className="w-100 mt-2 mb-3">
                                                <Divider>
                                                  <Chip
                                                    label={`${
                                                      commIndex + 1
                                                    } Commison and program details `}
                                                    size="small"
                                                  />
                                                </Divider>
                                              </div>
                                            </NoPaddingGridItem>
                                          )}

                                          <NoPaddingGridItem
                                            item
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                          >
                                            <FormGroup style={{}}>
                                              <Typography
                                                className="mb-1"
                                                fontFamily={FontFamily.Lato}
                                                fontSize={fontSize.h3.medium}
                                                fontWeight={fontWeight.W400}
                                              >
                                                Degree Type
                                              </Typography>
                                              <div className="">
                                                <CustomSelect
                                                  disabled={true}
                                                  value={
                                                    Degreelist
                                                      ? values.universities[
                                                          univIndex
                                                        ].commissionAndPrograms[
                                                          commIndex
                                                        ]?.degree
                                                      : ""
                                                  }
                                                  name={`universities[${univIndex}].commissionAndPrograms[${commIndex}].degreeType`}
                                                  multiple={false}
                                                  option={Degreelist}
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `universities[${univIndex}].commissionAndPrograms[${commIndex}].degreeType`,
                                                      e.target.value
                                                    )
                                                  }
                                                  fullWidth={true}
                                                  label={"Degree"}
                                                  // helperText={
                                                  //   <Typography
                                                  //     align="left"
                                                  //     // className="px-3"
                                                  //     color={ThemeColor.RED}
                                                  //     fontSize={
                                                  //       fontSize.h4.medium
                                                  //     }
                                                  //     fontFamily={FontFamily.Lato}
                                                  //     fontWeight={fontWeight.W500}
                                                  //   >
                                                  //     {
                                                  //       errors.universities[univIndex]
                                                  //         .commissionAndPrograms?.[
                                                  //           commIndex
                                                  //       ]?.degreeType
                                                  //     }
                                                  //   </Typography>
                                                  // }
                                                />
                                              </div>
                                            </FormGroup>
                                          </NoPaddingGridItem>
                                          <NoPaddingGridItem
                                            item
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                          >
                                            <FormGroup style={{}}>
                                              <div className="w-100">
                                                <Typography
                                                  className="mb-1"
                                                  fontFamily={FontFamily.Lato}
                                                  fontSize={fontSize.h3.medium}
                                                  fontWeight={fontWeight.W400}
                                                >
                                                  Program
                                                </Typography>
                                                <CustomSelect
                                                  disabled={true}
                                                  value={
                                                    Programlist
                                                      ? values.universities[
                                                          univIndex
                                                        ].commissionAndPrograms[
                                                          commIndex
                                                        ]?.program
                                                      : []
                                                  }
                                                  name={`universities[${univIndex}].commissionAndPrograms[${commIndex}].program`}
                                                  multiple={true}
                                                  option={Programlist}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `universities[${univIndex}].commissionAndPrograms[${commIndex}].program`,
                                                      e.target.value
                                                    );
                                                  }}
                                                  label={"Program"}
                                                  fullWidth={true}
                                                />
                                              </div>
                                            </FormGroup>
                                          </NoPaddingGridItem>

                                          <NoPaddingGridItem
                                            item
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                          >
                                            <FormGroup style={{}}>
                                              <div className="">
                                                <Typography
                                                  className="mb-1"
                                                  fontFamily={FontFamily.Lato}
                                                  fontSize={fontSize.h3.medium}
                                                  fontWeight={fontWeight.W400}
                                                >
                                                  Commision Type
                                                </Typography>
                                                <CustomSelect
                                                  disabled={
                                                    window.sessionStorage.getItem(
                                                      "user_role_type"
                                                    ) === "agent" &&
                                                    window.sessionStorage.getItem(
                                                      "role"
                                                    ) === "admin"
                                                  }
                                                  value={
                                                    values.universities[
                                                      univIndex
                                                    ].commissionAndPrograms[
                                                      commIndex
                                                    ].commissionType
                                                  }
                                                  name={`universities[${univIndex}].commissionAndPrograms[${commIndex}].commissionType`}
                                                  multiple={false}
                                                  option={[
                                                    {
                                                      _id: "F",
                                                      title: "Fixed",
                                                    },
                                                    {
                                                      _id: "P",
                                                      title: "Percentage",
                                                    },
                                                  ]}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `universities[${univIndex}].commissionAndPrograms[${commIndex}].commissionType`,
                                                      e.target.value
                                                    );
                                                  }}
                                                  label={"Commission Type"}
                                                  fullWidth={true}
                                                />
                                              </div>
                                            </FormGroup>
                                          </NoPaddingGridItem>

                                          <NoPaddingGridItem
                                            item
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                          >
                                            <FormGroup style={{}}>
                                              <div className="">
                                                <Typography
                                                  className="mb-2"
                                                  fontFamily={FontFamily.Lato}
                                                  fontSize={fontSize.h3.medium}
                                                  fontWeight={fontWeight.W400}
                                                >
                                                  Value
                                                </Typography>
                                                <OutlinedInputField
                                                  disable={
                                                    window.sessionStorage.getItem(
                                                      "user_role_type"
                                                    ) === "agent" &&
                                                    window.sessionStorage.getItem(
                                                      "role"
                                                    ) === "admin"
                                                  }
                                                  value={
                                                    values.universities[
                                                      univIndex
                                                    ].commissionAndPrograms[
                                                      commIndex
                                                    ].value
                                                  }
                                                  name={`universities[${univIndex}].commissionAndPrograms[${commIndex}].value`}
                                                  onChange={handleChange}
                                                  placeholder="Enter your details...."
                                                  fullWidth={true}
                                                />
                                              </div>
                                            </FormGroup>
                                          </NoPaddingGridItem>

                                          <NoPaddingGridItem
                                            item
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                          >
                                            <FormGroup style={{}}>
                                              <div className="">
                                                <Typography
                                                  className="mb-1"
                                                  fontFamily={FontFamily.Lato}
                                                  fontSize={fontSize.h3.medium}
                                                  fontWeight={fontWeight.W400}
                                                >
                                                  Duration
                                                </Typography>
                                                <CustomSelect
                                                  disabled={
                                                    window.sessionStorage.getItem(
                                                      "user_role_type"
                                                    ) === "agent" &&
                                                    window.sessionStorage.getItem(
                                                      "role"
                                                    ) === "admin"
                                                  }
                                                  value={
                                                    values.universities[
                                                      univIndex
                                                    ].commissionAndPrograms[
                                                      commIndex
                                                    ].duration
                                                  }
                                                  name={`universities[${univIndex}].commissionAndPrograms[${commIndex}].duration`}
                                                  multiple={false}
                                                  option={[
                                                    {
                                                      _id: "S",
                                                      title: "Semester-wise",
                                                    },
                                                    {
                                                      _id: "Y",
                                                      title: "Year-wise",
                                                    },
                                                  ]}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `universities[${univIndex}].commissionAndPrograms[${commIndex}].duration`,
                                                      e.target.value
                                                    );
                                                  }}
                                                  label={"Duration"}
                                                  fullWidth={true}
                                                />
                                              </div>
                                            </FormGroup>
                                          </NoPaddingGridItem>

                                          <NoPaddingGridItem
                                            item
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                          >
                                            <FormGroup style={{}}>
                                              <div className="">
                                                <Typography
                                                  className="mb-2"
                                                  fontFamily={FontFamily.Lato}
                                                  fontSize={fontSize.h3.medium}
                                                  fontWeight={fontWeight.W400}
                                                >
                                                  Payment Cycle
                                                </Typography>
                                                <OutlinedInputField
                                                  disable={
                                                    window.sessionStorage.getItem(
                                                      "user_role_type"
                                                    ) === "agent" &&
                                                    window.sessionStorage.getItem(
                                                      "role"
                                                    ) === "admin"
                                                  }
                                                  value={
                                                    values.universities[
                                                      univIndex
                                                    ].commissionAndPrograms[
                                                      commIndex
                                                    ].paymentCycle
                                                  }
                                                  name={`universities[${univIndex}].commissionAndPrograms[${commIndex}].paymentCycle`}
                                                  onChange={handleChange}
                                                  placeholder="Enter your payment cycle details...."
                                                  fullWidth={true}
                                                />
                                              </div>
                                            </FormGroup>
                                          </NoPaddingGridItem>
                                          <NoPaddingGridItem
                                            item
                                            lg={4}
                                            md={4}
                                            sm={12}
                                            xs={12}
                                          >
                                            <FormGroup style={{}}>
                                              <div className="">
                                                <Typography
                                                  className="mb-2"
                                                  fontFamily={FontFamily.Lato}
                                                  fontSize={fontSize.h3.medium}
                                                  fontWeight={fontWeight.W400}
                                                >
                                                  Terms And Condition
                                                </Typography>
                                                <OutlinedInputField
                                                  disable={
                                                    window.sessionStorage.getItem(
                                                      "user_role_type"
                                                    ) === "agent" &&
                                                    window.sessionStorage.getItem(
                                                      "role"
                                                    ) === "admin"
                                                  }
                                                  value={
                                                    values.universities[
                                                      univIndex
                                                    ].commissionAndPrograms[
                                                      commIndex
                                                    ].termsAndCondition
                                                  }
                                                  name={`universities[${univIndex}].commissionAndPrograms[${commIndex}].termsAndCondition`}
                                                  onChange={handleChange}
                                                  placeholder="Enter your terms and condition details...."
                                                  fullWidth={true}
                                                />
                                              </div>
                                            </FormGroup>
                                          </NoPaddingGridItem>
                                        </Grid>
                                      )
                                    )}
                                  </div>
                                )}
                              </FieldArray>

                              <div className="d-flex justify-content-start w-100 mt-3 mb-3">
                                <Typography
                                  className="px-2"
                                  fontSize={fontSize.h3.small}
                                  fontFamily={FontFamily.Lato}
                                  color={ThemeColor.BLACK}
                                  fontWeight={fontWeight.W600}
                                >
                                  Delete {univIndex} University
                                </Typography>
                                <IconButton
                                  className="form-icon"
                                  sx={{
                                    background: ThemeColor.RED,
                                    color: ThemeColor.WHITE,
                                  }}
                                  variant="contained"
                                  onClick={() => removeUniversity(univIndex)}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </div>
                          ))}

                          <Button
                            type="button"
                            onClick={() =>
                              pushUniversity({
                                university: "",
                                commissions: [
                                  {
                                    degreeType: "",
                                    program: [],
                                    commissionType: "",
                                    value: "",
                                    duration: "",
                                    paymentCycle: "",
                                    termsAndCondition: "",
                                  },
                                ],
                              })
                            }
                          >
                            Add University
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </Grid>
                )}
              </Grid>
              {(window.sessionStorage.getItem("user_role_type") === "admin" ||
                !window.sessionStorage.getItem("user_role_type") === "user" ||
                (window.sessionStorage.getItem("user_role_type") === "agent" &&
                  window.sessionStorage.getItem("role") === "admin")) && (
                <Box
                  sx={{
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                  }}
                  className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
                >
                  {" "}
                  <CustomButton
                    variant="contained"
                    type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    loading={formLoading}
                    //   padding={"0px"}
                    // onClick={handleClick}
                  >
                    {!Edit ? "Save  Organization" : "Save Changes"}
                  </CustomButton>
                  {Edit && (
                    <Box
                      className="px-2"
                      sx={{
                        paddingTop: {
                          lg: "0px",
                          md: "0px",
                          sm: "5px",
                          xs: "5px",
                        },
                      }}
                    >
                      <CustomButton
                        variant="contained"
                        // type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        loading={formLoading}
                        //   padding={"0px"}
                        onClick={() => {
                          if (Edit) {
                            if (childRef.current) {
                              childRef.current.someFunction(
                                prefieldData?.documents
                              );
                            }
                          } else {
                            if (childRef.current) {
                              childRef.current.someEditFunction();
                            }
                          }
                          resetForm();
                        }}
                      >
                        Cancel Changes
                      </CustomButton>
                    </Box>
                  )}
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function CreateOrganization() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<CreateOrganizationPage />} />
    </div>
  );
}

export default CreateOrganization;
