import React from "react";

function Setting({fill}) {
  return (
    <div>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9708 6.03219C16.9899 6.01078 17.008 5.988 17.0239 5.96411V5.96411C17.1328 5.80079 17.1469 5.59103 17.0536 5.41834C16.657 4.68417 16.1966 4.06076 15.6282 3.50376C15.435 3.31445 15.1419 3.27903 14.9 3.4V3.4C13.4 4.3 11.5 3.4 11.2 1.6V1.6C11.1389 1.35573 10.9429 1.16669 10.6953 1.12138C10.3243 1.05351 9.93888 1 9.5 1C9.04791 1 8.70936 1.05677 8.35603 1.12754C8.14889 1.16903 8 1.35207 8 1.56332V1.56332C8 1.58773 7.99801 1.61241 7.99369 1.63644C7.67429 3.41211 5.78984 4.29391 4.3 3.4V3.4C4.05807 3.27903 3.76477 3.31428 3.57433 3.50638C3.01009 4.07552 2.55229 4.74307 2.15763 5.4224C2.05914 5.59192 2.06834 5.80251 2.17709 5.96564V5.96564C2.19234 5.98851 2.20965 6.0103 2.22797 6.03079C3.39207 7.33291 2.88743 9.40471 1.3 10V10C1.12423 10.0586 1.01131 10.2305 1.03144 10.4147C1.12792 11.2977 1.29524 12.1684 1.58538 12.9228C1.65086 13.0931 1.81757 13.2 2 13.2V13.2C3.69338 13.1004 5.08909 14.6876 4.60576 16.3801C4.60201 16.3932 4.59693 16.4061 4.59084 16.4183V16.4183C4.53894 16.5221 4.57232 16.6485 4.66992 16.7113C5.49211 17.2399 6.39549 17.6772 7.38005 17.8769C7.45501 17.8921 7.53227 17.8677 7.58636 17.8136V17.8136C7.59544 17.8046 7.60382 17.7946 7.61136 17.7842C8.61234 16.4048 10.6893 16.4053 11.6896 17.7856C11.6965 17.7951 11.7042 17.8042 11.7125 17.8125V17.8125C11.7666 17.8666 11.8456 17.8877 11.9193 17.8669C12.9331 17.5816 13.8577 17.2039 14.6132 16.5742C14.6606 16.5346 14.663 16.463 14.6193 16.4193V16.4193C14.6066 16.4066 14.5972 16.3908 14.5924 16.3736C14.1135 14.6835 15.4093 13.1005 17.2 13.2V13.2C17.3812 13.2 17.5447 13.0899 17.609 12.9205C17.9166 12.1096 18.1463 11.2925 18.1918 10.4161C18.2016 10.2275 18.0792 10.0597 17.9 10V10C16.3131 9.30575 15.8083 7.33279 16.9708 6.03219Z"
          stroke={fill?fill:"white"}
          stroke-width="1.3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.81863 12.7918C11.5264 12.6711 12.8131 11.1889 12.6925 9.4811C12.5718 7.7733 11.0896 6.48664 9.3818 6.60727C7.674 6.7279 6.38734 8.21013 6.50797 9.91793C6.6286 11.6257 8.11083 12.9124 9.81863 12.7918Z"
          stroke={fill?fill:"white"}
          stroke-width="1.3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Setting;
