import {
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../select field/OutlinedSelectField";
import {
  AddPaymentDetailsAfterVisaApprovedService,
  UpdateStudentTransactionService,
  calcualteAgentCommissionService,
  getDefaultCommissionDetailsService,
} from "../../Redux/service/Application";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import OnlyDatePicker from "../datepicker/MyDatepicker";
import {
  GetAgentTransactionListAction,
  GetStudentAction,
  GetUnsettledListAction,
} from "../../Redux/action/ApplicationAction";
import Previews from "../dropzone/Mydropzone";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import {
  validationCommisionSchema,
  validationTransactionSchema,
} from "./ValidationConstant";
import OutlinedTextAreaField from "../inputfiled/OutlinedTextedArea";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const PaymentDetailModal = ({
  UserTransaction,
  handleClose,
  transactionId,
  AgentTransactionlist,
  handleCloseReminder,
  DefaultCommission,
}) => {
  const dispatch = useDispatch();
  const [VisaStatus, setVisaStatus] = useState("");
  const [commResdata, setCommResdata] = useState(null);
  const childRef = useRef(null);
  const formikRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const getCommisionAmount = async (value, id) => {
    // alert(value, "from field of receive from univ using debounce");
    //(id);
    console.log(value, id?.transactionId);
    setLoading(true);
    await calcualteAgentCommissionService(id?.transactionId, value)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setCommResdata(res.data.data);
          dispatch(showSnackbar(res?.data.message, "success"));
          dispatch(
            GetAgentTransactionListAction(
              transactionId?.organisation,
              transactionId.studentId
            )
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(showSnackbar(err.response.data.message, "error"));
      });
  };

  return (
    <Modal
      open={UserTransaction}
      onClose={() => {
        console.log("hanlde close");
        setCommResdata(null);
        setLoading(true);
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          align="right"
          onClick={() => {
            console.log("hanlde close");
            setCommResdata(null);
            setLoading(true);

            handleClose();
          }}
        >
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Update User Transaction details
        </Typography>
        <Formik
          innerRef={formikRef}
          initialValues={{
            receivedFromUniversity: transactionId?.receivedFromUniversity,
            exchangeRateUSDToINR: DefaultCommission
              ? DefaultCommission?.exchangeRateUSDToINR
              : "",
            gstPercentage: DefaultCommission
              ? DefaultCommission?.gstPercentage
              : "",
            tdsPercentage: DefaultCommission
              ? DefaultCommission?.tdsPercentage
              : "",
          }}
          // validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            //("Form data:", values);

            getCommisionAmount(values, transactionId);
          }}
          validationSchema={validationCommisionSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="mt-3"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Exchange Rate USD To INR
                        </Typography>
                        <OutlinedInputField
                          value={values.exchangeRateUSDToINR}
                          name={`exchangeRateUSDToINR`}
                          onChange={(e) => {
                            // handleInputChange(
                            //   e.target.value,
                            //   transactionId?.agentTransactionId
                            // );
                            handleChange(e);
                          }}
                          placeholder="Enter exchange rate USD To INR"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.exchangeRateUSDToINR}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Gst Percentage
                        </Typography>
                        <OutlinedInputField
                          value={values.gstPercentage}
                          name={`gstPercentage`}
                          onChange={(e) => {
                            // handleInputChange(
                            //   e.target.value,
                            //   transactionId?.agentTransactionId
                            // );
                            handleChange(e);
                          }}
                          placeholder="Enter Gst Percentage"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.gstPercentage}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Tds Percentage
                        </Typography>
                        <OutlinedInputField
                          value={values.tdsPercentage}
                          name={`tdsPercentage`}
                          onChange={(e) => {
                            // handleInputChange(
                            //   e.target.value,
                            //   transactionId?.agentTransactionId
                            // );
                            handleChange(e);
                          }}
                          placeholder="Enter Tds Percentage"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.tdsPercentage}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Amount Received from university
                        </Typography>
                        <OutlinedInputField
                          value={values.receivedFromUniversity}
                          name={`receivedFromUniversity`}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          placeholder="Enter amounts"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.receivedFromUniversity}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <Box
                      sx={{
                        flexDirection: {
                          lg: "row",
                          md: "row",
                          sm: "column",
                          xs: "column",
                        },
                      }}
                      className="d-flex justify-content-center align-items-center w-100 mt-2 mb-3"
                    >
                      {" "}
                      <CustomButton
                        variant="contained"
                        type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                      >
                        Calculate Commission
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
        {loading? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Formik
            // innerRef={formikRef}
            initialValues={{
              agentTransactionId: transactionId?.transactionId,
              isSettled: transactionId?.isSettled,
              isCredited: transactionId?.isCredited,
              // amountPayableToAgent: transactionId
              //   ? transactionId?.amountPayableToAgent
              //   : 0,

              receipts: [],

              chequePayable: commResdata ? commResdata.chequePayable : "",
              tdsAmount: commResdata ? commResdata?.tdsAmount : "",
              gstAmount: commResdata ? commResdata?.gstAmount : "",
              grossCommission: commResdata ? commResdata?.grossCommission : "",
              invoiceTotalAmount:commResdata?commResdata?.invoiceTotalAmount:"",
              organisationCommission: commResdata
                ? commResdata?.organisationCommission
                : "",
              notes: "",
            }}
            validationSchema={validationTransactionSchema}
            onSubmit={async (values, { resetForm }) => {
              //("Form data:", values);

              values["receivedFromUniversity"] = commResdata
                ? commResdata?.receivedFromUniversity
                : "";

              values["exchangeRateUSDToINR"] = commResdata
                ? commResdata?.exchangeRateUSDToINR
                : "";

              values["gstPercentage"] = commResdata
                ? commResdata.gstPercentage
                : "";

              values["tdsPercentage"] = commResdata
                ? commResdata.tdsPercentage
                : "";
              console.log(values);
              await UpdateStudentTransactionService(values)
                .then((res) => {
                  if (res.status === 200) {
                    handleClose();
                    handleCloseReminder();
                    resetForm();

                    dispatch(GetUnsettledListAction(false));

                    dispatch(showSnackbar(res.data.message, "success"));
                    // navigate("/CAE/CatalogueBrochureList");
                    // resetForm();

                    setCommResdata(null);
                  }
                })
                .catch((e) => {
                  dispatch(showSnackbar(e.response.data.message, "error"));
                });
            }}
            // validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="d-flex justify-content-center">
                <Box
                  className="mt-3"
                  sx={{
                    width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                  }}
                >
                  <Grid container spacing>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Organisation Commission
                          </Typography>
                          <OutlinedInputField
                            value={values.organisationCommission}
                            name={`organisationCommission`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter organisation commission"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.organisationCommission}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Invoice Total Amount
                          </Typography>
                          <OutlinedInputField
                            value={values.invoiceTotalAmount}
                            name={`invoiceTotalAmount`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter invoice Total Amount"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.invoiceTotalAmount}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Gst Amount
                          </Typography>
                          <OutlinedInputField
                            value={values.gstAmount}
                            name={`gstAmount`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter gst amount"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.gstAmount}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Gross Commission
                          </Typography>
                          <OutlinedInputField
                            value={values.grossCommission}
                            name={`grossCommission`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter gross commission"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.grossCommission}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Tds Amount
                          </Typography>
                          <OutlinedInputField
                            value={values.tdsAmount}
                            name={`tdsAmount`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter tds amount"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.tdsAmount}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Cheque Payable
                          </Typography>
                          <OutlinedInputField
                            value={values.chequePayable}
                            name={`chequePayable`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter cheque payable"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.chequePayable}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.isSettled}
                            name={"visaStatus"}
                            multiple={false}
                            option={[
                              { _id: true, title: "Yes" },
                              { _id: false, title: "No" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("isSettled", e.target.value);
                            }}
                            fullWidth={true}
                            label={"Is Settled"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.visaStatus}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={
                              AgentTransactionlist ? values?.isCredited : ""
                            }
                            name={"Is Credited"}
                            multiple={false}
                            option={[
                              { _id: true, title: "Yes" },
                              { _id: false, title: "No" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("isCredited", e.target.value);
                              // setVisaStatus(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Is Credited"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.isCredited}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Notes
                          </Typography>
                          <OutlinedTextAreaField
                            multiline={true}
                            minRows={4}
                            value={values.notes}
                            name={`notes`}
                            onChange={handleChange}
                            placeholder="Enter your Notes"
                            fullWidth={true}
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <div className="w-100 d-flex justify-content-center mt-3">
                        <Previews
                          ref={childRef}
                          initialValue={values?.receipts}
                          handleMydropzoneFile={(updatedFiles) => {
                            //(updatedFiles);
                            setFieldValue("receipts", updatedFiles);
                          }}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.receipts}
                            </Typography>
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                    className="d-flex justify-content-center align-items-center w-100 mt-3"
                  >
                    {" "}
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                      //   padding={"0px"}
                      // onClick={handleClick}
                    >
                      Update
                    </CustomButton>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        )}
        {/* {commResdata && loading && (
          <Formik
            // innerRef={formikRef}
            initialValues={{
              agentTransactionId: transactionId?.transactionId,
              isSettled: transactionId?.isSettled,
              isCredited: transactionId?.isCredited,
              // amountPayableToAgent: transactionId
              //   ? transactionId?.amountPayableToAgent
              //   : 0,

              receipts: [],

              chequePayable: commResdata ? commResdata.chequePayable : "",
              tdsAmount: commResdata ? commResdata?.tdsAmount : "",
              gstAmount: commResdata ? commResdata?.gstAmount : "",
              grossCommission: commResdata ? commResdata?.grossCommission : "",
              organisationCommission: commResdata
                ? commResdata?.organisationCommission
                : "",
              notes: "",
            }}
            validationSchema={validationTransactionSchema}
            onSubmit={async (values, { resetForm }) => {
              //("Form data:", values);

              values["receivedFromUniversity"] = commResdata
                ? commResdata?.receivedFromUniversity
                : "";

              values["exchangeRateUSDToINR"] = commResdata
                ? commResdata?.exchangeRateUSDToINR
                : "";

              values["gstPercentage"] = commResdata
                ? commResdata.gstPercentage
                : "";

              values["tdsPercentage"] = commResdata
                ? commResdata.tdsPercentage
                : "";
              console.log(values);
              await UpdateStudentTransactionService(values)
                .then((res) => {
                  if (res.status === 200) {
                    handleClose();
                    handleCloseReminder();
                    resetForm();

                    dispatch(GetUnsettledListAction(false));

                    dispatch(showSnackbar(res.data.message, "success"));
                    // navigate("/CAE/CatalogueBrochureList");
                    resetForm();

                    setCommResdata();
                  }
                })
                .catch((e) => {
                  dispatch(showSnackbar(e.response.data.message, "error"));
                });
            }}
            // validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="d-flex justify-content-center">
                <Box
                  className="mt-3"
                  sx={{
                    width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                  }}
                >
                  <Grid container spacing>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Organisation Commission
                          </Typography>
                          <OutlinedInputField
                            value={values.organisationCommission}
                            name={`organisationCommission`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter organisation commission"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.organisationCommission}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Gst Amount
                          </Typography>
                          <OutlinedInputField
                            value={values.gstAmount}
                            name={`gstAmount`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter gst amount"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.gstAmount}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Gross Commission
                          </Typography>
                          <OutlinedInputField
                            value={values.grossCommission}
                            name={`grossCommission`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter gross commission"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.grossCommission}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Tds Amount
                          </Typography>
                          <OutlinedInputField
                            value={values.tdsAmount}
                            name={`tdsAmount`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter tds amount"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.tdsAmount}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Cheque Payable
                          </Typography>
                          <OutlinedInputField
                            value={values.chequePayable}
                            name={`chequePayable`}
                            onChange={(e) => {
                              // handleInputChange(
                              //   e.target.value,
                              //   transactionId?.agentTransactionId
                              // );
                              handleChange(e);
                            }}
                            placeholder="Enter cheque payable"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.chequePayable}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.isSettled}
                            name={"visaStatus"}
                            multiple={false}
                            option={[
                              { _id: true, title: "Yes" },
                              { _id: false, title: "No" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("isSettled", e.target.value);
                            }}
                            fullWidth={true}
                            label={"Is Settled"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.visaStatus}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={
                              AgentTransactionlist ? values?.isCredited : ""
                            }
                            name={"Is Credited"}
                            multiple={false}
                            option={[
                              { _id: true, title: "Yes" },
                              { _id: false, title: "No" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("isCredited", e.target.value);
                              // setVisaStatus(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Is Credited"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.isCredited}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Notes
                          </Typography>
                          <OutlinedTextAreaField
                            multiline={true}
                            minRows={4}
                            value={values.notes}
                            name={`notes`}
                            onChange={handleChange}
                            placeholder="Enter your Notes"
                            fullWidth={true}
                          />
                        </div>
                      </FormGroup>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <div className="w-100 d-flex justify-content-center mt-3">
                        <Previews
                          ref={childRef}
                          initialValue={values?.receipts}
                          handleMydropzoneFile={(updatedFiles) => {
                            //(updatedFiles);
                            setFieldValue("receipts", updatedFiles);
                          }}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.receipts}
                            </Typography>
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                    className="d-flex justify-content-center align-items-center w-100 mt-3"
                  >
                    {" "}
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                      //   padding={"0px"}
                      // onClick={handleClick}
                    >
                      Update
                    </CustomButton>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        )} */}
      </Box>
    </Modal>
  );
};
