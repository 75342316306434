import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetRoleAction } from "../../Redux/action/SettingAction";
import OnlyDatePicker from "../../component/datepicker/MyDatepicker";
import {
  CreateUserService,
  EditUserService,
} from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { UploadFileService } from "../../Redux/service/FileService";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const AddNewUserPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const [Rolelist, setRolelist] = useState([]);
  const [formLoading, setFormLoading] = useState(false);

  const childRef = useRef(null);

  const { id } = useParams();

  const [Edit, setEdit] = useState(id ? true : false);

  const [prefieldData, setPrefieldData] = useState();

  const [roleEdit, setRoleEdit] = useState([]);

  const EditUserdataState = useSelector(
    (state) => state.setting?.user?.data?.activeUsers
  );
  //(EditUserdataState);
  useEffect(() => {
    setPrefieldData(EditUserdataState?.filter((data) => data._id === id)?.[0]);
    // setRoleEdit(
    //   EditUserdataState?.filter((data) => data._id === id)?.[0].roles.map(
    //     (data) => data._id
    //   )
    // );
  }, [Edit, EditUserdataState, id]);

  const handleClick = () => {
    if (childRef.current) {
      childRef.current.someFunction();
    }
  };

  const handleRevert = () => {
    formikRef.current.resetForm();

    // formikRef.current.setFieldValue('birthday', null);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Name of user is required"),
    lastName: Yup.string().required("Name of user is required"),
    // birthday: Yup.string().required("Birthday date of user is required"),
    email: Yup.string()
      .strict()
      .email("Please enter the valid email")
      .required("Email of user is required"),
    phone: Yup.string()
      // .matches(/^\d{12}$/, "Phone number must be proper")
      .required("Phone Number of user is required"),
    // personalEmail: Yup.string()
    //   .email("Please enter the valid personal email")
    //   .required("Personal Email of user is required"),
    // personalPhone: Yup.string().required(
    //   "Personal Phone Number of user is required"
    // ),
    roles: Yup.string().required("Roles is required"),
    // documents: Yup.array().required("Document for user is required"),
  });

  const RolelistState = useSelector(
    (state) => state.setting?.role?.data?.roles
  );

  useEffect(() => {
    dispatch(GetRoleAction());
  }, []);

  useEffect(() => {
    const updated = RolelistState?.filter(
      (role) => role.role === "accountant" || role.role === "counsellor"
    );
    setRolelist(updated);
  }, [RolelistState]);

  if (!prefieldData && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  const handleFormSubmit = async (values, formType) => {
    // //(values, formType, formikRef.current);
    setFormLoading(true);

    if (!Edit) {
      await CreateUserService(values)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/UserList");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          // formikRef.current.resetForm();
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    } else {
      //(values, "edit values");
      await EditUserService(values, id)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/UserList");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          // formikRef.current.resetForm();
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Settings
            </Typography>
            <Typography
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
            >
              Create user
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className="d-flex justify-content-center w-100">
            {(!window.sessionStorage.getItem("user_role_type") === "agent" ||
              window.sessionStorage.getItem("user_role_type") === "admin" ||
              !window.sessionStorage.getItem("user_role_type") === "user") && (
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                loading={formLoading}
                //   padding={"0px"}
                // onClick={handleClick}
                onClick={() =>
                  handleFormSubmit(formikRef.current?.values, "reset")
                }
              >
                Add Another User
              </CustomButton>
            )}
          </div>
        </Grid>
      </Grid>
      <div className="d-flex align-items-center"></div>
      <div className="container">
        <Formik
          innerRef={formikRef}
          initialValues={{
            firstName: Edit ? prefieldData?.firstName : "",
            lastName: Edit ? prefieldData?.lastName : "",
            documents: Edit ? prefieldData?.documents : [],
            personalEmail: Edit ? prefieldData?.personalEmail : "",
            personalPhone: Edit ? prefieldData?.personalPhone : "",
            email: Edit ? prefieldData?.email : "",
            phone: Edit ? prefieldData?.phone : "",
            roles: Edit ? prefieldData?.roles?._id : "",
            birthday: Edit ? prefieldData?.birthday : null,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            handleFormSubmit(values, "submit");
          }}
          // validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form className="" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* grid for right basic content */}
                <Grid item lg={8}>
                  <Typography
                    align="center"
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    Add New User
                  </Typography>
                  <Grid container spacing={2} className="mt-4">
                    {/* <NoPaddingGridItem item lg={2} md={2} sm={12} xs={12}>
                      <div className="agent-logo">
                        <img
                          src="/assest/dropzonelogo.png"
                          className="w-100 h-100"
                          alt="agentlogo"
                        ></img>
                      </div>
                    </NoPaddingGridItem> */}
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            First Name *
                          </Typography>
                          <OutlinedInputField
                            value={values.firstName}
                            name={"firstName"}
                            onChange={handleChange}
                            placeholder="Enter name"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.firstName}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Last Name *
                          </Typography>
                          <OutlinedInputField
                            value={values.lastName}
                            name={"lastName"}
                            onChange={handleChange}
                            placeholder="Enter name"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.lastName}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Business Email *
                          </Typography>
                          <OutlinedInputField
                            disable={Edit ? true : false}
                            value={values.email}
                            name={"email"}
                            onChange={handleChange}
                            placeholder="Enter email"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.email}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Business Phone *
                          </Typography>
                          <OutlinedInputField
                            value={values.phone}
                            name={"phone"}
                            onChange={handleChange}
                            placeholder="Enter phone"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.phone}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Personal Email
                          </Typography>
                          <OutlinedInputField
                            value={values.personalEmail}
                            name={"personalEmail"}
                            onChange={handleChange}
                            placeholder="Enter email"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.personalEmail}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Personal Phone
                          </Typography>
                          <OutlinedInputField
                            value={values.personalPhone}
                            name={"personalPhone"}
                            onChange={handleChange}
                            placeholder="Enter phone"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.personalPhone}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="mt-3">
                          <CustomSelect
                            value={Rolelist ? values?.roles : ""}
                            name={"roles"}
                            multiple={false}
                            option={Rolelist}
                            onChange={(e) => {
                              //(e);
                              setFieldValue("roles", e.target.value);
                            }}
                            label={"Business Role *"}
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.roles}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    {/* <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="mt-3">
                          <OutlinedInputField
                            value={values.city}
                            name={"city"}
                            // label={"NCR Extension"}
                            onChange={handleChange}
                            placeholder="Enter phone office"
                            fullWidth={true}
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem> */}
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Birthday
                          </Typography>
                          <OnlyDatePicker
                            views={["year", "month", "day"]}
                            label="Enter Birthday"
                            value={
                              values.birthday
                                ? dayjs(values.birthday).isValid()
                                  ? dayjs(values.birthday)
                                  : null
                                : null
                            }
                            onChange={(date) => {
                              setFieldValue(
                                `birthday`,
                                dayjs(date).format("YYYY-MM-DD")
                              );
                            }}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.birthday}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Field
                          name="birthday"
                          component={OnlyDatePicker}
                          label="Enter Birthday"
                          helperText={
                            errors.birthday && (
                              <Typography align="left" color="error">
                                {errors.birthday}
                              </Typography>
                            )
                          }
                        />
                      </LocalizationProvider> */}
                    </NoPaddingGridItem>
                  </Grid>
                </Grid>

                {/* grid for left docs uploading content */}
                <Grid item lg={4} xs={12}>
                  <div className="w-100 d-flex justify-content-center mt-4">
                    <div className="w-100 d-flex justify-content-center mt-4">
                      <Previews
                        ref={childRef}
                        initialValue={values?.documents}
                        handleMydropzoneFile={(updatedFiles) => {
                          //(updatedFiles);
                          setFieldValue("documents", updatedFiles);
                        }}
                        helperText={
                          <Typography
                            align="left"
                            // className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.documents}
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {(!window.sessionStorage.getItem("user_role_type") === "agent" ||
                window.sessionStorage.getItem("user_role_type") === "admin" ||
                !window.sessionStorage.getItem("user_role_type") ===
                  "user") && (
                <Box
                  sx={{
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                  }}
                  className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
                >
                  {" "}
                  <CustomButton
                    variant="contained"
                    type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    loading={formLoading}
                    //   padding={"0px"}
                    // onClick={handleClick}
                  >
                    {Edit ? "Save Changes" : "Save User"}
                  </CustomButton>
                  {Edit && (
                    <Box
                      className="px-2"
                      sx={{
                        paddingTop: {
                          lg: "0px",
                          md: "0px",
                          sm: "5px",
                          xs: "5px",
                        },
                      }}
                    >
                      <CustomButton
                        variant="contained"
                        // type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        loading={formLoading}
                        //   padding={"0px"}
                        onClick={() => {
                          // setFieldValue('birthday', null);
                          if (Edit) {
                            if (childRef.current) {
                              childRef.current.someFunction(
                                prefieldData?.documents
                              );
                            }
                          } else {
                            if (childRef.current) {
                              childRef.current.someEditFunction();
                            }
                          }
                          resetForm();
                        }}
                      >
                        Cancel Changes
                      </CustomButton>
                    </Box>
                  )}
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function AddNewUser() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<AddNewUserPage />} />
    </div>
  );
}

export default AddNewUser;
