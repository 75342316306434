import React from "react";

function FolderMenu() {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_40_1927)">
          <path
            d="M10.9999 21.2142C13.7089 21.2142 16.307 20.1381 18.2225 18.2225C20.1381 16.307 21.2142 13.7089 21.2142 10.9999C21.2142 8.29093 20.1381 5.69289 18.2225 3.77734C16.307 1.86179 13.7089 0.785645 10.9999 0.785645C8.29093 0.785645 5.69289 1.86179 3.77734 3.77734C1.86179 5.69289 0.785645 8.29093 0.785645 10.9999C0.785645 13.7089 1.86179 16.307 3.77734 18.2225C5.69289 20.1381 8.29093 21.2142 10.9999 21.2142Z"
            stroke="black"
            stroke-width="0.857143"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.28544 11.3926C6.18124 11.3926 6.08132 11.3513 6.00764 11.2776C5.93397 11.2039 5.89258 11.104 5.89258 10.9998C5.89258 10.8956 5.93397 10.7957 6.00764 10.722C6.08132 10.6483 6.18124 10.6069 6.28544 10.6069M6.28544 11.3926C6.38963 11.3926 6.48955 11.3513 6.56323 11.2776C6.6369 11.2039 6.67829 11.104 6.67829 10.9998C6.67829 10.8956 6.6369 10.7957 6.56323 10.722C6.48955 10.6483 6.38963 10.6069 6.28544 10.6069M10.9997 11.3926C10.8955 11.3926 10.7956 11.3513 10.7219 11.2776C10.6483 11.2039 10.6069 11.104 10.6069 10.9998C10.6069 10.8956 10.6483 10.7957 10.7219 10.722C10.7956 10.6483 10.8955 10.6069 10.9997 10.6069M10.9997 11.3926C11.1039 11.3926 11.2038 11.3513 11.2775 11.2776C11.3512 11.2039 11.3926 11.104 11.3926 10.9998C11.3926 10.8956 11.3512 10.7957 11.2775 10.722C11.2038 10.6483 11.1039 10.6069 10.9997 10.6069M15.714 11.3926C15.6098 11.3926 15.5099 11.3513 15.4362 11.2776C15.3625 11.2039 15.3212 11.104 15.3212 10.9998C15.3212 10.8956 15.3625 10.7957 15.4362 10.722C15.5099 10.6483 15.6098 10.6069 15.714 10.6069M15.714 11.3926C15.8182 11.3926 15.9181 11.3513 15.9918 11.2776C16.0655 11.2039 16.1069 11.104 16.1069 10.9998C16.1069 10.8956 16.0655 10.7957 15.9918 10.722C15.9181 10.6483 15.8182 10.6069 15.714 10.6069"
            stroke="black"
            stroke-width="0.857143"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_40_1927">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default FolderMenu;
