import { Box, FormGroup, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import moment from "moment";
import * as Yup from "yup";
import AttachmentDropzone from "../dropzone/AttachmentDropzone";
import { GetStudentChatByIdAction } from "../../Redux/action/ApplicationAction";
import { AddStudentChatService } from "../../Redux/service/Application";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "none",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  message: Yup.string()
  // .required("Resend date is required")/
  .when("fileUrl", {
    is: (fileUrl) => !fileUrl,
    then: (schema) =>
      schema.test(
        "is-greater",
        "Either text or file is required to send to chat",
        function (value) {
          const { fileUrl } = this.parent;

          if (fileUrl!=="" && !value) {
           // console.log("no file")
            return false;
          }
          return true;
        }
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  fileUrl: Yup.string().notRequired(),
});

export const StudentChatModal = ({ chatModal, handleClose, stdId }) => {
  const dispatch = useDispatch();
  const [ChatByIdlist, setChatByIdlist] = useState([]);

  const childRef = useRef();

  const [AttachedFiles, setAttachedFiles] = useState();

  const ChatByIdlistState = useSelector(
    (state) => state.application?.studentchat?.data?.data?.[0]
  );

  // console.log(ChatByIdlistState);

  useEffect(() => {
    setChatByIdlist(ChatByIdlistState);
  }, [ChatByIdlistState]);

  const downloadFile = (file) => {
    // const link = document.createElement("a");
    // link.href = file;
    // link.setAttribute("download", "");
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
    window.open(file, "_blank", "noopener noreferrer");
  };

  const FileHandler = (data) => {
    if (data?.split(".").pop() === "xlsx") {
      let urlParts = data.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          <div className="filename-icon">
            <img
              src="/assest/folderpng/excel.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div>
          <a
            href={data}
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else if (data?.split(".").pop() === "pdf") {
      let urlParts = data.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          <div className="filename-icon">
            <img
              src="/assest/folderpng/pdf.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div>
          <a
            href={data}
            target="_blank"
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else {
      return (
        <div className="mt-2">
          <img
            onClick={() => downloadFile(data)}
            src={data}
            style={{
              width: "100px",
              height: "100px",
            }}
            alt="chatimg"
          />
        </div>
      );
    }
  };

  return (
    <Modal
      open={chatModal}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography align="right" onClick={() => handleClose()}>
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Chat for "{ChatByIdlist?.student?.studentFirstName}"
        </Typography>
        <Box sx={{ height: "60%", overflowY: "scroll" }}>
          {ChatByIdlist?.messages?.map((data) => {
            return (
              <>
                {data.sender?._id ===
                window.sessionStorage.getItem("user_id") ? (
                  <div id="sender" className="ms-5 mt-3">
                    <Box className="d-flex justify-content-between">
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W700}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.BLACK}
                          align="left"
                        >
                          {moment.unix(data.dateTime).format("llll")}

                          {/* {data.dateTime} */}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W400}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.RED}
                          sx={{ textDecoration: "underline" }}
                          align="left"
                        >
                          {data.sender.firstName} &nbsp;
                          {data.sender.lastName}
                        </Typography>
                      </div>
                    </Box>
                    {data?.fileUrl && (
                      <div className="mt-2">
                        {/* <img
                          onClick={() => downloadFile(data.fileUrl)}
                          src={data.fileUrl}
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          alt="chatimg"
                        /> */}
                        {FileHandler(data?.fileUrl)}
                      </div>
                    )}

                    <Typography
                      className="mt-3"
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W400}
                      fontSize={fontSize.h4.medium}
                      color={ThemeColor.BLACK}
                      align="left"
                    >
                      {data.message}
                    </Typography>
                  </div>
                ) : (
                  <div id="receiver" className="me-5 mt-3">
                    <Box className="d-flex justify-content-between">
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W400}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.RED}
                          sx={{ textDecoration: "underline" }}
                          align="left"
                        >
                          {data.sender.firstName} &nbsp;
                          {data.sender.lastName}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W700}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.BLACK}
                          align="left"
                        >
                          {moment.unix(data.dateTime).format("llll")}
                        </Typography>
                      </div>
                    </Box>

                    {data?.fileUrl && (
                      <div className="mt-2">
                        <img
                          onClick={() => downloadFile(data.fileUrl)}
                          src={data.fileUrl}
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          alt="chatimg"
                        />
                      </div>
                    )}

                    <Typography
                      className="mt-3 pe-5"
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W400}
                      fontSize={fontSize.h4.medium}
                      color={ThemeColor.BLACK}
                      align="left"
                    >
                      {data.message}
                    </Typography>
                  </div>
                )}
              </>
            );
          })}
        </Box>
        <Formik
          initialValues={{
            message: "",
            // chatRoomId: ChatByIdlist?._id,
            fileUrl: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            values["chatRoomId"] = ChatByIdlist?._id;
            //("Form data:", values);

            await AddStudentChatService(values)
              .then((res) => {
                if (res.status === 200) {
                  //(res.status);

                  dispatch(GetStudentChatByIdAction(stdId));

                  setAttachedFiles([]);
                  childRef.current.resetDropzone();

                  resetForm();
                }
              })
              .catch((e) => {
                // dispatch(showSnackbar(e.response.data.message, "error"));
              });
          }}
          //   validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                  position: "absolute",
                  bottom: "-10%",
                }}
              >
                <FormGroup style={{}}>
                  <div className="d-flex justify-content-between">
                    <div className="px-2 w-100">
                      <OutlinedInputField
                        value={values.message}
                        name={"message"}
                        onChange={handleChange}
                        placeholder="Write something...."
                        fullWidth={true}
                        helperText={
                          <Typography
                            align="left"
                            // className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.message}
                          </Typography>
                        }
                      />
                      {AttachedFiles?.length>0 && (
                        <div>
                          <Typography
                            color={ThemeColor.PURPLE}
                            fontSize={fontSize.h3.medium}
                            fontFamily={FontFamily.Lato}
                          >
                            Selected file: {AttachedFiles[0]?.name}
                          </Typography>
                        </div>
                      )}
                      {/* {
                        values?.fileUrl.length>=0 && <div style={{ width: "20%" }}>
                          {values.fileUrl}
                        </div>
                      } */}
                    </div>
                    <div>
                      <AttachmentDropzone
                        ref={childRef}
                        handleMydropzoneFile={(updatedFiles, files) => {
                          //(updatedFiles, "oooyesah");
                          setFieldValue("fileUrl", updatedFiles[0].url);
                          setAttachedFiles(files);
                        }}
                      />
                    </div>
                    <div className="px-3">
                      <CustomButton
                        variant="contained"
                        type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        // loading={formLoading}
                        //   padding={"0px"}
                        // onClick={handleClick}
                      >
                        Send
                      </CustomButton>
                    </div>
                  </div>
                </FormGroup>

                <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-5"></div>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
