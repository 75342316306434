import {
  FolderStackConst,
  GetFileListConst,
  GetFolderAndFilesByParentlistConst,
  GetFolderListConst,
} from "../constant/FileManagerConstant";
import {
  GetFilelistService,
  GetFolderAndFilesByParentlistService,
  GetFolderlistService,
} from "../service/FileManagerService";

export const GetFolderAction = () => async (dispatch) => {
  try {
    const data = await GetFolderlistService();

    dispatch({ type: GetFolderListConst, payload: data });
  } catch (error) {
    dispatch({ type: GetFolderListConst, payload: error });
  }
};

export const GetFileAction = () => async (dispatch) => {
  try {
    const data = await GetFilelistService();

    dispatch({ type: GetFileListConst, payload: data });
  } catch (error) {
    dispatch({ type: GetFileListConst, payload: error });
  }
};

export const GetFolderAndFilesByParentlistAction = (id) => async (dispatch) => {
  try {
    // //(id, "action");

    const data = await GetFolderAndFilesByParentlistService(id);

    dispatch({ type: GetFolderAndFilesByParentlistConst, payload: data });
  } catch (error) {
    dispatch({ type: GetFolderAndFilesByParentlistConst, payload: error });
  }
};

export const FolderStackAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: FolderStackConst, payload: data });
  } catch (error) {
    dispatch({ type: FolderStackConst, payload: error });
  }
};
