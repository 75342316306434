import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Dashboard from "../page/Setting/SettingDashboard.page";
import Login from "../page/login/Login.page";
import Cfaelayout from "./cfaelayout";
import Dashlayout from "./dashlayout";
import CreateUniversity from "../page/Setting/CreateUniversity.page";
import AddNewAgent from "../page/Setting/AddNewAgent.page";
import AddNewUser from "../page/Setting/AddNewUser.page";
import AddCatalogueBrochure from "../page/Setting/AddCatalogueBrochure.page";
import Agentlist from "../page/Setting/Agentlist.page";
import Userlist from "../page/Setting/Userlist.page";
import Universitylist from "../page/Setting/Universitylist.page";
import Organizationlist from "../page/Setting/Organizationlist.page";
import CreateOrganization from "../page/Setting/CreateOrganization.page";
import SnackbarComponent from "../component/snackbar/snackbar";
import AddNewMail from "../page/mail/Newmail.page";
import CatalogueBrochurelist from "../page/Setting/CatalogueBrochurelist.page";
import AddProcessSheet from "../page/Setting/AddProcessSheet.page";
import Emaillist from "../page/mail/EmailList.page";
import SentEmaillist from "../page/mail/SentEmail.list.page";
import Processlist from "../page/Setting/Processlist.page";
import LoginModule from "../page/login/Login.module";
import ChangePasswordModule from "../page/login/ChangePassword.module";
import CreateApplication from "../page/application/CreateApplication.page";
import Studentlist from "../page/application/ApplicationList.page";
import FolderManagement from "../page/foldermanagement/foldermanagement.page";
import FileManagement from "../page/foldermanagement/foldermanagement.page";
import SubFolderFileManagement from "../page/foldermanagement/subFolderManagement.page";
import Chatlist from "../page/chat/Chatlistpage";
import CreateProgram from "../page/Setting/CreateProg.page";
import Programlist from "../page/Setting/Programlist.page";
import Payoutlist from "../page/payout/Payoutlist.page";
import SupportSection from "../page/support/supportlist";
import AddUniversityApplication from "../page/application/AddUniversityApplication.page";
import AddNewMailTemplate from "../page/mail/AddEmailTemplate.page";
import EmailTemplatelist from "../page/mail/EmailTemplateList.page";
import SentStudentNewMail from "../page/mail/SentStudentEmail.page";
import { useSelector } from "react-redux";
import NotFound from "../page/404page/NotFound.page";
import SubFolderFileManagementStd from "../page/foldermanagement/subFOlderManagementStd.page";
import AddDefaultIntake from "../page/Setting/AddDefaultIntake.page";
import AllNotification from "../page/notification/AllNotification.page";
import AddNotification from "../page/notification/CreateNotification.page";

const Index2 = () => {
  const [role, setrole] = useState([]);

  const isloggedin1 = () => {
    const cae_token = window.sessionStorage.getItem("user_token");
    // //(cae_token, "getgymt");
    if (cae_token != null) {
      // //("false", cae_token);
      return false;
    } else {
      // //("true");
      return true;
    }
  };

  const roleList = useSelector((state) => state.setting);
  //(roleList, "role list");

  useEffect(() => {
    if (window.sessionStorage.getItem("user_role_type")) {
      //(window.sessionStorage.getItem("user_role_type"));
      setrole((prev) => [window.sessionStorage.getItem("user_role_type")]);
    }
  }, []);

  return (
    <>
      {" "}
      <Router>
        <Routes>
          {isloggedin1() ? (
            <Route path="/" element={<Cfaelayout />}>
              <Route path="/" element={<LoginModule />} />
              <Route
                path="/changepassword"
                element={<ChangePasswordModule />}
              />
            </Route>
          ) : (
            <Route path="/CAE" element={<Dashlayout />}>
              <Route path="SettingDashboard" element={<Dashboard />} />

              <Route path="CreateUniversity" element={<CreateUniversity />} />
              <Route path="Universitylist" element={<Universitylist />} />
              <Route path="EditUniversity/:id" element={<CreateUniversity />} />

              <Route path="AddAgent" element={<AddNewAgent />} />
              <Route path="AgentList" element={<Agentlist />} />
              <Route path="EditAgent/:id" element={<AddNewAgent />} />

              <Route path="AddUser" element={<AddNewUser />} />
              <Route path="UserList" element={<Userlist />} />
              <Route path="EditUser/:id" element={<AddNewUser />} />

              <Route
                path="CreateOrganization"
                element={<CreateOrganization />}
              />
              <Route path="OrganizationList" element={<Organizationlist />} />
              <Route
                path="EditOrganization/:id"
                element={<CreateOrganization />}
              />

              <Route
                path="AddCatalogueBrochure"
                element={<AddCatalogueBrochure />}
              />
              <Route
                path="CatalogueBrochureList"
                element={<CatalogueBrochurelist />}
              />
              <Route
                path="EditCatalogandBrochure/:id"
                element={<AddCatalogueBrochure />}
              />
              <Route path="ProgramList" element={<Programlist />} />
              <Route path="AddProgram" element={<CreateProgram />} />
              <Route path="ProgramList/:id" element={<Programlist />} />
              <Route path="AddProgram/:id" element={<CreateProgram />} />
              <Route path="ProgramList/:id" element={<Programlist />} />
              <Route path="EditProgram/:id" element={<CreateProgram />} />

              <Route path="AddProcessSheet" element={<AddProcessSheet />} />
              <Route path="ProcessList" element={<Processlist />} />
              <Route path="EditProcess/:id" element={<AddProcessSheet />} />

              <Route path="AddDefaultIntake" element={<AddDefaultIntake />} />

              {/* apth for notification */}
              <Route path="AllNotification" element={<AllNotification />} />
              <Route path="CreateNotification" element={<AddNotification />} />

              {/* <Route path="mail/auth:code" element= */}

              <Route path="Sendmail" element={<AddNewMail />} />
              <Route path="addMailTemplate" element={<AddNewMailTemplate />} />
              <Route path="mailist" element={<Emaillist />} />
              <Route path="sentmailist" element={<SentEmaillist />} />
              <Route path="emailTemplatelist" element={<EmailTemplatelist />} />
              <Route
                path="EditMailTemplate/:id"
                element={<AddNewMailTemplate />}
              />
              <Route
                path="StudentMailTemplate/:email"
                element={<SentStudentNewMail />}
              />

              <Route path="CreateApplication" element={<CreateApplication />} />
              <Route path="Applicationlist" element={<Studentlist />} />
              <Route
                path="EditApplication/:id"
                element={<CreateApplication />}
              />
              <Route
                path="AddUniversityApplication/:id"
                element={<AddUniversityApplication />}
              />
              {role?.includes("admin") ? (
                <Route path="Payoutlist" element={<Payoutlist />} />
              ) : (
                ""
              )}

              <Route path="chatlist" element={<Chatlist />} />

              <Route path="Filemanager" element={<FileManagement />} />

              <Route
                path="FileManager/Folder/:id"
                element={<SubFolderFileManagement />}
              />
              <Route
                path="FileManager/StdFolder/:id"
                element={<SubFolderFileManagementStd />}
              />
              <Route path="support" element={<SupportSection />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          )}
        </Routes>
      </Router>
    </>
  );
};

export default Index2;
