import React, { useState } from "react";
import Crossicon from "../../../Icon/Crossicon";
import { Box, Modal, Typography } from "@mui/material";
import { FontFamily } from "../../theme/fontFamily";
import { fontWeight } from "../../theme/fontWeight";
import { fontSize } from "../../theme/fontSize";
import { ThemeColor } from "../../theme/color";
import DeleteMsgIcon from "../../../Icon/DeleteMsg";
// import "../deleteMsgModal/deleteMsg.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "40%", lg: "40%" },
  height: "40vh",
  overflow: "hidden",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  borderRadius: "25px",
  p: 4,
};
function DeleteMsg(props) {
  const [deleteMsgopen, setdeleteMsgOpen] = useState(false);
  const handleOpen = () => {
    props.handledeleteMsgOpen();
  };

  const handleClose = () => {
    setdeleteMsgOpen(false);
    props.handledeleteMsgClose();
  };
  return (
    <div>
      {/* modal for notes window */}
      <Modal
        open={props.deleteMsgopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="d-flex justify-content-center flex-column">
          {/* <Typography align="right" onClick={handleClose}>
            <Crossicon width={"20"} height={"20"} />
          </Typography> */}
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.BLACK}
            align="center"
          >
            {props.msg ? props.msg : "Deleted"}Successfully
          </Typography>
          <Box
            sx={{ height: "100%", overflowY: "hidden" }}
            className="d-flex flex-column justify-content-center align-items-center mt-2"
          >
            <DeleteMsgIcon />

            <Box className="d-flex justify-content-around w-100 mt-2">
              <div className="confirm-btn delete px-2" onClick={handleClose}>
                Continue
              </div>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default DeleteMsg;
