import { Box, FormGroup, Grid, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import moment from "moment";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import LeftSideArrow from "../../Icon/LeftSideArrow";
import { useEffect, useState } from "react";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import {
  GetStudentNotesByIdAction,
  GetStudentReminderByIdAction,
} from "../../Redux/action/ApplicationAction";
import {
  AddStudentNotesService,
  DeleteStudentNotesService,
  DeleteStudentReminderService,
} from "../../Redux/service/Application";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import CustomButton from "../button/button";
import { Form, Formik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "none",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const StudentNotesModal = ({
  NoteModal,
  handleClose,
  stdId,
  Studentlist,
}) => {
  const dispatch = useDispatch();
  const [confirmationopen, setConfirmationOpen] = useState(false);
  const [NotesByIdlist, setNotesByIdlist] = useState([]);
  const [noteId, setNoteId] = useState();

  const NotesByIdlistState = useSelector(
    (state) => state.application?.studentnotes?.data?.data
  );

  useEffect(() => {
    setNotesByIdlist(NotesByIdlistState);
  }, [NotesByIdlistState]);

  return (
    <>
      {" "}
      <Modal
        open={NoteModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography align="right" onClick={() => handleClose()}>
            <Crossicon width={"20"} height={"20"} />
          </Typography>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.BLACK}
            align="center"
          >
            Notes for "
            {
              Studentlist?.filter((data) => data.id === stdId)?.[0]
                ?.studentFirstName
            }
            "
          </Typography>
          <Box sx={{ height: "60%", overflowY: "scroll" }}>
            {NotesByIdlist?.messages?.map((data) => {
              return (
                <>
                  <div id="receiver" className="mt-3">
                    <Box className="d-flex justify-content-between">
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W400}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.RED}
                          sx={{ textDecoration: "underline" }}
                          align="left"
                        >
                          {data.sender.firstName} &nbsp;
                          {data.sender.lastName}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W700}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.BLACK}
                          align="left"
                        >
                          {moment.unix(data.dateTime).format("llll")}

                          {/* {data.dateTime} */}
                        </Typography>
                      </div>
                    </Box>

                    <Typography
                      className="mt-3"
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W400}
                      fontSize={fontSize.h4.medium}
                      color={ThemeColor.BLACK}
                      align="left"
                    >
                      {data.text}
                    </Typography>

                    <Typography
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W400}
                      fontSize={fontSize.h4.small}
                      color={ThemeColor.RED}
                      sx={{ textDecoration: "underline" }}
                      align="right"
                      onClick={() => {
                        setNoteId(data._id);
                        setConfirmationOpen(true);
                      }}
                    >
                      Delete
                    </Typography>
                  </div>
                </>
              );
            })}
          </Box>
          <Formik
            initialValues={{
              text: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              //("Form data:", values, stdId);

              values["student"] = stdId;

              await AddStudentNotesService(values)
                .then((res) => {
                  if (res.status === 200) {
                    // //(res);
                    dispatch(GetStudentNotesByIdAction(stdId));

                    resetForm();
                  }
                })
                .catch((e) => {
                  // dispatch(showSnackbar(e.response.data.message, "error"));
                });
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="d-flex justify-content-center">
                <Box
                  className="addcatalog-container"
                  sx={{
                    width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                    position: "absolute",
                    bottom: "-10%",
                  }}
                >
                  <FormGroup style={{}}>
                    <div className="d-flex justify-content-between">
                      <div className="px-2 w-100">
                        <OutlinedInputField
                          value={values.text}
                          name={"text"}
                          onChange={handleChange}
                          placeholder="add note text...."
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.text}
                            </Typography>
                          }
                        />
                      </div>
                      <div className="px-3">
                        <CustomButton
                          variant="contained"
                          type="submit"
                          backgroundColor={ThemeColor.PURPLE}
                          color={ThemeColor.WHITE}
                          // loading={formLoading}
                          padding={"0px"}
                          // onClick={handleClick}
                        >
                          Add
                        </CustomButton>
                      </div>
                    </div>
                  </FormGroup>

                  <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-5"></div>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationModal
        handleConfirmationClose={() => {
          setConfirmationOpen(false);
        }}
        deletefunction={async () => {
          try {
            const res = await DeleteStudentNotesService({ stdId, noteId });

            if (res.status === 200) {
              dispatch(GetStudentNotesByIdAction(stdId)); // Dispatch the action if success
              return res; // Return the result so the child can access it
            } else {
              return Promise.reject("Unexpected response status"); // Handle unexpected status codes
            }
          } catch (e) {
            dispatch(
              showSnackbar(
                e.response?.data?.message || "Error occurred",
                "error"
              )
            );
            return e;
          }
        }}
        confirmationopen={confirmationopen}
        message={"All deleted notes is never been retrievable"}
      />
    </>
  );
};
