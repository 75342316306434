import React from "react";
import { Button, CircularProgress, styled } from "@mui/material";
import { fontWeight } from "../theme/fontWeight";
import { fontSize } from "../theme/fontSize";
import { FontFamily } from "../theme/fontFamily";
import { darken } from "@mui/material/styles";
import { ThemeColor } from "../theme/color";

const StyledButton = styled(Button)(
  ({ theme, customColor, textcolor, padding }) => ({
    borderRadius: "40px",
    padding: "5px 25px",
    fontWeight: fontWeight.W600,
    fontSize: fontSize.h3.medium,
    fontFamily: FontFamily.Nunito,
    width: { sm: "100%", xs: "100%", lg: "70%", md: "70%" },
    textTransform: "none",
    backgroundColor: customColor || theme.palette.primary.main,
    color: textcolor,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: customColor
        ? darken(customColor, 0.05)
        : theme.palette.primary.dark,
    },
  })
);

const CustomButton = ({
  variant = "contained",
  color,
  onClick,
  children,
  type,
  backgroundColor,
  loading,
}) => {
  return (
    <StyledButton
      textcolor={color}
      variant={variant}
      type={type}
      onClick={onClick}
      customColor={backgroundColor}
    >
      {loading ? (
        <div className="d-flex align-items-center">
          {children}{" "}
          <div className="px-2 d-flex align-items-center">
            <CircularProgress size={24} color="inherit" className="" />
          </div>
        </div>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default CustomButton;
