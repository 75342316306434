import React, { useEffect, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import Setting from "../../Icon/Setting";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAgentAction,
  GetCatalogAction,
  GetProgramAction,
  GetUniversityAction,
} from "../../Redux/action/SettingAction";
import Message from "../../Icon/Message";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate, useParams } from "react-router-dom";
import FilterComponent from "../../component/filterForTable/FilterComponent";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import axios from "axios";
import { GetDegreeAction } from "../../Redux/action/ApplicationAction";
import ConfirmationModal from "../../component/Modal/ConfirmationModal/ConfirmationModal";
import {
  DeleteProgAvailableService,
  EditProgAvailableService,
} from "../../Redux/service/SettingService";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const ProgramlistPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [Programlist, setProgramlist] = useState([]);

  const [isToggled, setIsToggled] = useState(false);

  const [table_load, setTable_load] = useState(false);

  const [isFilterOpen, setFilterOpen] = useState(false);

  const [Degreelist, setDegreelist] = useState([]);

  const [Universitylist, setUniversitylist] = useState([]);

  const [confirmationopen, setConfirmationOpen] = useState(false);
  const [deleteconfirmationopen, setDeleteConfirmationOpen] = useState(false);

  const [changeBlockStatusOfOrg, setChangeBlockStatusOfOrg] = useState({});
  const [deleteId, setDeleteId] = useState();

  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );

  const DegreelistState = useSelector(
    (state) => state.application?.degree?.data?.degrees
  );

  useEffect(() => {
    dispatch(GetUniversityAction());
    dispatch(GetDegreeAction());
  }, []);

  useEffect(() => {
    setUniversitylist(
      UniversitylistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );

    setDegreelist(
      DegreelistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );
  }, [UniversitylistState, DegreelistState]);

  const fetchData = async (filters) => {
    let string = filters.map((data) => `${data.field}=${data.value}`);
    try {
      let response = await axios.get(
        basicurl + `/api/program/get-all-programs?${string.join("&")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const result = await response.json();
      console.log(response);

      setProgramlist(
        response?.data?.programs?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
      // setData(
      //   response.data.students?.map(({ _id, ...rest }) => ({
      //     id: _id,
      //     ...rest,
      //   }))
      // ); // Assuming the response structure has a `data` key
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleApplyFilters = (filters) => {
    fetchData(filters); // Call the API with filters
  };

  const columns = [
    {
      field: "program",
      headerName: "Program name",
      width: 250,
      //   renderCell: (params) => {
      //     return (
      //       <div style={{ textDecoration: "underline" }}>
      //         {params.row.name}
      //       </div>
      //     );
      //   },
    },
    {
      field: "university",
      headerName: "University name",
      width: 450,
      renderCell: (params) => {
        return (
          <div style={{ textDecoration: "underline" }}>
            {params.row.university.name}
          </div>
        );
      },
    },
    {
      field: "degree",
      headerName: "Degree name",
      width: 390,
      renderCell: (params) => {
        return <div>{params.row.degree.name}</div>;
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const handleDeleteProg = () => {
          setDeleteId(params.row.id);
          setDeleteConfirmationOpen(true);
        };
        return (
          <>
            {/* {window.sessionStorage.getItem("user_role_type") === "admin" && ( */}
            <Tooltip title="delete the program">
              {" "}
              <Box display="flex" alignItems="center" className="px-2 py-2">
                <DeleteIcon sx={{ color: "red" }} onClick={handleDeleteProg} />
              </Box>
            </Tooltip>
            {/* // )} */}
          </>
        );
      },
    },
    {
      field: "blocked",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const handleBlockingStatusChange = async (e) => {
          console.log(e.target.checked);
          setProgramlist((prev) =>
            prev.map((data) =>
              data.id === params.row.id
                ? { ...data, isAvailable: data.isAvailable }
                : data
            )
          );
          setConfirmationOpen(true);
          setChangeBlockStatusOfOrg({
            id: params.row.id,
            isAvailable: e.target.checked,
          });
        };
        return (
          <>
            {window.sessionStorage.getItem("user_role_type") === "admin" && (
              <Tooltip title="change status of block to unblock or vice-versa">
                {" "}
                <Box display="flex" alignItems="center" className="px-2">
                  <Typography>Available</Typography>
                  <Switch
                    sx={{
                      "& .MuiSwitch-track": {
                        // backgroundColor: isToggled ? "white" : "#ccc",
                        transition: "background-color 0.3s",
                        borderRadius: 20,
                      },
                      "& .MuiSwitch-thumb": {
                        width: 24,
                        height: 24,
                        backgroundColor: params.row.isAvailable
                          ? ThemeColor.PURPLE
                          : ThemeColor.BLACK,
                        transition: "transform 0.3s ease",
                        transform: params.row.isAvailable
                          ? "translateX(2px)"
                          : "translateX(0px)",
                      },
                      "& .MuiSwitch-switchBase": {
                        padding: 1,
                        transition: "transform 0.3s",
                        "&.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: ThemeColor.LIGHTPURPLE,
                        },
                      },
                    }}
                    checked={params.row.isAvailable}
                    onChange={(e) => handleBlockingStatusChange(e)}
                    color="primary"
                  />
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => {
    //     const handleEditActionClick = (event) => {
    //       event.stopPropagation();
    //       navigate(`/CAE/EditCatalogandBrochure/${params.row.id}`);
    //     };
    //     return (
    //       <div className="d-flex" onClick={handleEditActionClick}>
    //         Edit
    //       </div>
    //     );
    //   },
    // },
  ];

  const fields = [
    // {
    //   label: "University Name",
    //   value: "university",
    //   type: "select",
    //   option: Universitylist,
    // },
    {
      label: "Degree",
      value: "degree",
      type: "select",
      option: Degreelist,
    },
    ...(!id
      ? [
          {
            label: "University Name",
            value: "university",
            type: "select",
            option: Universitylist,
          },
        ]
      : []),
    {
      label: "Program",
      value: "program",
    },
    { label: "Country", value: "country", type: "text" },
    { label: "State", value: "state", type: "text" },
    { label: "City", value: "city", type: "text" },
    { label: "Contract Type", value: "contractType", type: "select",option:[
      { _id: "Direct", title: "Direct" },
      { _id: "Kaplan", title: "Kaplan" },
      { _id: "EAG", title: "EAG" },
      { _id: "EduCo.", title: "EduCo." },
      { _id: "Shorelight", title: "Shorelight" },
    ] }
  ];

  const ProgramlistState = useSelector(
    (state) => state.setting?.program?.data?.programs
  );
  //(ProgramlistState, "catalog data");

  useEffect(() => {
    setTable_load(true);
    if (id) dispatch(GetProgramAction(id));
    else dispatch(GetProgramAction());
  }, [id]);

  useEffect(() => {
    const updatedData = ProgramlistState?.map(({ _id, ...rest }) => ({
      id: _id,
      ...rest,
    }));
    setProgramlist(updatedData);
    setTable_load(false);
  }, [ProgramlistState]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} md={7} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Program List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },
            }}
          >
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              onClick={() => setFilterOpen(true)}
            >
              Filter
            </OutlineCustomButton>
            {/* {(!(
              window.sessionStorage.getItem("user_role_type") === "agent" &&
              (window.sessionStorage.getItem("role") === "agent_counsellor" ||
                window.sessionStorage.getItem("role") === "admin" ||
                window.sessionStorage.getItem("role") ===
                  "application_supervisor")
            ) ||
              window.sessionStorage.getItem("user_role_type") === "admin" ||
              window.sessionStorage.getItem("user_role_type") === "user") && (
              <div className="px-2">
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  onClick={() => navigate("/CAE/AddProgram")}

                  //   padding={"0px"}
                  // onClick={handleClick}
                >
                  Add Program
                </CustomButton>
              </div>
            )} */}

            {/* <div>
              <Settinglist fill={ThemeColor.GREY} />
            </div> */}
          </Box>
        </Grid>
      </Grid>

      <FilterComponent
        open={isFilterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={handleApplyFilters}
        fields={fields}
      />
      <div className="table-container mt-4" style={{ overflowX: "scroll" }}>
        <CustomDataTable
          row={Programlist ? Programlist : []}
          columns={columns}
          loading={table_load}
          getRowClassName={(params) =>
            params.row.isAvailable ? "row-white" : "row-dull"
          }
        />
      </div>
      <ConfirmationModal
        msg="Updated"
        btnName="Update"
        handleConfirmationClose={() => {
          setProgramlist((prev) =>
            prev.map((data) =>
              data.id === changeBlockStatusOfOrg.id
                ? {
                    ...data,
                    isAvailable: changeBlockStatusOfOrg.isAvailable,
                  }
                : data
            )
          );
          setConfirmationOpen(false);
        }}
        deletefunction={async () => {
          try {
            const res = await EditProgAvailableService(
              changeBlockStatusOfOrg.isAvailable,
              changeBlockStatusOfOrg.id
            );

            if (res.status === 200) {
              dispatch(GetProgramAction(id));
              setIsToggled(!isToggled); // Dispatch the action if success
              return res; // Return the result so the child can access it
            } else {
              return Promise.reject("Unexpected response status"); // Handle unexpected status codes
            }
          } catch (e) {
            dispatch(
              showSnackbar(
                e.response?.data?.message || "Error occurred",
                "error"
              )
            );
            return e;
          }
        }}
        confirmationopen={confirmationopen}
        message={"Want to change the blocking status?"}
      />

      {/* for delete */}
      <ConfirmationModal
        msg="Delete"
        btnName="Delete"
        handleConfirmationClose={() => {
          // setProgramlist((prev) =>
          //   prev.map((data) =>
          //     data.id === changeBlockStatusOfOrg.id
          //       ? {
          //           ...data,
          //           isAvailable: changeBlockStatusOfOrg.isAvailable,
          //         }
          //       : data
          //   )
          // );
          setDeleteConfirmationOpen(false);
        }}
        deletefunction={async () => {
          try {
            const res = await DeleteProgAvailableService(deleteId);

            if (res.status === 200) {
              dispatch(GetProgramAction(id));
              // setIsToggled(!isToggled); // Dispatch the action if success
              return res; // Return the result so the child can access it
            } else {
              return Promise.reject("Unexpected response status"); // Handle unexpected status codes
            }
          } catch (e) {
            dispatch(
              showSnackbar(
                e.response?.data?.message || "Error occurred",
                "error"
              )
            );
            return e;
          }
        }}
        confirmationopen={deleteconfirmationopen}
        message={"Want to delete the program?"}
      />
    </>
  );
};

function Programlist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<ProgramlistPage />} />
    </div>
  );
}

export default Programlist;
