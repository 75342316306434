import React from "react";
import { MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText } from "@mui/material";

const MultiSelect = ({ label, options, value, onChange }) => {
  const handleChange = (event) => {
    const selected = event.target.value;
    if (selected.includes("all")) {
      onChange(value?.length === options?.length ? [] : options?.map((option) => option._id));
    } else {
      onChange(selected);
    }
  };

  const isAllSelected = options?.length > 0 && value?.length === options?.length;

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        renderValue={(selected) =>
          options
            .filter((option) => selected.includes(option._id))
            .map((option) => option.title)
            .join(", ")
        }
      >
        <MenuItem value="all">
          <Checkbox checked={isAllSelected} />
          <ListItemText primary="Select All" />
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            <Checkbox checked={value.includes(option._id)} />
            <ListItemText primary={option.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
