import React, { useCallback, useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Switch,
  TextField,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import Setting from "../../Icon/Setting";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Icon/Message";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate } from "react-router-dom";
import {
  GetAgentTransactionListAction,
  GetStudentAction,
  GetStudentChatByIdAction,
  GetStudentNotesByIdAction,
  GetStudentReminderByIdAction,
  GetUnsettledListAction,
} from "../../Redux/action/ApplicationAction";
import Msg from "../../Icon/Msg";
import StudentChat from "../../Icon/StudentChat";
import moment from "moment";
import {
  DeleteStudentNotesService,
  DeleteStudentReminderService,
  exportForOrganisactionByStudentIdAPI,
  GetTransDetailForSelectedStdService,
  getUncreditedUnsettledTransactionsForSelectedStudentsAPI,
  UpdateStudentTransactionService,
} from "../../Redux/service/Application";
import ConfirmationModal from "../../component/Modal/ConfirmationModal/ConfirmationModal";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { HistoryOutlined } from "@ant-design/icons";

import { PayoutAllReminderModal } from "../../component/payoutsection/AllReminder.component";
import {
  GetOrganizationAction,
  GetUniversityAction,
} from "../../Redux/action/SettingAction";
import axios from "axios";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import FilterComponent from "../../component/filterForTable/FilterComponent";
import { DefaultCommissionModal } from "../../component/payoutsection/DefaultCommissionModal";
import ExcelJS from "exceljs";
import { EditUniversityAmountModal } from "../../component/payoutsection/EditUniversityAmount";
import { FilterExcelUniversity } from "../../component/payoutsection/FilterExcel";
import { UpdateBulkIsCredited } from "../../component/payoutsection/UpdateBulkIsCredited";
import { UpdateBulkIsSettled } from "../../component/payoutsection/UpdateBulkIsSettled";
import * as excelJs from "exceljs";

const PayoutlistPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const [confirmationopen, setConfirmationOpen] = useState(false);

  const [unsettledListlist, setunsettledListlist] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const [HistoryModal, setHistoryModal] = useState(false);

  const [isFilterOpen, setFilterOpen] = useState(false);

  const [edit, setEdit] = useState(false);

  const [stdId, setStdId] = useState("");

  const [transactionId, settransactionId] = useState({});

  const [AgentTransactionlist, setAgentTransactionlist] = useState([]);

  const [noteId, setNoteId] = useState();

  const [reminderId, setReminderId] = useState();

  const [TypeOfdelete, setTypeOfdelete] = useState("");

  const [selectionModel, setSelectionModel] = useState([]);

  const [agentId, setAgentId] = useState(0);

  const [Universitylist, setUniversitylist] = useState([]);

  const [Organizationlist, setOrganizationlist] = useState([]);

  const [isToggled, setIsToggled] = useState(false);

  const [defaultCommisionModal, setDefaultCommisionModal] = useState(false);

  const [FilterExcelModal, setFilterExcelModal] = useState(false);

  const [BulkIsSetteledModal, setBulkIsSetteledModal] = useState(false);

  const [BulkIsCreditedModal, setBulkIsCreditedModal] = useState(false);

  const AgentTransactionlistState = useSelector(
    (state) => state.application?.agenttransaction?.data?.agentTransactions
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //(AgentTransactionlistState, "caht by id data");

  useEffect(() => {
    const updatedData = AgentTransactionlistState?.map(({ _id, ...rest }) => ({
      id: _id,
      ...rest,
    }));
    setAgentTransactionlist(updatedData);
    setTable_load(false);
  }, [AgentTransactionlistState]);

  const columns = [
    {
      field: "history",
      headerName: "Transaction history",
      width: 150,
      renderCell: (params) => {
        const handleHistoryActionClick = (e) => {
          e.stopPropagation();
          setStdId(params.row.id);
          setHistoryModal(true);
          setTable_load(true);
          //(params);
          setAgentId(params.row?.agent?._id);
          dispatch(
            GetAgentTransactionListAction(
              params.row?.organizationId?._id,
              params.row.id
            )
          );
        };
        return (
          <Box className="">
            <IconButton onClick={handleHistoryActionClick}>
              <HistoryOutlined />
            </IconButton>
          </Box>
        );
      },
    },

    {
      field: "studentFirstName",
      headerName: "Student Name",
      width: 250,
      renderCell: (params) => {
        return (
          <Box className="d-flex align-items-center h-100">
            <Typography align="" fontSize={fontSize.h3.medium}>
              <div className="text-center" style={{}}>
                {" "}
                {params.row?.studentFirstName +
                  " " +
                  params.row?.studentLastName}
              </div>
              <div style={{ color: ThemeColor.RED }}>
                {params.row?.isDuplicate ? (
                  <div
                    className="px-4 py-1"
                    style={{
                      backgroundColor: "rgba(221, 32, 37, 0.2)",
                      fontSize: "10px",
                      colo: "red",
                      borderRadius: "15px",
                    }}
                  >
                    Duplicate
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "org",
      headerName: "Organization Name",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className="d-flex align-items-center h-100"
            style={{ textTransform: "lowercase" }}
          >
            <Typography align="" fontSize={fontSize.h3.medium}>
              {params.row?.organizationId?.organizationName}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "university",
      headerName: "University",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{ textDecoration: "underline", textTransform: "lowercase" }}
          >
            {params.row?.university?.name}
          </div>
        );
      },
    },
    {
      field: "universityId",
      headerName: "Student Id/University Id",
      width: 120,
    },
    {
      field: "intake",
      headerName: "intake",
      width: 150,
    },
    {
      field: "visaStatus",
      headerName: "Visa Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Box className="d-flex align-items-center h-100">
            <Typography align="" fontSize={fontSize.h3.medium}>
              {params.row?.visaStatus?.split("_")?.join(" ")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "commissionStatus",
      headerName: "Commission Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Box className="d-flex align-items-center h-100">
            {" "}
            <Typography align="" fontSize={fontSize.h3.medium}>
              {params.row?.commissionStatus?.split("_")?.join(" ")}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "end_date",
      headerName: "Entry Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div>{dayjs(params.row.createdOn).format("YYYY-MM-DD HH:MM")}</div>
        );
      },
    },
  ];

  const unsettledListState = useSelector(
    (state) => state.application?.unsettledList?.data?.allStudents
  );
  //(unsettledListState, "Student data");

  useEffect(() => {
    setTable_load(true);
    let isSettled = false;
    dispatch(GetUnsettledListAction(isSettled));
  }, []);

  useEffect(() => {
    const updatedData = unsettledListState?.map(({ _id, ...rest }) => ({
      id: _id,
      ...rest,
    }));
    setunsettledListlist(updatedData);
    setTable_load(false);
  }, [unsettledListState]);

  const handleDefaultCommissionModal = () => {
    setDefaultCommisionModal(!defaultCommisionModal);
  };

  const handleExport = async () => {
    const selectedRows = unsettledListlist.filter((row) =>
      selectionModel.includes(row.id)
    );
    let agent2 = [{}];
    let agent = [];
    // console.log(selectedRows.map((data)=>data.id))

    let transactionList = [];
    let totals = [];
    let totalOrganisationCommission = "";
    let exchangeRate = "";
    let commission = {};

    await GetTransDetailForSelectedStdService({
      studentIds: selectedRows.map((data) => data.id),
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          transactionList = res.data?.transactions;
          totals = res.data.totals;
          totalOrganisationCommission = res?.data?.totalOrganisationCommission;
          exchangeRate = res?.data?.commission?.exchangeRateUSDToINR;
          commission = res.data?.commission;

          const columns = [
            { header: "First Name", key: "studentId.studentFirstName" },
            { header: "Last Name", key: "studentId.studentLastName" },
            { header: "Student ID", key: "studentId.universityId" },
            { header: "Program", key: "studentId.program.program" },
            { header: "Degree", key: "studentId.degreeType.name" },

            {
              header: "Semester",
              key: "studentId.intake",
            },
            {
              header: "Payment",
              key: "currentTransactionNumber",
            },
            { header: "Amount in USD", key: "organisationCommission" },
          ];

          const columnsList2 = [
            { header: "Gross Commssion", key: "grossCommission" },
            { header: "Gst", key: "gstAmount" },
            // { header: "Invoice total amount", key: "studentId._id" },
            {
              header: "tds",
              key: "tdsAmount",
            },
            {
              header: "cheques payable",
              key: "chequePayable",
            },
          ];

          const transformedData = transactionList.map((item) => {
            const row = {};
            columns.forEach((col) => {
              row[col.header] =
                col.key.split(".").reduce((o, k) => (o || {})[k], item) || ""; // Handle nested keys
            });
            return row;
          });

          // const totalsRows = Object.keys(totals).map((key) => ({
          //   Key: key,
          //   Value: totals[key],
          // }));

          // console.log(totalsRows)

          const keyOrgAmt =
            transactionList.length > 1
              ? transactionList
                  .map((data) => data.organisationCommission)
                  .join("+")
              : `${transactionList[0]?.organisationCommission || 0}`;

          const keyInvoiceAmt = `${totalOrganisationCommission}*${exchangeRate}`;

          const keyGrossAMt = `(${totals.invoiceTotalAmount}/118)*100=${totals.grossCommission}`;

          const keyGstAmt = `(${totals.grossCommission}*${commission.gstPercentage})/100=${totals.gstAmount}`;

          const keyTdsAmt = `(${totals.grossCommission}*${commission.tdsPercentage})/100=${totals.tdsAmount}`;

          const keyChequePayable = `${totals.invoiceTotalAmount}-${totals.tdsAmount}=${totals.chequePayable}`;

          const totalsRows = [
            {
              key: `Total Commision(in USD)`,
              value:
                transactionList.length > 1
                  ? `${keyOrgAmt}=${totalOrganisationCommission}`
                  : keyOrgAmt,
            },
            {
              key: "Invoice Total Amount(in INR)",
              value: `${keyInvoiceAmt}=${totals.invoiceTotalAmount}`,
            },
            { key: "Total Gross Amount(in INR)", value: keyGrossAMt },
            { key: "Total GST Amount(in INR)", value: keyGstAmt },
            { key: "Total TDS Amount(in INR)", value: keyTdsAmt },

            { key: "Total Cheque Payable(in INR)", value: keyChequePayable },
          ];

          // console.log(totalsRows);

          // Create a worksheet for the first dataset
          const worksheet = XLSX.utils.json_to_sheet(transformedData);

          if (transactionList.length > 0) {
            XLSX.utils.sheet_add_json(worksheet, totalsRows, {
              origin: { r: transformedData.length + 2, c: 0 }, // Append to the next empty row
              skipHeader: true, // Include headers for the totals data
            });
          }

          // Append the totals data as separate columns

          // Create a workbook and append the sheet
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "CombinedData");

          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });

          const dataBlob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });

          saveAs(dataBlob, `OrganizationInvoice.xlsx`);
        }
      })
      .catch((err) => {
        console.log(err, "err of get trans detail for selected std");
        dispatch(showSnackbar(err.response.data.message, "error"));
      });

    // selectedRows.map((data) => {
    //   //(data);
    //   if (agent.length === 0 || !agent.some((item) => item === data.organizationId._id))
    //     agent.push(data.organizationId._id);
    // });
    //(agent);

    // const array = [];

    // agent.map((type) => {
    //   //(type);
    //   array.push(selectedRows.filter((data) => data.organizationId._id === type));
    // });

    // array.map((data) => {
    //   const worksheet = XLSX.utils.json_to_sheet(data);
    //   const workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedData");
    //   const excelBuffer = XLSX.write(workbook, {
    //     bookType: "xlsx",
    //     type: "array",
    //   });
    //   const dataBlob = new Blob([excelBuffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    //   });
    //   saveAs(dataBlob, `${data[0].organizationId.organizationName}.xlsx`);
    // });
  };

  const handleExportForUniv = async (univ, name) => {
    console.log(name);
    const selectedRows = unsettledListlist.filter((row) =>
      selectionModel.includes(row.id)
    );

    let transactionList = [];

    if (name === "Organisation-Transaction") {
      console.log("name for org")
      await exportForOrganisactionByStudentIdAPI({
        studentIds: selectedRows.map((data) => data.id),
      })
        .then((res) => {
          if (res.status === 200) {
            transactionList = res.data?.transactions.filter(
              (data) => data.commissionStatus !== "not_approved"
            );
            console.log(transactionList);
          }
        })
        .catch((err) => {
          console.log(err, "Error fetching transaction details");
        });
    } else {
      console.log("for inovice",name)
      await getUncreditedUnsettledTransactionsForSelectedStudentsAPI({
        studentIds: selectedRows.map((data) => data.id),
      })
        .then((res) => {
          if (res.status === 200) {
            if (name === "University-Invoice") {
              transactionList = res.data?.transactions.filter(
                (data) =>
                  data?.studentId.commissionStatus !== "pending"
              );
              console.log(transactionList);
            } 
            else
            {
              transactionList = res.data?.transactions
              console.log(transactionList);


            }
            
          }
        })
        .catch((err) => {
          console.log(err, "Error fetching transaction details");
        });
    }

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Transactions");

    // Define columns
    const columns = [
      { header: "First Name", key: "studentFirstName", width: 20 },
      { header: "Last Name", key: "studentLastName", width: 20 },
      { header: "Date of Birth", key: "dateOfBirth", width: 15 },
      { header: "Student ID", key: "universityId", width: 15 },
      { header: "Program", key: "program", width: 20 },
      { header: "Degree", key: "degree", width: 20 },
      { header: "Semester", key: "intake", width: 15 },
      { header: "Payment", key: "currentTransactionNumber", width: 15 },
      ...(name !== "Organisation-Transaction"
        ? [
            {
              header: "University Amount",
              key: "receivedFromUniversity",
              width: 20,
            },
          ]
        : [{ header: "Organisation Name", key: "organisation", width: 20 }]),
      { header: "Commission Status", key: "commissionStatus", width: 20 },
      {
        header: "Commission Status Notes",
        key: "commissionStatusNotes",
        width: 25,
      },
      { header: "Credited", key: "isCredited", width: 10 },
      { header: "Settled", key: "isSettled", width: 10 },
      { header: "Notes", key: "notes", width: 30 },
    ];

    // Assign columns to the worksheet
    worksheet.columns = columns;

    // Populate data
    transactionList.forEach((item) => {
      const rowData = {
        studentFirstName: item?.studentId?.studentFirstName || "",
        studentLastName: item?.studentId?.studentLastName || "",
        dateOfBirth: item?.studentId?.dateOfBirth || "",
        universityId: item?.studentId?.universityId || "",
        program: item?.studentId?.program?.program || "",
        degree: item?.studentId?.degreeType?.name || "",
        intake: item?.studentId?.intake || "",
        currentTransactionNumber: item?.currentTransactionNumber || "",
        receivedFromUniversity: item?.receivedFromUniversity || "",
        organisation: item?.studentId?.organizationId?.organizationName || "",
        commissionStatus: item?.studentId?.commissionStatus || "",
        commissionStatusNotes: item?.studentId?.commissionStatusNotes || "",
        isCredited: item?.isCredited ? "Yes" : "No",
        isSettled: item?.isSettled ? "Yes" : "No",
        notes: item?.notes || "",
      };

      worksheet.addRow(rowData);
    });

    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFADD8E6" },
    };

    // Apply data validation for "Commission Status" dropdown
    const lastRow = worksheet.rowCount; // Get last row number
    const commissionStatusOptions = ["pending", "approved", "not_approved"];

    for (let i = 2; i <= lastRow; i++) {
      worksheet.getCell(`J${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${commissionStatusOptions.join(",")}"`], // Dropdown list
      };
    }

    // Write Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, `${name}.xlsx`);
  };

  const fetchData = async (filters) => {
    let string = filters.map((data) => `${data.field}=${data.value}`);
    try {
      let response = await axios.get(
        basicurl +
          `/api/student/get-visa-approved-unsettled-student-list?${string.join(
            "&"
          )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const result = await response.json();
      console.log(response);

      setunsettledListlist(
        response?.data?.allStudents?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
      // setData(
      //   response.data.students?.map(({ _id, ...rest }) => ({
      //     id: _id,
      //     ...rest,
      //   }))
      // ); // Assuming the response structure has a `data` key
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleApplyFilters = (filters) => {
    fetchData(filters); // Call the API with filters
  };

  const fields = [
    {
      label: "University Name",
      value: "university",
      type: "select",
      option: Universitylist,
    },
    {
      label: "Organization",
      value: "organisationId",
      type: "select",
      option: Organizationlist,
    },
    {
      label: "Intake",
      value: "intake",
      type: "text",
    },
    {
      label: "Commission Status",
      value: "commissionStatus",
      type: "multi-select",
      option: [
        { _id: "pending", title: "Pending" },
        { _id: "approved", title: "Approved" },
        { _id: "not_approved", title: "Not Approved" },
      ],
    },
    {
      label: "Is Settled",
      value: "isToggled",
      type: "select",
      option: [
        { _id: true, title: "Yes" },
        { _id: false, title: "No" },
      ],
    },
  ];

  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );
  const OrganizationlistState = useSelector(
    (state) => state.setting?.organization?.data
  );

  useEffect(() => {
    dispatch(GetUniversityAction());
    dispatch(GetOrganizationAction());
  }, []);

  const handleStatusChange = async () => {
    const newToggleState = !isToggled;
    if (newToggleState) {
      dispatch(GetUnsettledListAction(true));
    } else {
      dispatch(GetUnsettledListAction(false));
    }
    setIsToggled(newToggleState);
  };

  useEffect(() => {
    setUniversitylist(
      UniversitylistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );

    setOrganizationlist(
      OrganizationlistState?.organizations?.map(
        ({ organizationName, ...rest }) => ({
          title: organizationName,
          ...rest,
        })
      )
    );
  }, [UniversitylistState, OrganizationlistState]);

  const handleCloseHistoryModal = () => setHistoryModal(!HistoryModal);
  const handleCloseFilterExcelModal = () => {
    dispatch(GetUnsettledListAction(false));

    setFilterExcelModal(!FilterExcelModal);
  };

  const handleCloseIsCreditedModal = () => {
    dispatch(GetUnsettledListAction(false));

    setBulkIsCreditedModal(!BulkIsCreditedModal);
  };

  const handleCloseIsSettledModal = () => {
    dispatch(GetUnsettledListAction(false));
    setBulkIsSetteledModal(!BulkIsSetteledModal);
  };

  const checkYearOfIntake = (data) => {
    const currentYear = moment().year();
    const intakeYear = moment(data?.intake?.split(" ")?.[1], "YYYY").year();
    console.log(
      intakeYear >= currentYear - 2,
      data?.intake?.split(" ")?.[1],
      moment(data?.intake?.split(" ")?.[1], "YYYY").year()
    );

    return intakeYear >= currentYear - 2;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4} md={7} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Payout List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={8} md={5} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },
              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            <div className="px-2">
              {" "}
              <OutlineCustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                onClick={() => setDefaultCommisionModal(!defaultCommisionModal)}
              >
                Default Commission Details
              </OutlineCustomButton>
            </div>
            <div className="px-2">
              {" "}
              <OutlineCustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                onClick={() => setFilterOpen(true)}
              >
                Filter
              </OutlineCustomButton>
            </div>

            <Box
              display="flex"
              sx={{ border: `2px solid ${ThemeColor.GREY}`, borderRadius: 25 }}
              alignItems="center"
              className="px-2"
            >
              <Typography>Settled</Typography>
              <Switch
                sx={{
                  "& .MuiSwitch-track": {
                    // backgroundColor: isToggled ? "white" : "#ccc",
                    transition: "background-color 0.3s",
                    borderRadius: 20,
                  },
                  "& .MuiSwitch-thumb": {
                    width: 24,
                    height: 24,
                    backgroundColor: isToggled
                      ? ThemeColor.PURPLE
                      : ThemeColor.BLACK,
                    transition: "transform 0.3s ease",
                    transform: isToggled
                      ? "translateX(2px)"
                      : "translateX(0px)",
                  },
                  "& .MuiSwitch-switchBase": {
                    padding: 1,
                    transition: "transform 0.3s",
                    "&.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: ThemeColor.LIGHTPURPLE,
                    },
                  },
                }}
                checked={isToggled ? true : false}
                onChange={handleStatusChange}
                color="primary"
              />
            </Box>
            <div className="px-2">
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                onClick={handleClick}
                // onClick={() => handleExport()}
              >
                Export
              </CustomButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() =>
                    handleExportForUniv(true, "University-Transaction")
                  }
                >
                  Export For University{" "}
                </MenuItem>
                <MenuItem onClick={() => handleCloseFilterExcelModal()}>
                  Import University Transaction{" "}
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleExportForUniv(true, "University-Invoice")
                  }
                >
                  Export For University Invoice
                </MenuItem>
                <MenuItem onClick={() => handleCloseIsCreditedModal()}>
                  Import Credited Amount
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleExportForUniv(false, "Organisation-Transaction")
                  }
                >
                  Export For Organisation
                </MenuItem>
                <MenuItem onClick={handleExport}>
                  Export For Organisation Invoice
                </MenuItem>
                <MenuItem onClick={() => handleCloseIsSettledModal()}>
                  Import Settled Amount
                </MenuItem>
              </Menu>
            </div>

            {/* <div>
              <Settinglist fill={ThemeColor.GREY} />
            </div> */}
          </Box>
        </Grid>
      </Grid>
      <div className="table-container mt-4" style={{ overflowX: "scroll" }}>
        <CustomDataTable
          row={
            unsettledListlist
              ? unsettledListlist.filter((data) => {
                  const isRecentIntake = checkYearOfIntake(data);

                  // If intake year is recent, keep all entries
                  // Otherwise, filter out only if commissionStatus is "not_approved"
                  return (
                    isRecentIntake || data.commissionStatus !== "not_approved"
                  );
                })
              : []
          }
          columns={columns}
          loading={table_load}
          rowheight={100}
          onSelectionChange={(newSelection) => {
            //(newSelection, "new selection");
            // setTable_load(true)
            setSelectionModel(newSelection);
          }}
          // getRowClassName={(params) =>
          //   params.row.isActive === "Y" ? "row-white" : "row-dull"
          // }
        />
      </div>

      {/* modal for all reminder window */}
      <PayoutAllReminderModal
        HistoryModal={HistoryModal}
        handleClose={handleCloseHistoryModal}
        AgentTransactionlist={AgentTransactionlist}
      />

      <ConfirmationModal
        handleConfirmationClose={() => {
          setConfirmationOpen(false);
        }}
        deletefunction={async () => {
          if (TypeOfdelete === "notes") {
            try {
              const res = await DeleteStudentNotesService({ stdId, noteId });

              if (res.status === 200) {
                dispatch(GetStudentNotesByIdAction(stdId)); // Dispatch the action if success
                return res; // Return the result so the child can access it
              } else {
                return Promise.reject("Unexpected response status"); // Handle unexpected status codes
              }
            } catch (e) {
              dispatch(
                showSnackbar(
                  e.response?.data?.message || "Error occurred",
                  "error"
                )
              );
              return e; // Return the error to the child if needed
            }
          } else {
            try {
              const res = await DeleteStudentReminderService({ reminderId });

              if (res.status === 200) {
                dispatch(GetStudentReminderByIdAction(stdId)); // Dispatch the action if success
                return res; // Return the result so the child can access it
              } else {
                return Promise.reject("Unexpected response status"); // Handle unexpected status codes
              }
            } catch (e) {
              dispatch(
                showSnackbar(
                  e.response?.data?.message || "Error occurred",
                  "error"
                )
              );
              return e; // Return the error to the child if needed
            }
          }
        }}
        confirmationopen={confirmationopen}
        message={
          TypeOfdelete === "notes"
            ? "All deleted notes is never been retrievable"
            : "All deleted reminder is never been retrievable"
        }
      />

      <FilterComponent
        open={isFilterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={handleApplyFilters}
        fields={fields}
      />

      <DefaultCommissionModal
        open={defaultCommisionModal}
        onClose={handleDefaultCommissionModal}
      />

      <FilterExcelUniversity
        open={FilterExcelModal}
        onClose={handleCloseFilterExcelModal}
      />

      <UpdateBulkIsCredited
        open={BulkIsCreditedModal}
        onClose={handleCloseIsCreditedModal}
      />

      <UpdateBulkIsSettled
        open={BulkIsSetteledModal}
        onClose={handleCloseIsSettledModal}
      />
    </>
  );
};

function Payoutlist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<PayoutlistPage />} />
    </div>
  );
}

export default Payoutlist;
