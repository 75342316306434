import React from "react";

function Crossicon({width,height}) {
  return (
    <div>
      <svg
        width={width?width:"7"}
        height={height?height:"7"}
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L6 6M1 6L6 1"
          stroke="#FF1F1F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Crossicon;
