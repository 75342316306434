import React, { useEffect, useState } from "react";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import { Box, IconButton, List, Typography } from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import { AddPersonToChatChatModal } from "./AddPersonToChat";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import axios from "axios";
import { Badge } from "antd";

export const ChatListNav = ({ toggleDrawer, Chatlist, generate, isOpen }) => {
  const [chatList, setChatList] = useState(Chatlist);



  return (
    <>
      <IconButton onClick={toggleDrawer} className="drawer-toggle-btn">
        {!isOpen ? <AddIcon /> : <CloseIcon />}
      </IconButton>
      <div
        className={`drawer ${isOpen ? "open" : ""}`}
        style={{ zIndex: 10, overflowY: "scroll" }}
      >
        <button className="close-btn" onClick={toggleDrawer}>
          X
        </button>
        {/* <Box className="mt-5">
          <Typography
            className="d-flex"
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.medium}
            color={ThemeColor.WHITE}
            align="left"
          >
            Person to Person{" "}
          </Typography>
          <List>
            {generate(
              Chatlist?.filter((data) => data.isGroupChat === false),
              <ChatIcon sx={{ fill: ThemeColor.WHITE }} />
            )}
          </List>
        </Box> */}
        <Box className="mt-5">
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.medium}
            color={ThemeColor.WHITE}
            align="left"
            className="d-flex"
          >
            Chat List
          </Typography>
          <List className="w-100">
            {generate(
              Chatlist?.filter((data) => data.isGroupChat === true),
                <ChatIcon sx={{ fill: ThemeColor.WHITE }} />
            )}
          </List>
        </Box>
      </div>
    </>
  );
};
