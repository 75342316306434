import React from "react";

function Delete() {
  return (
    <div>
      <svg
        width="16"
        height="21"
        viewBox="0 0 16 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.47 19.53H3C1.9 19.53 1 18.63 1 17.53V4.01001H14.47V17.53C14.47 18.63 13.57 19.53 12.47 19.53Z"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.5 4.01V2C10.5 1.45 10.05 1 9.50002 1H5.96002C5.41002 1 4.96002 1.45 4.96002 2V4.01"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.62 7.23999V15.2"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.83997 7.23999V15.2"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Delete;
