import React from "react";

function FolderShare() {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_40_1905)">
          <path
            d="M5.93194 12.7362L4.1792 10.9835L6.29644 8.86621C8.21117 6.95148 11.3265 6.95152 13.2413 8.86621L11.4886 10.6189C10.5404 9.67071 8.99737 9.67067 8.04914 10.6189L5.93194 12.7362Z"
            fill="#8B8D8E"
          />
          <path
            d="M12.3601 14.6582C11.0484 14.6582 9.81517 14.1474 8.88769 13.2198C6.973 11.3051 6.97296 8.18973 8.88769 6.275L13.094 2.06871C14.0216 1.14118 15.2547 0.630371 16.5664 0.630371C17.8781 0.630371 19.1113 1.14118 20.0389 2.06871C20.9664 2.99623 21.4772 4.22948 21.4772 5.54114C21.4772 6.8528 20.9664 8.08605 20.0389 9.01357L15.8325 13.2199C14.905 14.1474 13.6719 14.6582 12.3601 14.6582ZM16.5664 3.10907C15.9168 3.10907 15.3061 3.36207 14.8467 3.8214L10.6404 8.0277C9.69215 8.97598 9.69215 10.5189 10.6404 11.4671C11.0997 11.9265 11.7105 12.1795 12.3601 12.1795C13.0097 12.1795 13.6204 11.9265 14.0798 11.4672L18.2861 7.26084C18.7455 6.8015 18.9985 6.19074 18.9985 5.54114C18.9985 4.8915 18.7455 4.28078 18.2861 3.8214C17.8268 3.36207 17.216 3.10907 16.5664 3.10907Z"
            fill="#646768"
          />
          <path
            d="M14.7783 3.02536C14.5375 3.02536 14.3041 2.89749 14.18 2.67143C13.999 2.34138 14.1198 1.92704 14.4498 1.74597C15.7981 1.00643 17.4197 1.00239 18.682 1.73548C19.0075 1.92454 19.1181 2.34173 18.929 2.6673C18.7399 2.99283 18.3228 3.10344 17.9972 2.91437C17.1585 2.42719 16.0233 2.43768 15.1055 2.94123C15.0052 2.99639 14.8927 3.02533 14.7783 3.02536ZM5.56275 21.4531C4.30518 21.4531 3.04766 20.9744 2.09031 20.0171C0.175625 18.1024 0.175625 14.9869 2.09031 13.0722L5.11811 10.0444L6.8708 11.7972L3.84301 14.825C2.89477 15.7732 2.89477 17.3162 3.84301 18.2644C4.31708 18.7385 4.94 18.9755 5.5627 18.9756C6.18558 18.9757 6.80824 18.7386 7.28244 18.2644L11.4887 14.0581C11.9481 13.5987 12.2011 12.988 12.2011 12.3384C12.2011 11.6888 11.9481 11.078 11.4887 10.6187L13.2415 8.86593C15.1562 10.7807 15.1562 13.8961 13.2415 15.8108L9.03518 20.0171C8.07784 20.9744 6.82031 21.4531 5.56275 21.4531Z"
            fill="#8B8D8E"
          />
        </g>
        <defs>
          <clipPath id="clip0_40_1905">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default FolderShare;
