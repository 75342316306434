import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { FontFamily } from "../theme/fontFamily";
import { fontWeight } from "../theme/fontWeight";
import { fontSize } from "../theme/fontSize";
import { IconButton, InputAdornment, InputLabel } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeColor } from "../theme/color";

const StyledTextField = styled(TextField)(
  ({ theme, customColor, padding }) => ({
    "& label.MuiInputLabel-root": {
      color: ThemeColor.BLACK, // Default label color
      fontFamily: FontFamily.Lato,
      fontWeight: fontWeight.W500,
      fontSize: fontSize.h4.medium,
    },
    "& label.Mui-focused": {
      color: ThemeColor.LIGHTPURPLE,
      fontSize: fontSize.h4.large,
      // Label color when focused
    },
    // "& label.Mui-focused": {
    //   display: "none",
    // },
    // "& .MuiInput-underline:after": {
    //   bordeRadius: "30px",
    //   display: "none",
    // },
    "& .MuiOutlinedInput-root": {
      borderColor: "none",
      // borderRadius: "30px",
      // backgroundColor: "#F3F3F3",
      padding: padding,
      borderWidth: "2px",
      borderColor: ThemeColor.GREY,
      "& fieldset": {
        borderColor: "rgba(217, 217, 217, 3)",
      },
      "& input": {
        height: "12px",
      },
      "&:hover fieldset": {
        borderColor: "#F3F3F3",
      },
      // "&.Mui-focused fieldset": {
      //   display: "none",
      // },
      "&.Mui-focused fieldset": {
        borderColor: ThemeColor.LIGHTPURPLE, // Change this to your desired border color when focused
      },
      "& input::placeholder": {
        color: ThemeColor.BLACK,
        opacity: 2,
        fontFamily: FontFamily.Lato,
        fontWeight: fontWeight.W400,
        fontSize: fontSize.h4.medium,
      },
      "&.Mui-disabled": {
        "& fieldset": {
          fontSize:20,
          border: "none", // Remove the border when disabled
        },
      },
    },
  })
);

const OutlinedInputField = ({
  label,
  value,
  onChange,
  name,
  type,
  placeholder = "",
  fullWidth = true,
  helperText,
  padding,
  disable,
  onBlur,
}) => {
  const [showPassword, setShowPassword] = useState(type === "password");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <StyledTextField
        padding={padding}
        label={label}
        labelId="custom-select-label"
        id="custom-select"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        placeholder={placeholder}
        fullWidth={fullWidth}
        variant="outlined"
        className="mb-3"
        helperText={helperText}
        disabled={disable}
        InputProps={
          type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
    </>
  );
};

export default OutlinedInputField;
