import * as Yup from "yup";

export const validationCommisionSchema = Yup.object().shape({
  tdsPercentage: Yup.string().required("Tds Percentage is required!"),

  receivedFromUniversity: Yup.string().required(
    "Received from university is required!"
  ),
  exchangeRateUSDToINR: Yup.string().required("Required exchange rate!"),
  gstPercentage: Yup.string().required("Gst percentage required!"),
});

export const validationDefaultCommisionSchema = Yup.object().shape({
  tdsPercentage: Yup.string().required("Tds Percentage is required!"),
  exchangeRateUSDToINR: Yup.string().required("Required exchange rate!"),
  gstPercentage: Yup.string().required("Gst percentage required!"),
});

export const validationTransactionSchema = Yup.object().shape({
  gstAmount: Yup.string().required("GST Amount is required!"),

  grossCommission: Yup.string().required("Gross Commission is required!"),
  tdsAmount: Yup.string().required("TDS Amount required!"),
  chequePayable: Yup.string().required("Cheques Payable required!"),
});

export const validationUniversityAmountSchema = Yup.object().shape({
  receivedFromUniversity: Yup.string().required(
    "Received from university is required!"
  ),
});
