import axios from "axios";
import { basicurl } from "../ServiceUrl/url";

// const basicurl = basicurl;

const token = window.sessionStorage.getItem("user_token");

export const saveSupportTicket = async (data) => {
  let res = await axios.post(
    basicurl + "/api/support/create-new-support-ticket",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const getAllSupport = async () => {
  let res = await axios.get(basicurl + "/api/support/get-all-support-tickets", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const AddSupportChat = async (data) => {
  let res = await axios.post(
    basicurl + "/api/support/add-message-to-support-ticket",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const updateSupportTicketStatus = async (status, id) => {
  let res = await axios.put(
    basicurl + "/api/support/update-ticket-status",
    {
      ticketId: id,
      isSettled: status,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
