import {
  AllCountTotalUnseenMessagesConst,
  GET_ALL_NOTIFICATION,
  GetUnseenNotificationCountConst,
} from "../constant/NotificationConstant";

const notificationState = {
  allNotification: [],
  AllCountTotalUnseenMessages: [],
  GetUnseenNotificationCount: [],
};

export const NotificationReducer = (state = notificationState, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION:
      return { ...state, allNotification: action.payload };
    case AllCountTotalUnseenMessagesConst:
      return { ...state, AllCountTotalUnseenMessages: action.payload };
    case GetUnseenNotificationCountConst:
      return { ...state, GetUnseenNotificationCount: action.payload };
    default:
      return state;
  }
};
