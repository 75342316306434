import { Box, FormGroup, Grid, Modal, Typography, styled } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../select field/OutlinedSelectField";
import {
  AddPaymentDetailsAfterVisaApprovedService,
  CreateDefaultCommissionService,
  UpdateStudentTransactionService,
  calcualteAgentCommissionService,
  getDefaultCommissionDetailsService,
} from "../../Redux/service/Application";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import OnlyDatePicker from "../datepicker/MyDatepicker";
import {
  GetAgentTransactionListAction,
  GetStudentAction,
  GetUnsettledListAction,
} from "../../Redux/action/ApplicationAction";
import Previews from "../dropzone/Mydropzone";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import {
  validationCommisionSchema,
  validationDefaultCommisionSchema,
  validationTransactionSchema,
} from "./ValidationConstant";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const DefaultCommissionModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const [DefaultCommission, setDefaultCommission] = useState();

  const getDefaultCommission = async () => {
    await getDefaultCommissionDetailsService().then((res) => {
      if (res.status === 200) {
        setDefaultCommission(res.data.commission);
      }
    }).catch((err)=>
    {
        console.log("err",err)
    })
  };

  useEffect(() => {
    getDefaultCommission();
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography align="right" onClick={() => onClose()}>
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Update default Commssion details
        </Typography>
        <Formik
          innerRef={formikRef}
          initialValues={{
            exchangeRateUSDToINR: DefaultCommission
              ? DefaultCommission?.exchangeRateUSDToINR
              : "",
            gstPercentage: DefaultCommission
              ? DefaultCommission?.gstPercentage
              : "",
            tdsPercentage: DefaultCommission
              ? DefaultCommission?.tdsPercentage
              : "",
          }}
          // validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await CreateDefaultCommissionService(values)
              .then((res) => {
                if (res.status === 200) {
                  dispatch(showSnackbar(res?.data.message, "success"));
                  resetForm();
                  onClose();
                }
              })
              .catch((err) => {
                dispatch(showSnackbar(err.response.data.message, "error"));
              });
          }}
          validationSchema={validationDefaultCommisionSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="mt-3"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Exchange Rate USD To INR
                        </Typography>
                        <OutlinedInputField
                          value={values.exchangeRateUSDToINR}
                          name={`exchangeRateUSDToINR`}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          placeholder="Enter exchange rate USD To INR"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.exchangeRateUSDToINR}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Gst Percentage
                        </Typography>
                        <OutlinedInputField
                          value={values.gstPercentage}
                          name={`gstPercentage`}
                          onChange={(e) => {
                            // handleInputChange(
                            //   e.target.value,
                            //   transactionId?.agentTransactionId
                            // );
                            handleChange(e);
                          }}
                          placeholder="Enter Gst Percentage"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.gstPercentage}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Tds Percentage
                        </Typography>
                        <OutlinedInputField
                          value={values.tdsPercentage}
                          name={`tdsPercentage`}
                          onChange={(e) => {
                            // handleInputChange(
                            //   e.target.value,
                            //   transactionId?.agentTransactionId
                            // );
                            handleChange(e);
                          }}
                          placeholder="Enter Tds Percentage"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.tdsPercentage}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <Box
                      sx={{
                        flexDirection: {
                          lg: "row",
                          md: "row",
                          sm: "column",
                          xs: "column",
                        },
                      }}
                      className="d-flex justify-content-center align-items-center w-100 mt-2 mb-3"
                    >
                      {" "}
                      <CustomButton
                        variant="contained"
                        type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                      >
                        Add Details
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
