import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import dayjs from "dayjs";
import CustomButton from "../../component/button/button";
import CustomDataTable from "../../component/datatable/DataTable";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import { useDispatch, useSelector } from "react-redux";

import Message from "../../Icon/Message";
import SnackbarComponent from "../../component/snackbar/snackbar";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate } from "react-router-dom";
import {
  GetScheduleSendMailUsing365Action,
  GetSentMailAction,
} from "../../Redux/action/EmailAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  heigh: "70vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};
const SentEmaillistPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [msgBody, setMsgBody] = useState();

  const handleOpen = () => {
    setOpen(true);
    // setScheduleState(true);
  };
  const handleClose = () => setOpen(false);

  const [SentEmaillist, setSentEmaillist] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const columns = [
    {
      field: "from",
      headerName: "Email from",
      width: 250,
    },
    {
      field: "receivedDateTime",
      headerName: "Email Received Date/Time",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            {dayjs(params.row.receivedDateTime).format("YYYY-MM-DD HH:MM")}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const handleActionClick = (event) => {
          event.stopPropagation(); // Prevent the row from being selected when clicking the icon
          // dispatch(showSnackbar("This is a success message!", "success"));
          // //("Edit clicked for ID:", params.row.id);
          handleOpen();
          setMsgBody(params.row.body);
        };
        return (
          <div>
            <IconButton onClick={handleActionClick}>
              <Message />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const handlePaginationChange = (newPaginationModel, direction) => {
    setPaginationModel(newPaginationModel);
    //('Page Model:', newPaginationModel);
    //('Pagination Direction:', direction); // Logs "left" or "right"
  };
  const SentEmaillistState = useSelector(
    (state) => state.email?.sentemail?.data?.data
  );
  //(SentEmaillistState);

  useEffect(() => {
    setTable_load(true);
    dispatch(GetSentMailAction());
  }, []);

  useEffect(() => {
    if (Array.isArray(SentEmaillistState)) {
      const updatedData = SentEmaillistState?.map(
        ({ _id, ...rest }, index) => ({
          id: index,
          ...rest,
        })
      );
      setSentEmaillist(updatedData);
      //(SentEmaillistState);
    }
    if (SentEmaillistState != undefined) setTable_load(false);
  }, [SentEmaillistState]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} md={7} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Sent Email List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },
            }}
          >
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              //   color={ThemeColor.WHITE}
              //   padding={"0px"}
              // onClick={handleClick}
            >
              Action
            </OutlineCustomButton>
            <div className="px-2">
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                //   padding={"0px"}
                onClick={() => navigate("/CAE/Sendmail")}
              >
                Add Email
              </CustomButton>
            </div>

            <div>
              <Settinglist />
            </div>
          </Box>
        </Grid>
      </Grid>
      <div className="table-container" style={{ overflowX: "scroll" }}>
        <CustomDataTable
          row={SentEmaillist ? SentEmaillist : []}
          columns={columns}
          loading={table_load}
          onPaginationChange={handlePaginationChange}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="h11-100" style={{ height: "50vh" }}>
            {" "}
            <div
              dangerouslySetInnerHTML={{ __html: msgBody }} // Display the raw HTML
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

function SentEmaillist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<SentEmaillistPage />} />
    </div>
  );
}

export default SentEmaillist;
