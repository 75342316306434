import {
  GetAgentTransactionListConst,
  GetAllBranchListConst,
  GetDegreeListConst,
  GetStudentChatByIdConst,
  GetStudentListConst,
  GetStudentNotesByIdConst,
  GetStudentReminderByIdConst,
  GetUnsettledListConst,
} from "../constant/ApplicationConstant";
import {
  GetAgentTransactionListService,
  GetAllBranchlistService,
  GetDegreelistService,
  GetStudentChatByIdService,
  GetStudentNotesByIdService,
  GetStudentReminderByIdService,
  GetStudentlistService,
  GetUnsettledListService,
} from "../service/Application";

export const GetStudentAction = () => async (dispatch) => {
  try {
    const data = await GetStudentlistService();

    dispatch({ type: GetStudentListConst, payload: data });
  } catch (error) {
    dispatch({ type: GetStudentListConst, payload: error });
  }
};

export const GetDegreeAction = () => async (dispatch) => {
  try {
    const data = await GetDegreelistService();

    dispatch({ type: GetDegreeListConst, payload: data });
  } catch (error) {
    dispatch({ type: GetDegreeListConst, payload: error });
  }
};

export const GetAllBranchAction = () => async (dispatch) => {
  try {
    const data = await GetAllBranchlistService();

    dispatch({ type: GetAllBranchListConst, payload: data });
  } catch (error) {
    dispatch({ type: GetAllBranchListConst, payload: error });
  }
};
export const GetUnsettledListAction = (isSettled) => async (dispatch) => {
  try {
    const data = await GetUnsettledListService(isSettled);

    dispatch({ type: GetUnsettledListConst, payload: data });
  } catch (error) {
    dispatch({ type: GetUnsettledListConst, payload: error });
  }
};

export const GetAgentTransactionListAction = (orgId,stdId) => async (dispatch) => {
  try {
    const data = await GetAgentTransactionListService(orgId,stdId);

    dispatch({ type: GetAgentTransactionListConst, payload: data });
  } catch (error) {
    dispatch({ type: GetAgentTransactionListConst, payload: error });
  }
};

export const GetStudentChatByIdAction = (id) => async (dispatch) => {
  try {
    const data = await GetStudentChatByIdService(id);

    dispatch({ type: GetStudentChatByIdConst, payload: data });
  } catch (error) {
    dispatch({ type: GetStudentChatByIdConst, payload: error });
  }
};
export const GetStudentNotesByIdAction = (id) => async (dispatch) => {
  try {
    const data = await GetStudentNotesByIdService(id);

    dispatch({ type: GetStudentNotesByIdConst, payload: data });
  } catch (error) {
    dispatch({ type: GetStudentNotesByIdConst, payload: error });
  }
};
export const GetStudentReminderByIdAction = (id) => async (dispatch) => {
  try {
    const data = await GetStudentReminderByIdService(id);

    dispatch({ type: GetStudentReminderByIdConst, payload: data });
  } catch (error) {
    dispatch({ type: GetStudentReminderByIdConst, payload: error });
  }
};
