import logo from "./logo.svg";
import "./App.css";
import Login from "./page/login/Login.page";
import Cfaeroutes from "./routing/cfae.route";
import ResponsiveSidebar from "./component/sidebar/sidebar.component";
import SnackbarComponent from "./component/snackbar/snackbar";
import FolderManagement from "./page/foldermanagement/foldermanagement.page";
import ExcelDropdown from "./page/Setting/TestingImportExcel";
import ExcelToJson from "./page/Test";



function App() {
  return (
    <>
      {/* <Tempautocomplete /> */}
      <Cfaeroutes />
      {/* <ExcelToJson/> */}
      {/* <ExcelDropdown/> */}
      {/* <UserSelectForm/> */}

      {/* <ResponsiveSidebar/> */}
      {/* <MyForm orgOptions={dynamicOrgOptions} /> */}

    </>
  );
}

export default App;

