import React, { useEffect, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import Setting from "../../Icon/Setting";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAgentAction,
  GetCatalogAction,
} from "../../Redux/action/SettingAction";
import Message from "../../Icon/Message";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const CatalogueBrochurelistPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [CatalogueBrochurelist, setCatalogueBrochurelist] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const columns = [
    {
      field: "university_name",
      headerName: "University name",
      width: 450,
      renderCell: (params) => {
        return (
          <div style={{ textDecoration: "underline" }}>
            {params.row?.university?.name}
          </div>
        );
      },
    },
    {
      field: "folder_name",
      headerName: "Folder name",
      width: 450,
      renderCell: (params) => {
        return (
          <div style={{ textDecoration: "underline" }}>
            {params.row.folderId?.name}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const handleEditActionClick = (event) => {
          event.stopPropagation();
          navigate(`/CAE/EditCatalogandBrochure/${params.row.id}`);
        };
        return (
          <Tooltip title="Edit Organization">
            {" "}
            <IconButton onClick={handleEditActionClick}>
              <ModeEditIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const CatalogueBrochurelistState = useSelector(
    (state) => state.setting?.catalog?.data?.data
  );
  //(CatalogueBrochurelistState, "catalog data");

  useEffect(() => {
    setTable_load(true);
    dispatch(GetCatalogAction());
  }, []);

  useEffect(() => {
    const updatedData = CatalogueBrochurelistState?.map(({ _id, ...rest }) => ({
      id: _id,
      ...rest,
    }));
    setCatalogueBrochurelist(updatedData);
    setTable_load(false);
  }, [CatalogueBrochurelistState]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} md={7} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Catalogue/Brochure List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },
            }}
          >
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              //   color={ThemeColor.WHITE}
              //   padding={"0px"}
              // onClick={handleClick}
            >
              Action
            </OutlineCustomButton>
            <div className="px-2">
              {(!window.sessionStorage.getItem("user_role_type") === "agent" ||
                window.sessionStorage.getItem("user_role_type") === "admin" ||
                window.sessionStorage.getItem("user_role_type") === "user") && (
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  onClick={() => navigate("/CAE/AddCatalogueBrochure")}

                  //   padding={"0px"}
                  // onClick={handleClick}
                >
                  Add Catalogue
                </CustomButton>
              )}
            </div>

            <div>
              <Settinglist fill={ThemeColor.GREY} />
            </div>
          </Box>
        </Grid>
      </Grid>
      <div className="table-container mt-4" style={{ overflowX: "scroll" }}>
        <CustomDataTable
          row={CatalogueBrochurelist ? CatalogueBrochurelist : []}
          columns={columns}
          loading={table_load}
          // getRowClassName={(params) =>
          //   params.row.isActive === "Y" ? "row-white" : "row-dull"
          // }
        />
      </div>
    </>
  );
};

function CatalogueBrochurelist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<CatalogueBrochurelistPage />} />
    </div>
  );
}

export default CatalogueBrochurelist;
