import React from "react";

function Search() {
  return (
    <div>
      <svg
        width="23"
        height="25"
        viewBox="0 0 23 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_150_2654)">
          <path
            d="M22.298 20.1277L17.0619 14.9295C18.4082 13.4018 19.1458 11.4797 19.1458 9.47874C19.1458 4.80364 15.1611 1 10.2633 1C5.36562 1 1.38086 4.80364 1.38086 9.47874C1.38086 14.1538 5.36562 17.9575 10.2633 17.9575C12.102 17.9575 13.8542 17.4281 15.3522 16.4232L20.6281 21.6609C20.8486 21.8795 21.1452 22 21.463 22C21.7639 22 22.0493 21.8905 22.2659 21.6914C22.7263 21.2686 22.7409 20.5675 22.298 20.1277ZM10.2633 3.21185C13.8835 3.21185 16.8287 6.0231 16.8287 9.47874C16.8287 12.9344 13.8835 15.7456 10.2633 15.7456C6.64316 15.7456 3.69803 12.9344 3.69803 9.47874C3.69803 6.0231 6.64316 3.21185 10.2633 3.21185Z"
            fill="#ADADAD"
          />
        </g>
        <defs>
          <clipPath id="clip0_150_2654">
            <rect
              width="22"
              height="21"
              fill="white"
              transform="translate(1 1)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default Search;
