import React, { useState, useEffect, useCallback } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import dayjs from "dayjs";
import CustomButton from "../../component/button/button";
import CustomDataTable from "../../component/datatable/DataTable";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import { useDispatch, useSelector } from "react-redux";

import Message from "../../Icon/Message";
import SnackbarComponent from "../../component/snackbar/snackbar";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate } from "react-router-dom";
import { GetScheduleSendMailUsing365Action } from "../../Redux/action/EmailAction";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { AutoComplete, Input } from "antd";
import { GetScheduleSendMailUsing365ListService } from "../../Redux/service/MailService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  heigh: "200px",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};
const EmaillistPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const [open, setOpen] = React.useState(false);

  const [msgBody, setMsgBody] = useState();

  const handleOpen = () => {
    setOpen(true);
    // setScheduleState(true);
  };
  const handleClose = () => setOpen(false);

  const [Emaillist, setEmaillist] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const handlePaginationChange = (newPaginationModel, direction) => {
    setPaginationModel(newPaginationModel);
    if (direction === "right") {
      let data = {
        limit: newPaginationModel.pageSize * newPaginationModel.page + 1,
        page: newPaginationModel.page + 1,
      };
      //(data);
      dispatch(GetScheduleSendMailUsing365Action(data));
      // AddNewEmailToList()
    }
    //("Page Model:", newPaginationModel);
    //("Pagination Direction:", direction); // Logs "left" or "right"
  };

  const columns = [
    {
      field: "from",
      headerName: "Email from",
      width: 250,
    },
    {
      field: "receivedDateTime",
      headerName: "Email Received Date/Time",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            {dayjs(params.row.receivedDateTime).format("YYYY-MM-DD HH:MM")}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const handleActionClick = (event) => {
          event.stopPropagation(); // Prevent the row from being selected when clicking the icon
          // dispatch(showSnackbar("This is a success message!", "success"));
          // //("Edit clicked for ID:", params.row.id);
          handleOpen();
          setMsgBody(params.row.body);
        };
        return (
          <div>
            <IconButton onClick={handleActionClick}>
              <Message />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const EmaillistState = useSelector((state) => state.email?.email);
  //(EmaillistState);

  useEffect(() => {
    setTable_load(true);
    let data = {
      limit: 10,
      page: 1,
    };
    dispatch(GetScheduleSendMailUsing365Action(data));
  }, []);

  useEffect(() => {
    // if(typeof(data)===)
    if (Array.isArray(EmaillistState)) {
      const updatedData = EmaillistState?.map(
        ({ messageId, ...rest }, index) => ({
          id: messageId,
          ...rest,
        })
      );
      setEmaillist(updatedData);
    }

    if (EmaillistState != undefined) setTable_load(false);
  }, [EmaillistState]);

  const fetchOptions = async (query) => {
    try {
      //(query);
      let data = {
        email: query,
      };
      const response = await GetScheduleSendMailUsing365ListService(data);
      const email = response.data?.data;
      //(email, "email");
      if (email.length > 0) {
        const updatedData = email?.map(({ messageId, ...rest }, index) => ({
          id: messageId,
          ...rest,
        }));
        setEmaillist(updatedData);
      } else {
        setEmaillist([]);
      }

      // setOptions([
      //   {
      //     label: <Title title="Search Results" />,
      //     options: fetchedOptions,
      //   },
      // ]);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      if (value) {
        fetchOptions(value);
      } else {
        //("else part when value is not their");
        const updatedData = EmaillistState?.map(
          ({ messageId, ...rest }, index) => ({
            id: messageId,
            ...rest,
          })
        );
        //("updated data else part =>", updatedData);
        setEmaillist(updatedData);
      }
    }, 300),
    [EmaillistState]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} md={7} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Email List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },
            }}
          >
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              //   color={ThemeColor.WHITE}
              //   padding={"0px"}
              // onClick={handleClick}
            >
              Action
            </OutlineCustomButton>
            <div className="px-2">
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                //   padding={"0px"}
                onClick={() => navigate("/CAE/Sendmail")}
              >
                Add Email
              </CustomButton>
            </div>

            <div>
              <Settinglist />
            </div>
          </Box>
        </Grid>
      </Grid>
      <div
        className="table-container d-flex flex-column"
        style={{ overflowX: "scroll" }}
      >
        <div className="mb-3 w-100 d-flex justify-content-end px-2">
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={"100%"}
            // style={{
            //   width: "30%",
            // }}
            className="autocomplete"
            // options={options}
            onSearch={handleInputChange}
            size="large"
          >
            <Input.Search size="large" placeholder="input here" />
          </AutoComplete>
        </div>

        <CustomDataTable
          row={Emaillist ? Emaillist : []}
          columns={columns}
          loading={table_load}
          onPaginationChange={handlePaginationChange}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="h11-100" style={{ height: "50vh" }}>
            {" "}
            <div
              dangerouslySetInnerHTML={{ __html: msgBody }} // Display the raw HTML
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

function Emaillist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<EmaillistPage />} />
    </div>
  );
}

export default Emaillist;
