import { GetUniversityListConst } from "../constant/SettingConstant";
import {
  HIDE_SNACKBARConst,
  SHOW_SNACKBARConst,
} from "../constant/SnackbarConstant";

const initTheme = {
  open: false,
  message: "",
  severity: "info",
};

const SnackbarReducer = (state = initTheme, action) => {
  // //(action,"line 15");
  switch (action.type) {
    case SHOW_SNACKBARConst:
      return {
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    case HIDE_SNACKBARConst:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default SnackbarReducer;
