import {
  FolderStackConst,
  GetFileListConst,
  GetFolderAndFilesByParentlistConst,
  GetFolderListConst,
} from "../constant/FileManagerConstant";

const initTheme = {
  file: [],
  folder: [],
  folderstack: [],
  subFolder:[]
};

const FileManagerReducer = (state = initTheme, action) => {
  switch (action.type) {
    case GetFolderListConst:
      return { ...state, folder: action.payload };
    case GetFileListConst:
      return { ...state, file: action.payload };
    case GetFolderAndFilesByParentlistConst:
      return { ...state, subFolder: action.payload };
    case FolderStackConst:
      return { ...state, folderstack: [...state.folderstack, action.payload] };

    default:
      return state;
  }
};

export default FileManagerReducer;
