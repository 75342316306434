import React, { useEffect, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { Grid, Typography } from "@mui/material";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import "../Setting/settingPage.css";
import { ThemeColor } from "../../component/theme/color";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetSettingCountAction } from "../../Redux/action/SettingAction";
import { GetMicrosoftAccessTokenCodeService } from "../../Redux/service/MailService";

const SettingsDashboard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const code = searchParams?.get("code");

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (!window.sessionStorage.getItem("ms_auth") && code != null) {
        try {
          const res = await GetMicrosoftAccessTokenCodeService(code);
          if (res.status === 200) {
            //(res, "res from get access token");
            window.sessionStorage.setItem("ms_auth", res.data.accessToken);
          }
        } catch (err) {
          //("ms auth token error", err);
        }
      }
    };

    fetchAccessToken();
  }, [code]);

  const [settingCountData, setSettingCountData] = useState([]);

  useEffect(() => {
    dispatch(GetSettingCountAction());
  }, []);

  const SettingCountState = useSelector(
    (state) => state.setting?.settingCount?.data?.data
  );
  //(SettingCountState);

  useEffect(() => {
    setSettingCountData(SettingCountState);
  }, [SettingCountState]);

  return (
    <div>
      <Typography
        fontFamily={FontFamily.Poppins}
        fontWeight={fontWeight.W600}
        fontSize={fontSize.h2.small}
      >
        Settings
      </Typography>
      <Grid container spacing={4} pt={5}>
        {((window.sessionStorage.getItem("user_role_type") === "agent" &&
          (window.sessionStorage.getItem("role") === "admin" ||
            window.sessionStorage.getItem("role") === "agent_accountant")) ||
          window.sessionStorage.getItem("user_role_type") === "admin" ||
          window.sessionStorage.getItem("user_role_type") === "user") && (
          <Grid item xs={12} lg={4}>
            <div className="setting-card w-100">
              <div className="setting-card-left h-100">
                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h3.medium}
                  fontWeight={fontWeight.W500}
                >
                  Organization
                </Typography>
                <div className="setting-card-link-sec w-100">
                  {(window.sessionStorage.getItem("user_role_type") ===
                    "admin" ||
                    !window.sessionStorage.getItem("user_role_type") ===
                      "user") && (
                    <Typography
                      fontFamily={FontFamily.Ubuntu}
                      fontSize={fontSize.h4.medium}
                      fontWeight={fontWeight.W500}
                      color={ThemeColor.RED}
                      className="underline-hover"
                      onClick={() => {
                        navigate("/CAE/CreateOrganization");
                      }}
                    >
                      Add New
                    </Typography>
                  )}
                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="px-3 underline-hover"
                    onClick={() => {
                      navigate("/CAE/OrganizationList");
                    }}
                  >
                    View All
                  </Typography>
                </div>
              </div>
              <div className="setting-card-right">
                <div className="w-100 d-flex justify-content-center align-item-center h-100">
                  <div className="setting-per">
                    <Typography
                      fontFamily={FontFamily.Nunito}
                      fontSize={fontSize.h2.medium}
                      fontWeight={fontWeight.W500}
                    >
                      {settingCountData?.organizationCount}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={12} lg={4}>
          <div className="setting-card w-100">
            <div className="setting-card-left h-100">
              <Typography
                fontFamily={FontFamily.Ubuntu}
                fontSize={fontSize.h3.medium}
                fontWeight={fontWeight.W500}
              >
                Universities
              </Typography>
              <div className="setting-card-link-sec w-100">
                {(!window.sessionStorage.getItem("user_role_type") ===
                  "agent" ||
                  window.sessionStorage.getItem("user_role_type") === "admin" ||
                  !window.sessionStorage.getItem("user_role_type") ===
                    "user") && (
                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="underline-hover"
                    onClick={() => {
                      navigate("/CAE/CreateUniversity");
                    }}
                  >
                    Add New
                  </Typography>
                )}

                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h4.medium}
                  fontWeight={fontWeight.W500}
                  color={ThemeColor.RED}
                  className="px-3 underline-hover"
                  onClick={() => {
                    navigate("/CAE/Universitylist");
                  }}
                >
                  View All
                </Typography>
              </div>
            </div>
            <div className="setting-card-right">
              <div className="w-100 d-flex justify-content-center align-item-center h-100">
                <div className="setting-per">
                  <Typography
                    fontFamily={FontFamily.Nunito}
                    fontSize={fontSize.h2.medium}
                    fontWeight={fontWeight.W500}
                  >
                    {settingCountData?.universityCount}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        {(!window.sessionStorage.getItem("user_role_type") === "agent" ||
          window.sessionStorage.getItem("user_role_type") === "admin" ||
          window.sessionStorage.getItem("user_role_type") === "user") && (
          <Grid item xs={12} lg={4}>
            <div className="setting-card w-100">
              <div className="setting-card-left h-100">
                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h3.medium}
                  fontWeight={fontWeight.W500}
                >
                  CAE Users
                </Typography>
                <div className="setting-card-link-sec w-100">
                  {(!window.sessionStorage.getItem("user_role_type") ===
                    "agent" ||
                    window.sessionStorage.getItem("user_role_type") ===
                      "admin" ||
                    !window.sessionStorage.getItem("user_role_type") ===
                      "user") && (
                    <Typography
                      fontFamily={FontFamily.Ubuntu}
                      fontSize={fontSize.h4.medium}
                      fontWeight={fontWeight.W500}
                      color={ThemeColor.RED}
                      className="underline-hover"
                      onClick={() => {
                        navigate("/CAE/AddUser");
                      }}
                    >
                      Add New
                    </Typography>
                  )}

                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="px-3 underline-hover"
                    onClick={() => {
                      navigate("/CAE/UserList");
                    }}
                  >
                    View All
                  </Typography>
                </div>
              </div>
              <div className="setting-card-right">
                <div className="w-100 d-flex justify-content-center align-item-center h-100">
                  <div className="setting-per">
                    <Typography
                      fontFamily={FontFamily.Nunito}
                      fontSize={fontSize.h2.medium}
                      fontWeight={fontWeight.W500}
                    >
                      {settingCountData?.caeUserCount}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        )}

        {(!(
          window.sessionStorage.getItem("user_role_type") === "agent" &&
          (window.sessionStorage.getItem("role") === "agent_counsellor" ||
            window.sessionStorage.getItem("role") === "agent_accountant")
        ) ||
          window.sessionStorage.getItem("user_role_type") === "admin" ||
          window.sessionStorage.getItem("user_role_type") === "user") && (
          <Grid item xs={12} lg={4}>
            <div className="setting-card w-100">
              <div className="setting-card-left h-100">
                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h3.medium}
                  fontWeight={fontWeight.W500}
                >
                  Agent-User
                </Typography>
                <div className="setting-card-link-sec w-100">
                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="underline-hover"
                    onClick={() => {
                      navigate("/CAE/AddAgent");
                    }}
                  >
                    Add New
                  </Typography>
                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="px-3 underline-hover"
                    onClick={() => {
                      navigate("/CAE/AgentList");
                    }}
                  >
                    View All
                  </Typography>
                </div>
              </div>
              <div className="setting-card-right">
                <div className="w-100 d-flex justify-content-center align-item-center h-100">
                  <div className="setting-per">
                    <Typography
                      fontFamily={FontFamily.Nunito}
                      fontSize={fontSize.h2.medium}
                      fontWeight={fontWeight.W500}
                    >
                      {settingCountData?.agentCount}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        )}
        {
          <Grid item xs={12} lg={4}>
            <div className="setting-card w-100">
              <div className="setting-card-left h-100">
                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h3.medium}
                  fontWeight={fontWeight.W500}
                >
                  Program
                </Typography>
                <div className="setting-card-link-sec w-100">
                  {(!(
                    window.sessionStorage.getItem("user_role_type") ===
                      "agent" &&
                    (window.sessionStorage.getItem("role") ===
                      "agent_counsellor" ||
                      window.sessionStorage.getItem("role") === "admin" ||
                      window.sessionStorage.getItem("role") ===
                        "application_supervisor")
                  ) ||
                    window.sessionStorage.getItem("user_role_type") ===
                      "admin" ||
                    window.sessionStorage.getItem("user_role_type") ===
                      "user") && (
                    <Typography
                      fontFamily={FontFamily.Ubuntu}
                      fontSize={fontSize.h4.medium}
                      fontWeight={fontWeight.W500}
                      color={ThemeColor.RED}
                      className="underline-hover"
                      onClick={() => {
                        navigate("/CAE/AddProgram");
                      }}
                    >
                      Add New
                    </Typography>
                  )}

                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="px-3 underline-hover"
                    onClick={() => {
                      navigate("/CAE/Programlist");
                    }}
                  >
                    View All
                  </Typography>
                </div>
              </div>
              <div className="setting-card-right">
                <div className="w-100 d-flex justify-content-center align-item-center h-100">
                  <div className="setting-per">
                    <Typography
                      fontFamily={FontFamily.Nunito}
                      fontSize={fontSize.h2.medium}
                      fontWeight={fontWeight.W500}
                    >
                      {settingCountData?.programCount}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        }

        <Grid item xs={12} lg={4}>
          <div className="setting-card w-100">
            <div className="setting-card-left h-100">
              <Typography
                fontFamily={FontFamily.Ubuntu}
                fontSize={fontSize.h3.medium}
                fontWeight={fontWeight.W500}
              >
                Catalog / Brochure
              </Typography>
              <div className="setting-card-link-sec w-100">
                {(!window.sessionStorage.getItem("user_role_type") ===
                  "agent" ||
                  window.sessionStorage.getItem("user_role_type") === "admin" ||
                  window.sessionStorage.getItem("user_role_type") ===
                    "user") && (
                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="underline-hover"
                    onClick={() => {
                      navigate("/CAE/AddCatalogueBrochure");
                    }}
                  >
                    Add New
                  </Typography>
                )}
                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h4.medium}
                  fontWeight={fontWeight.W500}
                  color={ThemeColor.RED}
                  className="px-3 underline-hover"
                  onClick={() => {
                    navigate("/CAE/CatalogueBrochureList");
                  }}
                >
                  View All
                </Typography>
              </div>
            </div>
            <div className="setting-card-right">
              <div className="w-100 d-flex justify-content-center align-item-center h-100">
                <div className="setting-per">
                  <Typography
                    fontFamily={FontFamily.Nunito}
                    fontSize={fontSize.h2.medium}
                    fontWeight={fontWeight.W500}
                  >
                    {settingCountData?.catalogueBrochureCount}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className="setting-card w-100">
            <div className="setting-card-left h-100">
              <Typography
                fontFamily={FontFamily.Ubuntu}
                fontSize={fontSize.h3.medium}
                fontWeight={fontWeight.W500}
              >
                Process Sheet
              </Typography>
              <div className="setting-card-link-sec w-100">
                {(!window.sessionStorage.getItem("user_role_type") ===
                  "agent" ||
                  window.sessionStorage.getItem("user_role_type") === "admin" ||
                  window.sessionStorage.getItem("user_role_type") ===
                    "user") && (
                  <Typography
                    fontFamily={FontFamily.Ubuntu}
                    fontSize={fontSize.h4.medium}
                    fontWeight={fontWeight.W500}
                    color={ThemeColor.RED}
                    className="underline-hover"
                    onClick={() => {
                      navigate("/CAE/AddProcessSheet");
                    }}
                  >
                    Add New
                  </Typography>
                )}
                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h4.medium}
                  fontWeight={fontWeight.W500}
                  color={ThemeColor.RED}
                  className="px-3 underline-hover"
                  onClick={() => {
                    navigate("/CAE/Processlist");
                  }}
                >
                  View All
                </Typography>
              </div>
            </div>
            <div className="setting-card-right">
              <div className="w-100 d-flex justify-content-center align-item-center h-100">
                <div className="setting-per">
                  <Typography
                    fontFamily={FontFamily.Nunito}
                    fontSize={fontSize.h2.medium}
                    fontWeight={fontWeight.W500}
                  >
                    {settingCountData?.processSheetCount}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        {window.sessionStorage.getItem("user_role_type") === "admin" && (
          //  ||
          //   window.sessionStorage.getItem("user_role_type") === "user"
          <Grid item xs={12} lg={4}>
            <div className="setting-card w-100">
              <div className="setting-card-left h-100">
                <Typography
                  fontFamily={FontFamily.Ubuntu}
                  fontSize={fontSize.h3.medium}
                  fontWeight={fontWeight.W500}
                >
                  Default Intake
                </Typography>
                <div className="setting-card-link-sec w-100">
                  {
                    <Typography
                      fontFamily={FontFamily.Ubuntu}
                      fontSize={fontSize.h4.medium}
                      fontWeight={fontWeight.W500}
                      color={ThemeColor.RED}
                      className="underline-hover"
                      onClick={() => {
                        navigate("/CAE/AddDefaultIntake");
                      }}
                    >
                      Add New
                    </Typography>
                  }
                </div>
              </div>
              <div className="setting-card-right">
                <div className="w-100 d-flex justify-content-center align-item-center h-100">
                  <div className="setting-per">
                    <Typography
                      fontFamily={FontFamily.Nunito}
                      fontSize={fontSize.h2.medium}
                      fontWeight={fontWeight.W500}
                    >
                      {settingCountData?.processSheetCount}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

function Dashboard() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<SettingsDashboard />} />
    </div>
  );
}

export default Dashboard;
