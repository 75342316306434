import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import { GetUniversityAction } from "../../Redux/action/SettingAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddDefaultIntakeService,
  CreateCatalogService,
  CreateProcessService,
  EditProcessService,
  GetDefaultIntakeService,
} from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { UploadFileService } from "../../Redux/service/FileService";
import OnlyDatePicker from "../../component/datepicker/MyDatepicker";
import { DefaultIntakeValidationSchema } from "./ValidationConstant";
import axios from "axios";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const AddDefaultIntakePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const childRef = useRef(null);

  const { id } = useParams();

  const [Edit, setEdit] = useState(id ? true : false);

  const [prefieldData, setPrefieldData] = useState();

  const EditProcessdataState = useSelector(
    (state) => state.setting?.process?.data?.data
  );
  //(EditProcessdataState);
  useEffect(() => {
    setPrefieldData(
      EditProcessdataState?.filter((data) => data._id === id)?.[0]
    );
  }, [Edit, EditProcessdataState, id]);
  const [formLoading, setFormLoading] = useState(false);
  const [defaultIntake, setDefaultIntake] = useState();

  const GetDefaultIntake = async () => {
    GetDefaultIntakeService()
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setDefaultIntake(res.data?.defaultIntake?.[0]?.intake);
        }
      })
      .catch((err) => {
        console.log("error getting default intake=>", err);
      });
  };

  useEffect(() => {
    GetDefaultIntake();
  }, []);

  if (!prefieldData && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h2.small}
        >
          Settings
        </Typography>
        <Typography
          className="px-2 pt-1"
          fontFamily={FontFamily.Poppins}
          fontSize={fontSize.h4.medium}
          fontWeight={fontWeight.W300}
          color={ThemeColor.GREY}
        >
          {!Edit ? "Create" : "Edit"} Default Intake
        </Typography>
      </div>
      <div className="container">
        <Formik
          initialValues={{
            intake: Edit ? prefieldData?.intake?.split(" ")?.[0] : "",
            intakeyear: Edit ? prefieldData?.intake?.split(" ")?.[1] : null,
          }}
          onSubmit={async (values, { ressetForm }) => {
            values["intake"] = `${values.intake} ${values.intakeyear}`;

            delete values.intakeyear;
            setFormLoading(false);

            await AddDefaultIntakeService(values)
              .then((res) => {
                if (res.status === 200) {
                  // //(res);
                  setFormLoading(false);

                  dispatch(showSnackbar(res.data.message, "success"));
                  GetDefaultIntake();

                  ressetForm();
                }
              })
              .catch((e) => {
                setFormLoading(false);
                dispatch(showSnackbar(e.response.data.message, "error"));
              });
          }}
          validationSchema={DefaultIntakeValidationSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form
              className="d-flex justify-content-center"
              onSubmit={handleSubmit}
            >
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "80%", lg: "80%" },
                }}
              >
                <Typography
                  align="center"
                  fontFamily={FontFamily.Poppins}
                  fontWeight={fontWeight.W600}
                  fontSize={fontSize.h3.medium}
                  color={ThemeColor.PURPLE}
                >
                  {!Edit ? "Add" : "Edit"} Default Intake
                </Typography>

                <FormGroup style={{marginTop: 10,}}>
                  <div className="">
                    <CustomSelect
                      value={values?.intake}
                      name={"intake"}
                      multiple={false}
                      option={[
                        { _id: "fall", title: "Fall" },
                        { _id: "spring", title: "Spring" },
                        { _id: "summer", title: "Summer" },
                      ]}
                      onChange={(e) => {
                        setFieldValue("intake", e.target.value);
                      }}
                      fullWidth={true}
                      label={"Intake *"}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.intake}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>

                <FormGroup style={{}}>
                  <div className="">
                    <OnlyDatePicker
                      views={["year"]}
                      label="Year of Intake *"
                      format="YYYY"
                      openTo="year"
                      value={
                        values.intakeyear
                          ? dayjs(values.intakeyear).isValid()
                            ? dayjs(values.intakeyear).format("YYYY")
                            : null
                          : null
                      }
                      // value={dayjs(values.contacts[index].reminder_date).toDate()}
                      onChange={(date) => {
                        setFieldValue(`intakeyear`, dayjs(date).format("YYYY"));
                      }}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.intakeyear}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>

                {(!window.sessionStorage.getItem("user_role_type") ===
                  "agent" ||
                  window.sessionStorage.getItem("user_role_type") === "admin" ||
                  window.sessionStorage.getItem("user_role_type") ===
                    "user") && (
                  <Box
                    sx={{
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                    className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
                  >
                    {" "}
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                      loading={formLoading}
                      //   padding={"0px"}
                      // onClick={handleClick}
                    >
                      {!Edit ? "Save" : "Edit"} Default Intake
                    </CustomButton>
                    <Box
                      className="px-2"
                      sx={{
                        paddingTop: {
                          lg: "0px",
                          md: "0px",
                          sm: "5px",
                          xs: "5px",
                        },
                      }}
                    >
                      {Edit && (
                        <CustomButton
                          variant="contained"
                          // type="submit"
                          backgroundColor={ThemeColor.PURPLE}
                          color={ThemeColor.WHITE}
                          loading={formLoading}
                          //   padding={"0px"}
                          onClick={() => {
                            // setFieldValue('birthday', null);
                            if (childRef.current) {
                              childRef.current.someFunction();
                            }
                            setFieldValue("documents", []);
                            // //(values)
                            resetForm();
                          }}
                        >
                          Revert
                        </CustomButton>
                      )}
                    </Box>
                  </Box>
                )}

                <Box>
                  <Typography
                  className="mt-5"
                  align="center"
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.large}
                    fontFamily={FontFamily.Lato}
                  >
                    Default Intake : {defaultIntake}
                  </Typography>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function AddDefaultIntake() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<AddDefaultIntakePage />} />
    </div>
  );
}

export default AddDefaultIntake;
