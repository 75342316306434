import axios from "axios";
import { basicurl, token } from "../ServiceUrl/url";

export const getAllNotificationAPI = async () => {
  let res = await axios.get(
    basicurl + "/api/reminder/get-user-all-notifications",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const createGlobalNotificationAPI = async (data) => {
  let res = await axios.post(
    basicurl + "/api/reminder/create-global-notification",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const createUserNotificationAPI = async (data) => {
  let res = await axios.post(
    basicurl + "/api/reminder/send-notification-to-users",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const getAllCountTotalUnseenMessagesAPI = async () => {
  let res = await axios.get(basicurl + "/api/chat/countTotalUnseenMessages", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};


export const postmarkSeenMessagesAPI = async (id) => {
  let res = await axios.post(basicurl + `/api/chat/markMessagesAsSeen/${id}`,id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const GetUnseenNotificationCountAPI = async () => {
  let res = await axios.get(basicurl + `/api/reminder/getUnseenNotificationCount`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const MarkNotificationSeenAPI = async (id) => {
  let res = await axios.post(basicurl + `/api/reminder/markNotificationAsSeenById/${id}`,id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
