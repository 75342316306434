import React, { useState, useEffect, useCallback } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import Setting from "../../Icon/Setting";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import { useDispatch, useSelector } from "react-redux";

import { GetUniversityAction } from "../../Redux/action/SettingAction.js";
import Msg from "../../Icon/Msg";
import Message from "../../Icon/Message";
import SnackbarComponent from "../../component/snackbar/snackbar";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ConfirmationModal from "../../component/Modal/ConfirmationModal/ConfirmationModal";
import { EditUnivBlockedStatusService } from "../../Redux/service/SettingService";
import { handleExport } from "../ExportFunction/ExportFunction";
import FilterComponent from "../../component/filterForTable/FilterComponent";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListIcon from "@mui/icons-material/List";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";


const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const UniversitylistPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Universitylist, setUniversitylist] = useState([]);
  const [UniversitylistStateData, setUniversitylistStateData] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const [isToggled, setIsToggled] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [confirmationopen, setConfirmationOpen] = useState(false);
  const [changeBlockStatusOfOrg, setChangeBlockStatusOfOrg] = useState({});
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [data, setData] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  // const handleVisibleColumnsChange = useCallback((visibleColumns) => {
  //   console.log("handele visible",visibleColumns)

  //   // Handle the change in visible columns
  //   setVisibleColumns(visibleColumns);
  // }, []);

  const handleVisibleColumnsChange = (columns) => {
    console.log("Visible Columns in Parent:", columns);
    setVisibleColumns(columns);
  };

  const columns = [
    ...(!(
      window.sessionStorage.getItem("user_role_type") === "agent" &&
      window.sessionStorage.getItem("role") !== "admin"
    )
      ? [
          {
            field: "action",
            headerName: "Action",
            width: 250,
            renderCell: (params) => {
              const handleEditActionClick = (event) => {
                event.stopPropagation();

                navigate(`/CAE/EditUniversity/${params.row.id}`);
              };
              const handleAddProgramClick = (event) => {
                event.stopPropagation();
                navigate(`/CAE/AddProgram/${params.row.id}`);
              };
              const handleListProgramClick = (event) => {
                event.stopPropagation();
                navigate(`/CAE/ProgramList/${params.row.id}`);
              };
              return (
                <>
                  {" "}
                  {window.sessionStorage.getItem("user_role_type") ===
                    "agent" ||
                  window.sessionStorage.getItem("role") === "admin" ? (
                    <Tooltip title="View University">
                      {" "}
                      <IconButton onClick={handleEditActionClick}>
                        <RemoveRedEyeIcon
                          sx={{ fill: ThemeColor.LIGHTPURPLE }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <div>
                      <Tooltip title="Edit University">
                        {" "}
                        <IconButton onClick={handleEditActionClick}>
                          <ModeEditIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Add Program">
                        {" "}
                        <IconButton onClick={handleAddProgramClick}>
                          <AddCircleIcon
                            sx={{ fill: ThemeColor.LIGHTPURPLE }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="List Program">
                        {" "}
                        <IconButton onClick={handleListProgramClick}>
                          <ListIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </>
              );
            },
          },
        ]
      : []),

    {
      field: "name",
      headerName: "University name",
      width: 250,
      renderCell: (params) => {
        return (
          <div style={{ textDecoration: "underline" }}>{params.row.name}</div>
        );
      },
    },
    {
      field: "state",
      headerName: "City & state",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            {params.row.city} {params.row.state}
          </div>
        );
      },
    },
    // {
    //   field: "degree_prog",
    //   headerName: "Degree & Program",
    //   width: 250,
    //   renderCell: (params) => {
    //     return <div>{params.row.degree}</div>;
    //   },
    // },
    // {
    //   field: "commission",
    //   headerName: "Commission",
    //   width: 150,
    // },
    {
      field: "contractExpireDate",
      headerName: "Contact End Date",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            {dayjs(params.row.contractExpireDate).format("YYYY-MM-DD HH:MM")}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            {params.row?.isActive === "Y" ? "Active" : "In-active"}
          </div>
        );
      },
    },

    {
      field: "isBlocked",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const handleBlockingStatusChange = async (e) => {
          console.log(e.target.checked);
          setUniversitylist((prev) =>
            prev.map((data) =>
              data.id === params.row.id
                ? { ...data, isBlocked: data.isBlocked === "Y" ? "N" : "Y" }
                : data
            )
          );
          setConfirmationOpen(true);
          setChangeBlockStatusOfOrg({
            id: params.row.id,
            isBlocked: e.target.checked ? "Y" : "N",
          });
        };
        return (
          <>
            {window.sessionStorage.getItem("user_role_type") === "admin" && (
              <Tooltip title="change status of block to unblock or vice-versa">
                {" "}
                <Box display="flex" alignItems="center" className="px-2">
                  <Typography>Blocked</Typography>
                  <Switch
                    sx={{
                      "& .MuiSwitch-track": {
                        // backgroundColor: isToggled ? "white" : "#ccc",
                        transition: "background-color 0.3s",
                        borderRadius: 20,
                      },
                      "& .MuiSwitch-thumb": {
                        width: 24,
                        height: 24,
                        backgroundColor:
                          params.row.isBlocked === "Y"
                            ? ThemeColor.PURPLE
                            : ThemeColor.BLACK,
                        transition: "transform 0.3s ease",
                        transform:
                          params.row.isBlocked === "Y"
                            ? "translateX(2px)"
                            : "translateX(0px)",
                      },
                      "& .MuiSwitch-switchBase": {
                        padding: 1,
                        transition: "transform 0.3s",
                        "&.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: ThemeColor.LIGHTPURPLE,
                        },
                      },
                    }}
                    checked={params.row.isBlocked === "Y"}
                    onChange={(e) => handleBlockingStatusChange(e)}
                    color="primary"
                  />
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
    // {
    //   field: "action",
    //   headerName: "",
    //   width: 150,
    //   renderCell: (params) => {
    //     const handleActionClick = (event) => {
    //       event.stopPropagation(); // Prevent the row from being selected when clicking the icon
    //       dispatch(showSnackbar("This is a success message!", "success"));
    //       // //("Edit clicked for ID:", params.row.id);
    //     };
    //     return (
    //       <div>
    //         <IconButton onClick={handleActionClick}>
    //           <Message />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    // },
  ];

  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data
  );

  useEffect(() => {
    setTable_load(true);
    dispatch(GetUniversityAction());
  }, []);

  useEffect(() => {
    if (!isToggled) {
      setUniversitylistStateData(UniversitylistState?.universities);
      setUniversitylist(
        UniversitylistState?.universities?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    } else {
      setUniversitylistStateData(UniversitylistState?.blockedUniversity);

      setUniversitylist(
        UniversitylistState?.blockedUniversity?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    }
    if (UniversitylistState != undefined) setTable_load(false);
  }, [UniversitylistState]);

  // Handle toggle change
  const handleStatusChange = async () => {
    const newToggleState = !isToggled;
    if (newToggleState) {
      setUniversitylist(
        UniversitylistState?.blockedUniversity?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    } else {
      setUniversitylist(
        UniversitylistState?.universities?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    }
    setIsToggled(newToggleState);
  };

  const fields = [
    // { label: "Agent ID", value: "agentId", type: "select", option: Agentlist },
    {
      label: "University Name",
      value: "name",
      type: "text",
      // option: UniversitylistStateData?.map(({ name, ...rest }) => ({
      //   title: name,
      //   ...rest,
      // })),
    },
    {
      label: "City Name",
      value: "city",
      type: "text",
      // option: UniversitylistStateData?.map(({ name, ...rest }) => ({
      //   title: name,
      //   ...rest,
      // })),
    },
    {
      label: "Is Active",
      value: "isActive",
      type: "select",
      option: [
        { _id: "Y", title: "Yes" },
        { _id: "N", title: "No" },
      ],
    },
  ];
  const fetchData = async (filters) => {
    let string = filters.map((data) => `${data.field}=${data.value}`);
    try {
      let response = await axios.get(
        basicurl + `/api/university/get-all-university?${string.join("&")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const result = await response.json();
      console.log(response);
      if (isToggled) {
        setUniversitylist(
          response?.data?.blockedUniversity?.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          }))
        );
      } else {
        setUniversitylist(
          response?.data?.universities?.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          }))
        );
      }
      // setData(
      //   response.data.students?.map(({ _id, ...rest }) => ({
      //     id: _id,
      //     ...rest,
      //   }))
      // ); // Assuming the response structure has a `data` key
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleApplyFilters = (filters) => {
    fetchData(filters); // Call the API with filters
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              University List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },

              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            {/* <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              //   color={ThemeColor.WHITE}
              //   padding={"0px"}
              // onClick={handleClick}
            >
              Action
            </OutlineCustomButton> */}
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              onClick={() => setFilterOpen(true)}
            >
              Filter
            </OutlineCustomButton>
            <div className="px-1 ps-2">
              <OutlineCustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                onClick={() =>
                  handleExport(
                    selectionModel,
                    "University",
                    Universitylist,
                    "isActive",
                    visibleColumns
                  )
                }
              >
                Export
              </OutlineCustomButton>
            </div>
            <Box
              display="flex"
              sx={{ border: `2px solid ${ThemeColor.GREY}`, borderRadius: 25 }}
              alignItems="center"
              className="px-2"
            >
              <Typography>Blocked</Typography>
              <Switch
                sx={{
                  "& .MuiSwitch-track": {
                    // backgroundColor: isToggled ? "white" : "#ccc",
                    transition: "background-color 0.3s",
                    borderRadius: 20,
                  },
                  "& .MuiSwitch-thumb": {
                    width: 24,
                    height: 24,
                    backgroundColor: isToggled
                      ? ThemeColor.PURPLE
                      : ThemeColor.BLACK,
                    transition: "transform 0.3s ease",
                    transform: isToggled
                      ? "translateX(2px)"
                      : "translateX(0px)",
                  },
                  "& .MuiSwitch-switchBase": {
                    padding: 1,
                    transition: "transform 0.3s",
                    "&.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: ThemeColor.LIGHTPURPLE,
                    },
                  },
                }}
                checked={isToggled ? true : false}
                onChange={handleStatusChange}
                color="primary"
              />
            </Box>
            <div className="px-2">
              {(!window.sessionStorage.getItem("user_role_type") === "agent" ||
                window.sessionStorage.getItem("user_role_type") === "admin" ||
                !window.sessionStorage.getItem("user_role_type") ===
                  "user") && (
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  //   padding={"0px"}
                  onClick={() => navigate("/CAE/CreateUniversity")}
                >
                  Add University
                </CustomButton>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
      <div className="table-container" style={{ overflowX: "scroll" }}>
        <CustomDataTable
          row={Universitylist ? Universitylist : []}
          columns={columns}
          loading={table_load}
          onSelectionChange={(newSelection) => {
            // console.log(newSelection)
            setSelectionModel(newSelection);
          }}
          getRowClassName={(params) =>
            params.row.isActive === "Y" ? "row-white" : "row-dull"
          }
          // onColumnVisibilityModelChange={handleVisibleColumnsChange}
          // onColumnVisibilityModelChange={handleColumnVisibilityChange}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          // onColumnVisibilityModelChange={(model) => {
          //   const visibleColumns = Object.keys(model).filter((col) => model[col]);
          //   handleVisibleColumnsChange(visibleColumns); // Pass only visible columns
          // }}
        />
      </div>
      <FilterComponent
        open={isFilterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={handleApplyFilters}
        fields={fields}
      />
      <ConfirmationModal
        msg="Updated"
        btnName="Update"
        handleConfirmationClose={() => {
          setUniversitylist((prev) =>
            prev.map((data) =>
              data.id === changeBlockStatusOfOrg.id
                ? {
                    ...data,
                    isBlocked:
                      changeBlockStatusOfOrg.isBlocked === "Y" ? "N" : "Y",
                  }
                : data
            )
          );
          setConfirmationOpen(false);
        }}
        deletefunction={async () => {
          try {
            const res = await EditUnivBlockedStatusService(
              changeBlockStatusOfOrg
            );

            if (res.status === 200) {
              dispatch(GetUniversityAction());
              setIsToggled(!isToggled); // Dispatch the action if success
              return res; // Return the result so the child can access it
            } else {
              return Promise.reject("Unexpected response status"); // Handle unexpected status codes
            }
          } catch (e) {
            dispatch(
              showSnackbar(
                e.response?.data?.message || "Error occurred",
                "error"
              )
            );
            return e;
          }
        }}
        confirmationopen={confirmationopen}
        message={"Want to change the blocking status?"}
      />
    </>
  );
};

function Universitylist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<UniversitylistPage />} />
    </div>
  );
}

export default Universitylist;
