import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  Chip,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import "../mail/mail.css";
import Tempautocomplete from "../../component/autocomplete/Tempautocomplete";
import Delete from "../../Icon/Delete";
import Crossicon from "../../Icon/Crossicon";
import AutocompleteWithRadio from "../../component/autocomplete/Tempautocomplete";
import { List } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import CloseIcon from "@mui/icons-material/Close";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
  convertToRaw,
  convertFromHTML,
  ContentState,
  EditorState,
} from "draft-js";
import {
  GetMailTemplateListService,
  GetMicrosoftAccessTokenCodeService,
  GetMicrosoftAuthCodeService,
  GetScheduleSendMailUsing365Service,
  GetSendMailUsing365Service,
} from "../../Redux/service/MailService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { GetMailTemplateAction } from "../../Redux/action/EmailAction";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

const AddNewMailPage = () => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const formikRef = useRef(null);

  const [open, setOpen] = React.useState(false);

  const [selections, setSelections] = useState([]);
  const [formLoading, setFormLoading] = useState(false);

  const [b_description, setB_Description] = useState();

  const [editorState, setEditorState] = useState("");

  const [EmailTemplateList, setEmailTemplateList] = useState([]);
  const [templateData, settemplateData] = useState();

  const [scheduleState, setScheduleState] = useState(false);
  const [reminderdatatime, setReminderdatatime] = useState();

  useEffect(() => {
    dispatch(GetMailTemplateAction());
  }, []);

  const EditEmailTemplatedataState = useSelector(
    (state) => state.email?.emailTemplate?.data?.emailTemplates
  );
  //(EditEmailTemplatedataState);

  useEffect(() => {
    const updatedData = EditEmailTemplatedataState?.map(
      ({ templateName, ...rest }) => ({
        title: templateName,
        ...rest,
      })
    );
    //(updatedData, "branchlist");
    setEmailTemplateList(updatedData);
  }, [EditEmailTemplatedataState]);

  const handleOpen = () => {
    setOpen(true);
    // setScheduleState(true);
  };
  const handleClose = () => setOpen(false);
  const handleReminderSave = () => {
    setOpen(false);
    // setSchedulesubmitted(true);
    if (reminderdatatime != null) {
      handleFromSubmit(formikRef.current.values, "schedule_send_mail");
    }
  };

  const handleSelectionChange = (newSelections) => {
    setSelections(newSelections);
  };

  const handleDelete = (name) => {
    setSelections((prevSelections) =>
      prevSelections.filter((selection) => selection.name !== name)
    );
  };

  useEffect(() => {
    if (templateData) {
      formikRef.current.values.subject = templateData.subject;
      const blocksFromHTML = convertFromHTML(templateData?.body);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
      formikRef.current.values.body = templateData?.body;
    }
  }, [templateData]);

  function convertImagesToBase64(imagesArray) {
    const promises = imagesArray.map((image) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Convert image to base64 string when loaded
        reader.onload = () =>
          resolve({ name: image.name, base64: reader.result });
        // reader.onload = () => resolve(reader.result);

        // Handle errors
        reader.onerror = () =>
          reject(new Error("Error converting image to base64"));

        // Read the image as a data URL (base64)
        reader.readAsDataURL(image);
      });
    });

    // Return a promise that resolves when all images are converted
    return Promise.all(promises);
  }

  // Example usage
  // const imageFiles = [file1, file2]; // Array of image files (e.g. from file input)

  const handleFromSubmit = async (values, type) => {
    const cc = selections
      ?.filter((data) => data.value === "cc")
      ?.map((data) => data.email);
    const bcc = selections
      ?.filter((data) => data.value === "bcc")
      ?.map((data) => data.email);
    const to = selections
      ?.filter((data) => data.value === "to")
      ?.map((data) => data.email);

    if(values.otherto)
    {
      to.push(values.otherto)

    }


    values["to"] = to;
    values["cc"] = cc;
    values["bcc"] = bcc;
    values["accessToken"] = window.sessionStorage.getItem("ms_auth");

    if (type === "send_mail") {
      //(values, "inside send mail");
      await GetSendMailUsing365Service(values)
        .then((res) => {
          if (res.status === 200) {
            // //(res);
            setFormLoading(false);

            dispatch(showSnackbar("Email Sent Successfully.", "success"));

            // navigate("/CAE/UserList");
          }
        })
        .catch((e) => {
          setFormLoading(false);
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    }
    if (type === "schedule_send_mail") {
      values["sendAt"] = reminderdatatime;

      await GetScheduleSendMailUsing365Service(values)
        .then((res) => {
          if (res.status === 200) {
            // //(res);
            setFormLoading(false);

            dispatch(showSnackbar("Email Sent Successfully.", "success"));
            // navigate("/CAE/UserList");
          }
        })
        .catch((e) => {
          setFormLoading(false);
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Send Mail
            </Typography>
            <Typography
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
            >
              create a new mail
            </Typography>
          </div>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <div className="d-flex justify-content-center w-100">
            <CustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              color={ThemeColor.WHITE}
              //   padding={"0px"}
              // onClick={handleClick}
            >
              Add Another Agent
            </CustomButton>
          </div>
        </Grid> */}
      </Grid>
      <div className="container">
        <Formik
          innerRef={formikRef}
          initialValues={{ attachments: [], subject: templateData?.subject,otherto:"" }}
          // validationSchema={validationSchema}
          onSubmit={async (values) => {

            handleFromSubmit(values, "send_mail");
          }}
          // validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* grid for right basic content */}
                <Grid item lg={12}>
                  <Box>
                    <Typography
                      align="center"
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W600}
                      fontSize={fontSize.h3.medium}
                      color={ThemeColor.PURPLE}
                    >
                      New Mail
                    </Typography>
                    <div className="">
                      <CustomSelect
                        // value={EmailTemplateList ? values?.organizationId : ""}
                        name={"organization"}
                        multiple={false}
                        option={EmailTemplateList}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          settemplateData(
                            EmailTemplateList?.filter(
                              (template) => template._id === e.target.value
                            )?.[0]
                          );
                        }}
                        fullWidth={true}
                        label={"Email Template Name"}
                      />
                    </div>
                  </Box>

                  <Grid container spacing={2} className="mt-4">
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        align="left"
                        fontFamily={FontFamily.Nunito}
                        fontWeight={fontWeight.W700}
                        fontSize={fontSize.h3.medium}
                        color={ThemeColor.GREY}
                        sx={{ textDecoration: "underline" }}
                      >
                        From
                      </Typography>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="d-flex align-items-center mt-2"
                    >
                      <div className="mailProfile">
                        <img
                          className="w-100 h-100"
                          src="/assest/profile.png"
                        ></img>
                      </div>
                      <div>
                        <Typography
                          align="left"
                          fontFamily={FontFamily.Nunito}
                          fontWeight={fontWeight.W700}
                          fontSize={fontSize.h4.medium}
                          color={ThemeColor.BLACK}
                          className="px-2"
                        >
                          SelfEmail@gmail.com
                        </Typography>
                      </div>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        align="left"
                        fontFamily={FontFamily.Nunito}
                        fontWeight={fontWeight.W700}
                        fontSize={fontSize.h3.medium}
                        color={ThemeColor.GREY}
                        sx={{ textDecoration: "underline" }}
                        className="mt-4"
                      >
                        To
                      </Typography>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={10} md={10} sm={12} xs={12}>
                      <FormGroup style={{}} className="mb-4 ">
                        <div className=" mt-2">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Select Cae User to send mail
                          </Typography>
                          <AutocompleteWithRadio
                            onSelectionChange={handleSelectionChange}
                          />
                          {/* <Typography
                            className="mt-3"
                            fontFamily={FontFamily.Poppins}
                            fontSize={fontSize.h4.medium}
                            fontWeight={fontWeight.W400}
                            fontStyle={"italic"}
                          >
                            Sending to
                          </Typography> */}

                          <div>
                            {/* <Typography
                              className="mt-3"
                              fontFamily={FontFamily.Poppins}
                              fontSize={fontSize.h4.medium}
                              fontWeight={fontWeight.W400}
                              // fontStyle={"italic"}
                            >
                              To
                            </Typography> */}
                            {selections?.length > 0 && (
                              <div style={{ marginTop: "20px" }}>
                                {/* <Typography.Title level={4}>
                                  Selected Users
                                </Typography.Title> */}
                                <Typography
                                  className="mt-3"
                                  fontFamily={FontFamily.Poppins}
                                  fontSize={fontSize.h4.medium}
                                  fontWeight={fontWeight.W400}
                                  // fontStyle={"italic"}
                                >
                                  To
                                </Typography>
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    flexDirection: {
                                      lg: "row",
                                      md: "row",
                                      xs: "column",
                                      sm: "column",
                                    },
                                  }}
                                  spacing={2}
                                >
                                  {selections
                                    ?.filter((data) => data.value === "to")
                                    ?.map((data) => {
                                      return (
                                        <Chip
                                          sx={{
                                            background: ThemeColor.LIGHTPINK,
                                            paddingRight: 2,
                                          }}
                                          label={data.email}
                                          deleteIcon={
                                            <CloseIcon color="error" />
                                          }
                                          onDelete={() => {
                                            //("delete func call");
                                            handleDelete(data.name);
                                          }}
                                        ></Chip>
                                      );
                                    })}
                                </Stack>

                                {selections?.filter(
                                  (data) => data.value === "cc"
                                ).length > 0 && (
                                  <>
                                    {" "}
                                    <Typography
                                      className="mt-3"
                                      fontFamily={FontFamily.Poppins}
                                      fontSize={fontSize.h4.medium}
                                      fontWeight={fontWeight.W400}
                                      // fontStyle={"italic"}
                                    >
                                      in CC
                                    </Typography>
                                    <Stack
                                      direction={"row"}
                                      sx={{
                                        display: "flex",
                                        flexDirection: {
                                          lg: "row",
                                          md: "row",
                                          xs: "column",
                                          sm: "column",
                                        },
                                      }}
                                      spacing={2}
                                    >
                                      {selections
                                        ?.filter((data) => data.value === "cc")
                                        ?.map((data) => {
                                          return (
                                            <Chip
                                              sx={{
                                                background:
                                                  ThemeColor.LIGHTPINK,
                                                paddingRight: 2,
                                              }}
                                              label={data.email}
                                              deleteIcon={
                                                <CloseIcon color="error" />
                                              }
                                              onDelete={() => {
                                                //("delete func call");
                                                handleDelete(data.name);
                                              }}
                                            ></Chip>
                                          );
                                        })}
                                    </Stack>
                                  </>
                                )}

                                {selections?.filter(
                                  (data) => data.value === "bcc"
                                ).length > 0 && (
                                  <>
                                    <Typography
                                      className="mt-3"
                                      fontFamily={FontFamily.Poppins}
                                      fontSize={fontSize.h4.medium}
                                      fontWeight={fontWeight.W400}
                                      // fontStyle={"italic"}
                                    >
                                      in BCC
                                    </Typography>
                                    <Stack
                                      direction={"row"}
                                      sx={{
                                        display: "flex",
                                        flexDirection: {
                                          lg: "row",
                                          md: "row",
                                          xs: "column",
                                          sm: "column",
                                        },
                                      }}
                                      spacing={2}
                                    >
                                      {selections
                                        ?.filter((data) => data.value === "bcc")
                                        ?.map((data) => {
                                          return (
                                            <Chip
                                              sx={{
                                                background:
                                                  ThemeColor.LIGHTPINK,
                                                paddingRight: 2,
                                              }}
                                              label={data.email}
                                              deleteIcon={
                                                <CloseIcon color="error" />
                                              }
                                              onDelete={() => {
                                                //("delete func call");
                                                handleDelete(data.name);
                                              }}
                                            ></Chip>
                                          );
                                        })}
                                    </Stack>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={10} md={10} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Enter email of user outside of cae
                          </Typography>
                          <OutlinedInputField
                            value={values.otherto}
                            onChange={handleChange}
                            name={"otherto"}
                            placeholder="Enter user email outside of cae"
                            fullWidth={true}
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                  </Grid>
                </Grid>

                <Grid item lg={8}>
                  <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Subject
                        </Typography>
                        <OutlinedInputField
                          value={values.subject}
                          onChange={handleChange}
                          name={"subject"}
                          placeholder="Enter subject"
                          fullWidth={true}
                        />
                      </div>
                    </FormGroup>
                  </NoPaddingGridItem>
                  <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Add Mail Body
                        </Typography>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Start your mail......"
                          style={{ backgrond: "white" }}
                          editorStyle={{
                            color: "#164242",
                            background: "white",
                          }}
                          onEditorStateChange={(e) => {
                            //(e);
                            setEditorState(e);
                          }}
                          onChange={(e) => {
                            setB_Description(
                              draftToHtml(
                                convertToRaw(editorState.getCurrentContent())
                              )
                            );
                            setFieldValue(
                              "body",
                              draftToHtml(
                                convertToRaw(editorState.getCurrentContent())
                              )
                            );
                          }}
                        />
                      </div>
                    </FormGroup>
                  </NoPaddingGridItem>
                </Grid>

                {/* grid for left docs uploading content */}
                <Grid item lg={4}>
                  <div className="w-100 d-flex justify-content-center align-items-center h-100">
                    <Previews
                      // handleMydropzoneFile={(updatedFiles) => {
                      //   //(updatedFiles);
                      //   setFieldValue("attachments", updatedFiles);

                      // }}

                      ref={childRef}
                      initialValue={values?.attachments}
                      handleMydropzoneFile={(updatedFiles) => {
                        // //(updatedFiles, "oooyesah");
                        setFieldValue("attachments", updatedFiles);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Box
                className="w-100 mt-3"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: {
                    sm: "column",
                    xs: "column",
                    lg: "row",
                    md: "row",
                  },
                }}
              >
                <div>
                  <CustomButton
                    variant="contained"
                    type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    loading={formLoading}
                    //   padding={"0px"}
                    onClick={(e) => setScheduleState(false)}
                  >
                    Send Mail
                  </CustomButton>
                </div>
                <Box
                  sx={{
                    paddingLeft: { lg: 2, md: 2, sm: 0, xs: 0 },
                    marginTop: { lg: 0, md: 0, sm: 2, xs: 2 },
                  }}
                >
                  <CustomButton
                    variant="contained"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    //   padding={"0px"}
                    onClick={handleOpen}
                  >
                    Schedule Send
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormGroup style={{}}>
              <div className="">
                <Typography
                  className="mb-2"
                  fontFamily={FontFamily.Lato}
                  fontSize={fontSize.h3.medium}
                  fontWeight={fontWeight.W400}
                >
                  Reminder Date & Time
                </Typography>
                <MyDatePicker
                  minDate={dayjs().startOf("day")}
                  label="Enter Reminder Date & Time"
                  // value={reminderdatatime}
                  onChange={(date) => {
                    setReminderdatatime(dayjs(date).format("YYYY-MM-DD HH:MM"));
                  }}
                />
                <Box className="mt-3" sx={{}}>
                  <CustomButton
                    variant="contained"
                    // type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    //   padding={"0px"}
                    onClick={handleReminderSave}
                  >
                    Save & Send
                  </CustomButton>
                </Box>
              </div>
            </FormGroup>
          </Box>
        </Modal>
      </div>
    </>
  );
};

function AddNewMail() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<AddNewMailPage />} />
    </div>
  );
}

export default AddNewMail;
