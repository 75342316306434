import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../component/button/button";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateCatalogService,
  CreateProgramService,
  EditCatalogService,
} from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { GetDegreeAction } from "../../Redux/action/ApplicationAction";
import { GetUniversityAction } from "../../Redux/action/SettingAction";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const CreateProgramPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const childRef = useRef(null);

  const { id } = useParams();

  const [Edit, setEdit] = useState(id ? true : false);

  const [Degreelist, setDegreelist] = useState([]);

  const [prefieldData, setPrefieldData] = useState();

  const [Universitylist, setUniversitylist] = useState([]);
  const [formLoading, setFormLoading] = useState(false);

  const EditCatalogdataState = useSelector(
    (state) => state.setting?.catalog?.data?.data
  );
  //(EditCatalogdataState);

  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );

  const DegreelistState = useSelector(
    (state) => state.application?.degree?.data?.degrees
  );

  useEffect(() => {
    dispatch(GetUniversityAction());
    dispatch(GetDegreeAction());
  }, []);

  useEffect(() => {
    setUniversitylist(
      UniversitylistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );

    setDegreelist(
      DegreelistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );
  }, [UniversitylistState, DegreelistState]);

  useEffect(() => {
    console.log(UniversitylistState?.filter((data) => data._id === id)?.[0]);
    setPrefieldData(
      UniversitylistState?.filter((data) => data._id === id)?.[0]
    );
  }, [Edit, UniversitylistState, id]);

  const validationSchema = Yup.object({
    program: Yup.string().required("Name for program is required"),
    university: Yup.string().required("University is required"),
    degree: Yup.string().required("Degree is required"),
  });

  if (!prefieldData && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h2.small}
        >
          Settings
        </Typography>
        <Typography
          className="px-2 pt-1"
          fontFamily={FontFamily.Poppins}
          fontSize={fontSize.h4.medium}
          fontWeight={fontWeight.W300}
          color={ThemeColor.GREY}
        >
          {!Edit ? "Create" : "Edit"} Program
        </Typography>
      </div>
      <div className="container">
        <Formik
          initialValues={{
            program: "",
            description: "",
            university: prefieldData ? prefieldData?._id : "",
            degree: "",
          }}
          // validationSchema={validationSchema}
          onSubmit={async (values, { ressetForm }) => {
            setFormLoading(true);

            console.log("Form data:", values.program.split(","));
            values["programs"] = values.program.split(",");
            delete values["program"];

            // if (!Edit) {
            await CreateProgramService(values)
              .then((res) => {
                if (res.status === 200) {
                  // //(res);
                  setFormLoading(false);

                  dispatch(showSnackbar(res.data.message, "success"));
                  if (Edit) navigate(`/CAE/Programlist/${id}`);
                  else navigate(`/CAE/Programlist`);

                  ressetForm();
                }
              })
              .catch((e) => {
                setFormLoading(false);
                dispatch(showSnackbar(e.response.data.message, "error"));
              });
            // } else {
            //   //(values, id, "for edit");
            //   await EditCatalogService(values, id)
            //     .then((res) => {
            //       if (res.status === 200) {
            //         // //(res);
            //         setFormLoading(false);

            //         dispatch(showSnackbar(res.data.message, "success"));
            //         navigate("/CAE/CatalogueBrochureList");
            //         ressetForm();
            //       }
            //     })
            //     .catch((e) => {
            //       setFormLoading(false);
            //       dispatch(showSnackbar(e.response.data.message, "error"));
            //     });
            // }
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form
              className="d-flex justify-content-center"
              onSubmit={handleSubmit}
            >
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "80%", lg: "80%" },
                }}
              >
                <Typography
                  align="center"
                  fontFamily={FontFamily.Poppins}
                  fontWeight={fontWeight.W600}
                  fontSize={fontSize.h3.medium}
                  color={ThemeColor.PURPLE}
                >
                  {!Edit ? "Add" : "Edit"} Program
                </Typography>
                <FormGroup style={{}}>
                  <Typography
                    className="mb-2"
                    fontFamily={FontFamily.Lato}
                    fontSize={fontSize.h3.medium}
                    fontWeight={fontWeight.W400}
                  >
                    University *
                  </Typography>
                  <div className="w-100">
                    <CustomSelect
                      disabled={Edit}
                      value={Universitylist ? values?.university : ""}
                      name={"university"}
                      multiple={false}
                      option={Universitylist}
                      onChange={(e) =>
                        setFieldValue("university", e.target.value)
                      }
                      fullWidth={true}
                      label={"University "}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.university}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>
                <FormGroup style={{}}>
                  <Typography
                    className="mb-2"
                    fontFamily={FontFamily.Lato}
                    fontSize={fontSize.h3.medium}
                    fontWeight={fontWeight.W400}
                  >
                    Degree *
                  </Typography>
                  <div className="">
                    <CustomSelect
                      value={Degreelist ? values?.degreeType : ""}
                      name={"degreeType"}
                      multiple={false}
                      option={Degreelist}
                      onChange={(e) => setFieldValue("degree", e.target.value)}
                      fullWidth={true}
                      label={"Degree"}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.degree}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>
                <FormGroup style={{}}>
                  <div className="">
                    <Typography
                      className="mb-2"
                      fontFamily={FontFamily.Lato}
                      fontSize={fontSize.h3.medium}
                      fontWeight={fontWeight.W400}
                    >
                      Program Name *
                    </Typography>
                    <OutlinedInputField
                      value={values.name}
                      onChange={handleChange}
                      name={"program"}
                      placeholder="Enter your program name...."
                      fullWidth={true}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.program}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>

                <FormGroup style={{}} className="mt-2">
                  <div className="">
                    <Typography
                      className="mb-2"
                      fontFamily={FontFamily.Lato}
                      fontSize={fontSize.h3.medium}
                      fontWeight={fontWeight.W400}
                    >
                      Description
                    </Typography>
                    <OutlinedInputField
                      value={values.gstName}
                      onChange={handleChange}
                      name={"description"}
                      placeholder="Enter description...."
                      fullWidth={true}
                    />
                  </div>
                </FormGroup>

                <Box
                  sx={{
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                  }}
                  className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
                >
                  {" "}
                  <CustomButton
                    variant="contained"
                    type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    loading={formLoading}
                    //   padding={"0px"}
                    // onClick={handleClick}
                  >
                    {!Edit ? "Save" : "Add"} Program
                  </CustomButton>
                  <Box
                    className="px-2"
                    sx={{
                      paddingTop: {
                        lg: "0px",
                        md: "0px",
                        sm: "5px",
                        xs: "5px",
                      },
                    }}
                  >
                    {!Edit && (
                      <CustomButton
                        variant="contained"
                        // type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        loading={formLoading}
                        //   padding={"0px"}
                        onClick={() => {
                          // setFieldValue('birthday', null);
                          // if (childRef.current) {
                          //   childRef.current.someFunction();
                          // }
                          if (!Edit) {
                            if (childRef.current) {
                              childRef.current.someFunction();
                            }
                          } else {
                            if (childRef.current) {
                              childRef.current.someEditFunction();
                            }
                          }
                          // setFieldValue("documents", []);
                          // //(values)
                          resetForm();
                        }}
                      >
                        Cancel Changes
                      </CustomButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function CreateProgram() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<CreateProgramPage />} />
    </div>
  );
}

export default CreateProgram;
