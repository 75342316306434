import {
  GetAgentListConst,
  GetBranchByOrgIdConst,
  GetCatalogListConst,
  GetFilteredUserConst,
  GetOrganizationListConst,
  GetProcessListConst,
  GetProgramListConst,
  GetRoleListConst,
  GetSettingCountConst,
  GetUniversityListConst,
  GetUserChatByIdConst,
  GetUserChatConst,
  GetUserListConst,
  SaveRolesConst,
} from "../constant/SettingConstant";

import {
  GetAgentListService,
  GetBranchByOrganizationIdService,
  GetCatalogListService,
  GetFilteredUserService,
  GetOrganizationListService,
  GetProcessListService,
  GetProgramListService,
  GetRoleListService,
  GetSettingCountService,
  GetUniversityListService,
  GetUserChatByIdService,
  GetUserChatService,
  GetUserListService,
} from "../service/SettingService";

export const GetUniversityAction = () => async (dispatch) => {
  try {
    const data = await GetUniversityListService();

    dispatch({ type: GetUniversityListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetUniversityListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetAgentAction = () => async (dispatch) => {
  try {
    const data = await GetAgentListService();

    dispatch({ type: GetAgentListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetAgentListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetOrganizationAction = () => async (dispatch) => {
  try {
    const data = await GetOrganizationListService();

    dispatch({ type: GetOrganizationListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetOrganizationListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetRoleAction = () => async (dispatch) => {
  try {
    const data = await GetRoleListService();

    dispatch({ type: GetRoleListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetRoleListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetUserAction = () => async (dispatch) => {
  try {
    const data = await GetUserListService();

    dispatch({ type: GetUserListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetUserListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};
export const GetCatalogAction = () => async (dispatch) => {
  try {
    const data = await GetCatalogListService();

    dispatch({ type: GetCatalogListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetCatalogListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetProgramAction = (id) => async (dispatch) => {
  try {
    const data = await GetProgramListService(id);

    dispatch({ type: GetProgramListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetProgramListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetProcessAction = () => async (dispatch) => {
  try {
    const data = await GetProcessListService();

    dispatch({ type: GetProcessListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetProcessListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};
export const GetUserChatAction = () => async (dispatch) => {
  try {
    const data = await GetUserChatService();

    dispatch({ type: GetUserChatConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetUserChatConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};
export const GetSettingCountAction = () => async (dispatch) => {
  try {
    const data = await GetSettingCountService();

    dispatch({ type: GetSettingCountConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetSettingCountConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};
export const GetFilteredUserAction = (value) => async (dispatch) => {
  try {
    const data = await GetFilteredUserService(value);

    dispatch({ type: GetFilteredUserConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetFilteredUserConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};
export const GetUserChatByIdAction = (id) => async (dispatch) => {
  try {
    const data = await GetUserChatByIdService(id);

    dispatch({ type: GetUserChatByIdConst, payload: data });

  } catch (error) {
    dispatch({ type: GetUserChatByIdConst, payload: error });
  }
};
export const GetBranchByOrgIdAction = (id) => async (dispatch) => {
  try {
    const data = await GetBranchByOrganizationIdService(id);

    dispatch({ type: GetBranchByOrgIdConst, payload: data });

  } catch (error) {
    dispatch({ type: GetBranchByOrgIdConst, payload: error });
  }
};

export const saveRoles = (data) => async (dispatch) => {
  //(data)
  dispatch({ type: SaveRolesConst, payload: data });
};

