import dayjs from "dayjs";
import * as Yup from "yup";

export const orgvalidationSchema = Yup.object().shape({
  organizationName: Yup.string().required("Name of organization is required"),
  // city: Yup.string().required("City of university is required"),
  // address: Yup.string().required("Organization address is required"),
  // startContractDate: Yup.string().required(
  //   "Start Contract date for organization is required"
  // ),
  // endContractDate: Yup.string().required(
  //   "End Contract date for organization is required"
  // ),
  // address: Yup.string().required("Organisation Address is required."),
  // documents: Yup.array().required("Document for university is required"),
  reminders: Yup.array().of(
    Yup.object({
      isNew: Yup.boolean(), // Flag to determine if it's a new entry
      reminderDateTime: Yup.date()
        .transform((value, originalValue) => {
          // Convert empty strings to null
          console.log(value, originalValue);
          return originalValue === "Invalid Date" ? null : value;
        })
        .nullable("Reminder date time cannot be null")
        // .required("Reminder date and time cannot be empty")
        // .required("Reminder date is required")
        .when("isNew", {
          is: true, // Only validate if it's a new entry
          then: (schema) =>
            schema.test(
              "is-greater",
              "Reminder date time cannot be empty and date time must be after the current date time",
              function (value) {
                console.log(this.parent);
                if (this.parent?.text) {
                  return dayjs(value).isAfter(dayjs(), "millisecond");
                }
                return true;
              }
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
      resendDateTime: Yup.date()
        .transform((value, originalValue) => {
          // Convert empty strings to null
          console.log(value, originalValue);
          return originalValue === "Invalid Date" ? null : value;
        })
        // .required("Resend date is required")
        .nullable()
        .when("isNew", {
          is: true,
          then: (schema) =>
            schema.test(
              "is-greater",
              "Resend date must be after the reminder date",
              function (value) {
                const { reminderDateTime } = this.parent;

                const reminderDate =
                  dayjs(reminderDateTime).format("YYYY-MM-DD");
                const resendDate = dayjs(value).format("YYYY-MM-DD");
                if (this.parent?.text) {
                  // Check if the resend date is after the reminder date
                  if (resendDate > reminderDate) {
                    return true;
                  }

                  // If the dates are the same, check if the resend time is after the reminder time
                  if (resendDate === reminderDate) {
                    const reminderTime =
                      dayjs(reminderDateTime).format("HH:MM");
                    const resendTime = dayjs(value).format("HH:MM");
                    //(reminderTime, resendTime);
                    return resendTime > reminderTime;
                  }
                }

                return true;
              }
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
      text: Yup.string()
        // .required("Resend date is required")/
        .when("isNew", {
          is: true,
          then: (schema) =>
            schema.test(
              "is-greater",
              "Reminder Note is required!",
              function (value) {
                const { reminderDateTime } = this.parent;

                if (reminderDateTime) {
                  return !!value;
                }
                return true;
              }
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
    })
  ),
  owners: Yup.array().of(
    Yup.object().shape({
      ownerName: Yup.string().required("Name of owner person is required"),
      email: Yup.string()
        .strict()
        .email("Invalid email format")
        .required("Email of owner person is required"),
      phone: Yup.string()
        // .matches(/^\d{12}$/, "Phone number must be proper")
        .required("Phone number of owner person is required"),
    })
  ),
});

export const universityvalidationSchema = Yup.object().shape({
  name: Yup.string().required("Name of university is required"),
  country: Yup.string().required("Country of university is required"),
  state: Yup.string().required("State of university is required"),
  city: Yup.string().required("City of university is required"),
  // address: Yup.string().required("Campus address is required"),
  // websiteLink: Yup.string().required("Website Link of university is required"),
  // contractExpireDate: Yup.string().required("Contract Expire date is required"),
  // documents: Yup.array().required("Document for university is required"),

  // commissionsAndPrograms: Yup.array().of(
  //   Yup.object().shape({
  //     degree: Yup.string().required(
  //       "You must select Degree for commissions program!"
  //     ),
  //     program: Yup.array().required("Program for commission prog is required"),
  //     commissionType: Yup.string().required(
  //       "Commission Type for commision and program is required"
  //     ),
  //     // designation: Yup.string().required(
  //     //   "Designation of contact person is required"
  //     // ),
  //     duration: Yup.string().when("commissionType", {
  //       is: "F",
  //       then: (schema) => schema.required("Duration is required!"),
  //       otherwise: (schema) => schema.notRequired(),
  //     }),
  //     paymentCycle: Yup.string().when("commissionType", {
  //       is: "F",
  //       then: (schema) => schema.required("Payment Cycle is required!"),
  //       otherwise: (schema) => schema.notRequired(),
  //     }),
  //   })
  // ),

  commissionsAndPrograms: Yup.array().of(
    Yup.lazy(
      (entry) =>
        entry?.isNew
          ? Yup.object().shape({
              degree: Yup.string().required(
                "You must select Degree for commissions program!"
              ),
              program: Yup.array().required(
                "Program for commission prog is required"
              ),
              commissionType: Yup.string().required(
                "Commission Type for commission and program is required"
              ),
              duration: Yup.string().when("commissionType", {
                is: "F",
                then: (schema) => schema.required("Duration is required!"),
                otherwise: (schema) => schema.notRequired(),
              }),
              paymentCycle: Yup.string().when("commissionType", {
                is: "F",
                then: (schema) => schema.required("Payment Cycle is required!"),
                otherwise: (schema) => schema.notRequired(),
              }),
            })
          : Yup.object().shape({}) // Skip validation for existing entries
    )
  ),

  reminders: Yup.array().of(
    Yup.object({
      isNew: Yup.boolean(), // Flag to determine if it's a new entry
      reminderDateTime: Yup.date()
        .transform((value, originalValue) => {
          // Convert empty strings to null
          console.log(value, originalValue);
          return originalValue === "Invalid Date" ? null : value;
        })
        .nullable("Reminder date time cannot be null")
        // .required("Reminder date and time cannot be empty")
        // .required("Reminder date is required")
        .when("isNew", {
          is: true, // Only validate if it's a new entry
          then: (schema) =>
            schema.test(
              "is-greater",
              "Reminder date time cannot be empty and date time must be after the current date time",
              function (value) {
                console.log(this.parent);
                if (this.parent?.text) {
                  return dayjs(value).isAfter(dayjs(), "millisecond");
                }
                return true;
              }
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
      resendDateTime: Yup.date()
        .transform((value, originalValue) => {
          // Convert empty strings to null
          console.log(value, originalValue);
          return originalValue === "Invalid Date" ? null : value;
        })
        // .required("Resend date is required")
        .nullable()
        .when("isNew", {
          is: true,
          then: (schema) =>
            schema.test(
              "is-greater",
              "Resend date must be after the reminder date",
              function (value) {
                const { reminderDateTime } = this.parent;

                const reminderDate =
                  dayjs(reminderDateTime).format("YYYY-MM-DD");
                const resendDate = dayjs(value).format("YYYY-MM-DD");
                if (this.parent?.text) {
                  // Check if the resend date is after the reminder date
                  if (resendDate > reminderDate) {
                    return true;
                  }

                  // If the dates are the same, check if the resend time is after the reminder time
                  if (resendDate === reminderDate) {
                    const reminderTime =
                      dayjs(reminderDateTime).format("HH:MM");
                    const resendTime = dayjs(value).format("HH:MM");
                    //(reminderTime, resendTime);
                    return resendTime > reminderTime;
                  }
                }

                return true;
              }
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
      text: Yup.string()
        // .required("Resend date is required")
        .when("isNew", {
          is: true,
          then: (schema) =>
            schema.test(
              "check for reminder date time",
              "Reminder Note is required!",
              function (value) {
                const { reminderDateTime } = this.parent;

                if (reminderDateTime) {
                  return !!value;
                }
                return true;
              }
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
    })
  ),
});

export const agentvalidationSchema = Yup.object({
  firstName: Yup.string().required("Name of agent is required"),
  email: Yup.string()
    .strict()
    .email("Please enter the valid email")
    .required("Email of agent is required"),
  // phone: Yup.string().required("Phone Number of agent is required"),
  // branch: Yup.string().required("Branch of agent is required"),

  branch: Yup.string()
    .required("Branch of agent is required")
    .test(
      "organization-selected",
      "First select an organization",
      function (value) {
        //(this.parent);
        const { organization } = this.parent;
        if (!organization) {
          return false;
        }
        return true;
      }
    ),
  organization: Yup.string().required("Organization is required"),
  roles: Yup.string().required("Roles is required"),
});

export const DefaultIntakeValidationSchema = Yup.object().shape({
  intake: Yup.string().required("Intake is required!"),
  intakeyear: Yup.string().required("Intake Year is required!"),
});
