import React, { useState, useCallback } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Radio, Space } from 'antd';
import axios from 'axios';
import { GetEmailIdService } from '../../Redux/service/MailService';
import { showSnackbar } from '../../Redux/action/SnackbarAction';
import { useDispatch } from 'react-redux';

// Debounce function
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const Title = (props) => (
  <Space align="center" justify="space-between">
    {props.title}
  </Space>
);

const renderItem = (name, email, onRadioChange) => ({
  value: name,
  label: (
    <Space direction="vertical" align="start" style={{ width: '100%' }}>
      <Space align="center" justify="space-between" style={{ width: '100%' }}>
        {name} {email}
      </Space>
      <Radio.Group onChange={(e) => onRadioChange(e, name, email)} style={{ marginLeft: 16 }}>
        <Radio value="to">To</Radio>
        <Radio value="cc">CC</Radio>
        <Radio value="bcc">BCC</Radio>
      </Radio.Group>
    </Space>
  ),
});

const AutocompleteWithRadio = ({ onSelectionChange }) => {
  const dispatch=useDispatch()
  const [options, setOptions] = useState([]);

  const handleRadioChange = (e, name, email) => {
    const selectedValue = e.target.value;
    const newSelection = { name, email, value: selectedValue };

    onSelectionChange((prevSelections) => {
      const existingIndex = prevSelections.findIndex((selection) => selection.name === name);

      if (existingIndex === -1) {
        return [...prevSelections, newSelection];
      } else {
        dispatch(showSnackbar(`${name} has already been selected.`, "error"));
        // alert(`${name} has already been selected.`);
        return prevSelections;
      }
    });
  };

  const fetchOptions = async (query) => {
    try {
      const response = await GetEmailIdService(query);
      const data = response.data?.data;

      const fetchedOptions = data.map((item) =>
        renderItem(item.name, item.email, handleRadioChange)
      );

      setOptions([
        {
          label: <Title title="Search Results" />,
          options: fetchedOptions,
        },
      ]);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const handleInputChange = useCallback(
    debounce((value) => {
      if (value) {
        fetchOptions(value);
      } else {
        setOptions([]);
      }
    }, 300),
    []
  );

  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      popupMatchSelectWidth={"100%"}
      style={{
        width: "100%",
      }}
      options={options}
      onSearch={handleInputChange}
      size="large"
    >
      <Input.Search size="large" placeholder="input here" />
    </AutoComplete>
  );
};

export default AutocompleteWithRadio;
