import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { FontFamily } from "../theme/fontFamily";
import { fontWeight } from "../theme/fontWeight";
import { fontSize } from "../theme/fontSize";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    bordeRadius: "30px",
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    borderColor: "none",
    borderRadius: "30px",
    backgroundColor: "#F3F3F3",
    padding: "5px 20px 5px 20px",
    "& fieldset": {
      borderColor: "#F3F3F3",
    },
    "& input": {
      height: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#999999",
    },
    "&.Mui-focused fieldset": {
      display: "none",
    },
    "& input::placeholder": {
      color: "#999999",
      fontFamily: FontFamily.Lato,
      fontWeight: fontWeight.W400,
      fontSize: fontSize.h4.medium,
    },
  },
});

const InputField = ({
  label,
  value,
  onChange,
  name,
  type = "text",
  placeholder = "",
  fullWidth = true,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(type === "password");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <StyledTextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      placeholder={placeholder}
      fullWidth={fullWidth}
      variant="outlined"
      className="mb-3"
      helperText={helperText}
      InputProps={
        type === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}
      }
    />
  );
};

export default InputField;
