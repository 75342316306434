import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import "../dropzone/dropzone.css";
import Dropdown from "../../Icon/Dropdown";
import { Box, FormHelperText, Typography } from "@mui/material";
import { FontFamily } from "../theme/fontFamily";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { ThemeColor } from "../theme/color";
import Delete from "../../Icon/Delete";
import { UploadFileService } from "../../Redux/service/FileService";
import { DownloadDoneOutlined } from "@mui/icons-material";
import { DownCircleOutlined } from "@ant-design/icons";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  //   marginTop: 16
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid black",
  //   marginTop: 10,
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const Previews = forwardRef((props, ref) => {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   "image/*": [],
    // },
    multiple: true,
    onDrop: async (acceptedFiles) => {
      // setFiles(...files,
      //   ...acceptedFiles.map((file) =>
      //     Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     })
      //   )
      // );
      // console.log(acceptedFiles, "dropzone clg");
      var docsFile = [];

      try {
        const uploadPromises = acceptedFiles.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          //  console.log(formData, "form data");

          const response = await UploadFileService(formData);
          if (response.status === 200) {
            //   console.log(response.data.result);
            docsFile.push({
              ...response.data.result,
              preview: URL.createObjectURL(file),
            });
          }
        });

        // Wait for all uploads to complete
        await Promise.all(uploadPromises);
      } catch (err) {
        //("uploadPhotoPost : err ", err);
      }
      const updatedFiles = [
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
      //  console.log(docsFile);
      //(docsFile, "docs file");
      setFiles((prevFiles) => [...prevFiles, ...docsFile]);

      // props?.handleMydropzoneFile(docsFile);

      //   setFiles([...files, ...acceptedFiles]);
    },
  });

  const downloadFile = async (file) => {
    // console.log(file, "url of file in my drop zone");
    // const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    // const response = await fetch(`${proxyUrl}${file}`);
    // const blob = await response.blob();
    // const blobUrl = URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.href = blobUrl;
    // link.setAttribute("download", "Group.png");
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
    // URL.revokeObjectURL(blobUrl);

    window.open(file, "_blank", "noopener noreferrer");
  };

  useEffect(() => {
    // This will be called whenever `files` is updated
    if (files.length > 0) {
      props?.handleMydropzoneFile(files);
    }
  }, [files]);
  const someFunction = (values) => {
    setFiles(values);
    props.handleMydropzoneFile(values);
  };

  const someEditFunction = () => {
    setFiles(props.initialValue);
    // props.handleMydropzoneFile(props.initialValue);
  };

  useImperativeHandle(ref, () => ({
    someFunction,
    someEditFunction,
  }));

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    props.handleMydropzoneFile(newFiles);
  };

  const previewFile = (file) => {
    window.open(file.url, "_blank");
  };

  const thumbs = files?.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <button onClick={() => handleRemove(index)}>Remove</button>
      </div>
    </div>
  ));

  useEffect(() => {
    //(props.initialValue, "nonnoon");
    setFiles(props.initialValue);
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    // return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const FileHandler = (data) => {
    if (data?.url.split(".").pop() === "xlsx") {
      let urlParts = data?.url.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          {/* <div className="filename-icon">
            <img
              src="/assest/folderpng/excel.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div> */}
          <a
            href={data.url}
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else if (data?.url.split(".").pop() === "pdf") {
      let urlParts = data?.url.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          {/* <div className="filename-icon">
            <img
              src="/assest/folderpng/pdf.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div> */}
          <a
            href={data?.url}
            target="_blank"
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else {
      return (
        <div className="mt-2">
          <Typography
            className="px-2"
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W400}
            fontSize={fontSize.h4.medium}
            color={ThemeColor.BLACK}
            onClick={() => downloadFile(data?.url)}
            align="left"
          >
            {/* <img
              onClick={() => downloadFile(data?.url)}
              src={data?.url}
              style={{
                // display:"none",
                width: "20px",
                height: "20px",
              }}
              alt="chatimg"
            /> */}
          {data?.name}

          </Typography>

          {/* <img
            onClick={() => downloadFile(data)}
            src={data}
            style={{
              width: "100px",
              height: "100px",
            }}
            alt="chatimg"
          /> */}
        </div>
      );
    }
  };

  return (
    <section className="my-dropzone-container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="my-dropzone-content">
          <div>
            <Dropdown />
          </div>

          <Typography
            fontFamily={FontFamily.Lato}
            fontSize={fontSize.h4.large}
            fontWeight={fontWeight.W400}
          >
            Upload your document here
          </Typography>
          <Typography
            fontFamily={FontFamily.Lato}
            color={ThemeColor.GREY}
            fontSize={fontSize.h4.medium}
            align="center"
          >
            will create new folder with university name and save you file in it{" "}
          </Typography>
          <FormHelperText>{props.helperText}</FormHelperText>
        </div>
      </div>
      {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
      <Box
        className="w-100"
        sx={{
          height: files.length > 0 ? (props?.height ? props.height : 200) : 10,
          overflowY: "scroll",
          overflowX: "auto",
        }}
        // style={{ height: "200px", overflowY: "scroll" }}
      >
        <ul>
          {files?.map((file, index) => (
            <li key={index} className="d-flex  mb-2">
              <span>
                <Typography
                  fontWeight={fontWeight.W800}
                  fontSize={fontSize.h3.medium}
                >
                  {index + 1}.
                </Typography>
              </span>{" "}
              {file?.url && FileHandler(file)}
              {/* File {index + 1} */}
              <div className="px-2 d-flex">
                <div className="px-1">
                  {" "}
                  <button
                    type="button"
                    onClick={() => handleRemove(index)}
                    className="py-1 px-2"
                    style={{
                      background: ThemeColor.RED,
                      borderColor: ThemeColor.RED,
                      borderRadius: "50%",
                      boxShadow: "none",
                    }}
                  >
                    <Delete />
                  </button>
                </div>

                <div className="px-1">
                  <button
                    type="button"
                    onClick={() => previewFile(file)}
                    className="py-1"
                    style={{
                      // background: ThemeColor.RED,
                      borderColor: ThemeColor.BLACK,
                      borderRadius: "50%",
                      boxShadow: "none",
                    }}
                  >
                    <VisibilityIcon />
                  </button>
                </div>

                {/* <div className="px-1">
                  <button
                    type="button"
                    // onClick={() => downloadFile(file)}
                    className="py-1"
                    style={{
                      // background: ThemeColor.RED,
                      borderColor: ThemeColor.BLACK,
                      borderRadius: "50%",
                      boxShadow: "none",
                    }}
                  >
                    <DownloadIcon />
                  </button>
                </div> */}
              </div>
            </li>
          ))}
        </ul>
      </Box>
    </section>
  );
});
// function Previews(props, ref) {}

export default Previews;
