import { styled } from "@mui/material/styles";
// import TextField from "@mui/material/TextField";
// import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import { ThemeColor } from "../theme/color";
import { FontFamily } from "../theme/fontFamily";
import { fontWeight } from "../theme/fontWeight";
import { fontSize } from "../theme/fontSize";
import { FormHelperText } from "@mui/material";

const MyTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  border: `0.2px solid ${ThemeColor.GREY}`,
  borderRadius: "4px",
  fontFamily: FontFamily.Lato,
  opacity: 4,
  fontWeight: 400,
  fontSize: fontSize.h4.medium,
  "&:focus": {
    outline: "none",
    border: `2px solid ${ThemeColor.LIGHTPURPLE}`,
  },
  "&::placeholder": {
    color: theme.palette.text.secondary,
  },
}));

const OutlinedTextAreaField = ({
  label,
  value,
  multiline,
  minRows,
  onChange,
  disabled,
  name,
  type = "text",
  placeholder = "",
  fullWidth = true,
  helperText
}) => {
  const [showPassword, setShowPassword] = useState(type === "password");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <MyTextareaAutosize
        name={name}
        multiline={multiline}
        minRows={minRows}
        label={label}
        value={value}
        onChange={onChange}
        // type={type === "password" ? (showPassword ? "text" : "password") : type}
        placeholder={placeholder}
        fullWidth={fullWidth}
        variant="outlined"
        disabled={disabled}
      />
      <FormHelperText className="mb-3">{helperText}</FormHelperText>
    </>
  );
};

export default OutlinedTextAreaField;
