import React, { useState } from "react";
import {
  Drawer,
  TextField,
  Button,
  MenuItem,
  Grid,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FontFamily } from "../theme/fontFamily";
import { fontWeight } from "../theme/fontWeight";
import { fontSize } from "../theme/fontSize";
import { ThemeColor } from "../theme/color";
import CustomButton from "../button/button";
import OutlineCustomButton from "../button/outlineButton";
import { PlusCircleFilled } from "@ant-design/icons";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import CustomSelect from "../select field/OutlinedSelectField";

const FilterComponent = ({ open, onClose, onApply, fields }) => {
  const [filterFields, setFilterFields] = useState([]);

  const handleAddFilterField = () => {
    console.log(fields)
    setFilterFields([...filterFields, { field: "", value: "", type: "" }]);
  };

  const handleFieldChange = (index, field) => {
    // console.log(field, index);
    const updatedFields = [...filterFields];
    updatedFields[index].field = field;
    updatedFields[index].type = fields.filter(
      (data) => data.value === field
    )?.[0]?.type;
    updatedFields[index].option = fields.filter(
      (data) => data.value === field
    )?.[0]?.option;

    setFilterFields(updatedFields);
  };

  const handleValueChange = (index, value) => {
    // console.log(index, value, filterFields);
    const updatedFields = [...filterFields];
    updatedFields[index].value = value;
    setFilterFields(updatedFields);
  };

  const applyFilters = () => {
    onApply(filterFields); // Send the filters back to the parent component
    onClose(); // Close the drawer
  };

  const resetFilters = () => {
    setFilterFields([]);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ style: { width: "400px", padding: "16px" } }}
    >
      <Box>
        <Box
          className="d-flex w-100"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography
            fontFamily={FontFamily.Lato}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.medium}
          >
            Filter By selecting fields
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid container spacing={2} className="mt-3">
          {filterFields.map((filter, index) => (
            <React.Fragment key={index}>
              <Grid item xs={5}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "45px",
                      // Adjust the height as needed
                      "& .MuiSelect-select": {
                        padding: "8px",
                        borderColor: "red", // Adjust padding to control height further
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: `0.2 solid rgb(217,217,217)`, // Default border color
                      },
                      "& label": {
                        color: "black",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: ThemeColor.GREY, // Border color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: ThemeColor.LIGHTPURPLE, // Border color when focused
                      },
                    },
                  }}
                  select
                  label="Field"
                  value={filter.field}
                  onChange={(e) => handleFieldChange(index, e.target.value)}
                  fullWidth
                >
                  {fields.map((field) => (
                    <MenuItem key={field.value} value={field.value}>
                      {field.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {filter.type === "select" ? (
                <Grid item xs={5}>
                  <CustomSelect
                    multiple={false}
                    option={filter?.option}
                    // onChange={(e) => setFieldValue("gender", e.target.value)}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    fullWidth={true}
                    label={"Select"}
                  />
                </Grid>
              ) : filter.type === "multi-select" ? (
                <Grid item xs={5}>
                  <CustomSelect
                    multiple={true}
                    option={filter?.option}
                    value={filter?.value===""?[]:filter.value}

                    // onChange={(e) => setFieldValue("gender", e.target.value)}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    fullWidth={true}
                    label={"Multi Select"}
                  />
                </Grid>
              ) : (
                <Grid item xs={5}>
                  <OutlinedInputField
                    label="Value"
                    value={filter.value}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            {/* <Button variant="outlined" >
              Add
            </Button> */}
            <Typography className="w-100" onClick={handleAddFilterField}>
              Add fields and their data to filter values <PlusCircleFilled />
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2} sx={{ display: "flex" }}>
          <CustomButton
            variant="contained"
            type="submit"
            backgroundColor={ThemeColor.PURPLE}
            color={ThemeColor.WHITE}
            onClick={applyFilters}
          >
            Apply
          </CustomButton>
          <div className="px-2">
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              onClick={resetFilters}
            >
              Reset
            </OutlineCustomButton>
          </div>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterComponent;
