import {
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../select field/OutlinedSelectField";
import {
  AddPaymentDetailsAfterVisaApprovedService,
  UpdateStudentTransactionService,
  calcualteAgentCommissionService,
  getDefaultCommissionDetailsService,
} from "../../Redux/service/Application";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import OnlyDatePicker from "../datepicker/MyDatepicker";
import {
  GetAgentTransactionListAction,
  GetStudentAction,
  GetUnsettledListAction,
} from "../../Redux/action/ApplicationAction";
import Previews from "../dropzone/Mydropzone";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import {
  validationCommisionSchema,
  validationTransactionSchema,
  validationUniversityAmountSchema,
} from "./ValidationConstant";
import OutlinedTextAreaField from "../inputfiled/OutlinedTextedArea";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const EditUniversityAmountModal = ({
  UserTransaction,
  handleClose,
  transactionId,
  AgentTransactionlist,
  handleCloseReminder,
  DefaultCommission,
}) => {
  const dispatch = useDispatch();
  const [VisaStatus, setVisaStatus] = useState("");
  const [commResdata, setCommResdata] = useState(null);
  const childRef = useRef(null);
  const formikRef = useRef(null);

  const [loading, setLoading] = useState(true);

  return (
    <Modal
      open={UserTransaction}
      onClose={() => {
        console.log("hanlde close");
        setCommResdata(null);
        setLoading(true);
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          align="right"
          onClick={() => {
            console.log("hanlde close");
            setCommResdata(null);
            setLoading(true);

            handleClose();
          }}
        >
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Save University Amount & Bonus
        </Typography>
        <Formik
          innerRef={formikRef}
          initialValues={{
            agentTransactionId: transactionId?.transactionId,
            receivedFromUniversity: transactionId?.receivedFromUniversity,
            bonusFromUniversity: transactionId?.bonusFromUniversity,
          }}
          // validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            //("Form data:", values);
            console.log(values);

            await UpdateStudentTransactionService(values)
              .then((res) => {
                if (res.status === 200) {
                  handleClose();
                  handleCloseReminder();
                  resetForm();

                  dispatch(GetUnsettledListAction(false));

                  dispatch(showSnackbar(res.data.message, "success"));
                  // navigate("/CAE/CatalogueBrochureList");
                  // resetForm();

                  setCommResdata(null);
                }
              })
              .catch((e) => {
                dispatch(showSnackbar(e.response.data.message, "error"));
              });
          }}
          validationSchema={validationUniversityAmountSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="mt-4"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Bonus From University
                        </Typography>
                        <OutlinedInputField
                          value={values.bonusFromUniversity}
                          name={`bonusFromUniversity`}
                          onChange={(e) => {
                            // handleInputChange(
                            //   e.target.value,
                            //   transactionId?.agentTransactionId
                            // );
                            handleChange(e);
                          }}
                          placeholder="Enter Tds Percentage"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.bonusFromUniversity}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Amount Received from university
                        </Typography>
                        <OutlinedInputField
                          value={values.receivedFromUniversity}
                          name={`receivedFromUniversity`}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          placeholder="Enter amounts"
                          fullWidth={true}
                          helperText={
                            <Typography
                              align="left"
                              // className="px-3"
                              color={ThemeColor.RED}
                              fontSize={fontSize.h4.medium}
                              fontFamily={FontFamily.Lato}
                              fontWeight={fontWeight.W500}
                            >
                              {errors.receivedFromUniversity}
                            </Typography>
                          }
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <Box
                      sx={{
                        flexDirection: {
                          lg: "row",
                          md: "row",
                          sm: "column",
                          xs: "column",
                        },
                      }}
                      className="d-flex justify-content-center align-items-center w-100 mt-2 mb-3"
                    >
                      {" "}
                      <CustomButton
                        variant="contained"
                        type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                      >
                        Save
                      </CustomButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
