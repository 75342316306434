import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MultiSelectAutocomplete = ({ label, options, value, onChange,disabled }) => {
  // console.log(options)
  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => `${option.title} ${option.roleType} ${option.email} ${option?.organization?.organizationName || ''}`}
      value={value}
      disabled={disabled}
      onChange={(event, newValue) => onChange(newValue)}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography variant="body1">{option.title}</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
           ({option.roleType} {option?.organization?.organizationName ? `from ${option?.organization?.organizationName}`:""}) {option.email}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Choose..."
        />
      )}
    />
  );
};

export default MultiSelectAutocomplete;
