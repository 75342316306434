import {
  Box,
  FormGroup,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetFilteredUserAction,
  GetUserChatAction,
} from "../../Redux/action/SettingAction";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import CustomSelect from "../select field/OutlinedSelectField";
import { AddPersonChatService } from "../../Redux/service/SettingService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};


export const CreatePersonChatModal = ({
  AddPersonChat,
  handleClose,
  stdId,
  Organizationlist,
  filteredUser,
  Rolelist,
}) => {
  const dispatch = useDispatch();

  const [Chatlist, setChatlist] = useState([]);

  const [formLoading, setFormLoading] = useState(false);

  const ChatlistState = useSelector(
    (state) => state.setting?.allchat?.data?.data
  );

  useEffect(() => {
    setChatlist(ChatlistState);
  }, [ChatlistState]);

  const validationSchemaPerson = Yup.object({
    recipient: Yup.string().required(
      "Recipient is required to start the chat."
    ),
    email: Yup.string()
      .strict()
      // .lowercase("Email should be in lowercase.")
      .email("Invalid email address")
  });

  return (
    <Modal
      open={AddPersonChat}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography align="right" onClick={() => handleClose()}>
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Add Person to chat
        </Typography>
        <Formik
          initialValues={{
            firstName: null,
            lastName: null,
            roles: null,
            roleType: null,
            email: null,
            organization: null,
          }}
          onSubmit={async (values, { resetForm }) => {
            //("Form data:", values);
            dispatch(GetFilteredUserAction(values));
            dispatch(showSnackbar("Filtered Successfully!", "success"));
            resetForm();
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center mt-4">
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} xs={6} sm={6}>
                    <FormGroup style={{}}>
                      <div className="">
                        <OutlinedInputField
                          value={values.firstName}
                          name={"firstName"}
                          onChange={handleChange}
                          placeholder="Enter first name"
                          fullWidth={true}
                          label={"First Name"}
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={4} md={4} xs={6} sm={6}>
                    <FormGroup style={{}}>
                      <div className="">
                        <OutlinedInputField
                          value={values.lastName}
                          name={"lastName"}
                          onChange={handleChange}
                          placeholder="Enter last name"
                          fullWidth={true}
                          label={"Last Name"}
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={4} md={4} xs={12} sm={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <OutlinedInputField
                          value={values.email}
                          name={"email"}
                          onChange={handleChange}
                          placeholder="Enter email"
                          fullWidth={true}
                          label={"Email"}
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={4} md={4} xs={12} sm={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <CustomSelect
                          value={values?.roleType}
                          name={"textDetails"}
                          multiple={false}
                          option={[
                            { _id: "admin", title: "Admin" },
                            { _id: "agent", title: "Agent" },
                            { _id: "sub_admin", title: "Sub Admin" },
                            { _id: "user", title: "User" },
                          ]}
                          onChange={(e) => {
                            setFieldValue("roleType", e.target.value);
                          }}
                          label={"Role Type"}
                          fullWidth={true}
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={4} md={4} xs={12} sm={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <CustomSelect
                          value={values?.organization}
                          name={"organization"}
                          multiple={false}
                          option={Organizationlist}
                          onChange={(e) => {
                            setFieldValue("organization", e.target.value);
                          }}
                          label={"Organization"}
                          fullWidth={true}
                        />
                      </div>
                    </FormGroup>
                  </Grid>

                  <Grid item lg={4} md={4} xs={12} sm={12}>
                    <FormGroup style={{}}>
                      <div className="">
                        <CustomSelect
                          value={values?.roles}
                          name={"roles"}
                          multiple={false}
                          option={Rolelist}
                          onChange={(e) => {
                            setFieldValue("roles", e.target.value);
                          }}
                          label={"Roles"}
                          fullWidth={true}
                        />
                      </div>
                    </FormGroup>
                  </Grid>
                </Grid>

                <div className="d-flex -center align-items-center h-100 w-100 mt-3 mb-3">
                  <div className="px-3">
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                    >
                      Filter
                    </CustomButton>
                  </div>
                </div>
              </Box>
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={{}}
          onSubmit={async (values, { resetForm }) => {
            setFormLoading(true);
            await AddPersonChatService(values)
              .then((res) => {
                if (res.status === 200) {
                  // //(res);
                  dispatch(GetUserChatAction());
                  setFormLoading(false);
                  handleClose();
                  dispatch(showSnackbar(res.data.message, "success"));
                  resetForm();
                }
              })
              .catch((e) => {
                setFormLoading(false);
                dispatch(showSnackbar(e.response.data.message, "error"));
              });
          }}
          validationSchema={validationSchemaPerson}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                }}
              >
                <FormGroup style={{}}>
                  <div className="">
                    <div className="w-100">
                      <Typography
                        className="mb-2"
                        fontFamily={FontFamily.Lato}
                        fontSize={fontSize.h3.medium}
                        fontWeight={fontWeight.W400}
                      >
                        Name Of Recipient
                      </Typography>
                      <CustomSelect
                        value={values.recipient}
                        name={"recipient"}
                        multiple={false}
                        option={filteredUser}
                        onChange={(e) =>
                          setFieldValue("recipient", e.target.value)
                        }
                        fullWidth={true}
                        label={"Select name of recipient"}
                        helperText={
                          <Typography
                            align="left"
                            // className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.recipient}
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </FormGroup>

                <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-3">
                  <div className="px-3">
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                    >
                      Submit
                    </CustomButton>
                  </div>
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
