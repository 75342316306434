import {
  Box,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import moment from "moment";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import LeftSideArrow from "../../Icon/LeftSideArrow";
import { useEffect, useState } from "react";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { GetStudentReminderByIdAction } from "../../Redux/action/ApplicationAction";
import {
  DeleteStudentReminderService,
  getDefaultCommissionDetailsService,
} from "../../Redux/service/Application";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";
import CustomDataTable from "../datatable/DataTable";
import { Check, Circle, Edit, UpgradeOutlined } from "@mui/icons-material";
import { PaymentDetailModal } from "./PaymentDetailsModal.component";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { EditUniversityAmountModal } from "./EditUniversityAmount";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "80vh",
  // overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 2,
};

export const PayoutAllReminderModal = ({
  HistoryModal,
  handleClose,
  AgentTransactionlist,
  debounce,
  handleInputChange,
}) => {
  const dispatch = useDispatch();
  const [ReminderByIdlist, setReminderByIdlist] = useState([]);
  const [reminderId, setReminderId] = useState();
  const [confirmationopen, setConfirmationOpen] = useState(false);
  const [table_load, setTable_load] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [UserTransaction, setUserTransaction] = useState(false);

  const [edit, setEdit] = useState(false);

  const [stdId, setStdId] = useState("");

  const [transactionId, settransactionId] = useState({});

  const [DefaultCommission, setDefaultCommission] = useState();

  const [UniversityAmount, setUniversityAmount] = useState();

  const getDefaultCommission = async () => {
    console.log(DefaultCommission);
    await getDefaultCommissionDetailsService()
      .then((res) => {
        if (res.status === 200) {
          console.log("checking for calling", res);
          setDefaultCommission(res.data.commission);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const historycolumns = [
    {
      field: "action",
      headerName: "Update Transaction",
      width: 150,
      renderCell: (params) => {
        const handleUserTransactionActionClick = (e) => {
          e.stopPropagation();
          getDefaultCommission();
          setEdit(true);
          setStdId(params.row.studentId);
          setUserTransaction(true);
          console.log(params.row);
          settransactionId({
            transactionId: params.row.id,
            isCredited: params.row.isCredited,
            isSettled: params.row.isSettled,
            receivedFromUniversity: params.row.receivedFromUniversity,
            amountPayableToAgent: params.row.amountPayableToAgent,
            studentId: params.row?.studentId,
            organisation: params.row?.organisation,
          });
        }
          const handleUnivAmtActionClick = (e) => {
            e.stopPropagation();
            getDefaultCommission();
            setEdit(true);
            setStdId(params.row.studentId);
            handleCloseUniversityAmountModal()

            settransactionId({
              transactionId: params.row.id,
              // isCredited: params.row.isCredited,
              receivedFromUniversity: params.row.receivedFromUniversity,
              bonusFromUniversity: params.row.bonusFromUniversity,
            });
        };
      
        return (
          <Box className="">
            <IconButton onClick={handleUserTransactionActionClick}>
              <ModeEditIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
            </IconButton>
            <IconButton onClick={handleUnivAmtActionClick}>
              {/* <ModeEditIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} /> */}save
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "currentTransactionNumber",
      headerName: "Current Transaction Number",
      width: 150,
      renderCell: (params) => {
        return <Box>{params.row?.currentTransactionNumber}</Box>;
      },
    },
    {
      field: "studentId.universityId",
      headerName: "Student Id",
      width: 150,
      renderCell: (params) => {
        return <Box>{params.row?.studentId?.universityId}</Box>;
      },
    },
    {
      field: "receivedFromUniversity",
      headerName: "University Commission",
      width: 250,
      renderCell: (params) => {
        return <div>$ {params.row.receivedFromUniversity}</div>;
      },
    },
    {
      field: "isSettled",
      headerName: "Settled",
      width: 150,
      renderCell: (params) => {
        return (
          <Box flexDirection={"row"} sx={{ pt: 5 }} display={"flex"}>
            {params.row.isSettled ? (
              <Check sx={{ fill: "green" }} />
            ) : (
              <Circle sx={{ fill: "grey" }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "settledAt",
      headerName: "Settled At",
      width: 250,
      renderCell: (params) => {
        return (
          <div>{dayjs(params.row.settledAt).format("YYYY-MM-DD HH:MM")}</div>
        );
      },
    },
    {
      field: "isCredited",
      headerName: "Amount Received",
      width: 150,
      renderCell: (params) => {
        return (
          <Box flexDirection={"row"} sx={{ pt: 5 }} display={"flex"}>
            {params.row.isCredited ? (
              <Check sx={{ fill: "green" }} />
            ) : (
              <Circle sx={{ fill: "grey" }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "creditedAt",
      headerName: "Amount Received At",
      width: 250,
      renderCell: (params) => {
        return (
          <div>{dayjs(params.row.creditedAt).format("YYYY-MM-DD HH:MM")}</div>
        );
      },
    },
    {
      field: "organisationCommission",
      headerName: "Organisation Commission",
      width: 250,
      renderCell: (params) => {
        return <div>$ {params.row.organisationCommission}</div>;
      },
    },

    {
      field: "chequePayable",
      headerName: "Cheque Payable (in rs)",
      width: 250,
    },
    {
      field: "exchangeRateUSDToINR",
      headerName: "Exchange Rate USD To INR",
      width: 250,
    },
    {
      field: "grossCommission",
      headerName: "Gross Commission",
      width: 250,
    },
    {
      field: "invoiceTotalAmount",
      headerName: "Invoice Total Amount",
      width: 250,
    },
    {
      field: "gstAmount",
      headerName: "Gst Amount",
      width: 250,
    },
    {
      field: "gstPercentage",
      headerName: "Gst Percentage",
      width: 250,
    },
    {
      field: "tdsAmount",
      headerName: "Tds Amount",
      width: 250,
    },
    {
      field: "tdsPercentage",
      headerName: "Tds Percentage",
      width: 250,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 250,
    },
  ];

  const ReminderByIdlistState = useSelector(
    (state) => state.application?.studentreminder?.data?.reminders
  );
  //(ReminderByIdlistState);
  useEffect(() => {
    setReminderByIdlist(ReminderByIdlistState);
  }, [ReminderByIdlistState]);

  const handleClosePayementDetailModal = () => {
    setDefaultCommission();
    setUserTransaction(!UserTransaction);
  };

  const handleCloseUniversityAmountModal = () => {
    setDefaultCommission();
    setUniversityAmount(!UniversityAmount);
  };

  return (
    <>
      {" "}
      <Modal
        open={HistoryModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography align="right" onClick={() => handleClose()}>
            <Crossicon width={"20"} height={"20"} />
          </Typography>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.BLACK}
            align="center"
          >
            Transaction History
          </Typography>

          <div>
            <CustomDataTable
              row={AgentTransactionlist ? AgentTransactionlist : []}
              columns={historycolumns}
              loading={table_load}
              height={"80%"}
              rowheight={100}
              onSelectionChange={(newSelection) => {
                setSelectionModel(newSelection);
              }}
              // getRowClassName={(params) =>
              //   params.row.isActive === "Y" ? "row-white" : "row-dull"
              // }
            />
          </div>
        </Box>
      </Modal>
      {DefaultCommission && (
        <PaymentDetailModal
          transactionId={transactionId}
          UserTransaction={UserTransaction}
          handleClose={handleClosePayementDetailModal}
          AgentTransactionlist={AgentTransactionlist}
          handleCloseReminder={handleClose}
          DefaultCommission={DefaultCommission}
        />
      )}
      <EditUniversityAmountModal
        transactionId={transactionId}
        UserTransaction={UniversityAmount}
        handleClose={handleCloseUniversityAmountModal}
        AgentTransactionlist={AgentTransactionlist}
        handleCloseReminder={handleClose}
        DefaultCommission={DefaultCommission}
      />
    </>
  );
};
