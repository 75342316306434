import React from "react";

function Reminder() {
  return (
    <div>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.3125 0C5.86623 0 4.45243 0.428871 3.2499 1.23238C2.04736 2.03589 1.1101 3.17794 0.556634 4.51413C0.00316839 5.85031 -0.141643 7.32061 0.140511 8.7391C0.422665 10.1576 1.11911 11.4605 2.14178 12.4832C3.16446 13.5059 4.46742 14.2023 5.88591 14.4845C7.30439 14.7666 8.77469 14.6218 10.1109 14.0684C11.4471 13.5149 12.5891 12.5776 13.3926 11.3751C14.1961 10.1726 14.625 8.75878 14.625 7.3125C14.623 5.37373 13.8519 3.51496 12.481 2.14404C11.11 0.773127 9.25127 0.00204737 7.3125 0ZM11.25 7.875H7.3125C7.16332 7.875 7.02025 7.81574 6.91476 7.71025C6.80927 7.60476 6.75 7.46168 6.75 7.3125V3.375C6.75 3.22582 6.80927 3.08274 6.91476 2.97725C7.02025 2.87176 7.16332 2.8125 7.3125 2.8125C7.46169 2.8125 7.60476 2.87176 7.71025 2.97725C7.81574 3.08274 7.875 3.22582 7.875 3.375V6.75H11.25C11.3992 6.75 11.5423 6.80926 11.6478 6.91475C11.7532 7.02024 11.8125 7.16332 11.8125 7.3125C11.8125 7.46168 11.7532 7.60476 11.6478 7.71025C11.5423 7.81574 11.3992 7.875 11.25 7.875Z"
          fill="#8DA0D9"
        />
      </svg>
    </div>
  );
}

export default Reminder;
