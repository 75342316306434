import {
  GetAgentTransactionListConst,
  GetAllBranchListConst,
  GetDegreeListConst,
  GetStudentChatByIdConst,
  GetStudentListConst,
  GetStudentNotesByIdConst,
  GetStudentReminderByIdConst,
  GetUnsettledListConst,
} from "../constant/ApplicationConstant";

const initTheme = {
  student: [],
  degree: [],
  studentchat: [],
  studentnotes: [],
  studentreminder: [],
  allbranch: [],
  unsettledList: [],
  agenttransaction: [],
};

const ApplicationReducer = (state = initTheme, action) => {
  switch (action.type) {
    case GetStudentListConst:
      return { ...state, student: action.payload };
    case GetDegreeListConst:
      return { ...state, degree: action.payload };
    case GetStudentChatByIdConst:
      return { ...state, studentchat: action.payload };
    case GetStudentNotesByIdConst:
      return { ...state, studentnotes: action.payload };
    case GetStudentReminderByIdConst:
      return { ...state, studentreminder: action.payload };
    case GetAllBranchListConst:
      return { ...state, allbranch: action.payload };
    case GetUnsettledListConst:
      return { ...state, unsettledList: action.payload };
    case GetAgentTransactionListConst:
      return { ...state, agenttransaction: action.payload };

    default:
      return state;
  }
};

export default ApplicationReducer;
