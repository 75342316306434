import {
  GetAgentListConst,
  GetBranchByOrgIdConst,
  GetCatalogListConst,
  GetFilteredUserConst,
  GetOrganizationListConst,
  GetProcessListConst,
  GetProgramListConst,
  GetRoleListConst,
  GetSettingCountConst,
  GetUniversityListConst,
  GetUserChatByIdConst,
  GetUserChatConst,
  GetUserListConst,
  SaveRolesConst,
} from "../constant/SettingConstant";

const initTheme = {
  university: [],
  user: [],
  agent: [],
  organization: [],
  role: [],
  catalog: [],
  process: [],
  roles: [],
  allchat: [],
  chat: [],
  branchByOrg: [],
  filteredUser: [],
  program: [],
  settingCount: [],
};

const SettingReducer = (state = initTheme, action) => {
  //.log(state)
  // //(action, "line 9");

  switch (action.type) {
    case GetUniversityListConst:
      return { ...state, university: action.payload };
    case GetAgentListConst:
      return { ...state, agent: action.payload };
    case GetUserListConst:
      return { ...state, user: action.payload };
    case GetOrganizationListConst:
      return { ...state, organization: action.payload };
    case GetRoleListConst:
      return { ...state, role: action.payload };
    case GetCatalogListConst:
      return { ...state, catalog: action.payload };
    case GetProcessListConst:
      return { ...state, process: action.payload };
    case SaveRolesConst:
      return { ...state, roles: action.payload };
    case GetUserChatConst:
      return { ...state, allchat: action.payload };
    case GetUserChatByIdConst:
      return { ...state, chat: action.payload };
    case GetBranchByOrgIdConst:
      return { ...state, branchByOrg: action.payload };
    case GetFilteredUserConst:
      return { ...state, filteredUser: action.payload };
    case GetProgramListConst:
      return { ...state, program: action.payload };
    case GetSettingCountConst:
      return { ...state, settingCount: action.payload };

    default:
      return state;
  }
};

export default SettingReducer;
