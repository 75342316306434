import { combineReducers } from "redux";
import SettingReducer from "./SettingReducer";
import SnackbarReducer from "./SnackbarReducer";
import EmailReducer from "./EmailReducer";
import ApplicationReducer from "./ApplicationReducer";
import FileManagerReducer from "./FileManagerReducer";
import { SupportReducer } from "./SupportReducer";
import { notification } from "antd";
import { NotificationReducer } from "./NotificationReducer";
import { uiReducer } from "./UiReducer";

export const reducers = combineReducers({
  setting: SettingReducer,
  snackbar: SnackbarReducer,
  email: EmailReducer,
  application: ApplicationReducer,
  fileManager: FileManagerReducer,
  support: SupportReducer,
  notification: NotificationReducer,
  ui: uiReducer,
});
