import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import { GetUniversityAction } from "../../Redux/action/SettingAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateCatalogService,
  EditCatalogService,
} from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { UploadFileService } from "../../Redux/service/FileService";
import { createGlobalNotificationAPI } from "../../Redux/service/NotificationService";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const AddNotificationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const childRef = useRef(null);

  const { id } = useParams();

  const [Edit, setEdit] = useState(id ? true : false);

  const [prefieldData, setPrefieldData] = useState();

  const [Universitylist, setUniversitylist] = useState([]);
  const [formLoading, setFormLoading] = useState(false);

  const EditCatalogdataState = useSelector(
    (state) => state.setting?.catalog?.data?.data
  );
  //(EditCatalogdataState);
  useEffect(() => {
    setPrefieldData(
      EditCatalogdataState?.filter((data) => data._id === id)?.[0]
    );
  }, [Edit, EditCatalogdataState, id]);

  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );

  useEffect(() => {
    dispatch(GetUniversityAction());
  }, []);

  useEffect(() => {
    const updatedData = UniversitylistState?.map(({ name, ...rest }) => ({
      title: name,
      ...rest,
    }));
    setUniversitylist(updatedData);
  }, [UniversitylistState]);

  const validationSchema = Yup.object({
    text: Yup.string().required("Notification Text is required"),
    title: Yup.string().required("Notification Title is required"),
  });

  if (!prefieldData && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h2.small}
        >
          Notification
        </Typography>
        <Typography
          className="px-2 pt-1"
          fontFamily={FontFamily.Poppins}
          fontSize={fontSize.h4.medium}
          fontWeight={fontWeight.W300}
          color={ThemeColor.GREY}
        >
          {!Edit ? "Create" : "Edit"} notification
        </Typography>
      </div>
      <div className="container">
        <Formik
          initialValues={{
            title: "",
            text: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { ressetForm }) => {
            setFormLoading(true);
            //("Form data:", values);
            await createGlobalNotificationAPI(values)
              .then((res) => {
                if (res.status === 200) {
                  // //(res);
                  setFormLoading(false);

                  dispatch(showSnackbar(res.data.message, "success"));
                  navigate("/CAE/AllNotification");
                  ressetForm();
                }
              })
              .catch((e) => {
                setFormLoading(false);
                dispatch(showSnackbar(e.response.data.message, "error"));
              });
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form
              className="d-flex justify-content-center"
              onSubmit={handleSubmit}
            >
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "80%", lg: "80%" },
                }}
              >
                <Typography
                  align="center"
                  fontFamily={FontFamily.Poppins}
                  fontWeight={fontWeight.W600}
                  fontSize={fontSize.h3.medium}
                  color={ThemeColor.PURPLE}
                >
                  {!Edit ? "Add" : "Edit"} Notification
                </Typography>

                <FormGroup style={{}}>
                  <div className="">
                    <Typography
                      className="mb-2"
                      fontFamily={FontFamily.Lato}
                      fontSize={fontSize.h3.medium}
                      fontWeight={fontWeight.W400}
                    >
                      Notification title
                    </Typography>
                    <OutlinedInputField
                      value={values.title}
                      name={"title"}
                      onChange={handleChange}
                      placeholder="Enter title"
                      fullWidth={true}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.title}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>

                <FormGroup style={{}}>
                  <div className="">
                    <Typography
                      className="mb-2"
                      fontFamily={FontFamily.Lato}
                      fontSize={fontSize.h3.medium}
                      fontWeight={fontWeight.W400}
                    >
                      Notification text
                    </Typography>
                    <OutlinedInputField
                      value={values.text}
                      name={"text"}
                      onChange={handleChange}
                      placeholder="Enter text"
                      fullWidth={true}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.text}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>

                {/* <div className="w-100 d-flex justify-content-center mt-4">
                  <Previews
                    initialValue={values.documents}
                    ref={childRef}
                    handleMydropzoneFile={(updatedFiles) => {
                      //(updatedFiles, "oooyesah");
                      setFieldValue("documents", updatedFiles);
                    }}
                    helperText={
                      <Typography
                        align="left"
                        // className="px-3"
                        color={ThemeColor.RED}
                        fontSize={fontSize.h4.medium}
                        fontFamily={FontFamily.Lato}
                        fontWeight={fontWeight.W500}
                      >
                        {errors.documents}
                      </Typography>
                    }
                  />
                </div> */}
                {(!window.sessionStorage.getItem("user_role_type") ===
                  "agent" ||
                  window.sessionStorage.getItem("user_role_type") === "admin" ||
                  window.sessionStorage.getItem("user_role_type") ===
                    "user") && (
                  <Box
                    sx={{
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                    className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
                  >
                    {" "}
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                      loading={formLoading}
                      //   padding={"0px"}
                      // onClick={handleClick}
                    >
                      {!Edit ? "Save" : "Edit"} Notification
                    </CustomButton>
                    <Box
                      className="px-2"
                      sx={{
                        paddingTop: {
                          lg: "0px",
                          md: "0px",
                          sm: "5px",
                          xs: "5px",
                        },
                      }}
                    >
                      {Edit && (
                        <CustomButton
                          variant="contained"
                          // type="submit"
                          backgroundColor={ThemeColor.PURPLE}
                          color={ThemeColor.WHITE}
                          loading={formLoading}
                          //   padding={"0px"}
                          onClick={() => {
                            // setFieldValue('birthday', null);
                            // if (childRef.current) {
                            //   childRef.current.someFunction();
                            // }
                            if (!Edit) {
                              if (childRef.current) {
                                childRef.current.someFunction();
                              }
                            } else {
                              if (childRef.current) {
                                childRef.current.someEditFunction();
                              }
                            }
                            // setFieldValue("documents", []);
                            // //(values)
                            resetForm();
                          }}
                        >
                          Revert
                        </CustomButton>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function AddNotification() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<AddNotificationPage />} />
    </div>
  );
}

export default AddNotification;
