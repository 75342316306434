import axios from "axios";
import { basicurl } from "../ServiceUrl/url";

// const basicurl = basicurl;

const token = window.sessionStorage.getItem("user_token");

export const GetFolderlistService = async () => {
  let res = await axios.get(
    basicurl + `/api/folder/get-all-public-root-folders-and-files`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetFilelistService = async () => {
  let res = await axios.get(
    basicurl + `/api/folder/get-user-root-folders-and-files`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetFolderAndFilesByParentlistService = async (parentid) => {
  //(parentid,"service")
  let res = await axios.get(
    basicurl + `/api/folder/get-folders-and-files-by-parent/${parentid}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const UpdateFolderService = async (data,sourceid) => {
  // //(parentid,"service")
  let res = await axios.put(
    basicurl + `/api/folder/update-folder/${sourceid}`,data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const UpdateFileService = async (data,sourceid) => {
  // //(parentid,"service")
  let res = await axios.put(
    basicurl + `/api/folder/update-file/${sourceid}`,data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const CreateFolderService = async (data) => {
  // //(parentid,"service")
  let res = await axios.post(
    basicurl + `/api/folder/create-new-folder`,data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const CreateFileService = async (data) => {
  // //(parentid,"service")
  let res = await axios.post(
    basicurl + `/api/folder/create-new-file`,data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const DeleteFileService = async (fileId) => {
  //(fileId)
  let res = await axios.delete(
    basicurl + `/api/folder/delete-file/${fileId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const DeleteFolderService = async ({ folderId }) => {
  let res = await axios.delete(
    basicurl + `/api/folder/delete-folder/${folderId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
