import React from "react";

function Msg(props) {
  return (
    <div>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4599 7.41992H5.6799"
          stroke={props.stroke?props.stroke:"white"}
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.9699 10.71H7.67991"
          stroke={props.stroke?props.stroke:"white"}
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.3299 14.94C18.9999 11.27 18.5599 5.05002 14.0199 1.99002C11.1899 0.0800211 7.36992 0.140021 4.58992 2.11002C0.749917 4.83002 -0.0100831 9.87002 2.30992 13.51L1.22992 16.19C0.979917 16.8 1.58992 17.41 2.20992 17.17L4.88992 16.09C8.12992 18.16 12.4899 17.78 15.3299 14.94Z"
          stroke={props.stroke?props.stroke:"white"}
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Msg;
