import { Box, Button, Modal, Typography } from "@mui/material";
import { fontSize } from "../theme/fontSize";
import CustomButton from "../button/button";
import { ThemeColor } from "../theme/color";
import OutlineCustomButton from "../button/outlineButton";
import { updateSupportTicketStatus } from "../../Redux/service/SupportService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { sm: "50%", xs: "50%", md: "50%", lg: "50%" },
    height: "20vh",
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid grey",
    boxShadow: 24,
    p: 4,
};

export const SettleSupport = ({ open, handleClose, id, dispatch }) => {

    const handleSettle = async () => {
        try {
            const resp = await updateSupportTicketStatus(true, id);
            if (resp.status == 200) {
                dispatch(showSnackbar(resp.data.message, "success"))
            }
            handleClose()
        } catch (err) {
            dispatch(showSnackbar(err.response.data.message, "error"))
        }
    }

    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >

        <Box sx={style}>
            <Typography sx={{ fontSize: fontSize.h1.small }} align="center">Are You sure to settle this ticket</Typography>
            <Box justifyContent={"center"} alignSelf={"center"} display={"flex"} flex={1}>
                <div style={{ margin: 10 }}>
                    <OutlineCustomButton onClick={handleClose}>Cancel</OutlineCustomButton>
                </div>
                <div style={{ margin: 10 }}>

                    <CustomButton
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        onClick={handleSettle}
                    >Yes Settel</CustomButton>
                </div>
            </Box>
        </Box>
    </Modal>

}