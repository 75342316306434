import { GET_ALL_SUPPORT_TICKET, SAVE_SUPPORT_TICKET } from "../constant/SupportConstant"

const support = {
    supportList: [],
    save: {}
}

export const SupportReducer = (state = support, action) => {
    switch (action.type) {
        case SAVE_SUPPORT_TICKET:
            return { ...state, save: action.payload };
        case GET_ALL_SUPPORT_TICKET:
            return { ...state, supportList: action.payload }
        default:
            return state;
    }
}
