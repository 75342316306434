// FolderManagement.js
import React, { useEffect, useMemo, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import {
  Box,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import { ThemeColor } from "../../component/theme/color";
import CustomButton from "../../component/button/button";
import "../foldermanagement/foldermanagement.css";
import FolderMenu from "../../Icon/FolderMenu";
import FolderShare from "../../Icon/FolderShare";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FolderStackAction,
  GetFileAction,
  GetFolderAction,
} from "../../Redux/action/FileManagerAction";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import {
  CreateFolderService,
  DeleteFileService,
  UpdateFileService,
  UpdateFolderService,
} from "../../Redux/service/FileManagerService";
import { AddCircleOutlined, Check, Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import Crossicon from "../../Icon/Crossicon";
import * as Yup from "yup";
import { RightOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../component/Modal/ConfirmationModal/ConfirmationModal";
import { CreateFileModal } from "./CreateFileModal.component";
import VisibilityIcon from '@mui/icons-material/Visibility';


// A constant type for drag-and-drop items
const ItemTypes = {
  FILE: "file",
  FOLDER: "folder",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "35vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};


const getFileIcon = (fileName) => {
  if (!fileName) return "";

  const extension = fileName.split(".").pop()?.toLowerCase();
  console.log(extension,fileName)

  switch (extension) {
    case "pdf":
      return "/assest/folderpng/pdf.png"; // Path to your PDF icon
    case "xlsx":
      return "/assest/folderpng/excel.png";
    case "xls":
      return "/assest/folderpng/excel.png"; // Path to your Excel icon
    default:
      return "/assest/folderpng/folder.png"; // Default file icon
  }
};

// Folder component
const Folder = ({
  folder,
  onDrop,
  onClick,
  moveFolder,
  handleFolderActionFromChild,
}) => {
  const navigate = useNavigate();
  const disptach = useDispatch();
  const [folderstack, setFolderstack] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [renameOn, setRenameOn] = useState("none");

  // const FolderStackState = useSelector(
  //   (state) => state.fileManager?.folderstack
  // );
  // //("stack of the folder", FolderStackState);
  // useEffect(() => {
  //   setFolderstack(FolderStackState);
  // }, [FolderStackState]);

  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.FILE, ItemTypes.FOLDER],
    drop: (item) => {
      //(item, "inside the drop of folder");
      // if (item.type === ItemTypes.FOLDER) {
      //   moveFolder(item.id, folder.id); // Handle moving folders
      // } else {
      //   onDrop(item, folder.id); // Handle file drop
      // }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.FOLDER,
    item: { id: folder.id, type: ItemTypes.FOLDER },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const dragDropRef = (node) => {
    drag(node); // Assign drag ref
    drop(node); // Assign drop ref
  };

  const handleRename = async () => {
    setRenameOn("flex");
  };

  const updateFileSchema = Yup.object().shape({
    name: Yup.string().required("Name of file is required."),
  });

  return (
    <div
      //   ref={drag}
      ref={dragDropRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        marginBottom: "5px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "10px",
        borderBottom: "1px solid rgba(210, 205, 205, 1)",
        backgroundColor: isOver ? "lightgreen" : "",
      }}
      onClick={() => onClick(folder)}
    >
      <Box className="file" sx={{ width: "80%" }}>
        <div className="w-100 d-flex">
          <div className="filename-icon">
            <img
              src="/assest/folderpng/folder.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div>
          <div className="file-name px-2 w-100">
            <Typography
              onClick={() => {
                const duplicateId = folderstack.some((id) => id === folder.id);
                //(duplicateId, "status of stack");
                // if (!duplicateId) disptach(FolderStackAction(folder.id));
                navigate(`Folder/${folder.id}`);
              }}
              sx={{ display: renameOn === "none" ? "flex" : "none" }}
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W500}
              fontSize={fontSize.h3.medium}
            >
              {folder.name}
            </Typography>
            <Box
              sx={{ display: renameOn === "none" ? "none" : "flex" }}
              className="w-100"
            >
              <Formik
                initialValues={{
                  name: folder ? folder.name : "",
                }}
                validationSchema={updateFileSchema}
                className="w-100"
                onSubmit={async (values) => {
                  //(values, "agent payload");
                  await UpdateFolderService(values, folder.id)
                    .then((res) => {
                      //(res);
                      if (res.status === 200) {
                        //(res);
                        handleFolderActionFromChild();

                        disptach(showSnackbar(res.data.message, "success"));
                        setRenameOn("none");
                      }
                    })
                    .catch((e) => {
                      disptach(showSnackbar(e.response.data.message, "error"));
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <Form className="w-100" onSubmit={handleSubmit}>
                    <Grid container spacing={2} className="">
                      <Grid item lg={6} xs={6}>
                        <div className="">
                          <OutlinedInputField
                            value={values.name}
                            name={"name"}
                            onChange={handleChange}
                            placeholder="Update file name"
                            fullWidth={true}
                            onBlur={handleBlur}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.name}
                              </Typography>
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Box
                          sx={{
                            flexDirection: {
                              lg: "row",
                              md: "row",
                              sm: "column",
                              xs: "column",
                            },
                          }}
                          className=""
                        >
                          <IconButton type="submit">
                            <Check sx={{ fill: ThemeColor.BLUE }} />
                          </IconButton>
                          <IconButton>
                            <Close
                              sx={{ fill: "red" }}
                              onClick={() => {
                                setRenameOn("none");
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      </Box>

      <Box className="file-action" sx={{ width: "30%" }}>
        <div className="w-100 d-flex justify-content-end justify-content-between">
          <div className="file-action-content d-flex">
            <div className="px-2">
              <IconButton
                sx={{ padding: 0 }}
                // onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                {/* <FolderMenu /> */}
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={handleRename}>Rename</MenuItem>
              </Menu>
            </div>
            {/* <div className="px-2">
              <FolderShare />
            </div> */}
          </div>

          <div className="file-action-date d-flex">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h3.small}
              fontWeight={fontWeight.W500}
              color={ThemeColor.GREY}
            >
              {dayjs().format("DD/MM/YYYY")}
            </Typography>
          </div>
        </div>
      </Box>
    </div>
  );
};

// File component
const File = ({ file, handleFolderActionFromChild, handleDelete }) => {
  const disptach = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [renameOn, setRenameOn] = useState("none");

  const handleRename = async () => {
    setRenameOn("flex");
  };

  const updateFileSchema = Yup.object().shape({
    name: Yup.string().required("Name of file is required."),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.FILE,
    item: { id: file.id, name: file.name },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const fileIcon = useMemo(() => getFileIcon(file?.name), [file?.name]);
  

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        marginBottom: "5px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "10px",
        borderBottom: "1px solid rgba(210, 205, 205, 1)",
      }}
    >
      <Box className="file" sx={{ width: "80%" }}>
        <div className="w-100 d-flex">
          <div className="filename-icon">
            {/* <img
              src="/assest/folderpng/excel.png"
              alt="file icon"
              className="w-100 h-100"
            ></img> */}
             <img src={fileIcon} alt="file icon" className="file-icon" />
          </div>
          <div className="file-name px-2 w-100">
            <Typography
              sx={{ display: renameOn === "none" ? "flex" : "none" }}
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W500}
              fontSize={fontSize.h3.medium}
            >
              {file.name}
            </Typography>
            <Box
              sx={{ display: renameOn === "none" ? "none" : "flex" }}
              className="w-100"
            >
              <Formik
                initialValues={{
                  name: file ? file.name : "",
                }}
                validationSchema={updateFileSchema}
                className="w-100"
                onSubmit={async (values) => {
                  //(values, "agent payload");
                  await UpdateFileService(values, file.id)
                    .then((res) => {
                      //(res);
                      if (res.status === 200) {
                        //(res);
                        handleFolderActionFromChild();

                        disptach(showSnackbar(res.data.message, "success"));
                        setRenameOn("none");
                      }
                    })
                    .catch((e) => {
                      disptach(showSnackbar(e.response.data.message, "error"));
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <Form className="w-100" onSubmit={handleSubmit}>
                    <Grid container spacing={2} className="">
                      <Grid item lg={6} xs={6}>
                        <div className="">
                          <OutlinedInputField
                            value={values.name}
                            name={"name"}
                            onChange={handleChange}
                            placeholder="Update file name"
                            fullWidth={true}
                            onBlur={handleBlur}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.name}
                              </Typography>
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Box
                          sx={{
                            flexDirection: {
                              lg: "row",
                              md: "row",
                              sm: "column",
                              xs: "column",
                            },
                          }}
                          className=""
                        >
                          <IconButton type="submit">
                            <Check sx={{ fill: ThemeColor.BLUE }} />
                          </IconButton>
                          <IconButton>
                            <Close
                              sx={{ fill: "red" }}
                              onClick={() => {
                                setRenameOn("none");
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </div>
        </div>
      </Box>

      <Box className="file-action" sx={{ width: "30%" }}>
        <div className="w-100 d-flex justify-content-end justify-content-between">
          <div className="file-action-content d-flex">
            <div className="px-2">
              <IconButton
                sx={{ padding: 0 }}
                // onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                {/* <FolderMenu /> */}
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={handleRename}>Rename</MenuItem>
                <MenuItem onClick={() => handleDelete("file", file.id)}>
                  Delete
                </MenuItem>
              </Menu>
            </div>
            <div className="px-2">
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => {
                  window.open(file.url, "_blank", "noopener noreferrer");
                  // window.location.href = `${file.url}`;
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </div>
          </div>

          <div className="file-action-date d-flex">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h3.small}
              fontWeight={fontWeight.W500}
              color={ThemeColor.GREY}
            >
              {dayjs().format("DD/MM/YYYY")}
            </Typography>
          </div>
        </div>
      </Box>
    </div>
  );
};

// Main Folder Management component
const FolderManagement = (props) => {
  const disptach = useDispatch();
  const navigate = useNavigate();

  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [createFileModal, setCreateFileModal] = useState(false);

  const [ConfirmationOpen, setConfirmationOpen] = useState(false);

  const [TypeOfdelete, setTypeOfdelete] = useState("");

  const [deleteId, setDeleteId] = useState();

  const handleCloseFileModal = () => {
    setCreateFileModal(!createFileModal);
  };

  const handleDelete = (type, deleteid) => {
    //(deleteid)
    setDeleteId(deleteid);
    setTypeOfdelete(type);
    setConfirmationOpen(true);
  };

  const [folders, setFolders] = useState([
    // {
    //   id: 1,
    //   name: "Folder 1",
    //   files: [{ id: 1, name: "File A" }],
    //   subfolders: [],
    // },
    // {
    //   id: 2,
    //   name: "Folder 2",
    //   files: [{ id: 2, name: "File B" }],
    //   subfolders: [],
    // },
  ]);

  const handleFolderActionFromChild = () => {
    setFolders([]);
    setFiles([]);
    //("calling disptach");
    disptach(GetFolderAction());
  };

  const [files, setFiles] = useState([
    // { id: 3, name: "File C" },
    // { id: 4, name: "File D" },
  ]);

  const [selectedFolder, setSelectedFolder] = useState(null);

  useEffect(() => {
    setFolders([]);
    setFiles([]);
    disptach(GetFolderAction());
  }, [props]);

  const FolderSelector = useSelector(
    (state) => state.fileManager?.folder?.data?.data
  );

  useEffect(() => {
    FolderSelector?.map((data) => {
      //(data);
      if (data.type === "folder") {
        let json = {
          id: data._id,
          name: data.name,
          files: data.files,
          subfolders: data.children,
        };
        const isDuplicateFolder = folders.some(
          (item) => item.id === data._id && item.name === data.name
        );
        if (!isDuplicateFolder) setFolders((prevFiles) => [...prevFiles, json]);
      } else {
        let json = {
          id: data._id,
          name: data.name,
          url: data.url,
        };
        const isDuplicateFiles = files.some(
          (item) => item.id === data._id && item.name === data.name
        );
        if (!isDuplicateFiles) setFiles((prevFiles) => [...prevFiles, json]);
      }
    });
  }, [FolderSelector]);

  // Handle file drop into a folder
  const handleDrop = async (file, folderId) => {
    //("handle drop", file, folderId);
    let data = {
      name: file.name,
      folder: folderId,
    };

    await UpdateFileService(data, file.id)
      .then((res) => {
        if (res.status === 200) {
          disptach(GetFolderAction());
          disptach(showSnackbar(res.data.message, "success"));
          // setFolders((prevFolders) =>
          //   prevFolders.map((folder) => {
          //     const res =
          //       folder.id === folderId
          //         ? { ...folder, files: [...folder.files, file] }
          //         : folder;
          //     //(res, "added folder");

          //     return res;
          //   })
          // );

          //(folders);
          // setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id));
        }
      })
      .catch((e) => {
        disptach(showSnackbar(e.response.data.message, "error"));
      });
  };

  // Handle moving a folder into another folder
  const moveFolder = async (sourceFolderId, destinationFolderId) => {
    //("move folder", sourceFolderId, destinationFolderId);

    const sourceFolder = folders.find((folder) => folder.id === sourceFolderId);

    //(sourceFolder);

    let data = {
      name: sourceFolder.name,
      parent: destinationFolderId,
    };

    await UpdateFolderService(data, sourceFolderId)
      .then((res) => {
        if (res.status === 200) {
          disptach(GetFolderAction());
          disptach(showSnackbar(res.data.message, "success"));
        }
      })
      .catch((e) => {
        disptach(showSnackbar(e.response.data.message, "error"));
      });

    // Remove the source folder from the main folder list
    setFolders((prevFolders) =>
      prevFolders
        .filter((folder) => folder.id !== sourceFolderId) // Remove the folder from the main list
        .map((folder) =>
          folder.id === destinationFolderId
            ? { ...folder, subfolders: [...folder.subfolders, sourceFolder] }
            : folder
        )
    );
  };

  // Handle folder click to display its files and subfolders
  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              My Folder
            </Typography>
            <Typography
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
            >
              / Student List 2024 /
            </Typography>
          </div>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Box className="d-flex justify-content-end">
            <Typography
              onClick={() => setCreateFolderModal(true)}
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
              align="right"
            >
              <AddCircleOutlined /> create folder
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Box className="d-flex justify-content-end">
            <Typography
              onClick={() => handleCloseFileModal(true)}
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
              align="right"
            >
              <AddCircleOutlined /> create file
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* drang n drop container */}
      <div className="container-filemanager">
        <DndProvider backend={HTML5Backend} className="w-100">
          <List
            sx={{ width: "100%", maxWidth: "100%" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //   <ListSubheader
            //     component="div"
            //     id="nested-list-subheader"
            //     sx={{ background: "none" }}
            //   >
            //     Folder
            //   </ListSubheader>
            // }
          >
            {folders?.map((folder) => (
              <Folder
                key={folder.id}
                folder={folder}
                onDrop={handleDrop}
                moveFolder={moveFolder}
                onClick={handleFolderClick}
                handleFolderActionFromChild={handleFolderActionFromChild}
              />
            ))}
          </List>
          <List
            sx={{ width: "100%", maxWidth: "100%" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //   <ListSubheader
            //     component="div"
            //     id="nested-list-subheader"
            //     sx={{ background: "none" }}
            //   >
            //     Folder
            //   </ListSubheader>
            // }
          >
            {files?.map((file) => (
              <ListItem disablePadding sx={{ padding: 0 }}>
                {/* <ListItemButton>
                  <ListItemIcon></ListItemIcon>
                </ListItemButton> */}
                <File
                  key={file.id}
                  file={file}
                  handleFolderActionFromChild={handleFolderActionFromChild}
                  handleDelete={handleDelete}
                />
              </ListItem>
            ))}
          </List>

          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2>Files</h2>
              {files.map((file) => (
                <File key={file.id} file={file} />
              ))}
            </div>
            <div>
              <h2>Folders</h2>
              {folders.map((folder) => (
                <Folder
                  key={folder.id}
                  folder={folder}
                  onDrop={handleDrop}
                  moveFolder={moveFolder}
                  onClick={handleFolderClick}
                />
              ))}
            </div>
            <div>
              <h2>Selected Folder</h2>
              {selectedFolder ? (
                <>
                  <h3>{selectedFolder.name}</h3>
                  {selectedFolder.files.length > 0 && <h4>Files:</h4>}
                  {selectedFolder.files.length > 0 ? (
                    selectedFolder.files.map((file) => (
                      <p key={file.id}>{file.name}</p>
                    ))
                  ) : (
                    <p>No files in this folder</p>
                  )}
                  {selectedFolder.subfolders.length > 0 && <h4>Subfolders:</h4>}
                  {selectedFolder.subfolders.length > 0 ? (
                    selectedFolder.subfolders.map((subfolder) => (
                      <p key={subfolder.id}>{subfolder.name}</p>
                    ))
                  ) : (
                    <p>No subfolders in this folder</p>
                  )}
                </>
              ) : (
                <p>Click on a folder to view its contents</p>
              )}
            </div>
          </div> */}
        </DndProvider>

        {/* modal for chat window */}
        <Modal
          open={createFolderModal}
          onClose={() => setCreateFolderModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              align="right"
              onClick={() => setCreateFolderModal(false)}
            >
              <Crossicon width={"20"} height={"20"} />
            </Typography>
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h3.large}
              color={ThemeColor.BLACK}
              align="center"
            >
              Create Folder
            </Typography>
            <Formik
              initialValues={{
                name: "",
              }}
              // validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                //("FOLDER data:", values);

                await CreateFolderService(values)
                  .then((res) => {
                    if (res.status === 200) {
                      // //(res);
                      disptach(GetFolderAction());
                      setCreateFolderModal(false);

                      disptach(showSnackbar(res.data.message, "success"));
                      // navigate("/CAE/Filemanager");
                      resetForm();
                    }
                  })
                  .catch((e) => {
                    disptach(showSnackbar(e.response.data.message, "error"));
                  });
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required(
                  "Name of folder is must to create..."
                ),
              })}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                touched,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form className="d-flex justify-content-center">
                  <Box
                    className="addcatalog-container"
                    sx={{
                      width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                      position: "absolute",
                      bottom: "0%",
                    }}
                  >
                    <FormGroup style={{}}>
                      <div className="d-flex justify-content-between">
                        <div className="px-2 w-100">
                          <OutlinedInputField
                            value={values.name}
                            name={"name"}
                            onChange={handleChange}
                            placeholder="Folder Name...."
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.name}
                              </Typography>
                            }
                          />
                        </div>
                        <div className="px-3">
                          <CustomButton
                            variant="contained"
                            type="submit"
                            backgroundColor={ThemeColor.PURPLE}
                            color={ThemeColor.WHITE}
                            // loading={formLoading}
                            //   padding={"0px"}
                            // onClick={handleClick}
                          >
                            Create
                          </CustomButton>
                        </div>
                      </div>
                    </FormGroup>

                    <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-5"></div>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>

        <ConfirmationModal
          handleConfirmationClose={() => {
            setConfirmationOpen(false);
          }}
          deletefunction={async () => {
            if (TypeOfdelete === "file") {
              try {
                const res = await DeleteFileService(deleteId);
                if (res.status === 200) {
                  handleFolderActionFromChild(); // Dispatch the action if success
                  return res; // Return the result so the child can access it
                } else {
                  return Promise.reject("Unexpected response status"); // Handle unexpected status codes
                }
              } catch (e) {
                disptach(
                  showSnackbar(
                    e.response?.data?.message || "Error occurred",
                    "error"
                  )
                );
                return e; // Return the error to the child if needed
              }
            }
          }}
          confirmationopen={ConfirmationOpen}
          message={
            TypeOfdelete === "file"
              ? "All deleted files is never been retrievable"
              : "All deleted folder is never been retrievable"
          }
        />

        <CreateFileModal
          handleClose={handleCloseFileModal}
          createFileModal={createFileModal}
        />
      </div>
    </>
  );
};

function FileManagement() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<FolderManagement />} />
    </div>
  );
}

export default FileManagement;
