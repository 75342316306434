import axios from "axios";
import { basicurl } from "../ServiceUrl/url";

// const basicurl = basicurl;

const token = window.sessionStorage.getItem("user_token");
//.log(token)
// //(token);
export const CreateApplicationService = async (data) => {
  let res = await axios.post(basicurl + "/api/student/add-student", [data], {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetStudentlistService = async () => {
  let res = await axios.get(basicurl + `/api/student/get-all-students`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const GetAllBranchlistService = async () => {
  let res = await axios.get(basicurl + `/api/branch/get-all-braches`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetDegreelistService = async () => {
  let res = await axios.get(basicurl + `/api/degree/get-all-degrees`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const GetCAEidService = async () => {
  let res = await axios.get(basicurl + `/api/student/generate-unique-cae-id`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetStudentChatByIdService = async (data) => {
  //(data, "line 170");
  let res = await axios.get(
    basicurl + `/api/chat-room/get-chats-by-studentId/${data}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetStudentNotesByIdService = async (data) => {
  let res = await axios.get(
    basicurl + `/api/notes/get-student-notes-by-studentId/${data}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetStudentReminderByIdService = async (data) => {
  let res = await axios.get(
    basicurl + `/api/reminder/get-reminders-by-studentId/${data}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const AddStudentChatService = async (data) => {
  let res = await axios.post(
    basicurl + "/api/chat-room/add-new-message-in-chat",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const AddStudentNotesService = async (data) => {
  let res = await axios.post(
    basicurl + "/api/notes/add-message-to-studentnotes",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const AddStudentTransferService = async (data) => {
  let res = await axios.put(basicurl + "/api/student/tramsfer-student", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const DeleteStudentNotesService = async ({ stdId, noteId }) => {
  let res = await axios.delete(
    basicurl +
      `/api/notes/delete-message-from-student-notes/${stdId}/${noteId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const DeleteStudentReminderService = async ({ reminderId }) => {
  let res = await axios.delete(
    basicurl + `/api/reminder/delete-reminder/${reminderId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const AddReminderService = async (data) => {
  let res = await axios.post(basicurl + "/api/reminder/create-reminder", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetUnsettledListService = async (isSettled) => {
  let res = await axios.get(
    basicurl +
      `/api/student/get-visa-approved-unsettled-student-list?isSettled=${isSettled}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetAgentTransactionListService = async (orgId, stdId) => {
  //(orgId, stdId);
  let res = await axios.get(
    basicurl +
      `/api/student/get-transactions-by-organisaction-studentId/${orgId}/${stdId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const EditApplicationService = async (data, id) => {
  let res = await axios.put(
    basicurl + `/api/student/update-student/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const AddPaymentDetailsAfterVisaApprovedService = async (data) => {
  let res = await axios.put(
    basicurl + "/api/student/update-visa-status",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const UpdateStudentTransactionService = async (data) => {
  let res = await axios.put(
    basicurl + "/api/student/update-agent-transaction",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const UpdateStudentPinnedService = async (data, id) => {
  let res = await axios.put(
    basicurl + `/api/student/update-student-isPined-status/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const calcualteAgentCommissionService = async (transcId, data) => {
  //(transcId)
  let res = await axios.post(
    basicurl + `/api/student/calculate-commission-by-transactionId/${transcId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const getUnivByOrgIdService = async (orgId) => {
  //(transcId)
  let res = await axios.get(
    basicurl + `/api/university/get-univesity-by-organisaction/${orgId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const CreateDefaultCommissionService = async (data) => {
  //(transcId)
  let res = await axios.post(
    basicurl + `/api/student/saveCalcualteCommistionDetails`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const getDefaultCommissionDetailsService = async () => {
  //(transcId)
  let res = await axios.get(
    basicurl + `/api/student/getCalculateCommission`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetTransDetailForSelectedStdService = async (data) => {
  let res = await axios.put(
    // basicurl + `/api/student/getTransactionDetailsForSelectedStudents`,
    basicurl+`/api/student/getTransactionDetailsAndUpdateCommission`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const postmarkSeenMessagesStdAPI = async (id) => {
  let res = await axios.post(basicurl + `/api/chat-room/markMessagesAsSeen/${id}`,id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const getUncreditedUnsettledTransactionsForSelectedStudentsAPI = async (ids) => {
  let res = await axios.post(basicurl + `/api/student/getUncreditedUnsettledTransactionsForSelectedStudents`,ids, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const exportForOrganisactionByStudentIdAPI = async (ids) => {
  let res = await axios.post(basicurl + `/api/student/exportForOrganisactionByStudentId`,ids, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const postFilterStdApproveorNotAPI = async (data) => {
  let res = await axios.post(basicurl + `/api/student/filterStudents`,data?.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const bulkUpdateTransactionReceivedFromUniversityNotesAPI = async (data) => {
  let res = await axios.put(basicurl + `/api/student/bulkUpdateTransactionReceivedFromUniversityNotes`,data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const bulkUpdateIsCreditStatusAPI = async (data) => {
  let res = await axios.put(basicurl + `/api/student/bulkUpdateIsCreditStatus`,data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const bulkUpdateIsSettledStatusAPI = async (data) => {
  let res = await axios.put(basicurl + `/api/student/bulkUpdateIsSettledStatus`,data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};