import { Box, FormGroup, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import {
  CreateFileService,
  CreateFolderService,
} from "../../Redux/service/FileManagerService";
import { GetFolderAction, GetFolderAndFilesByParentlistAction } from "../../Redux/action/FileManagerAction";
import CustomButton from "../../component/button/button";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontSize } from "../../component/theme/fontSize";
import { fontWeight } from "../../component/theme/fontWeight";
import { ThemeColor } from "../../component/theme/color";
import Previews from "../../component/dropzone/Mydropzone";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "65vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};
const validationSchema = Yup.object({
  message: Yup.string()
    // .required("Resend date is required")/
    .when("fileUrl", {
      is: (fileUrl) => !fileUrl,
      then: (schema) =>
        schema.test(
          "is-greater",
          "Either text or file is required to send to chat",
          function (value) {
            const { fileUrl } = this.parent;

            if (fileUrl !== "" && !value) {
              // console.log("no file")
              return false;
            }
            return true;
          }
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
  fileUrl: Yup.string().notRequired(),
});

export const CreateFileModal = ({ createFileModal, handleClose, folderId }) => {
  const disptach = useDispatch();

  return (
    <Modal
      open={createFileModal}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography align="right" onClick={() => handleClose()}>
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Create File
        </Typography>
        <Formik
          initialValues={{
            name: "",
            file: [],
          }}
          // validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            //("FOLDER data:", values);
            values["folder"] = folderId;
            values["size"] = values?.file?.[0]?.size;
            values["type"] = values?.file?.[0]?.type;
            values["url"] = values?.file?.[0]?.url;
            delete values["file"];

            await CreateFileService(values)
              .then((res) => {
                if (res.status === 200) {
                  // //(res);
                  if (folderId) disptach(GetFolderAndFilesByParentlistAction(folderId));
                  else disptach(GetFolderAction());
                  handleClose();

                  disptach(showSnackbar(res.data.message, "success"));
                  // navigate("/CAE/Filemanager");
                  resetForm();
                }
              })
              .catch((e) => {
                disptach(showSnackbar(e.response.data.message, "error"));
              });
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name of file is must to create..."),
            file: Yup.array().min(1, "Select one file to create file"),
          })}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                  position: "absolute",
                  bottom: "0%",
                }}
              >
                <FormGroup style={{}}>
                  <div className="d-flex justify-content-between">
                    <div className="px-2 w-100">
                      <OutlinedInputField
                        value={values.name}
                        name={"name"}
                        onChange={handleChange}
                        placeholder="File Name...."
                        fullWidth={true}
                        helperText={
                          <Typography
                            align="left"
                            // className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.name}
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </FormGroup>

                <FormGroup style={{}} className="px-2">
                  <div className="w-100 d-flex justify-content-center mt-2">
                    <Previews
                      height="25px"
                      //   ref={childRef}
                      initialValue={values?.file}
                      handleMydropzoneFile={(updatedFiles) => {
                        //(updatedFiles);
                        setFieldValue("file", updatedFiles);
                      }}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.file}
                        </Typography>
                      }
                    />
                  </div>
                </FormGroup>

                <div className="w-100"></div>

                <div className="d-flex justify-content-center align-items-center mt-4 w-100">
                  <CustomButton
                    variant="contained"
                    type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    // loading={formLoading}
                    //   padding={"0px"}
                    // onClick={handleClick}
                  >
                    Create
                  </CustomButton>
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
