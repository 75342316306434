import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Input,
  OutlinedInput,
  createSvgIcon,
  FormHelperText,
  Checkbox,
  ListItemText,
} from "@mui/material";
import InputField from "../inputfiled/Inputfiled";
import Uparrow from "../../Icon/Uparrow";
import { ThemeColor } from "../theme/color";

const icon = createSvgIcon(
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 4.5L6 7.5L3 4.5"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CustomSelect = ({
  label,
  value,
  error,
  onChange,
  multiple,
  onBlur,
  disabled,
  option,
  helperText,
  onSelect,
  defaultValue,
}) => {



  const handleSelectAllChange = (event) => {
    const isAllSelected = event.target.value.includes("select_all");
    const activeOptions = option.map((opt) => opt._id);

    if (isAllSelected) {
      // Toggle Select All
      const newValue =
        value.length === activeOptions.length ? [] : activeOptions;
      onChange({
        target: {
          value: newValue,
        },
      });
    } else {
      onChange(event);
    }
  };

  // const isAllChecked = value.length === option.length;
  // const isIndeterminate = value.length > 0 && value.length < option.length;
  return (
    <FormControl
      fullWidth
      className="mb-2"
      size="small"
      sx={{
        "& .MuiOutlinedInput-root": {
          height: "50px",
          // Adjust the height as needed
          "& .MuiSelect-select": {
            padding: "8px",
            borderColor: "red", // Adjust padding to control height further
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `0.2 solid rgb(217,217,217)`, // Default border color
          },
          "& label": {
            color: "black",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: ThemeColor.GREY, // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: ThemeColor.LIGHTPURPLE, // Border color when focused
          },
        },
      }}
    >
      <InputLabel
        id="custom-select-label"
        sx={{
          color: "black", // Default color
          "&.Mui-focused": {
            color: ThemeColor.LIGHTPURPLE, // Color when focused
          },
          "&.MuiFormLabel-filled": {
            color: ThemeColor.GREY, // Color when the select has a value
          },
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -8px) scale(0.75)", // Custom transform when label is shrunk
            backgroundColor: "#fff", // Optional: background color behind the label when shrunk
            padding: "0 4px", // Optional: padding around the label when shrunk
          },
        }}
      >
        {label}
      </InputLabel>
      {/* <Select
        value={value}
        multiple={multiple}
        onChange={handleSelectAllChange}
        // fullWidth={fullWidth}
        displayEmpty
        renderValue={(selected) => {
          return multiple
            ? selected
                .map((_id) => option.find((opt) => opt._id === _id)?.title)
                .join(", ")
            : option.find((opt) => opt._id === selected)?.title;
        }}
      >
        {multiple && (
          <MenuItem value="select_all">
            <Checkbox checked={isAllChecked} indeterminate={isIndeterminate} />
            <ListItemText primary="Select All" />
          </MenuItem>
        )}
        {option.map((opt) => (
          <MenuItem key={opt._id} value={opt._id}>
            <Checkbox checked={value.includes(opt._id)} />
            {opt.title}
          </MenuItem>
        ))}
      </Select> */}

      <Select
        labelId="custom-select-label"
        id="custom-select"
        className="w-100"
        value={value}
        defaultValue={defaultValue}
        variant="outlined"
        onChange={onChange}
        onBlur={onBlur}
        multiple={multiple}
        IconComponent={icon}
        placeholder="ooo"
        disabled={disabled}
        error={error}
        onSelect={onSelect}
        renderValue={(selected) => {
          return multiple
          ? selected
              .map(
                (_id) => option.find((option) => option._id === _id)?.title
              )
              .join(", ")
          : option.find((option) => option._id === selected)?.title;
        }}
      >
        {/* {multiple && (
          <MenuItem value="select_all">
            <Checkbox
              checked={
                value.length ===
                option.filter((opt) => opt.isActive !== "N").length
              }
              indeterminate={
                value.length > 0 &&
                value.length <
                  option.filter((opt) => opt.isActive !== "N").length
              }
            />
            Select All
          </MenuItem>
        )} */}
        {option?.map((option) => (
          <MenuItem
            disabled={option.isActive === "N"}
            key={option._id}
            value={option._id}
          >
            {option.title} {option.roleType}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
