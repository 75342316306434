import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DropzoneArea } from "material-ui-dropzone";
import "../dropzone/dropzone.css";
import Dropdown from "../../Icon/Dropdown";
import { FileUploader } from "react-drag-drop-files";
import { Typography } from "@mui/material";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";

const fileTypes = ["JPG", "PNG", "GIF"];

const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      root: {
        backgroundColor: "red",
        // textContainer
        color: "#000",
      },
      textContainer: {
        width: 200,
        backgroundColor: "black",
      },
      successAlert: {
        backgroundColor: "#FAA",
        color: "#000",
      },
    },
  },
});

function MyLogoDropzone() {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    console.log(file)
    setFile(file);
  };
  return (
    <div>
      {" "}
      {/* <ThemeProvider theme={theme}>
        <DropzoneArea
          opens={true}
          acceptedFiles={["image/*"]}
          dropzoneText={"hehhe"}
          Icon={Dropdown}
          //   onChange={(propertyImages) => {
          //     //(propertyImages);
          //     setFieldValue("propertyImages", propertyImages);
          //     // uploadPhotoPost(propertyImages);
          //   }}
          //   filesLimit={6}
          //   showPreviews
          showFileNames={true}
          showPreviewsInDropzone={true}
          previewGridClasses={{
            container: "previewgrid",
            item: "previewgridItem",
          }}
          dropzoneClass="my-dropzone"
          previewText={""}
        ></DropzoneArea>
      </ThemeProvider> */}
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        classes=""
        children={
          <div className="">
            {/* <div>Public</div> */}
            <div className="my-dropzone-content">
              <div className="my-logodropzone-logo">
                <img src="/assest/dropzonelogo.png" className="w-100 h-100"></img>
              </div>
              <Typography
                fontFamily={FontFamily.Lato}
                fontSize={fontSize.h4.medium}
                fontWeight={fontWeight.W500}
                color={ThemeColor.PURPLE}
              >
                Upload your document here
              </Typography>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default MyLogoDropzone;
