
import { GetMailTemplateListConst, GetScheduleSendMailUsing365ListConst, GetSentMailListConst } from "../constant/EmailConstant";

import { GetMailTemplateListService, GetScheduleSendMailUsing365ListService, GetSentMailListService } from "../service/MailService";

export const GetScheduleSendMailUsing365Action = (pagination) => async (dispatch) => {
  try {
    const data = await GetScheduleSendMailUsing365ListService(pagination);

//("mail action",data?.data?.data)

    dispatch({ type: GetScheduleSendMailUsing365ListConst, payload: data?.data?.data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetScheduleSendMailUsing365ListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetSentMailAction = () => async (dispatch) => {
  try {
    const data = await GetSentMailListService();

    dispatch({ type: GetSentMailListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetSentMailListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};

export const GetMailTemplateAction = () => async (dispatch) => {
  try {
    const data = await GetMailTemplateListService();

    dispatch({ type: GetMailTemplateListConst, payload: data });

    // alert("Login Sucessfully!")
  } catch (error) {
    dispatch({ type: GetMailTemplateListConst, payload: error });
    //.log(error);
    // alert("Error :", error);
  }
};




