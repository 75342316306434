import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch } from "react-redux";

function flattenObject(obj, parentKey = "", result = {}) {
  for (let key in obj) {
    if (
      typeof obj[key] === "object" &&
      !Array.isArray(obj[key]) &&
      obj[key] !== null
    ) {
      flattenObject(obj[key], `${parentKey}${key}.`, result); // Recursive call for nested objects
    } else {
      result[`${parentKey}${key}`] = obj[key]; // Add the value to the result
    }
  }
  return result;
}

export const handleExport = (
  selectionModel,
  nameofFile,
  unsettledListlist,
  check,
  visibleColumns,
  columns // Pass the dynamically determined visible columns here
) => {
  const selectedRows = unsettledListlist.filter((row) =>
    selectionModel.includes(row.id)
  );
  console.log(visibleColumns, selectedRows);

  let exportData = [];
  if (check === "isDisabled") {
    exportData = selectedRows?.filter((data) => data.isDisabled === false);
  }
  if (check === "isActive") {
    exportData = selectedRows?.filter((data) => data.isActive === "Y");
  }

  if (exportData.length > 0) {
    // Flatten and filter the data to include only visible columns
    const filteredData = exportData.map((row) => {
      const flattenedRow = flattenObject(row);
      const filteredRow = Object.keys(flattenedRow)
        .filter((key) => visibleColumns?.includes(key)) // Include only visible columns
        .reduce((acc, key) => {
          acc[key] = flattenedRow[key];
          return acc;
        }, {});

        console.log(filteredRow,flattenedRow)
      return filteredRow;
    });

    // const filteredData = exportData.map((row) => {
    //   const flattenedRow = flattenObject(row);
    //   const filteredRow = Object.keys(flattenedRow)
    //     .filter((key) => visibleColumns?.includes(key)) // Include only visible columns
    //     .reduce((acc, key) => {
    //       acc[key] = flattenedRow[key];
    //       return acc;
    //     }, {});

    //   console.log(filteredRow, flattenedRow);

    //   // Handle the "owners" field dynamically
    //   visibleColumns.forEach((columnField) => {
    //     const columnDef = columns.find(
    //       (col) => col.field === columnField
    //     );
    //     console.log(columnDef);
    //     if (columnDef?.field === "owners.phone") {
    //       // Handle the "owners" field specifically
    //       filteredRow[columnDef.headerName] = row.owners
    //         ?.map((owner) => owner.phone)
    //         .join(", "); // Combine phone numbers into a single string
    //     } else if (columnDef?.field && row[columnDef.field] !== undefined) {
    //       // For other fields, use the raw value
    //       filteredRow[columnDef.headerName] = row[columnDef.field];
    //     }
    //   });

    //   return filteredRow;
    // });

    // Convert filtered data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedData");

    // Write the workbook to a buffer and save as a file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    // Save the file
    saveAs(dataBlob, `${nameofFile}.xlsx`);
  } else {
    console.warn("No data to export.");
  }
};

export const handleExportOrg = (
  selectionModel,
  nameofFile,
  unsettledListlist,
  check,
  visibleColumns,
  columns // Pass the dynamically determined visible columns here
) => {
  const selectedRows = unsettledListlist.filter((row) =>
    selectionModel.includes(row.id)
  );
  console.log(visibleColumns, selectedRows);

  let exportData = [];
  if (check === "isDisabled") {
    exportData = selectedRows?.filter((data) => data.isDisabled === false);
  }
  if (check === "isActive") {
    exportData = selectedRows?.filter((data) => data.isActive === "Y");
  }

  if (exportData.length > 0) {
    // Flatten and filter the data to include only visible columns
    // const filteredData = exportData.map((row) => {
    //   const flattenedRow = flattenObject(row);
    //   const filteredRow = Object.keys(flattenedRow)
    //     .filter((key) => visibleColumns?.includes(key)) // Include only visible columns
    //     .reduce((acc, key) => {
    //       acc[key] = flattenedRow[key];
    //       return acc;
    //     }, {});
    //   return filteredRow;
    // });

    const filteredData = exportData.map((row) => {
      const flattenedRow = flattenObject(row);
      const filteredRow = Object.keys(flattenedRow)
        .filter((key) => visibleColumns?.includes(key)) // Include only visible columns
        .reduce((acc, key) => {
          acc[key] = flattenedRow[key];
          return acc;
        }, {});

      console.log(filteredRow, flattenedRow);

      // Handle the "owners" field dynamically
      visibleColumns.forEach((columnField) => {
        const columnDef = columns.find((col) => col.field === columnField);
        console.log(columnDef);
        if (columnDef?.field === "owners.phone") {
          // Handle the "owners" field specifically
          filteredRow[columnDef.headerName] = row.owners
            ?.map((owner) => owner.phone)
            .join(", "); // Combine phone numbers into a single string
        } else if (columnDef?.field === "owners.firstName") {
          // Handle the "owners" field specifically
          filteredRow[columnDef.headerName] = row.owners
            ?.map((owner) => owner.firstName)
            .join(", "); // Combine phone numbers into a single string
        } else if (columnDef?.field && row[columnDef.field] !== undefined) {
          // For other fields, use the raw value
          filteredRow[columnDef.headerName] = row[columnDef.field];
        }
      });

      return filteredRow;
    });

    // Convert filtered data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedData");

    // Write the workbook to a buffer and save as a file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    // Save the file
    saveAs(dataBlob, `${nameofFile}.xlsx`);
  } else {
    console.warn("No data to export.");
  }
};
