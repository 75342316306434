import React, { useState } from "react";
import Crossicon from "../../../Icon/Crossicon";
import { Box, Modal, Typography } from "@mui/material";
import { FontFamily } from "../../theme/fontFamily";
import { fontWeight } from "../../theme/fontWeight";
import { fontSize } from "../../theme/fontSize";
import { ThemeColor } from "../../theme/color";
import "../ConfirmationModal/Confirmation.css";
import DeleteMsg from "../DeleteMsgModal/DeleteMsg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "40%", lg: "40%" },
  height: "35vh",
  overflow: "hidden",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  borderRadius: "25px",
  p: 4,
};
function ConfirmationModal(props) {
  const [confirmationopen, setConfirmationOpen] = useState(false);
  const [deleteMsgopen, setdeleteMsgOpen] = useState(false);

  const handleOpen = () => {
    props.handleConfirmationOpen();
  };

  const deletefunction = async () => {
    try {
      const val = await props.deletefunction(); // Await the result of the deletefunction
      if (val.status == 200) {
        handleClose();
        setdeleteMsgOpen(true);
      }
    } catch (error) {
      console.error("Error occurred:", error); // Handle any errors thrown by the async function
    }
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    props.handleConfirmationClose();
  };
  return (
    <div>
      {/* modal for notes window */}
      <Modal
        open={props.confirmationopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="d-flex justify-content-center flex-column">
          {/* <Typography align="right" onClick={handleClose}>
            <Crossicon width={"20"} height={"20"} />
          </Typography> */}
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.BLACK}
            align="center"
          >
            Are Your Sure
          </Typography>
          <Box
            sx={{ height: "60%", overflowY: "scroll" }}
            className="d-flex flex-column justify-content-center align-items-center mt-2"
          >
            <Typography
              sx={{ background: ThemeColor.LIGHTBLUE, borderRadius: "12px" }}
              className="w-100 py-3"
              align="center"
            >
              {props.message}
            </Typography>

            <Box className="d-flex justify-content-around w-100 mt-2">
              <div className="confirm-btn delete px-2" onClick={deletefunction}>
                {props.btnName ? props.btnName : "Delete"}
              </div>
              <div className="confirm-btn cancel px-2" onClick={handleClose}>
                Cancel
              </div>
            </Box>
          </Box>
        </Box>
      </Modal>
      <DeleteMsg
        msg={props?.msg}
        handledeleteMsgClose={() => {
          setdeleteMsgOpen(false);
        }}
        deleteMsgopen={deleteMsgopen}
        message="All deleted file is never been retrievable"
      />
    </div>
  );
}

export default ConfirmationModal;
