export const fontWeight={
    W200:200,
    W300:300,
    W400:400,
    W500:500,
    W600:600,
    W700:700,
    W800:800

}

