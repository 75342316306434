import { SET_SELECTED_INDEX } from "../action/UiAction";


// Initial state
const initialState = {
  selectedIndex: null,
};

// Reducer function
export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_INDEX:
      return {
        ...state,
        selectedIndex: action.payload,
      };
    default:
      return state;
  }
};
