import axios from "axios";
import { basicurl } from "../ServiceUrl/url";

// const basicurl = basicurl;

const token = window.sessionStorage.getItem("user_token");
//.log(token)
// //(token);

export const UploadFileService = async (data) => {
  let res = await axios.post(basicurl + "/api/upload/upload-file",data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};