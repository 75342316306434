import React from "react";

function Star() {
  return (
    <div>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0967 1.45L13.1667 7.17L19.2467 7.37C19.8867 7.39 20.1567 8.2 19.6467 8.6L14.8467 12.33L16.5367 18.17C16.7167 18.79 16.0267 19.29 15.4967 18.93L10.4667 15.52L5.43667 18.93C4.90667 19.29 4.21667 18.79 4.39667 18.17L6.08667 12.33L1.26667 8.6C0.756668 8.21 1.02667 7.39 1.66667 7.37L7.74667 7.17L9.81667 1.45C10.0267 0.85 10.8767 0.85 11.0967 1.45Z"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Star;
