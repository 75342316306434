import { useDispatch } from "react-redux";
import { showSnackbar } from "../Redux/action/SnackbarAction";

export const CopyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
        // useDispatch(showSnackbar("Copyied To Clipboard", "success"));

      // Optionally, you could add a toast notification here to inform the user
    })
    .catch((error) => {
      console.error("Failed to copy text to clipboard: ", error);
    });
};
