import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../dropzone/dropzone.css";
import Dropdown from "../../Icon/Dropdown";
import { FormHelperText, Typography } from "@mui/material";
import { FontFamily } from "../theme/fontFamily";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { ThemeColor } from "../theme/color";
import Delete from "../../Icon/Delete";
import { UploadFileService } from "../../Redux/service/FileService";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  //   marginTop: 16
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid black",
  //   marginTop: 10,
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Mylogodropzone(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    onDrop: async (acceptedFiles) => {
      try {
        const formData = new FormData();
        var docsFile = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
          formData.append("file", acceptedFiles[i]);

          let response = await UploadFileService(formData);
          // //("photoresp", response);
          if (response.status == 200) {
            docsFile.push(response.data.result);
          }

          //
        }
        //(docsFile);
        // return docsFile;
      } catch (err) {
        //("uploadPhotoPost : err ", err);
      }
      // const updatedFiles = [
      //   acceptedFiles.map((file) =>
      //     Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     })
      //   ),
      // ];
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      console.log(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      //   setFiles([...files, ...acceptedFiles]);
      props.handleMyLogodropzoneFile(docsFile?.[0]?.url);
    },
  });

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    props.handleMyLogodropzoneFile(newFiles);
  };

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <button onClick={() => handleRemove(index)}>Remove</button>
      </div>
    </div>
  ));

  useEffect(() => {
    //(files, "nonnoon");
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="my-logodropzone-container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="my-dropzone-content">
          <div className="my-logodropzone-logo">
            <img src={files?.length>0?files?.[0]?.preview:"/assest/dropzonelogo.png"} className="w-100 h-100" style={{borderRadius: "50%",}}></img>
          </div>

          <Typography
            fontFamily={FontFamily.Lato}
            fontSize={fontSize.h4.medium}
            fontWeight={fontWeight.W500}
            color={ThemeColor.PURPLE}
          >
            Upload University Logo
          </Typography>
        </div>
      </div>
      {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
      <div className="h-100 w-100">
        <ul>
          {files.map((file, index) => (
            <li key={index} className="d-flex align-items-center">
              {file.name} - {file.size} bytes
              <div className="px-2">
                <button
                  onClick={() => handleRemove(index)}
                  className=""
                  style={{
                    background: ThemeColor.RED,
                    borderColor: ThemeColor.RED,
                    borderRadius: "50%",
                    boxShadow: "none",
                  }}
                >
                  <Delete />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <FormHelperText>{props.helperText}</FormHelperText>
    </section>
  );
}

export default Mylogodropzone;
