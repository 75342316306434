import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeColor } from "../theme/color";
import "../sidebar/sidebar.css";
import { FontFamily } from "../theme/fontFamily";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import Users from "../../Icon/Users";
import Star from "../../Icon/Star";
import Delete from "../../Icon/Delete";
import Msg from "../../Icon/Msg";
import Filemanager from "../../Icon/Filemanager";
import Filemanager2 from "../../Icon/Filemanager2";
import Setting from "../../Icon/Setting";
import { hover } from "@testing-library/user-event/dist/hover";
import Logout from "../../Icon/Logout";
import { Collapse, Grid, InputAdornment, TextField } from "@mui/material";
import Search from "../../Icon/Search";
import Filter from "../../Icon/Filter";
import ProfileDropdown from "../dropdown/Profiledropdown";
import { useLocation, useNavigate } from "react-router-dom";
import SnackbarComponent from "../snackbar/snackbar";
import { ChatBubble, ExpandLess, ExpandMore } from "@mui/icons-material";
import Message from "../../Icon/Message";
import { ProfileFilled } from "@ant-design/icons";
import { useEffect } from "react";
import { setSelectedIndex } from "../../Redux/action/UiAction";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCountTotalUnseenMessages } from "../../Redux/action/NotificationAction";
import { AllCountTotalUnseenMessagesConst } from "../../Redux/constant/NotificationConstant";

const drawerWidth = 260;

function ResponsiveDrawer(props) {
  const [role, setrole] = React.useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const itemRefs = React.useRef([]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [hoveredIndex, setHoveredIndex] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(null);
  const [CountTotalUnseenMsg, setCountTotalUnseenMsg] = React.useState();

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(GetAllCountTotalUnseenMessages());
  }, []);
  const CountTotalUnseenMsgState = useSelector(
    (state) =>
      state.notification?.AllCountTotalUnseenMessages?.data?.data
        ?.totalUnseenMessages
  );
  console.log(CountTotalUnseenMsgState);
  useEffect(() => {
    setCountTotalUnseenMsg(CountTotalUnseenMsgState);
  }, [CountTotalUnseenMsgState]);

  const handleClick = (item) => {
    setActiveItem(item);
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    if (window?.sessionStorage.getItem("user_role_type")) {
      //(window.sessionStorage.getItem("user_role_type"));
      setrole((prev) => [window.sessionStorage.getItem("user_role_type")]);
    }
  }, []);

  const drawerItem = [
    // {
    //   text: "Dashboard",
    //   icon: <Star />,
    //   key: "dashboard",
    // },
    {
      text: "Applicants",
      icon: <Users />,
      key: "application",
      navigate: "/CAE/applicationlist",
    },
    // {
    //   text: "University",
    //   icon: <Star />,
    //   key: "university",
    // },
    {
      text: "File Manager",
      icon: <Filemanager />,
      key: "file_manager",
      navigate: "/CAE/Filemanager",
    },
    // {
    //   text: "Deleted Data",
    //   icon: <Delete />,
    //   key: "deleted_data",
    // },
    {
      text: "Setting",
      icon: <Setting />,
      key: "setting",
      navigate: "/CAE/SettingDashboard",
    },
    {
      text: "Support",
      icon: <ChatBubble sx={{ fill: "#fff" }} />,
      key: "support",
      navigate: "/CAE/support",
    },
    {
      text: `Chat`,
      icon: <Msg />,
      key: "chat",
      navigate: "/CAE/chatlist",
      notify: CountTotalUnseenMsg,
    },
    {
      text: "Notification",
      icon: <Msg />,
      key: "notification",
      navigate: "/CAE/AllNotification",
    },
    {
      text: "Accounts",
      icon: <Msg />,
      key: "accounts",
      navigate: "/CAE/payoutlist",
    },
    // ...(window?.sessionStorage.getItem("user_role_type") === "admin"
    //   ? [
    //       {
    //         text: "Accounts",
    //         icon: <Msg />,
    //         key: "accounts",
    //         navigate: "/CAE/payoutlist",
    //       },
    //     ]
    //   : []),

    // ...(!(
    //   window.sessionStorage.getItem("user_role_type") === "admin"
    //   // window.sessionStorage.getItem("role") === "agent_counsellor"
    // )
    //   ? [
    //     {
    //       text: "Accounts",
    //       icon: <Msg />,
    //       key: "accounts",
    //       navigate: "/CAE/payoutlist",
    //     },
    //     ]
    //   : []),

    // {
    //   text: "Agent",
    //   icon: <Msg />,
    //   key: "gent",
    // },
  ];

  const filteredArray = drawerItem.filter(
    (item) => !(item.key === "accounts" && role.includes("admin"))
  );

  const selectedIndex = useSelector((state) => state.ui.selectedIndex);

  const handleItemClick = (index, navigateTo) => {
    dispatch(setSelectedIndex(index));
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  useEffect(() => {
    // Ensure itemRefs length matches filteredArray length
    itemRefs.current = Array(filteredArray.length).fill(null);
  }, [filteredArray.length]);

  const drawer = (
    <div className="sidebar-list w-100 h-100">
      <div className="sidebar-list-content">
        <List className="ps-2">
          {filteredArray?.map((data, index) =>
            data.key === "accounts" && role.includes("admin") ? (
              ""
            ) : (
              <ListItem
                key={data.key}
                ref={(el) => (itemRefs.current[index] = el)}
                disablePadding
                // secondaryAction={

                // }
                onMouseEnter={() =>
                  data.key === "file_manager" ? setHoveredIndex(true) : ""
                }
                onMouseLeave={() =>
                  data.key === "file_manager" ? setHoveredIndex(false) : ""
                }
                // onClick={() => {
                //   navigate(data?.navigate ? data.navigate : "");
                //   setActiveItem(data.key);
                // }}
                onClick={() => handleItemClick(index, data.navigate)}
                className={
                  location.pathname === data.navigate
                    ? "list-item-active"
                    : "list-item"
                }
              >
                <ListItemButton className="w-50">
                  <ListItemIcon>
                    {data.key === "file_manager" ? (
                      hoveredIndex ? (
                        <Filemanager2 />
                      ) : (
                        data.icon
                      )
                    ) : (
                      data.icon
                    )}
                  </ListItemIcon>

                  <ListItemText className="py-2" sx={{ width: "5px" }}>
                    <Typography
                      fontFamily={FontFamily.Inter}
                      fontSize={fontSize.h3.medium}
                      lineHeight="19.36px"
                      fontWeight={fontWeight.W500}
                    >
                      {data?.text}
                    </Typography>
                  </ListItemText>
                  {data.key === "chat" && (
                    <div>
                      {CountTotalUnseenMsg !== 0 && (
                        <div className={
                          location.pathname === data.navigate
                            ? "chat-unseen-num"
                            : "chat-unseen-num white"
                        }>
                          <Typography>{CountTotalUnseenMsg}</Typography>
                        </div>
                      )}
                    </div>
                  )}
                </ListItemButton>
              </ListItem>
            )
          )}
          <ListItem
            key={"Mail"}
            disablePadding
            onClick={() => {
              // navigate(data?.navigate ? data.navigate : "");
            }}
          >
            {" "}
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <Star />
              </ListItemIcon>
              <ListItemText className="py-2">
                <Typography
                  fontFamily={FontFamily.Inter}
                  fontSize={fontSize.h3.medium}
                  lineHeight="19.36px"
                  fontWeight={fontWeight.W500}
                >
                  Email
                  <span className="px-3">
                    {open ? (
                      <ExpandLess sx={{ fill: "white" }} />
                    ) : (
                      <ExpandMore sx={{ color: "white" }} />
                    )}
                  </span>
                  {/* {data?.text} */}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                className={
                  location.pathname === "/CAE/Sendmail"
                    ? "list-item-active"
                    : "list-item"
                }
                sx={{ pl: 4, color: "white" }}
                onClick={() => {
                  dispatch(setSelectedIndex("send_email"));
                  navigate("/CAE/Sendmail");
                }}
              >
                <ListItemIcon>
                  <Msg />
                </ListItemIcon>
                <ListItemText className="py-2">
                  <Typography
                    fontFamily={FontFamily.Inter}
                    fontSize={fontSize.h3.medium}
                    lineHeight="19.36px"
                    fontWeight={fontWeight.W500}
                  >
                    COMPOSE
                  </Typography>
                </ListItemText>
              </ListItemButton>
              <ListItemButton
                className={
                  location.pathname === "/CAE/mailist"
                    ? "list-item-active"
                    : "list-item"
                }
                sx={{ pl: 4, color: "white" }}
                onClick={() => {
                  dispatch(setSelectedIndex("mail_list"));
                  navigate("/CAE/mailist");
                }}
              >
                <ListItemIcon>
                  <Msg />
                </ListItemIcon>
                <ListItemText className="py-2">
                  <Typography
                    fontFamily={FontFamily.Inter}
                    fontSize={fontSize.h3.medium}
                    lineHeight="19.36px"
                    fontWeight={fontWeight.W500}
                  >
                    INBOX
                  </Typography>
                </ListItemText>
              </ListItemButton>
              <ListItemButton
                className={
                  location.pathname === "/CAE/sentmailist"
                    ? "list-item-active"
                    : "list-item"
                }
                sx={{ pl: 4, color: "white" }}
                onClick={() => {
                  dispatch(setSelectedIndex("sent_mail_list"));
                  navigate("/CAE/sentmailist");
                }}
              >
                <ListItemIcon>
                  <Msg />
                </ListItemIcon>
                <ListItemText className="py-2">
                  <Typography
                    fontFamily={FontFamily.Inter}
                    fontSize={fontSize.h3.medium}
                    lineHeight="19.36px"
                    fontWeight={fontWeight.W500}
                  >
                    SENT
                  </Typography>
                </ListItemText>
              </ListItemButton>
              <ListItemButton
                className={
                  location.pathname === "/CAE/emailTemplatelist"
                    ? "list-item-active"
                    : "list-item"
                }
                sx={{ pl: 4, color: "white" }}
                onClick={() => {
                  dispatch(setSelectedIndex("email_templatelist"));
                  navigate("/CAE/emailTemplatelist");
                }}
              >
                <ListItemIcon>
                  <Msg />
                </ListItemIcon>
                <ListItemText className="py-2">
                  <Typography
                    fontFamily={FontFamily.Inter}
                    fontSize={fontSize.h3.medium}
                    lineHeight="19.36px"
                    fontWeight={fontWeight.W500}
                  >
                    Email Template
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </div>

      <div className="sidebar-footer px-3">
        <div className="d-flex mt-4 align-items-center justify-content-center">
          <Logout />
          <Typography
            onClick={() => {
              sessionStorage.removeItem("user_token");
              navigate("/");
              navigate(0);
            }}
            fontFamily={FontFamily.Ubuntu}
            fontSize={fontSize.h3.medium}
            fontWeight={fontWeight.W500}
            className="px-2"
            color={ThemeColor.WHITE}
          >
            Log out
          </Typography>
        </div>
      </div>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          //   height:200,
          background: "#F6FAFF",
          boxShadow: "none",
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: ThemeColor.NAVYBLUE }}
          >
            <MenuIcon />
          </IconButton>
          <div className="toolbar-content mt-2">
            <Grid spacing={2} container className="">
              <Grid item lg={9} md={9} sm={6} xs={6}>
                <div className="toolbar-search">
                  <TextField
                    variant="outlined"
                    placeholder="Search Student, Teacher, Doc..."
                    fullWidth
                    sx={{
                      borderRadius: "26px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "26px",
                        height: 50,

                        "& fieldset": {
                          borderColor: ThemeColor.WhHITESMOKE, // Default border color (outline)
                          borderRadius: "26px", // Set the thickness of the border
                        },
                        "&:hover fieldset": {
                          borderColor: ThemeColor.NAVYBLUE, // Border color on hover (outline)
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: ThemeColor.NAVYBLUE, // Border color when focused (outline)
                        },
                      },
                      "& .MuiInputAdornment-root": {
                        color: "#1976d2", // Color of the icons
                      },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px", // Adjust padding inside input
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              display: { sm: "none", xs: "none", lg: "flex" },
                            }}
                          >
                            <Filter />{" "}
                            <Typography
                              fontFamily={FontFamily.Poppins}
                              fontSize={fontSize.h4.medium}
                              color={ThemeColor.GREY}
                              className="px-2 pt-1"
                            >
                              Filter
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                  />
                </div>
              </Grid>
              {/* <Grid item lg={1} md={1} sm={1} xs={1}></Grid> */}
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <div className="toolbar-profile d-flex">
                  <ProfileDropdown />
                </div>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: ThemeColor.BLUE,
              height: "100vh",
              overflow: "hidden",
              border: "none",
            },
            "& .MuiListItem-root": {
              // padding: '12px 16px',
              borderRadius: "8px",
              border: "none",
              transition: "background-color 0.3s ease-in-out",
              color: ThemeColor.WHITE,
              borderRadius: "25px 0px 0px 25px",
              overflow: "hidden",
              mr: 15,

              "&:hover": {
                backgroundColor: ThemeColor.WhHITESMOKE, // Add your hover background color
                cursor: "pointer", // Change cursor to pointer on hover
                mr: 15,
                color: ThemeColor.BLUE,
                borderRadius: "25px 0px 0px 25px",
                "& svg": {
                  fill: ThemeColor.BLUE, // Change to desired hover color
                },
              },
            },
          }}
          className="sidebar"
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: ThemeColor.BLUE,
              border: "none",
              height: "100vh",
              overflow: "hidden",

              // pl: 2,
            },
            "& .MuiListItem-root": {
              // padding: '12px 16px',
              borderRadius: "8px",
              transition: "background-color 0.3s ease-in-out",
              color: ThemeColor.WHITE,
              borderRadius: "25px 0px 0px 25px",
              mr: 15,
              overflow: "none",

              "&:hover": {
                backgroundColor: ThemeColor.WhHITESMOKE, // Add your hover background color
                cursor: "pointer", // Change cursor to pointer on hover
                mr: 15,
                color: ThemeColor.BLUE,
                borderRadius: "25px 0px 0px 25px",
                "& svg": {
                  fill: ThemeColor.BLUE, // Change to desired hover color
                },
              },
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* main content box */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          height: "100vh",
          overflowX: "hidden",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.maincontent}

        <div>
          <SnackbarComponent />
        </div>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
