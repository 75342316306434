import React from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import CustomButton from "../../component/button/button";
import CustomDataTable from "../../component/datatable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { SupportPopup } from "../../component/support/newpopup.js";
import { getAllSupportTicket } from "../../Redux/action/SupportAction.js";
import moment from "moment";
import {
  Chat,
  Check,
  CheckBox,
  Circle,
  RouterOutlined,
} from "@mui/icons-material";
import { SupportChat } from "../../component/support/supportChat.js";
import { CopyFilled, DotNetOutlined } from "@ant-design/icons";
import { SettleSupport } from "../../component/support/settlePopup.js";

const SupportSectionList = (props) => {
  const columns = [
    {
      field: "id",
      headerName: "Ticket Id",
      width: 250,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 250,
    },
    {
      field: "isSettled",
      headerName: "Resolved",
      width: 150,
      renderCell: (params) => {
        return (
          <Box flexDirection={"row"} sx={{ p: 1 }} display={"flex"}>
            {params.row.isSettled ? (
              <Check sx={{ fill: "green" }} />
            ) : (
              <Circle sx={{ fill: "grey" }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "createdOn",
      headerName: "Raised on",
      width: 250,
      renderCell: (params) => {
        return <div>{moment(params.row.createdOn).format("LLL")}</div>;
      },
    },
    {
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <Box flexDirection={"row"} sx={{ p: 1 }} display={"flex"}>
            <Typography
              sx={{ p: 1 }}
              onClick={() => {
                showDetails(params.row.messages, params.row.id);
              }}
            >
              <Chat />
            </Typography>
            <Typography
              onClick={() => {
                setShowAlert(true);
                setselectedChatId(params.row.id);
              }}
            >
              {!params.row.isSettled ? <Switch /> : null}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const supportlist = useSelector((state) => state.support.supportList);
  const [open, setOpen] = React.useState(false);
  const [chatWindowOpen, setChatWindowOpen] = React.useState(false);
  const [message, setMessage] = React.useState([]);
  const [selectedChatId, setselectedChatId] = React.useState(0);
  const [showAlert, setShowAlert] = React.useState(false);

  const handleCreateSupportPopup = () => setOpen(!open);
  const handleChatWindowOpen = () => setChatWindowOpen(!chatWindowOpen);

  React.useEffect(() => {
    dispatch(getAllSupportTicket());
  }, []);

  const showDetails = (message, id) => {
    setMessage(message);
    setselectedChatId(id);
    handleChatWindowOpen();
  };

  return (
    <>
      <Grid container>
        <Grid item lg={8} md={7} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Support List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },
            }}
          >
            {window.sessionStorage.getItem("user_role_type") !== "admin" && (
              <div className="px-2">
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  onClick={handleCreateSupportPopup}
                >
                  Add New Ticket
                </CustomButton>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <SupportPopup open={open} handleClose={handleCreateSupportPopup} />
      <SupportChat
        chatWindow={chatWindowOpen}
        handleClose={handleChatWindowOpen}
        messages={message}
        dispatch={dispatch}
        chatId={selectedChatId}
      />
      <SettleSupport
        dispatch={dispatch}
        open={showAlert}
        id={selectedChatId}
        handleClose={() => {
          setShowAlert(!showAlert);
          dispatch(getAllSupportTicket());
        }}
      />
      <div className="table-container" style={{ overflowX: "scroll" }}>
        <CustomDataTable row={supportlist} columns={columns} />
      </div>
    </>
  );
};

function SupportSection() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<SupportSectionList />} />
    </div>
  );
}

export default SupportSection;
