import React from "react";

function Filemanager() {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.93745 0H1.31245C0.58873 0 0 0.58873 0 1.31245V4.68745C0 5.41132 0.58873 6.00005 1.31245 6.00005H6.93745C7.66132 6.00005 8.25005 5.41132 8.25005 4.68745V1.31245C8.25005 0.58873 7.66132 0 6.93745 0Z"
          fill="white"
        />
        <path
          d="M6.93745 7.5H1.31245C0.58873 7.5 0 8.08873 0 8.81259V16.6876C0 17.4113 0.58873 18 1.31245 18H6.93745C7.66132 18 8.25005 17.4113 8.25005 16.6876V8.81259C8.25005 8.08873 7.66132 7.5 6.93745 7.5Z"
          fill="white"
        />
        <path
          d="M16.6875 12H11.0625C10.3387 12 9.74994 12.5887 9.74994 13.3126V16.6876C9.74994 17.4113 10.3387 18 11.0625 18H16.6875C17.4113 18 18 17.4113 18 16.6876V13.3126C18 12.5887 17.4113 12 16.6875 12Z"
          fill="white"
        />
        <path
          d="M16.6875 0H11.0625C10.3387 0 9.74994 0.58873 9.74994 1.31245V9.18745C9.74994 9.91132 10.3387 10.5 11.0625 10.5H16.6875C17.4113 10.5 18 9.91132 18 9.18745V1.31245C18 0.58873 17.4113 0 16.6875 0V0Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default Filemanager;
