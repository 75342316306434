import React from "react";

function Note() {
  return (
    <div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.9375 4.5C3.9375 4.35082 3.99676 4.20774 4.10225 4.10225C4.20774 3.99676 4.35082 3.9375 4.5 3.9375H9C9.14918 3.9375 9.29226 3.99676 9.39775 4.10225C9.50324 4.20774 9.5625 4.35082 9.5625 4.5C9.5625 4.64918 9.50324 4.79226 9.39775 4.89775C9.29226 5.00324 9.14918 5.0625 9 5.0625H4.5C4.35082 5.0625 4.20774 5.00324 4.10225 4.89775C3.99676 4.79226 3.9375 4.64918 3.9375 4.5ZM4.5 7.3125H9C9.14918 7.3125 9.29226 7.25324 9.39775 7.14775C9.50324 7.04226 9.5625 6.89918 9.5625 6.75C9.5625 6.60082 9.50324 6.45774 9.39775 6.35225C9.29226 6.24676 9.14918 6.1875 9 6.1875H4.5C4.35082 6.1875 4.20774 6.24676 4.10225 6.35225C3.99676 6.45774 3.9375 6.60082 3.9375 6.75C3.9375 6.89918 3.99676 7.04226 4.10225 7.14775C4.20774 7.25324 4.35082 7.3125 4.5 7.3125ZM6.75 8.4375H4.5C4.35082 8.4375 4.20774 8.49676 4.10225 8.60225C3.99676 8.70774 3.9375 8.85082 3.9375 9C3.9375 9.14918 3.99676 9.29226 4.10225 9.39775C4.20774 9.50324 4.35082 9.5625 4.5 9.5625H6.75C6.89918 9.5625 7.04226 9.50324 7.14775 9.39775C7.25324 9.29226 7.3125 9.14918 7.3125 9C7.3125 8.85082 7.25324 8.70774 7.14775 8.60225C7.04226 8.49676 6.89918 8.4375 6.75 8.4375ZM13.5 1.125V8.76727C13.5005 8.91506 13.4716 9.06147 13.415 9.19799C13.3583 9.33451 13.2752 9.45841 13.1702 9.5625L9.5625 13.1702C9.45841 13.2752 9.33451 13.3583 9.19799 13.415C9.06147 13.4716 8.91506 13.5005 8.76727 13.5H1.125C0.826631 13.5 0.540483 13.3815 0.329505 13.1705C0.118526 12.9595 0 12.6734 0 12.375V1.125C0 0.826631 0.118526 0.540483 0.329505 0.329505C0.540483 0.118526 0.826631 0 1.125 0H12.375C12.6734 0 12.9595 0.118526 13.1705 0.329505C13.3815 0.540483 13.5 0.826631 13.5 1.125ZM1.125 12.375H8.4375V9C8.4375 8.85082 8.49676 8.70774 8.60225 8.60225C8.70774 8.49676 8.85082 8.4375 9 8.4375H12.375V1.125H1.125V12.375ZM9.5625 9.5625V11.5805L11.5798 9.5625H9.5625Z"
          fill="#8DA0D9"
        />
      </svg>
    </div>
  );
}

export default Note;
