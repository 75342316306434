import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import { MenuItem, Select, Checkbox, ListItemText } from "@mui/material";
import { UploadFileService } from "../../Redux/service/FileService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateApplicationService,
  EditApplicationService,
  GetCAEidService,
  getUnivByOrgIdService,
} from "../../Redux/service/Application";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import OnlyDatePicker from "../../component/datepicker/MyDatepicker";
import {
  GetAgentAction,
  GetOrganizationAction,
  GetProgramAction,
  GetUniversityAction,
} from "../../Redux/action/SettingAction";
import {
  GetAllBranchAction,
  GetDegreeAction,
} from "../../Redux/action/ApplicationAction";
import { validationSchema } from "./Constant";
import { GetDefaultIntakeService } from "../../Redux/service/SettingService";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const CreateApplicationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const childRef = useRef(null);
  const formikRef = useRef(null);

  const { id } = useParams();

  const [Edit, setEdit] = useState(id ? true : false);
  const [prefieldData, setPrefieldData] = useState();

  const [formLoading, setFormLoading] = useState(false);
  const [Universitylist, setUniversitylist] = useState([]);
  const [Degreelist, setDegreelist] = useState([]);
  const [uniqueCaeId, setUniqueCaeId] = useState();
  const [Organizationlist, setOrganizationlist] = useState([]);
  const [Agentlist, setAgentlist] = useState([]);
  const [Branchlist, setBranchlist] = useState([]);

  const [TaggingStatus, setTaggingStatus] = useState("");
  const [CommissionStatus, setCommissionStatus] = useState("");
  const [VisaStatus, setVisaStatus] = useState("");
  const [ApplicationStatus, setApplicationStatus] = useState("");

  const [Programlist, setProgramlist] = useState([]);

  const EditApplicationdataState = useSelector(
    (state) => state.application?.student?.data?.students
  );

  useEffect(() => {
    setPrefieldData(
      EditApplicationdataState?.filter((data) => data._id === id)?.[0]
    );
    let prefield = EditApplicationdataState?.filter(
      (data) => data._id === id
    )?.[0];

    if (Edit && prefield) handleUnivByOrgId(prefield?.organizationId?._id);

    setVisaStatus(prefield?.visaStatus);
    setApplicationStatus(prefield?.applicationStatus);
    setTaggingStatus(prefield?.taggingStatus);
    setCommissionStatus(prefield?.commissionStatus);

    // dispatch(
    //   GetBranchByOrgIdAction(
    //     EditApplicationdataState?.filter((data) => data._id === id)?.[0]?.organization
    //       ?._id
    //   )
    // );
    // setInputValue(
    //   EditApplicationdataState?.filter((data) => data._id === id)?.[0]?.branch
    //     ?.branchName
    // );
    // set
  }, [Edit, EditApplicationdataState, id]);

  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );

  const DegreelistState = useSelector(
    (state) => state.application?.degree?.data?.degrees
  );

  const OrganizationlistState = useSelector(
    (state) => state.setting?.organization?.data?.organizations
  );

  const AgentlistState = useSelector(
    (state) => state.setting?.agent?.data?.activeAgents
  );

  const BranchlistState = useSelector(
    (state) => state.application?.allbranch?.data?.branches
  );
  //(BranchlistState, "branch");

  useEffect(() => {
    dispatch(GetUniversityAction());
    dispatch(GetDegreeAction());
    dispatch(GetOrganizationAction());
    dispatch(GetAgentAction());
    dispatch(GetAllBranchAction());
  }, []);

  useEffect(() => {
    dispatch(GetProgramAction());
  }, []);

  const ProgramlistState = useSelector(
    (state) => state.setting?.program?.data?.programs
  );
  //(ProgramlistState, "catalog data");

  useEffect(() => {
    const updatedData = ProgramlistState?.map(({ program, ...rest }) => ({
      title: program,
      ...rest,
    }));
    setProgramlist(updatedData);
  }, [ProgramlistState]);

  useEffect(() => {
    if (!Edit) {
      const updatedData = UniversitylistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }));
      setUniversitylist(updatedData);
    }
  }, [UniversitylistState]);

  useEffect(() => {
    const updatedData = DegreelistState?.map(({ name, ...rest }) => ({
      title: name,
      ...rest,
    }));
    setDegreelist(updatedData);
  }, [DegreelistState]);

  useEffect(() => {
    const updatedData = BranchlistState?.map(({ branchName, ...rest }) => ({
      title: branchName,
      ...rest,
    }));
    //(updatedData, "branchlist");
    setBranchlist(updatedData);
  }, [BranchlistState]);

  useEffect(() => {
    const updatedData = OrganizationlistState?.map(
      ({ organizationName, ...rest }) => ({
        title: organizationName,
        ...rest,
      })
    );
    //(updatedData, "org list");
    setOrganizationlist(updatedData);
  }, [OrganizationlistState]);

  useEffect(() => {
    // const updatedData = AgentlistState?.map(({ email, ...rest }) => ({
    //   title: email,
    //   ...rest,
    // }));
    //("Agent list", AgentlistState);
    setAgentlist(AgentlistState);
  }, [AgentlistState]);

  useEffect(() => {
    GetCAEidService()
      .then((res) => {
        if (res.status === 200) {
          // //(res)
          formikRef.current.values.uniqueCaeId = res?.data?.uniqueCaeId;
          dispatch(showSnackbar(res.data.message, "success"));
          // setUniqueCaeId(res.data.uniqueCaeId);
        }
      })
      .catch((e) => {
        dispatch(showSnackbar(e.response.data.message, "error"));
      });
  }, []);

  // handle form submit
  const handleFormSubmit = async (values, formType) => {
    setFormLoading(true);

    //(values, formType, formikRef.current);
    await CreateApplicationService(values)
      .then((res) => {
        if (res.status === 200) {
          //(res);
          setFormLoading(false);

          dispatch(showSnackbar(res?.data?.message, "success"));
          if (formType === "reset") {
            formikRef.current.resetForm();
          } else {
            navigate("/CAE/Applicationlist");
          }
        }
      })
      .catch((e) => {
        setFormLoading(false);
        dispatch(showSnackbar(e.response?.data?.message, "error"));
      });
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        //(data, "country");
        // setCountries(data.countries);
        // setSelectedCountry(data.userSelectValue);
      });
  }, []);

  const GetDefaultIntake = async () => {
    GetDefaultIntakeService()
      .then((res) => {
        if (res.status === 200) {
          //  console.log(res);
          if (formikRef.current) {
            formikRef.current.values.intake =
              res.data?.defaultIntake?.[0]?.intake?.split(" ")?.[0];
            formikRef.current.values.intakeyear =
              res.data?.defaultIntake?.[0]?.intake?.split(" ")?.[1];
            // formikRef.current.setFieldValue(
            //   "intake",
            //   res.data?.defaultIntake?.[0]?.intake?.split(" ")?.[0]
            // );
            // formikRef.current.setFieldValue(
            //   "intakeyear",
            //   res.data?.defaultIntake?.[0]?.intake?.split(" ")?.[1]
            // );
          }
        }
      })
      .catch((err) => {
        //   console.log("error getting default intake=>", err);
      });
  };

  useEffect(() => {
    GetDefaultIntake();
  }, []);

  const handleUnivByOrgId = async (orgId) => {
    await getUnivByOrgIdService(orgId).then((res) => {
      if (res.status === 200) {
        console.log(res);
        setUniversitylist(
          res.data.universities?.map(({ name, ...rest }) => ({
            title: name,
            ...rest,
          }))
        );
      }
    });
  };

  if (!prefieldData && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Applicant Details
            </Typography>
            <Typography
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
            >
              {!Edit ? "Create" : "Edit"} Application
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className="d-flex justify-content-center w-100">
            {!Edit && (
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                loading={formLoading}
                onClick={() =>
                  handleFormSubmit(formikRef.current?.values, "reset")
                }
              >
                Add Another Application
              </CustomButton>
            )}
          </div>
        </Grid>
      </Grid>
      <div className="container">
        <Formik
          innerRef={formikRef}
          initialValues={{
            uniqueCaeId: "",
            isDisabled: false,
            agent: Edit ? prefieldData?.agent?._id : "",
            organizationId: Edit ? prefieldData?.organizationId?._id : "",
            branch: Edit ? prefieldData?.branch : "",
            studentFirstName: Edit ? prefieldData?.studentFirstName : "",
            studentLastName: Edit ? prefieldData?.studentLastName : "",
            gender: Edit ? prefieldData?.gender : "",
            dateOfBirth: Edit ? prefieldData?.dateOfBirth : null,
            studentEmailId: Edit ? prefieldData?.studentEmailId : "",
            countryOfCitizenship: Edit
              ? prefieldData?.countryOfCitizenship
              : "",
            university: "",
            degreeType: "",
            program: "",
            referenceId: "",
            universityId: "",
            applicationSubmissionDate: null,
            applicationPortalLoginCredentials: Edit
              ? prefieldData?.applicationPortalLoginCredentials
              : "",
            remarksByAgent: Edit ? prefieldData?.remarksByAgent : "",
            taggingStatus: Edit ? prefieldData?.taggingStatus : "",
            taggingRequirement: Edit ? prefieldData?.taggingRequirement : "",
            applicationStatus: Edit ? prefieldData?.applicationStatus : "",
            decisionStatus: Edit ? prefieldData?.decisionStatus : "",
            visaStatus: Edit ? prefieldData?.visaStatus : "",
            caeNotes: Edit ? prefieldData?.caeNotes : "",
            commentsFromUniversity: "",
            intake: "",
            intakeyear: null,
            applicationintake: Edit
              ? prefieldData?.intake?.split(" ")?.[0]
              : "",
            applicationintakeyear: Edit
              ? prefieldData?.intake?.split(" ")?.[1]
              : null,
            visaintake: Edit ? prefieldData?.intake?.split(" ")?.[0] : "",
            visaintakeyear: Edit ? prefieldData?.intake?.split(" ")?.[1] : null,
            documents: Edit ? prefieldData?.documents : [],

            taggingStatusNotes: Edit ? prefieldData?.taggingStatusNotes : "",
            visaStatusNotes: Edit ? prefieldData?.visaStatusNotes : "",
            commissionStatusNotes: Edit
              ? prefieldData?.commissionStatusNotes
              : "",
            commissionStatus: Edit ? prefieldData?.commissionStatus : "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            //(values.intakeyear);
            values["intake"] = `${values.intake} ${values.intakeyear}`;

            if (values?.applicationintake && values.applicationintakeyear) {
              values[
                "applicationStatusDefferredIntake"
              ] = `${values.applicationintake} ${values.applicationintakeyear}`;
            }
            if (values.visaintake && values.visaintakeyear) {
              values[
                "visaStatusDefferredIntake"
              ] = `${values.visaintake} ${values.visaintakeyear}`;
            }

            delete values.intakeyear;
            delete values.applicationintake;
            delete values.applicationintakeyear;
            delete values.visaintake;
            delete values.visaintakeyear;

            // //({ organization: result }, "result for org list");
            // //("Form data:", values);
            // if (values.documents && values.documents.length > 0) {
            //   let docs = await GetFileLink(values.documents);
            //   values["documents"] = docs;
            // }
            // if (values.logo && values.logo.length > 0) {
            //   let logo = await GetFileLink(values.logo[0]);
            //   values["logo"] = logo[0].url;
            // }

            //(values);
            handleFormSubmit(values, "submit");
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form className="" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={8} xs={12}>
                  <Grid container lg={12} className="mt-4" spacing={2}>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <OutlinedInputField
                            value={values.uniqueCaeId}
                            onChange={handleChange}
                            name={"uniqueCaeId"}
                            padding={"0px 0px 5px 0px"}
                            placeholder=""
                            fullWidth={true}
                            label={"CAE ID"}
                            disable={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-4"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.uniqueCaeId}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={
                              Organizationlist ? values?.organizationId : ""
                            }
                            name={"organization"}
                            multiple={false}
                            option={Organizationlist}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("organizationId", e.target.value);
                              handleUnivByOrgId(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Organization Name"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-4"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.organizationId}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        className="w-100"
                        // error={Boolean(errors.agent && touched.agent)}
                        disabled={!values.organizationId}
                      >
                        <div className="">
                          <CustomSelect
                            // disabled={!values.organizationId}
                            value={Agentlist ? values?.agent : ""}
                            name={"agent"}
                            multiple={false}
                            option={Agentlist?.filter(
                              (org) =>
                                values.organizationId === org.organization._id
                            )?.map(({ email, ...rest }) => ({
                              title: email,
                              ...rest,
                            }))}
                            onChange={(e) => {
                              setFieldValue("agent", e.target.value);
                              let branch = Agentlist?.filter(
                                (branch) => branch._id === e.target.value
                              )?.[0]?.branch._id;
                              //   console.log(branch);
                              setFieldValue("branch", branch);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(errors.agent)}
                            fullWidth={true}
                            label={"Agent Email"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.agent}
                              </Typography>
                            }
                          />
                          <FormHelperText>
                            {errors.agent && touched.agent && errors.agent}
                          </FormHelperText>
                        </div>
                      </FormControl>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <FormControl
                            className="w-100"
                            // error={Boolean(errors.agent && touched.agent)}
                            disabled={!values.organizationId}
                          >
                            <div className="">
                              <CustomSelect
                                // disabled={!values.organizationId}
                                value={Agentlist ? values?.agent : ""}
                                name={"agentname"}
                                multiple={false}
                                option={Agentlist?.filter(
                                  (org) =>
                                    values.organizationId ===
                                    org.organization._id
                                )?.map(({ firstName, ...rest }) => ({
                                  title: firstName,
                                  ...rest,
                                }))}
                                onChange={(e) => {
                                  setFieldValue("agent", e.target.value);
                                  // setFieldValue("branch",)
                                  let branch = Agentlist?.filter(
                                    (branch) => branch._id === e.target.value
                                  )?.[0]?.branch._id;
                                  //  console.log(branch);
                                  setFieldValue("branch", branch);
                                }}
                                onBlur={handleBlur}
                                error={Boolean(errors.agentname)}
                                fullWidth={true}
                                label={"Agent Name"}
                                helperText={
                                  <Typography
                                    align="left"
                                    // className="px-3"
                                    color={ThemeColor.RED}
                                    fontSize={fontSize.h4.medium}
                                    fontFamily={FontFamily.Lato}
                                    fontWeight={fontWeight.W500}
                                  >
                                    {errors.agentname}
                                  </Typography>
                                }
                              />
                              <FormHelperText>
                                {errors.agent && touched.agent && errors.agent}
                              </FormHelperText>
                            </div>
                          </FormControl>
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <FormControl
                            className="w-100"
                            // error={Boolean(errors.agent && touched.agent)}
                            disabled={!values.organizationId}
                          >
                            <div className="">
                              <CustomSelect
                                // disabled={!values.organizationId}
                                value={Branchlist ? values?.branch : ""}
                                name={"branch"}
                                multiple={false}
                                option={Branchlist?.filter(
                                  (data) =>
                                    values.organizationId ===
                                    data?.organisation?._id
                                )}
                                onChange={(e) => {
                                  setFieldValue("branch", e.target.value);
                                  // setFieldValue("branch",)
                                }}
                                onBlur={handleBlur}
                                error={Boolean(errors.branch)}
                                fullWidth={true}
                                label={"Branch Name"}
                                helperText={
                                  <Typography
                                    align="left"
                                    // className="px-3"
                                    color={ThemeColor.RED}
                                    fontSize={fontSize.h4.medium}
                                    fontFamily={FontFamily.Lato}
                                    fontWeight={fontWeight.W500}
                                  >
                                    {errors.branch}
                                  </Typography>
                                }
                              />
                              <FormHelperText>
                                {errors.branch &&
                                  touched.branch &&
                                  errors.branch}
                              </FormHelperText>
                            </div>
                          </FormControl>
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                  </Grid>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <div className="w-100 d-flex justify-content-center mt-4">
                    <Previews
                      ref={childRef}
                      initialValue={values?.documents}
                      handleMydropzoneFile={(updatedFiles) => {
                        //(updatedFiles);
                        setFieldValue("documents", updatedFiles);
                      }}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.documents}
                        </Typography>
                      }
                    />
                  </div>
                </Grid>

                {/* grid for right basic content */}
                <Grid item lg={12}>
                  <Typography
                    align="left"
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    Personal Details
                  </Typography>

                  {/* basic personal details */}
                  <Grid container spacing={2} className="mt-4">
                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <Tooltip title="Add the student's first name as per the passport. If not available, mark as FNU (First Name Unknown).">
                        <FormGroup style={{}}>
                          <div className="">
                            <OutlinedInputField
                              value={values.studentFirstName}
                              padding={"0px 0px 5px 0px"}
                              label={"First Name *"}
                              name={"studentFirstName"}
                              onChange={handleChange}
                              placeholder="Enter First Name *"
                              fullWidth={true}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.studentFirstName}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </Tooltip>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <Tooltip title="Add the student's last name as per the passport. If not available, mark as LNU (Last Name Unknown)">
                        <FormGroup style={{}}>
                          <div className="">
                            <OutlinedInputField
                              value={values.studentLastName}
                              padding={"0px 0px 5px 0px"}
                              label={"Last Name *"}
                              name={"studentLastName"}
                              onChange={handleChange}
                              placeholder="Enter last Name *"
                              fullWidth={true}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.studentLastName}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </Tooltip>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.gender}
                            name={"gender"}
                            multiple={false}
                            option={[
                              { _id: "Male", title: "Male" },
                              { _id: "Female", title: "Female" },
                              { _id: "Other", title: "Other" },
                            ]}
                            onChange={(e) =>
                              setFieldValue("gender", e.target.value)
                            }
                            fullWidth={true}
                            label={"Gender"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.gender}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <OnlyDatePicker
                            label="Date of Birth"
                            views={["year", "month", "day"]}
                            value={
                              values.dateOfBirth
                                ? dayjs(values.dateOfBirth).isValid()
                                  ? dayjs(values.dateOfBirth)
                                  : null
                                : null
                            }
                            // value={dayjs(values.contacts[index].reminder_date).toDate()}
                            onChange={(date) => {
                              setFieldValue(
                                `dateOfBirth`,
                                dayjs(date).format("YYYY-MM-DD")
                              );
                            }}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.dateOfBirth}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <Tooltip title="EMAIL ID used while submitting the application">
                        <FormGroup style={{}}>
                          <Box
                            className=""
                            sx={{ marginTop: { sm: 2, xs: 2, md: 0, lg: 0 } }}
                          >
                            <OutlinedInputField
                              value={values.studentEmailId}
                              padding={"0px 0px 5px 0px"}
                              label={"Student Email *"}
                              name={"studentEmailId"}
                              onChange={handleChange}
                              placeholder="Enter student Email Id*"
                              fullWidth={true}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.studentEmailId}
                                </Typography>
                              }
                            />
                          </Box>
                        </FormGroup>
                      </Tooltip>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.countryOfCitizenship}
                            name={"countryOfCitizenship"}
                            multiple={false}
                            option={[
                              { _id: "India", title: "India" },
                              { _id: "America", title: "America" },
                              { _id: "Japan", title: "Japan" },
                            ]}
                            onChange={(e) =>
                              setFieldValue(
                                "countryOfCitizenship",
                                e.target.value
                              )
                            }
                            fullWidth={true}
                            label={"Country of Citizenship"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.countryOfCitizenship}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                  </Grid>
                  {/* basic univ details */}
                  <Grid container spacing={2} className="mt-4">
                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.university}
                            name={"university"}
                            multiple={false}
                            option={Universitylist}
                            onChange={(e) =>
                              setFieldValue("university", e.target.value)
                            }
                            fullWidth={true}
                            label={"University "}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.university}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.degreeType}
                            name={"degreeType"}
                            multiple={false}
                            option={Degreelist}
                            onChange={(e) =>
                              setFieldValue("degreeType", e.target.value)
                            }
                            fullWidth={true}
                            label={"Degree"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.degreeType}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{ width: "100%" }}>
                        <div className="w-100">
                          <CustomSelect
                            value={Programlist ? values?.program : ""}
                            name={"program"}
                            multiple={false}
                            option={Programlist?.filter(
                              (prog) =>
                                values.university === prog.university._id &&
                                values.degreeType === prog.degree._id
                            )?.map(({ email, ...rest }) => ({
                              title: email,
                              ...rest,
                            }))}
                            onChange={(e) => {
                              setFieldValue("program", e.target.value);
                            }}
                            label={"Program"}
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.program}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <Tooltip title="It is an ID generated just after the submission of the application">
                        <FormGroup style={{}}>
                          <div className="">
                            <OutlinedInputField
                              value={values.referenceId}
                              padding={"0px 0px 5px 0px"}
                              label={"Application / Reference Id"}
                              name={"referenceId"}
                              onChange={handleChange}
                              placeholder="Application / Reference Id"
                              fullWidth={true}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.referenceId}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </Tooltip>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <Tooltip title="Issued to students after application submission or upon admission">
                        <FormGroup style={{}}>
                          <div className="">
                            <OutlinedInputField
                              value={values.universityId}
                              padding={"0px 0px 5px 0px"}
                              label={"Student / University Id"}
                              name={"universityId"}
                              onChange={handleChange}
                              placeholder="Enter university Id*"
                              fullWidth={true}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.universityId}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </Tooltip>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.intake}
                            name={"intake"}
                            multiple={false}
                            option={[
                              { _id: "fall", title: "Fall" },
                              { _id: "spring", title: "Spring" },
                              { _id: "summer", title: "Summer" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("intake", e.target.value);
                            }}
                            fullWidth={true}
                            label={"Intake"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.intake}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <OnlyDatePicker
                            views={["year"]}
                            label="Year of Intake"
                            format="YYYY"
                            openTo="year"
                            value={
                              values.intakeyear
                                ? dayjs(values.intakeyear).isValid()
                                  ? dayjs(values.intakeyear).format("YYYY")
                                  : null
                                : null
                            }
                            // value={dayjs(values.contacts[index].reminder_date).toDate()}
                            onChange={(date) => {
                              setFieldValue(
                                `intakeyear`,
                                dayjs(date).format("YYYY")
                              );
                            }}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.intakeyear}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <Box
                          className=""
                          sx={{ marginTop: { sm: 2, xs: 2, md: 0, lg: 0 } }}
                        >
                          <OnlyDatePicker
                            label="Application Submission Date"
                            // value={dayjs(values.contacts[index].reminder_date).toDate()}
                            views={["year", "month", "day"]}
                            value={
                              values.applicationSubmissionDate
                                ? dayjs(
                                    values.applicationSubmissionDate
                                  ).isValid()
                                  ? dayjs(values.applicationSubmissionDate)
                                  : null
                                : null
                            }
                            onChange={(date) => {
                              setFieldValue(
                                `applicationSubmissionDate`,
                                dayjs(date).format("YYYY-MM-DD")
                              );
                            }}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.applicationSubmissionDate}
                              </Typography>
                            }
                          />
                        </Box>
                      </FormGroup>
                    </NoPaddingGridItem>
                  </Grid>
                </Grid>

                {/* grid for bottom contact person content */}
                <Grid item lg={12}>
                  <Typography
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    Status & Remarks
                  </Typography>
                  <Grid container lg={12} className="mt-4" spacing={2}>
                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.applicationStatus}
                            name={"applicationStatus"}
                            multiple={false}
                            option={[
                              {
                                _id: "application_submitted",
                                title: "Application Submitted",
                              },
                              {
                                _id: "application_not_submitted",
                                title: "Application Not Submitted",
                              },
                              {
                                _id: "application_incomplete",
                                title: "Application Incomplete",
                              },
                              {
                                _id: "decision_awaited",
                                title: "Decision Waited",
                              },
                              { _id: "admitted", title: "Admitted" },
                              {
                                _id: "admitted_and_finalized",
                                title: "Admitted and Finalized",
                              },
                              {
                                _id: "admitted_but_not_finalized",
                                title: "Admitted but not finalized",
                              },
                              { _id: "deferred", title: "Deferred" },
                              { _id: "denied", title: "Denied" },
                              { _id: "withdrawn", title: "Withdrawn" },
                            ]}
                            onChange={(e) => {
                              setFieldValue(
                                "applicationStatus",
                                e.target.value
                              );
                              setApplicationStatus(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Application Status"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.applicationStatus}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    {ApplicationStatus === "deferred" && (
                      <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <CustomSelect
                              value={values?.applicationintake}
                              name={"Application intake"}
                              multiple={false}
                              option={[
                                { _id: "fall", title: "Fall" },
                                { _id: "spring", title: "Spring" },
                                { _id: "summer", title: "Summer" },
                              ]}
                              onChange={(e) => {
                                setFieldValue(
                                  "applicationintake",
                                  e.target.value
                                );
                              }}
                              fullWidth={true}
                              label={"Application Intake"}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.applicationintake}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}

                    {ApplicationStatus === "deferred" && (
                      <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <OnlyDatePicker
                              views={["year"]}
                              label="Application Year of Intake"
                              format="YYYY"
                              openTo="year"
                              value={
                                values.applicationintakeyear
                                  ? dayjs(
                                      values.applicationintakeyear
                                    ).isValid()
                                    ? dayjs(
                                        values.applicationintakeyear
                                      ).format("YYYY")
                                    : null
                                  : null
                              }
                              // value={dayjs(values.contacts[index].reminder_date).toDate()}
                              onChange={(date) => {
                                setFieldValue(
                                  `applicationintakeyear`,
                                  dayjs(date).format("YYYY")
                                );
                              }}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.applicationintakeyear}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}

                    <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.taggingStatus}
                            name={"taggingStatus"}
                            multiple={false}
                            disabled={
                              window.sessionStorage.getItem(
                                "user_role_type"
                              ) === "admin" ||
                              window.sessionStorage.getItem(
                                "user_role_type"
                              ) === "user"
                                ? false
                                : true
                            }
                            option={[
                              { _id: "tagged", title: "Tagged" },
                              { _id: "not_tagged", title: "Not Tagged" },
                              {
                                _id: "awaited",
                                title: "Awaited",
                              },
                            ]}
                            onChange={(e) => {
                              setFieldValue("taggingStatus", e.target.value);
                              setTaggingStatus(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Tagging Status"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.taggingStatus}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    {ApplicationStatus === "admitted_and_finalized" && (
                      <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <CustomSelect
                              value={values?.visaStatus}
                              name={"visaStatus"}
                              multiple={false}
                              option={[
                                {
                                  _id: "visa_approved",
                                  title: "Visa Approved",
                                },
                                {
                                  _id: "visa_rejected",
                                  title: "Visa Rejected",
                                },
                                { _id: "deferred", title: "Deferred" },
                                { _id: "awaited", title: "Awaited" },
                                {
                                  _id: "transferred",
                                  title: "Transferred/Dropped",
                                },
                                { _id: "pending", title: "Pending" },
                              ]}
                              onChange={(e) => {
                                setFieldValue("visaStatus", e.target.value);
                                setVisaStatus(e.target.value);
                              }}
                              fullWidth={true}
                              label={"Visa Staus"}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.visaStatus}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}

                    {(TaggingStatus === "not_tagged" ||
                      TaggingStatus === "awaited") && (
                      <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <OutlinedTextAreaField
                              multiline={true}
                              minRows={4}
                              disabled={
                                window.sessionStorage.getItem(
                                  "user_role_type"
                                ) === "admin" ||
                                window.sessionStorage.getItem(
                                  "user_role_type"
                                ) === "user"
                                  ? false
                                  : true
                              }
                              padding={"0px 0px 5px 0px"}
                              value={values.taggingStatusNotes}
                              label={"Tagging Status Notes"}
                              onChange={handleChange}
                              name={"taggingStatusNotes"}
                              placeholder="tagging status notes"
                              fullWidth={true}
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}

                    {VisaStatus === "transferred" ? (
                      <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <OutlinedTextAreaField
                              multiline={true}
                              minRows={4}
                              padding={"0px 0px 5px 0px"}
                              value={values.visaStatusNotes}
                              label={"Visa Status Notes"}
                              onChange={handleChange}
                              name={"visaStatusNotes"}
                              placeholder="Enter your details"
                              fullWidth={true}
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    ) : (
                      ""
                    )}

                    {VisaStatus === "deferred" && (
                      <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <CustomSelect
                              value={values?.visaintake}
                              name={"Visa intake"}
                              multiple={false}
                              option={[
                                { _id: "fall", title: "Fall" },
                                { _id: "spring", title: "Spring" },
                                { _id: "summer", title: "Summer" },
                              ]}
                              onChange={(e) => {
                                setFieldValue("visaintake", e.target.value);
                              }}
                              fullWidth={true}
                              label={"Visa Intake"}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.visaintake}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}

                    {VisaStatus === "deferred" && (
                      <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <OnlyDatePicker
                              views={["year"]}
                              label="Visa Year of Intake"
                              format="YYYY"
                              openTo="year"
                              value={
                                values.visaintakeyear
                                  ? dayjs(values.visaintakeyear).isValid()
                                    ? dayjs(values.visaintakeyear).format(
                                        "YYYY"
                                      )
                                    : null
                                  : null
                              }
                              // value={dayjs(values.contacts[index].reminder_date).toDate()}
                              onChange={(date) => {
                                setFieldValue(
                                  `visaintakeyear`,
                                  dayjs(date).format("YYYY")
                                );
                              }}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.visaintakeyear}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}

                    {ApplicationStatus === "admitted_and_finalized" && (
                      <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                        <Tooltip title="Commission Status">
                          <FormGroup style={{}}>
                            <div className="">
                              <CustomSelect
                                disabled={
                                  window.sessionStorage.getItem(
                                    "user_role_type"
                                  ) === "admin"
                                    ? false
                                    : true
                                }
                                value={values?.commissionStatus}
                                name={"commissionStatus"}
                                multiple={false}
                                option={[
                                  {
                                    _id: "not_approved",
                                    title: "Commission Not Approved",
                                  },
                                  {
                                    _id: "approved",
                                    title: "Commission Approved",
                                  },
                                  {
                                    _id: "pending",
                                    title: "Commission Pending",
                                  },
                                ]}
                                onChange={(e) => {
                                  setFieldValue(
                                    "commissionStatus",
                                    e.target.value
                                  );
                                  setCommissionStatus(e.target.value);
                                }}
                                fullWidth={true}
                                label={"Commission Staus"}
                                helperText={
                                  <Typography
                                    align="left"
                                    // className="px-3"
                                    color={ThemeColor.RED}
                                    fontSize={fontSize.h4.medium}
                                    fontFamily={FontFamily.Lato}
                                    fontWeight={fontWeight.W500}
                                  >
                                    {errors.commissionStatus}
                                  </Typography>
                                }
                              />
                            </div>
                          </FormGroup>
                        </Tooltip>
                      </NoPaddingGridItem>
                    )}

                    {(CommissionStatus === "not_approved" ||
                      CommissionStatus === "pending") && (
                      <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                        <Tooltip title="Visa Status notes">
                          <FormGroup style={{}}>
                            <div className="">
                              <OutlinedTextAreaField
                                multiline={true}
                                disabled={
                                  window.sessionStorage.getItem(
                                    "user_role_type"
                                  ) === "admin"
                                    ? false
                                    : true
                                }
                                minRows={4}
                                padding={"0px 0px 5px 0px"}
                                value={values.commissionStatusNotes}
                                label={"Commission Status Notes"}
                                onChange={handleChange}
                                name={"commissionStatusNotes"}
                                placeholder="Commssion status notes"
                                fullWidth={true}
                                helperText={
                                  <Typography
                                    align="left"
                                    // className="px-3"
                                    color={ThemeColor.RED}
                                    fontSize={fontSize.h4.medium}
                                    fontFamily={FontFamily.Lato}
                                    fontWeight={fontWeight.W500}
                                  >
                                    {errors.commissionStatusNotes}
                                  </Typography>
                                }
                              />
                            </div>
                          </FormGroup>
                        </Tooltip>
                      </NoPaddingGridItem>
                    )}

                    {(window.sessionStorage.getItem("user_role_type") ===
                      "admin" ||
                      (window.sessionStorage.getItem("user_role_type") ===
                        "user" &&
                        window.sessionStorage.getItem("role") ===
                          "counsellor")) && (
                      <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                        <Tooltip title="Comments from university">
                          {" "}
                          <FormGroup style={{}}>
                            <div className="">
                              <OutlinedTextAreaField
                                multiline={true}
                                minRows={4}
                                value={values.caeNotes}
                                onChange={handleChange}
                                name={"caeNotes"}
                                placeholder="Comment from CAE admin"
                                fullWidth={true}
                                helperText={
                                  <Typography
                                    align="left"
                                    // className="px-3"
                                    color={ThemeColor.RED}
                                    fontSize={fontSize.h4.medium}
                                    fontFamily={FontFamily.Lato}
                                    fontWeight={fontWeight.W500}
                                  >
                                    {errors.caeNotes}
                                  </Typography>
                                }
                              />
                            </div>
                          </FormGroup>
                        </Tooltip>
                      </NoPaddingGridItem>
                    )}

                    {/* <NoPaddingGridItem item lg={3} md={3} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <OutlinedInputField
                            value={values.taggingRequirement}
                            onChange={handleChange}
                            name={"taggingRequirement"}
                            padding={"0px 0px 5px 0px"}
                            placeholder="requirement"
                            fullWidth={true}
                            label={"Tagging Requirement"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.taggingRequirement}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem> */}
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                }}
                className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              >
                {" "}
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  loading={formLoading}
                  //   padding={"0px"}
                  // onClick={handleClick}
                >
                  Add
                </CustomButton>
                <Box
                  className="px-2"
                  sx={{
                    paddingTop: {
                      lg: "0px",
                      md: "0px",
                      sm: "5px",
                      xs: "5px",
                    },
                  }}
                >
                  {Edit && (
                    <CustomButton
                      variant="contained"
                      // type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                      loading={formLoading}
                      //   padding={"0px"}
                      onClick={() => {
                        if (Edit) {
                          if (childRef.current) {
                            childRef.current.someFunction(
                              prefieldData?.documents
                            );
                          }
                        } else {
                          if (childRef.current) {
                            childRef.current.someEditFunction();
                          }
                        }
                        resetForm();
                      }}
                    >
                      Revert
                    </CustomButton>
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function AddUniversityApplication() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<CreateApplicationPage />} />
    </div>
  );
}

export default AddUniversityApplication;
