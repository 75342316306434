import React from "react";
import { Button, styled } from "@mui/material";
import { fontWeight } from "../theme/fontWeight";
import { fontSize } from "../theme/fontSize";
import { FontFamily } from "../theme/fontFamily";
import { darken, lighten } from "@mui/material/styles";
import { ThemeColor } from "../theme/color";

const StyledButton = styled(Button)(({ theme, customColor, padding }) => ({
  borderRadius: "40px",
  padding: "5px 25px",
  borderWidth: "2px",
  borderColor: ThemeColor.BLUE,
  fontWeight: fontWeight.W600,
  fontSize: fontSize.h3.medium,
  fontFamily: FontFamily.Nunito,
  border: `2px solid ${ThemeColor.BLACK}`,
  width: { sm: "100%", xs: "100%", lg: "70%", md: "70%" },
  textTransform: "none",
  color: ThemeColor.BLACK,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: customColor
      ? lighten(customColor, 0.5)
      : lighten(theme.palette.primary.dark),
  },
}));

const OutlineCustomButton = ({
  variant = "outlined",
  color = "primary",
  onClick,
  type,
  children,
  backgroundColor,
  padding,
}) => {
  return (
    <StyledButton
      color={color}
      type={type}
      onClick={onClick}
      customColor={backgroundColor}
      padding={padding}
    >
      {children}
    </StyledButton>
  );
};

export default OutlineCustomButton;
