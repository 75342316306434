export const ThemeColor = {
    WHITE:"#FFFFFF",
    BLUE: "#15539E",
    LIGHTBLUE:'#EFF6FF',
    LIGHTPINK:"#C8BDFF3D",
    BLACK:"#484848",
    NAVYBLUE:"#2F4C6B",
    // PURPLE:"#6A4BFF",
    PURPLE:"#15539E",
    RED:"#E8363D",
    WhHITESMOKE:"#F6FAFF",
    LIGHTPURPLE:"#8DA0D9",
    VERYLIGHTPURPLE:"rgba(206, 206, 254, 1)",
    GREY:"rgb(132,149,168)",
    WHITEOPACITY:"rgba(245,245,245,0.7)",
    YELLOW:"#FCBF01"
}