import { Box, FormGroup, Grid, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import moment from "moment";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import LeftSideArrow from "../../Icon/LeftSideArrow";
import { useEffect, useState } from "react";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import {
    GetStudentAction,
  GetStudentNotesByIdAction,
  GetStudentReminderByIdAction,
} from "../../Redux/action/ApplicationAction";
import {
  AddStudentNotesService,
  AddStudentTransferService,
  DeleteStudentNotesService,
  DeleteStudentReminderService,
} from "../../Redux/service/Application";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import CustomButton from "../button/button";
import { Form, Formik } from "formik";
import CustomSelect from "../select field/OutlinedSelectField";
import Agentlist from "../../page/Setting/Agentlist.page";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "none",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const StudentTransferModal = ({
  TransferModal,
  handleClose,
  stdId,
  Agentlist,
  Studentlist,
}) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    newAgent: Yup.string().required("Agent is required to transfer!"),
  });

  return (
    <>
      {" "}
      <Modal
        open={TransferModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography align="right" onClick={() => handleClose()}>
            <Crossicon width={"20"} height={"20"} />
          </Typography>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.BLACK}
            align="center"
          >
            Transfer Student to different Agent
          </Typography>
          <Formik
            initialValues={{
              newAgent: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              //("Form data:", values, stdId);
            //  console.log(Studentlist);

              if (Studentlist?.length > 0) {
                values["studentIds"]=Studentlist
                await AddStudentTransferService(values)
                  .then((res) => {
                    if (res.status === 200) {
                      // //(res);
                      dispatch(GetStudentAction());
                      dispatch(showSnackbar(res?.data.message, "success"));
                      handleClose()

                      resetForm();
                    }
                  })
                  .catch((e) => {
                    dispatch(showSnackbar(e.response.data.message, "error"));
                  });
              } else {
                dispatch(showSnackbar("Select Atleast one student!", "error"));
              }
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="d-flex justify-content-center">
                <Box
                  className="addcatalog-container"
                  sx={{
                    width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                  }}
                >
                  <FormGroup style={{}} className="mt-3">
                    <div className="">
                      <CustomSelect
                        value={values?.newAgent}
                        name={"Agent"}
                        multiple={false}
                        option={Agentlist}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          setFieldValue("newAgent", e.target.value)
                        }
                        fullWidth={true}
                        // disabled={RoleTypeAgent && true}
                        label={"Agent Name"}
                        helperText={
                          <Typography
                            align="left"
                            // className="px-4"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.newAgent}
                          </Typography>
                        }
                      />
                    </div>
                  </FormGroup>

                  <Box
                    sx={{
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                    className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
                  >
                    {" "}
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                    >
                      Transfer
                    </CustomButton>
                    <Box
                      className="px-2"
                      sx={{
                        paddingTop: {
                          lg: "0px",
                          md: "0px",
                          sm: "5px",
                          xs: "5px",
                        },
                      }}
                    >
                      <CustomButton
                        variant="contained"
                        // type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        //   padding={"0px"}
                        onClick={() => {
                          //   resetForm();
                          handleClose();
                        }}
                      >
                        Cancel
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
