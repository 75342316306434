import { Box, FormGroup, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import moment from "moment";
import * as Yup from "yup"
import { AddSupportChat } from "../../Redux/service/SupportService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { getAllSupportTicket } from "../../Redux/action/SupportAction";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
    height: "60vh",
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid grey",
    boxShadow: 24,
    p: 4,
};

const validationSchema = Yup.object({
    text: Yup.string().required("Text is required"),
});

export const SupportChat = ({ chatWindow, handleClose, messages, chatId, dispatch }) => {

    const SubmitSupportChat = async (values) => {
        const payload = {
            "ticketId": values.chatId,
            "message": values.text
        }
        const res = await AddSupportChat(payload);
        if (res.status == 200) {
            dispatch(showSnackbar(res.data.message, "success"))
        } else {
            dispatch(showSnackbar(res.data.message, "error"))
        }
        handleClose();
        dispatch(getAllSupportTicket());
    }


    return (<Modal
        open={chatWindow}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Typography align="right" onClick={handleClose}>
                <Crossicon width={"20"} height={"20"} />
            </Typography>
            <Typography
                fontFamily={FontFamily.Poppins}
                fontWeight={fontWeight.W600}
                fontSize={fontSize.h3.large}
                color={ThemeColor.BLACK}
                align="center"
            >
                Conversation
            </Typography>
            <Box sx={{ height: "60%", overflowY: "scroll" }}>
                {messages?.map((data) => {
                    return (
                        <>
                            {data.sender?._id ===
                                window.sessionStorage.getItem("user_id") ? (
                                <div id="sender" className="ms-5 mt-3">
                                    <Box className="d-flex justify-content-between">
                                        <div>
                                            <Typography
                                                fontFamily={FontFamily.Poppins}
                                                fontWeight={fontWeight.W700}
                                                fontSize={fontSize.h4.small}
                                                color={ThemeColor.BLACK}
                                                align="left"
                                            >
                                                {moment.unix(data.timestamp).format("llll")}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                fontFamily={FontFamily.Poppins}
                                                fontWeight={fontWeight.W400}
                                                fontSize={fontSize.h4.small}
                                                color={ThemeColor.RED}
                                                sx={{ textDecoration: "underline" }}
                                                align="left"
                                            >
                                                {data.sender.firstName} &nbsp;
                                                {data.sender.lastName}
                                            </Typography>
                                        </div>
                                    </Box>

                                    <Typography
                                        className="mt-3 ps-5"
                                        fontFamily={FontFamily.Poppins}
                                        fontWeight={fontWeight.W400}
                                        fontSize={fontSize.h4.medium}
                                        color={ThemeColor.BLACK}
                                        align="left"
                                    >
                                        {data.message}
                                    </Typography>
                                </div>
                            ) : (
                                <div id="receiver" className="me-5 mt-3">
                                    <Box className="d-flex justify-content-between">
                                        <div>
                                            <Typography
                                                fontFamily={FontFamily.Poppins}
                                                fontWeight={fontWeight.W400}
                                                fontSize={fontSize.h4.small}
                                                color={ThemeColor.RED}
                                                sx={{ textDecoration: "underline" }}
                                                align="left"
                                            >
                                                {data.sender.firstName} &nbsp;
                                                {data.sender.lastName}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                fontFamily={FontFamily.Poppins}
                                                fontWeight={fontWeight.W700}
                                                fontSize={fontSize.h4.small}
                                                color={ThemeColor.BLACK}
                                                align="left"
                                            >
                                                {moment.unix(data.timestamp).format("llll")}
                                            </Typography>
                                        </div>
                                    </Box>

                                    <Typography
                                        className="mt-3 pe-5"
                                        fontFamily={FontFamily.Poppins}
                                        fontWeight={fontWeight.W400}
                                        fontSize={fontSize.h4.medium}
                                        color={ThemeColor.BLACK}
                                        align="left"
                                    >
                                        {data.message}
                                    </Typography>
                                </div>
                            )}
                        </>
                    );
                })}
            </Box>
            <Formik
                initialValues={{
                    text: "",
                    chatId: chatId,
                }}
                onSubmit={SubmitSupportChat}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Form className="d-flex justify-content-center">
                        <Box
                            className="addcatalog-container"
                            sx={{
                                width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                                position: "absolute",
                                bottom: "0%",
                            }}
                        >
                            <FormGroup style={{}}>
                                <div className="d-flex justify-content-between">
                                    <div className="px-2 w-100">
                                        <OutlinedInputField
                                            value={values.text}
                                            name={"text"}
                                            onChange={handleChange}
                                            placeholder="Write something...."
                                            fullWidth={true}
                                            helperText={
                                                <Typography
                                                    align="left"
                                                    // className="px-3"
                                                    color={ThemeColor.RED}
                                                    fontSize={fontSize.h4.medium}
                                                    fontFamily={FontFamily.Lato}
                                                    fontWeight={fontWeight.W500}
                                                >
                                                    {errors.text}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                    <div className="px-3">
                                        <CustomButton
                                            variant="contained"
                                            type="submit"
                                            backgroundColor={ThemeColor.PURPLE}
                                            color={ThemeColor.WHITE}
                                        >
                                            Send
                                        </CustomButton>
                                    </div>
                                </div>
                            </FormGroup>

                            <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-5"></div>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    </Modal>)
}