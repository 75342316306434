import {
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../select field/OutlinedSelectField";
import { AddPaymentDetailsAfterVisaApprovedService } from "../../Redux/service/Application";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import OnlyDatePicker from "../datepicker/MyDatepicker";
import { GetStudentAction } from "../../Redux/action/ApplicationAction";
import { PaymentDetailModal } from "../payoutsection/PaymentDetailsModal.component";
import OutlinedTextAreaField from "../inputfiled/OutlinedTextedArea";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  visaStatus: Yup.string().required("Visa Status is required!"),

  visaintake: Yup.string().when("visaStatus", {
    is: (value) => value === "deferred",
    then: (schema) => schema.required("Visa Intake is required"),
    otherwise: (schema) => schema,
  }),

  visaintakeyear: Yup.string().when("visaStatus", {
    is: (value) => value === "deferred",
    then: (schema) => schema.required("Visa Intake year is required"),
    otherwise: (schema) => schema,
  }),
});

export const StudentVisaApproveModal = ({
  AddPaymentModal,
  handleClose,
  stdId,
  Studentlist,
}) => {
  const dispatch = useDispatch();
  const [VisaStatus, setVisaStatus] = useState("");
  const [commStatus, setCommStatus] = useState("");
  const [prefieldData, setPrefieldData] = useState();

  useEffect(() => {
    setPrefieldData(Studentlist?.filter((data) => data._id === stdId)?.[0]);
    setCommStatus(
      Studentlist?.filter((data) => data._id === stdId)?.[0]?.commissionStatus
    );
  }, [Studentlist, stdId]);

  if (!prefieldData && AddPaymentModal) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  return (
    <>
      <Modal
        open={AddPaymentModal}
        onClose={() => {
          setCommStatus("");
          setVisaStatus("");
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            align="right"
            onClick={() => {
              setCommStatus("");
              setVisaStatus("");
              handleClose();
            }}
          >
            <Crossicon width={"20"} height={"20"} />
          </Typography>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.BLACK}
            align="center"
          >
            Update Student Visa status
          </Typography>
          <Formik
            initialValues={{
              visaintake: "",
              visaintakeyear: null,
              visaStatus: prefieldData ? prefieldData.visaStatus : "",
              commissionStatus: prefieldData
                ? prefieldData.commissionStatus
                : "",
              commissionStatusNotes: prefieldData
                ? prefieldData?.commissionStatusNotes
                : "",
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              values["studentId"] = stdId;

              if (values.visaintake && values.visaintakeyear) {
                values[
                  "visaStatusDefferredIntake"
                ] = `${values.visaintake} ${values.visaintakeyear}`;
              }

              delete values.visaintake;
              delete values.visaintakeyear;

              // console.log(values)

              await AddPaymentDetailsAfterVisaApprovedService(values)
                .then((res) => {
                  if (res.status === 200) {
                    dispatch(GetStudentAction());
                    handleClose();
                    setVisaStatus("");

                    dispatch(showSnackbar(res.data.message, "success"));
                    resetForm();
                  }
                })
                .catch((e) => {
                  dispatch(showSnackbar(e.response.data.message, "error"));
                });
            }}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="d-flex justify-content-center">
                <Box
                  className="mt-3"
                  sx={{
                    width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                  }}
                >
                  <Grid container spacing={2}>
                    {" "}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.visaStatus}
                            name={"visaStatus"}
                            multiple={false}
                            option={[
                              { _id: "visa_approved", title: "Visa Approved" },
                              { _id: "visa_rejected", title: "Visa Rejected" },
                              { _id: "deferred", title: "Deferred" },
                              { _id: "awaited", title: "Awaited" },
                              { _id: "transferred", title: "Transferred" },
                              { _id: "pending", title: "Pending" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("visaStatus", e.target.value);
                              setVisaStatus(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Visa Staus"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.visaStatus}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    {VisaStatus === "deferred" && (
                      <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <CustomSelect
                              // value={values?.intake}
                              name={"Visa intake"}
                              multiple={false}
                              option={[
                                { _id: "fall", title: "Fall" },
                                { _id: "spring", title: "Spring" },
                                { _id: "summer", title: "Summer" },
                              ]}
                              onChange={(e) => {
                                setFieldValue("visaintake", e.target.value);
                              }}
                              fullWidth={true}
                              label={"Visa Intake"}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.visaintake}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}
                    {VisaStatus === "deferred" && (
                      <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                        <FormGroup style={{}}>
                          <div className="">
                            <OnlyDatePicker
                              views={["year"]}
                              label="Visa Year of Intake"
                              format="YYYY"
                              openTo="year"
                              value={
                                values.visaintakeyear
                                  ? dayjs(values.visaintakeyear).isValid()
                                    ? dayjs(values.visaintakeyear).format(
                                        "YYYY"
                                      )
                                    : null
                                  : null
                              }
                              // value={dayjs(values.contacts[index].reminder_date).toDate()}
                              onChange={(date) => {
                                setFieldValue(
                                  `visaintakeyear`,
                                  dayjs(date).format("YYYY")
                                );
                              }}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.visaintakeyear}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </NoPaddingGridItem>
                    )}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <CustomSelect
                            value={values?.commissionStatus}
                            name={"commissionStatus"}
                            multiple={false}
                            option={[
                              { _id: "approved", title: "Approved" },
                              { _id: "not_approved", title: "Not Approved" },
                              { _id: "pending", title: "Pending" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("commissionStatus", e.target.value);
                              setCommStatus(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Commission Status"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.commissionStatus}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </Grid>
                    {(commStatus === "not_approved" ||
                      commStatus === "pending") && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {" "}
                        <FormGroup style={{}}>
                          <div className="">
                            <OutlinedTextAreaField
                              multiline={true}
                              minRows={4}
                              value={values.commissionStatusNotes}
                              onChange={handleChange}
                              name={"commissionStatusNotes"}
                              placeholder="Commision Status Notes"
                              fullWidth={true}
                              helperText={
                                <Typography
                                  align="left"
                                  // className="px-3"
                                  color={ThemeColor.RED}
                                  fontSize={fontSize.h4.medium}
                                  fontFamily={FontFamily.Lato}
                                  fontWeight={fontWeight.W500}
                                >
                                  {errors.commissionStatusNotes}
                                </Typography>
                              }
                            />
                          </div>
                        </FormGroup>
                      </Grid>
                    )}
                  </Grid>
                  <Box
                    sx={{
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                    className="d-flex justify-content-center align-items-center w-100 mt-3"
                  >
                    {" "}
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                    >
                      Update
                    </CustomButton>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
