import React from "react";

function Dropdown() {
  return (
    <div>
      <svg
        width="74"
        height="74"
        viewBox="0 0 74 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M49.3328 49.3333L36.9995 37M36.9995 37L24.6662 49.3333M36.9995 37V64.75M62.8687 56.7025C65.876 55.063 68.2517 52.4687 69.6208 49.3291C70.9899 46.1895 71.2745 42.6833 70.4296 39.364C69.5848 36.0446 67.6586 33.1012 64.9551 30.9981C62.2516 28.8951 58.9247 27.7523 55.4995 27.75H51.6145C50.6812 24.1402 48.9418 20.7889 46.5268 17.9481C44.1119 15.1072 41.0845 12.8509 37.672 11.3485C34.2595 9.8462 30.5509 9.13701 26.8249 9.2743C23.0989 9.41158 19.4525 10.3918 16.1598 12.1411C12.8671 13.8905 10.0139 16.3636 7.8146 19.3744C5.61529 22.3852 4.12714 25.8554 3.46203 29.5241C2.79693 33.1929 2.97217 36.9646 3.97459 40.5559C4.977 44.1471 6.78051 47.4644 9.24951 50.2583"
          stroke="#6A4BFF"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Dropdown;
