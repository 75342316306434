import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetAllNotification,
  GetUnseenNotificationCount,
} from "../../Redux/action/NotificationAction";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CustomButton from "../../component/button/button";
import { AddPersonToNotificationModal } from "../../component/notificationModal/SendUserNotification";
import { MarkNotificationSeenAPI } from "../../Redux/service/NotificationService";
import { Height } from "@mui/icons-material";

const AllNotificationpage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [AllNotification, setAllNotification] = useState([]);

  const NotificationlistState = useSelector(
    (state) => state.notification?.allNotification?.data?.data
  );

  const [Chatlist, setChatlist] = useState([]);

  const [AddPersonNotification, setAddPersonNotification] = useState(false);

  const [selectedUser, setselectedUser] = useState();

  const ChatlistState = useSelector(
    (state) => state.setting?.allchat?.data?.data
  );

  const handleClosePersonNotification = () =>
    setAddPersonNotification(!AddPersonNotification);

  useEffect(() => {
    setChatlist(ChatlistState);
  }, [ChatlistState]);

  useEffect(() => {
    setAllNotification(NotificationlistState);
  }, [NotificationlistState]);

  useEffect(() => {
    dispatch(GetAllNotification());
  }, []);

  const handleSendNotificationToUser = (data) => {
    console.log(data);
    handleClosePersonNotification();
    setselectedUser(data);
  };

  const handleMarkNotificationSeen = async (id) => {
    await MarkNotificationSeenAPI(id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          dispatch(GetAllNotification());
          dispatch(GetUnseenNotificationCount());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Notification List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },

              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            <div className="px-2">
              {
                //   (window.sessionStorage.getItem("user_role_type") === "admin" ||
                //     !window.sessionStorage.getItem("user_role_type") ===
                //       "user") &&
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  onClick={() => handleClosePersonNotification()}
                >
                  Send Notification to User
                </CustomButton>
              }
            </div>
            <div className="px-2">
              {
                //   (window.sessionStorage.getItem("user_role_type") === "admin" ||
                //     !window.sessionStorage.getItem("user_role_type") ===
                //       "user") &&
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  onClick={() => navigate("/CAE/CreateNotification")}
                >
                  Create Global Notification
                </CustomButton>
              }
            </div>
          </Box>
        </Grid>
      </Grid>
      <div className="container">
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {AllNotification?.length > 0 &&
            AllNotification.map((data) => (
              <>
                <ListItem
                  alignItems="flex-start"
                  onClick={() => {
                    handleMarkNotificationSeen(data._id);
                  }}
                  sx={{
                    backgroundColor: `${
                      data?.isView === false ? "#D3D3D3" : ThemeColor.WHITE
                    }`,
                  }}
                  // secondaryAction={
                  //   <IconButton
                  //     aria-label="comment"
                  //     onClick={() => {
                  //       handleSendNotificationToUser(data);
                  //     }}
                  //   >
                  //     <PersonAddIcon />
                  //   </IconButton>
                  // }
                >
                  <ListItemAvatar>
                    <NotificationsActiveIcon />
                  </ListItemAvatar>
                  <ListItemText
                    // primary={data.title}
                    primary={
                      <div className="d-flex">
                        <Typography
                          component="span"
                          variant="body2"
                          fontSize={fontSize.h3.medium}
                          sx={{ color: "text.primary", display: "inline" }}
                        >
                          {data.title}
                        </Typography>{" "}
                        {data.isPublic ? (
                          <div
                            className="px-1 ms-2 "
                            style={{
                              backgroundColor: ThemeColor.RED,
                              color: "white",
                              borderRadius: "5px",
                              fontSize: fontSize.h4.medium,
                              height: "20px",
                            }}
                          >
                            Global
                          </div>
                        ) : (
                          <div
                            className="px-1 ms-2 "
                            style={{
                              backgroundColor: ThemeColor.BLUE,
                              color: "white",
                              borderRadius: "5px",
                              fontSize: fontSize.h4.medium,
                              height: "20px",
                            }}
                          >
                            Personal
                          </div>
                        )}
                      </div>
                    }
                    secondary={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="d-flex">
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: "text.primary", display: "inline" }}
                          >
                            {data.text}
                          </Typography>{" "}
                          {/* {data.isPublic ? (
                            <div
                              className="px-2 ms-2"
                              style={{
                                backgroundColor: ThemeColor.RED,
                                color: "white",
                                borderRadius: "5px",
                              }}
                            >
                              Global
                            </div>
                          ) : (
                            "User"
                          )} */}
                        </div>

                        {/* {data?.isView === false && (
                          <div
                            className=""
                            style={{
                              backgroundColor: ThemeColor.RED,
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        )} */}
                      </div>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ))}
        </List>
      </div>

      <AddPersonToNotificationModal
        selectedUser={selectedUser}
        Participantlist={Chatlist?.participants?.map(
          ({ firstName, ...rest }) => ({
            title: firstName,
            ...rest,
          })
        )}
        AddPersonNotification={AddPersonNotification}
        handleClose={handleClosePersonNotification}
      />
    </>
  );
};

function AllNotification() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<AllNotificationpage />} />
    </div>
  );
}

export default AllNotification;
