import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import * as Yup from "yup";
import CustomButton from "../../component/button/button";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAgentAction,
  GetBranchByOrgIdAction,
  GetOrganizationAction,
  GetRoleAction,
} from "../../Redux/action/SettingAction";
import {
  CreateAgentService,
  CreateBranchByOrganizationIdService,
  EditAgentService,
} from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate, useParams } from "react-router-dom";
import { AutoComplete, Col, Input, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { agentvalidationSchema } from "./ValidationConstant";
import { Form, Formik } from "formik";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const AddNewAgentPage = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const [Edit, setEdit] = useState(id ? true : false);

  const [prefieldData, setPrefieldData] = useState();

  const [formLoading, setFormLoading] = useState(false);

  const [Rolelist, setRolelist] = useState([]);

  const [Organizationlist, setOrganizationlist] = useState([]);

  const [organization, setOrganization] = useState();

  const [branchData, setBranchData] = useState([]);

  const [branches, setBranches] = useState([]); // List of branches
  const [inputValue, setInputValue] = useState(""); // Input field value
  const [showAddButton, setShowAddButton] = useState(false);

  const [RoleTypeAgent, setRoleTypeAgent] = useState();
  const [RoleBasedOnRoleType, setRoleBasedOnRoleType] = useState();

  const OrganizationlistState = useSelector(
    (state) => state.setting?.organization?.data?.organizations
  );

  const EditAgentdataState = useSelector(
    (state) => state.setting?.agent?.data?.activeAgents
  );

  useEffect(() => {
    console.log(EditAgentdataState?.filter((data) => data._id === id)?.[0]);
    setPrefieldData(EditAgentdataState?.filter((data) => data._id === id)?.[0]);
    dispatch(
      GetBranchByOrgIdAction(
        EditAgentdataState?.filter((data) => data._id === id)?.[0]?.organization
          ?._id
      )
    );
    setInputValue(
      EditAgentdataState?.filter((data) => data._id === id)?.[0]?.branch
        ?.branchName
    );
    // set
  }, [Edit, EditAgentdataState, id]);

  useEffect(() => {
    dispatch(GetOrganizationAction());
    dispatch(GetAgentAction());
  }, []);

  const AgentlistState = useSelector(
    (state) => state.setting?.agent?.data?.activeAgents
  );

  useEffect(() => {
    const updatedData = OrganizationlistState?.map(
      ({ organizationName, ...rest }) => ({
        title: organizationName,
        ...rest,
      })
    );
    setOrganizationlist(updatedData);
    if (window.sessionStorage.getItem("user_role_type") === "agent") {
      setRoleBasedOnRoleType(window.sessionStorage.getItem("role"));
      console.log(
        AgentlistState?.filter(
          (data) => data._id === window.sessionStorage.getItem("user_id")
        )?.[0]
      );
      setRoleTypeAgent(
        AgentlistState?.filter(
          (data) => data._id === window.sessionStorage.getItem("user_id")
        )?.[0]
      );
    }
  }, [OrganizationlistState]);

  const RolelistState = useSelector(
    (state) => state.setting?.role?.data?.roles
  );
  const BranchByOrglistState = useSelector(
    (state) => state.setting?.branchByOrg?.data?.branches
  );

  useEffect(() => {
    dispatch(GetRoleAction());
  }, []);

  useEffect(() => {
    // //("role list==>",RolelistState)
    const updated = RolelistState?.filter(
      (role) =>
        role.role === "agent_counsellor" ||
        role.role === "agent_accountant" ||
        role.role === "application_supervisor" ||
        role.role === "admin"
    );
    setRolelist(updated);
  }, [RolelistState]);

  useEffect(() => {
    const fetchBranches = async () => {
      // Simulated API call, replace with actual API call
      if (formikRef?.current?.values?.organization) {
        //(formikRef.current.values.organization);
        dispatch(GetBranchByOrgIdAction(formikRef.current.values.organization));
        if (
          !Organizationlist?.filter(
            (data) => data.id === formikRef.current.values.organization
          )?.[0]?.branch
        ) {
          setShowAddButton(true);
        }
      }
      // const fetchedBranches = ["Branch 1", "Branch 2", "Branch 3"];
      // setBranches(fetchedBranches);
    };
    fetchBranches();
  }, [formikRef?.current?.values?.organization, organization]);

  useEffect(() => {
    //(BranchByOrglistState);
    setBranchData(BranchByOrglistState);
    setBranches(BranchByOrglistState?.map((data) => data.branchName));
    if (
      !Organizationlist?.filter((data) => data.id === organization)?.[0]?.branch
    ) {
      setShowAddButton(true);
    }
  }, [BranchByOrglistState]);

  // Handle input change
  const handleInputChange = (value) => {
    setInputValue(value);

    // Check if the input value exists in the branches list
    if (branches.length == 0) {
      setShowAddButton(true);
    } else {
      if (value && !branches.includes(value)) {
        setShowAddButton(true); // Show the "Add" button if branch doesn't exist
      } else {
        setShowAddButton(false); // Hide the "Add" button if branch exists or input is empty
      }
    }
  };

  // Handle adding a new branch
  const handleAddBranch = async () => {
    if (Edit) {
      if (
        prefieldData?.organization?._id ===
        formikRef.current.values.organization
      ) {
        //("if");

        if (inputValue && !branches.includes(inputValue)) {
          // //(inputValue, organization);
          let data = {
            organisation: prefieldData?.organization?._id,
            branchName: inputValue,
          };

          await CreateBranchByOrganizationIdService(data)
            .then((res) => {
              if (res.status === 200) {
                setInputValue(""); // Clear input field
                setShowAddButton(false);
                dispatch(
                  GetBranchByOrgIdAction(prefieldData?.organization?._id)
                );

                dispatch(showSnackbar(res.data.message, "success"));
              }
            })
            .catch((e) => {
              dispatch(showSnackbar(e.response.data.message, "error"));
            });
        }
      }
    } else {
      //("else");
      if (inputValue && !branches.includes(inputValue)) {
        // //(inputValue, organization);

        let data = {
          organisation: formikRef.current.values.organization,
          branchName: inputValue,
        };

        await CreateBranchByOrganizationIdService(data)
          .then((res) => {
            if (res.status === 200) {
              setInputValue(""); // Clear input field
              setShowAddButton(false);
              dispatch(
                GetBranchByOrgIdAction(formikRef.current.values.organization)
              );

              dispatch(showSnackbar(res.data.message, "success"));
            }
          })
          .catch((e) => {
            dispatch(showSnackbar(e.response.data.message, "error"));
          });
      }
    }
  };

  const handleFormSubmit = async (values, formType) => {
    setFormLoading(true);

    if (!Edit) {
      await CreateAgentService(values)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/AgentList");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          // formikRef.current.resetForm();
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    } else {
      //(values, "edit values");
      await EditAgentService(values, id)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/AgentList");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          // formikRef.current.resetForm();
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    }
  };

  if (window.sessionStorage.getItem("user_role_type") === "agent" && !Edit) {
    if (!RoleTypeAgent) {
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <CircularProgress color="inherit" />
        </div>
      );
    }
  }

  if (!prefieldData && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Settings
            </Typography>
            <Typography
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
            >
              Create Agent-User
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className="d-flex justify-content-center w-100">
            {!Edit ? (
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                //   padding={"0px"}
                // onClick={handleClick}
                onClick={() =>
                  handleFormSubmit(formikRef.current?.values, "reset")
                }
              >
                Add Another Agent
              </CustomButton>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
      <div className="container">
        <Formik
          innerRef={formikRef}
          initialValues={{
            roles: Edit ? prefieldData?.roles._id : "",
            organization: Edit
              ? prefieldData?.organization?._id
              : RoleTypeAgent
              ? RoleBasedOnRoleType === "admin" ||
                RoleBasedOnRoleType === "application_supervisor"
                ? RoleTypeAgent?.organization?._id
                : ""
              : "",
            firstName: Edit ? prefieldData?.firstName : "",
            email: Edit ? prefieldData?.email : "",
            phone: Edit ? prefieldData?.phone : "",
            branch: "",
          }}
          validationSchema={agentvalidationSchema}
          onSubmit={async (values) => {
            handleFormSubmit(values, "submit");
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form className="" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* grid for right basic content */}
                <Grid item lg={12}>
                  <Typography
                    align="center"
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    {!Edit ? "Add New Agent-User" : "Edit New Agent-User"}
                  </Typography>
                  <Grid container spacing={2} className="mt-4">
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Name of Agent-User *
                          </Typography>
                          <OutlinedInputField
                            value={values.firstName}
                            name={"firstName"}
                            onChange={handleChange}
                            placeholder="Enter name"
                            fullWidth={true}
                            onBlur={handleBlur}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.firstName}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="mt-2">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Name of the organization *
                          </Typography>
                          <CustomSelect
                            value={Organizationlist ? values.organization : ""}
                            name={"organization"}
                            disabled={
                              RoleTypeAgent
                                ? RoleBasedOnRoleType === "admin" ||
                                  RoleBasedOnRoleType ===
                                    "application_supervisor"
                                  ? true
                                  : false
                                : false
                            }
                            multiple={false}
                            onBlur={handleBlur}
                            option={Organizationlist}
                            onChange={(e) => {
                              setFieldValue("organization", e.target.value);
                              setOrganization(e.target.value);
                            }}
                            fullWidth={true}
                            label={"Enter name of organization"}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.organization}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="mt-3">
                          <CustomSelect
                            value={Rolelist ? values?.roles : ""}
                            name={"roles"}
                            multiple={false}
                            option={Rolelist}
                            onChange={(e) => {
                              //(e);
                              setFieldValue("roles", e.target.value);
                            }}
                            label={"Role in organization *"}
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.roles}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="mt-3">
                          {/* AutoComplete with branch suggestions */}
                          <AutoComplete
                            options={branches?.map((branch) => ({
                              value: branch,
                            }))}
                            // disabled={!}
                            style={{ width: "100%", height: "50px" }}
                            size="large"
                            name="branch"
                            onBlur={handleBlur}
                            value={inputValue}
                            onSelect={(e) => {
                              // //(branchData?.filter((id)=>id.branchName===e)?.[0]?._id)
                              setFieldValue(
                                "branch",
                                branchData?.filter(
                                  (id) => id.branchName === e
                                )?.[0]?._id
                              );
                            }}
                            initialValues={
                              Edit && prefieldData?.branch?.branchName
                            }
                            onChange={handleInputChange}
                            placeholder="Select or Add a Branch *"
                            filterOption={(inputValue, option) =>
                              option.value
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                          >
                            <Input
                              style={{
                                height: 50,
                                border: "1px solid rgb(196,196,196)",
                              }}
                            />
                          </AutoComplete>

                          <Typography
                            align="left"
                            className="px-3"
                            color={ThemeColor.RED}
                            fontSize={fontSize.h4.medium}
                            fontFamily={FontFamily.Lato}
                            fontWeight={fontWeight.W500}
                          >
                            {errors.branch}
                          </Typography>

                          {/* Show Add button if branch doesn't exist */}
                          {showAddButton && (
                            <Row justify="start">
                              <Col>
                                <Button
                                  type="primary"
                                  icon={<PlusOutlined />}
                                  onClick={handleAddBranch}
                                >
                                  Add New Branch
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Email *
                          </Typography>
                          <OutlinedInputField
                            disable={Edit ? true : false}
                            value={values.email}
                            onChange={handleChange}
                            name={"email"}
                            placeholder="Enter Email"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.email}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Phone
                          </Typography>
                          <OutlinedInputField
                            value={values.phone}
                            onChange={handleChange}
                            name={"phone"}
                            placeholder="Enter Phone"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.phone}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                }}
                className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              >
                {" "}
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  loading={formLoading}
                >
                  {!Edit ? "Save Agent" : "Save Changes"}
                </CustomButton>
                <Box
                  className="px-2"
                  sx={{
                    paddingTop: {
                      lg: "0px",
                      md: "0px",
                      sm: "5px",
                      xs: "5px",
                    },
                  }}
                >
                  {Edit && (
                    <CustomButton
                      variant="contained"
                      // type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                      loading={formLoading}
                      //   padding={"0px"}
                      onClick={() => {
                        // setFieldValue('birthday', null);
                        // if (childRef.current) {
                        //   childRef.current.someFunction();
                        // }
                        // setFieldValue("documents", []);
                        // //(values)
                        setInputValue(prefieldData?.branch?.branchName);
                        resetForm();
                      }}
                    >
                      Cancel Changes
                    </CustomButton>
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function AddNewAgent() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<AddNewAgentPage />} />
    </div>
  );
}

export default AddNewAgent;
