import {
  GetMailTemplateListConst,
  GetScheduleSendMailUsing365ListConst,
  GetSentMailListConst,
} from "../constant/EmailConstant";

const initTheme = {
  email: [],
  sentemail: [],
  emailTemplate: [],
};

const EmailReducer = (state = initTheme, action) => {
  //.log(state)
  //(state.email, "line 9");

  switch (action.type) {
    case GetScheduleSendMailUsing365ListConst:
      const newEmails = action.payload;

      const updatedEmailList = [...state.email];

      if (newEmails.success !== false) {
        newEmails?.forEach((email) => {
          // Check if the email already exists in the updatedEmailList
          if (
            !updatedEmailList.some(
              (existingEmail) => existingEmail.messageId === email.messageId
            )
          ) {
            updatedEmailList.push(email);
          }
        });
      }

      return { ...state, email: updatedEmailList };
    case GetSentMailListConst:
      return { ...state, sentemail: action.payload };

    case GetMailTemplateListConst:
      return { ...state, emailTemplate: action.payload };

    default:
      return state;
  }
};

export default EmailReducer;
