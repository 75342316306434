import { Box, FormGroup, Grid, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import moment from "moment";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import LeftSideArrow from "../../Icon/LeftSideArrow";
import { useEffect, useState } from "react";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { GetStudentReminderByIdAction } from "../../Redux/action/ApplicationAction";
import { DeleteStudentReminderService } from "../../Redux/service/Application";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const StudentAllReminderModal = ({
  AllReminderModal,
  handleClose,
  stdId,
  Studentlist,
  handleCloseReminderModal,
}) => {
  const dispatch = useDispatch();
  const [ReminderByIdlist, setReminderByIdlist] = useState([]);
  const [reminderId, setReminderId] = useState();
  const [confirmationopen, setConfirmationOpen] = useState(false);

  const ReminderByIdlistState = useSelector(
    (state) => state.application?.studentreminder?.data?.reminders
  );
  //(ReminderByIdlistState);
  useEffect(() => {
    setReminderByIdlist(ReminderByIdlistState);
  }, [ReminderByIdlistState]);

  return (
    <>
      {" "}
      <Modal
        open={AllReminderModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between">
            <div>
              <Typography
                align="right"
                onClick={() => {
                  handleCloseReminderModal();
                }}
              >
                <LeftSideArrow />
              </Typography>
            </div>
            <div>
              <Typography align="right" onClick={() => handleClose()}>
                <Crossicon width={"20"} height={"20"} />
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h3.large}
              color={ThemeColor.BLACK}
              align="center"
            >
              All Reminder for "
              {
                Studentlist?.filter((data) => data.id === stdId)?.[0]
                  ?.studentFirstName
              }
              "
            </Typography>
          </div>

          <Box sx={{ height: "90%", overflowY: "scroll" }}>
            {ReminderByIdlist?.map((data) => {
              return (
                <>
                  <div id="receiver" className="mt-3">
                    <Box className="d-flex justify-content-between">
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W400}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.RED}
                          sx={{ textDecoration: "underline" }}
                          align="left"
                        >
                          {/* {data.sender.firstName} &nbsp;
                          {data.sender.lastName} */}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          fontFamily={FontFamily.Poppins}
                          fontWeight={fontWeight.W700}
                          fontSize={fontSize.h4.small}
                          color={ThemeColor.BLACK}
                          align="left"
                        >
                          {moment.unix(data.reminderDateTime).format("llll")}

                          {/* {data.dateTime} */}
                        </Typography>
                      </div>
                    </Box>

                    <Typography
                      className="mt-3"
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W400}
                      fontSize={fontSize.h4.medium}
                      color={ThemeColor.BLACK}
                      align="left"
                    >
                      {data.text}
                    </Typography>

                    <Typography
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W400}
                      fontSize={fontSize.h4.small}
                      color={ThemeColor.RED}
                      sx={{ textDecoration: "underline" }}
                      align="right"
                      onClick={() => {
                        setReminderId(data._id);
                        setConfirmationOpen(true);
                      }}
                    >
                      Delete
                    </Typography>
                  </div>
                </>
              );
            })}
          </Box>
        </Box>
      </Modal>
      <ConfirmationModal
        handleConfirmationClose={() => {
          setConfirmationOpen(false);
        }}
        deletefunction={async () => {
          try {
            const res = await DeleteStudentReminderService({ reminderId });

            if (res.status === 200) {
              dispatch(GetStudentReminderByIdAction(stdId)); // Dispatch the action if success
              return res; // Return the result so the child can access it
            } else {
              return Promise.reject("Unexpected response status"); // Handle unexpected status codes
            }
          } catch (e) {
            dispatch(
              showSnackbar(
                e.response?.data?.message || "Error occurred",
                "error"
              )
            );
            return e; // Return the error to the child if needed
          }
        }}
        confirmationopen={confirmationopen}
        message={"All deleted reminder is never been retrievable"}
      />
    </>
  );
};
