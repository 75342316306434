import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Button,
  Box,
  Typography,
  Divider,
  ListItemText,
  ListItem,
  List,
  ListItemAvatar,
  Badge,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Uparrow from "../../Icon/Uparrow";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { fontSize } from "../theme/fontSize";
import { FontFamily } from "../theme/fontFamily";
import { fontWeight } from "../theme/fontWeight";
import { ThemeColor } from "../theme/color";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import {
  GetAllNotification,
  GetUnseenNotificationCount,
} from "../../Redux/action/NotificationAction";
import { useDispatch, useSelector } from "react-redux";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { MarkNotificationSeenAPI } from "../../Redux/service/NotificationService";
import "./profile.css";
// import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [AllNotification, setAllNotification] = useState([]);
  const [UnseenNotification, setUnseenNotification] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationanchor, setNotificationanchor] = useState(false);

  useEffect(() => {
    dispatch(GetUnseenNotificationCount());
  }, []);

  const UnseenNotificationlistState = useSelector(
    (state) =>
      state.notification?.GetUnseenNotificationCount?.data?.data
        ?.totalUnseenCount
  );

  console.log(UnseenNotificationlistState);

  useEffect(() => {
    setUnseenNotification(UnseenNotificationlistState);
  }, [UnseenNotificationlistState]);

  const NotificationlistState = useSelector(
    (state) => state.notification?.allNotification?.data?.data
  );

  useEffect(() => {
    setAllNotification(NotificationlistState);
  }, [NotificationlistState]);

  useEffect(() => {
    dispatch(GetAllNotification());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    sessionStorage.removeItem("user_token");
    navigate("/");
    navigate(0);
    //("Logout clicked");
    handleClose();
  };

  const handleMarkNotificationSeen = async (id) => {
    await MarkNotificationSeenAPI(id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          dispatch(GetUnseenNotificationCount());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ height: 45 }}>
      <IconButton
        onClick={handleClick}
        aria-controls="profile-menu"
        aria-haspopup="true"
        className="d-flex align-items-center h-100 justify-content-center"
      >
        <div className="px-1">
          {" "}
          <Uparrow />
        </div>
        <div className="px-1">
          <Typography
            fontSize={fontSize.h3.small}
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W500}
            color={ThemeColor.WHITE}
          >
            {window.sessionStorage.getItem("user_name")}
          </Typography>
        </div>

        <AccountCircleIcon fontSize="large" sx={{ fill: "white" }} />
        {UnseenNotification && UnseenNotification !== 0 ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setNotificationanchor(!notificationanchor);
            }}
          >
            <Badge badgeContent={UnseenNotification} color="error">
              <CircleNotificationsIcon
                fontSize="large"
                sx={{ fill: "white" }}
              />
            </Badge>
          </IconButton>
        ) : (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setNotificationanchor(!notificationanchor);
            }}
          >
            <CircleNotificationsIcon fontSize="large" sx={{ fill: "white" }} />
          </IconButton>
        )}
      </IconButton>
      {/* <Button
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<AccountCircleIcon fontSize="large" sx={{ fill: "white" }} />}
        startIcon={
          <div className="">
            <Uparrow />
          </div>
        }
      ></Button> */}
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      {notificationanchor && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            right: "2.8%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "5px",
            height: "400px",
            width: "300px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            padding: 5,
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {AllNotification?.length > 0 &&
              AllNotification.slice(0, 5).map((data) => (
                <>
                  <ListItem
                    onClick={() => {
                      setNotificationanchor(false);
                      handleMarkNotificationSeen(data._id);
                      navigate("/CAE/AllNotification");
                    }}
                    alignItems="flex-start"
                    className={
                      data?.isView && !data?.isView
                        ? `background-grey`
                        : `background-white`
                    }
                    sx={{
                      backgroundColor: `${
                        data?.isView === false ? "#D3D3D3" : ThemeColor.WHITE
                      }`,
                    }}

                    // secondaryAction={
                    //   <IconButton aria-label="comment">
                    //     <PersonAddIcon />
                    //   </IconButton>
                    // }
                  >
                    {/* <ListItemAvatar>
                      <NotificationsActiveIcon />
                    </ListItemAvatar> */}
                    <ListItemText
                      primary={data.title}
                      secondary={
                        <div style={{ display: "flex",justifyContent:"space-between" }}>
                          <div>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: "text.primary", display: "inline" }}
                            >
                              Ali Connors
                            </Typography>{" "}
                            {`— ${data.title}`}
                          </div>

                          {data?.isView === false && (
                            <div
                              className=""
                              style={{
                                backgroundColor: ThemeColor.RED,
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          )}
                        </div>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
          </List>
          <Typography
            onClick={() => {
              setNotificationanchor(false);
              navigate("/CAE/AllNotification");
            }}
            align="center"
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              padding: "10px",
              color: ThemeColor.LIGHTPURPLE,
              border: "none",
              cursor: "pointer",
            }}
          >
            View All
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default ProfileDropdown;
