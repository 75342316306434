import { Email } from "@mui/icons-material";
import axios from "axios";
import { basicurl } from "../ServiceUrl/url";

// const basicurl = basicurl;

const token = window.sessionStorage.getItem("user_token");

export const GetEmailIdService = async (data) => {
  let res = await axios.get(
    basicurl + `/api/mail/fetch-emailId-to-send-mail?search=${data}`,
    {
      headers: {
        "Content-Type": "text/html",
        "Content-Encoding": "gzip,deflate",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetMicrosoftAuthCodeService = async () => {
  //(window.sessionStorage.getItem("user_token"));
  let res = await axios.get(basicurl + `/api/office365/getAuthorizationCode`, {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("user_token")}`,
    },
  });
  return res;
};

export const GetMicrosoftAccessTokenCodeService = async (auth) => {
  let res = await axios.get(
    basicurl + `/api/office365/getAccessToken?code=${auth}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetSendMailUsing365Service = async (data) => {
  //(data, "inside api func");
  let res = await axios.post(basicurl + `/api/mail/sendMailUsing365`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });
  return res;
};
export const GetScheduleSendMailUsing365Service = async (data) => {
  let res = await axios.post(basicurl + `/api/mail/scheduleEmail`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetScheduleSendMailUsing365ListService = async (data) => {
  //("data for pagination in service", data);
  let res = await axios.get(
    basicurl +
      `/api/mail/fetchInboxMailMessage?accessToken=${window.sessionStorage.getItem(
        "ms_auth"
      )}&limit${data?.limit ? data.limit : ""}&page=${
        data?.page ? data.page : ""
      }&senderEmail=${data?.email ? data.email : ""}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetSentMailListService = async () => {
  let res = await axios.get(
    basicurl +
      `/api/mail/getSentMailList?accessToken=${window.sessionStorage.getItem(
        "ms_auth"
      )}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetMailTemplateListService = async () => {
  let res = await axios.get(
    basicurl + `/api/template/get-all-email-templates`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const AddEmailTemplateService = async (data) => {
  let res = await axios.post(basicurl + `/api/template/add-email-template`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const EditEmailTemplateService = async (id,data) => {
  let res = await axios.put(basicurl + `/api/template/update-email-template/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};


