import React from "react";

function Users() {
  return (
    <div>
      <svg
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5684 6.5385C16.6042 5.20307 16.3613 3.28081 15.0259 2.24502C13.6905 1.20922 11.7682 1.45212 10.7324 2.78755C9.69661 4.12299 9.93951 6.04524 11.2749 7.08104C12.6104 8.11684 14.5326 7.87393 15.5684 6.5385Z"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.23975 15.86C7.23975 12.6 9.88975 9.94995 13.1497 9.94995C16.4097 9.94995 19.0597 12.6 19.0597 15.86"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.0138 6.59905C7.57212 5.55734 7.18025 4.26025 6.13854 3.70192C5.09682 3.1436 3.79973 3.53547 3.2414 4.57718C2.68308 5.6189 3.07495 6.91599 4.11666 7.47431C5.15838 8.03264 6.45547 7.64077 7.0138 6.59905Z"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 13.41C1 11.13 2.85 9.28003 5.13 9.28003C6.82 9.28003 8.27 10.29 8.91 11.75"
          stroke="white"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Users;
