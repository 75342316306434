import { Box, FormGroup, Grid, Modal, Typography } from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetFilteredUserAction,
  GetUserChatAction,
} from "../../Redux/action/SettingAction";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import CustomSelect from "../select field/OutlinedSelectField";
import {
  AddGroupChatService,
  AddPersonChatService,
} from "../../Redux/service/SettingService";
import CustomizedFilterAutoComplete from "../autocomplete/FilterAutocomplete";
import MultiSelectAutocomplete from "../autocomplete/FilterAutocomplete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

export const CreateGroupChatModal = ({
  AddGroupChat,
  handleClose,
  stdId,
  Organizationlist,
  Rolelist,
}) => {
  const dispatch = useDispatch();

  const [Chatlist, setChatlist] = useState([]);

  const [formLoading, setFormLoading] = useState(false);

  const [filteredUser, setFilteredUser] = useState([]);

  const ChatlistState = useSelector(
    (state) => state.setting?.allchat?.data?.data
  );

  const FilteredUserlistState = useSelector(
    (state) => state.setting?.filteredUser?.data?.users
  );

  //console.log("filtered user", FilteredUserlistState);

  useEffect(() => {
    const updatedData = FilteredUserlistState?.map(
      ({ firstName, ...rest }) => ({
        title: firstName,
        ...rest,
      })
    )?.filter((data) => data._id !== window.sessionStorage.getItem("user_id"));
    setFilteredUser(updatedData);
  }, [FilteredUserlistState]);

  useEffect(() => {
    setChatlist(ChatlistState);
  }, [ChatlistState]);

  useEffect(() => {
    dispatch(GetFilteredUserAction());
  }, []);

  const validationSchemaGroup = Yup.object({
    groupName: Yup.string().required("Text is required"),
    selectedOptions: Yup.array()
      .required("Please select at least one option")
      .min(1, "Minimum one participant is required to create a group."),
    // participants: Yup.array()
    //   .min(2, "Minimum two participant is required to create a group.")
    //   .required("Participants is required"),
  });

  return (
    <Modal
      open={AddGroupChat}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography align="right" onClick={() => handleClose()}>
          <Crossicon width={"20"} height={"20"} />
        </Typography>
        <Typography
          fontFamily={FontFamily.Poppins}
          fontWeight={fontWeight.W600}
          fontSize={fontSize.h3.large}
          color={ThemeColor.BLACK}
          align="center"
        >
          Create Chat
        </Typography>
        <Formik
          initialValues={{
            selectedOptions: [],
          }}
          // validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            setFormLoading(true);
            let participants = [];
            if (values.selectedOptions) {
              values.selectedOptions?.map((data) => {
                participants.push(data._id);
              });
            }

            let payload = {
              groupName: values.groupName,
              participants: participants,
            };

            await AddGroupChatService(payload)
              .then((res) => {
                if (res.status === 200) {
                  // //(res);
                  dispatch(GetUserChatAction());
                  setFormLoading(false);
                  handleClose();
                  dispatch(showSnackbar(res.data.message, "success"));

                  resetForm();
                }
              })
              .catch((e) => {
                setFormLoading(false);
                dispatch(showSnackbar(e.response.data.message, "error"));
              });
          }}
          validationSchema={validationSchemaGroup}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="d-flex justify-content-center">
              <Box
                className="addcatalog-container"
                sx={{
                  width: { sm: "100%", xs: "100%", md: "100%", lg: "100%" },
                }}
              >
                <FormGroup style={{}} className="">
                  <div className="">
                    <Typography
                      className="mb-2"
                      fontFamily={FontFamily.Lato}
                      fontSize={fontSize.h3.medium}
                      fontWeight={fontWeight.W400}
                    >
                      Enter chat Name
                    </Typography>
                    <OutlinedInputField
                      value={values.groupName}
                      name={"groupName"}
                      onChange={handleChange}
                      placeholder="Enter chat name"
                      fullWidth={true}
                    />
                    {errors.groupName && (
                      <div style={{ color: "red" }}>{errors.groupName}</div>
                    )}
                  </div>
                </FormGroup>

                <FormGroup className="mt-3">
                  <MultiSelectAutocomplete
                    options={filteredUser}
                    label="Select user..."
                    value={values.selectedOptions}
                    onChange={(newValue) => {
                      //console.log(newValue);
                      // dispatch(GetFilteredUserAction());
                      setFieldValue("selectedOptions", newValue);
                    }}
                  />
                  {errors.selectedOptions && (
                    <div className="mt-2" style={{ color: "red" }}>
                      {errors.selectedOptions}
                    </div>
                  )}
                </FormGroup>

                <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-3">
                  <div className="px-3">
                    <CustomButton
                      variant="contained"
                      type="submit"
                      backgroundColor={ThemeColor.PURPLE}
                      color={ThemeColor.WHITE}
                    >
                      Submit
                    </CustomButton>
                  </div>
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
