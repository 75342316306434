import React, { useCallback, useEffect, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import dayjs from "dayjs";
import CustomButton from "../../component/button/button";
import CustomDataTable from "../../component/datatable/DataTable";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import {
  GetOrganizationAction,
  GetProgramAction,
  GetRoleAction,
  GetUniversityAction,
  GetUserAction,
  GetUserChatAction,
  GetUserChatByIdAction,
} from "../../Redux/action/SettingAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Message from "../../Icon/Message";
import Msg from "../../Icon/Msg";
import { type } from "@testing-library/user-event/dist/type";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ConfirmationModal from "../../component/Modal/ConfirmationModal/ConfirmationModal";
import { EditOrgBlockedStatusService } from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import {
  handleExport,
  handleExportOrg,
} from "../ExportFunction/ExportFunction";
import FilterComponent from "../../component/filterForTable/FilterComponent";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};
const UOrganizationlistPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [Organizationlist, setOrganizationlist] = useState([]);

  const [Chatlist, setChatlist] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const [ChatByIdlist, setChatByIdlist] = useState([]);

  const [chatWindow, setChatWindow] = useState(false);

  const [chatId, setChatId] = useState("");

  const [Userlist, setUserlist] = useState([]);

  const [chatWithOwner, setChatWithOwner] = useState(false);

  const [OrganizationlistStateData, setOrganizationlistStateData] = useState(
    []
  );
  const [isToggled, setIsToggled] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [confirmationopen, setConfirmationOpen] = useState(false);
  const [changeBlockStatusOfOrg, setChangeBlockStatusOfOrg] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [data, setData] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  const handleVisibleColumnsChange = useCallback((visibleColumns) => {
    // Handle the change in visible columns
    setVisibleColumns(visibleColumns);
  }, []);

  useEffect(() => {
    dispatch(GetUniversityAction());

    dispatch(GetRoleAction());
    dispatch(GetProgramAction());
  }, []);

  const handleOpen = () => {
    // setOpen(true);
    navigate("/CAE/chatlist");
  };
  const handleClose = () => {
    setChatWithOwner(false);
    setOpen(false);
  };

  useEffect(() => {
    setTable_load(true);
    dispatch(GetOrganizationAction());
  }, []);

  useEffect(() => {
    dispatch(GetUserChatAction());
  }, []);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const handleEditActionClick = (event) => {
          event.stopPropagation();
          navigate(`/CAE/EditOrganization/${params.row.id}`);
        };
        return (
          <>
            <Tooltip title="Edit Organization">
              {" "}
              <IconButton onClick={handleEditActionClick}>
                <ModeEditIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "organizationName",
      headerName: "Organization Name",
      width: 200,
    },
    {
      field: "owners.firstName",
      headerName: "Owner Name",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex" style={{ textTransform: "lowercase" }}>
            {params.row.owners?.map((owner, index) => {
              //(params.row.owners?.length);
              return (
                <div>
                  {owner.firstName}{" "}
                  {index + 1 === params.row.owners?.length ? "" : ", "}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "owners.phone",
      headerName: "Owner Phone",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="d-flex" style={{ textTransform: "lowercase" }}>
            {params.row.owners?.map((owner, index) => {
              //(params.row.owners?.length);
              return (
                <div>
                  {owner.phone}{" "}
                  {index + 1 === params.row.owners?.length ? "" : ", "}
                </div>
              );
            })}
          </div>
        );
      },
    },

    {
      field: "additionalOrganizationName",
      headerName: "Organization Additional Name",
      width: 250,
    },
    // {
    //   field: "id",
    //   headerName: "Organiztion Id",
    //   width: 250,
    // },
    {
      field: "address",
      headerName: "Organiztion Address",
      width: 250,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            {dayjs(params.row.startContractDate).format("YYYY-MM-DD HH:MM")}
          </div>
        );
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            {dayjs(params.row.endContractDate).format("YYYY-MM-DD HH:MM")}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 150,
    },
    {
      field: "blocked",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const handleBlockingStatusChange = async (e) => {
          console.log(e.target.checked);
          setOrganizationlist((prev) =>
            prev.map((data) =>
              data.id === params.row.id
                ? { ...data, isBlocked: data.isBlocked === "Y" ? "N" : "Y" }
                : data
            )
          );
          setConfirmationOpen(true);
          setChangeBlockStatusOfOrg({
            id: params.row.id,
            isBlocked: e.target.checked ? "Y" : "N",
          });
        };
        return (
          <>
            {window.sessionStorage.getItem("user_role_type") === "admin" && (
              <Tooltip title="change status of block to unblock or vice-versa">
                {" "}
                <Box display="flex" alignItems="center" className="px-2">
                  <Typography>Blocked</Typography>
                  <Switch
                    sx={{
                      "& .MuiSwitch-track": {
                        // backgroundColor: isToggled ? "white" : "#ccc",
                        transition: "background-color 0.3s",
                        borderRadius: 20,
                      },
                      "& .MuiSwitch-thumb": {
                        width: 24,
                        height: 24,
                        backgroundColor:
                          params.row.isBlocked === "Y"
                            ? ThemeColor.PURPLE
                            : ThemeColor.BLACK,
                        transition: "transform 0.3s ease",
                        transform:
                          params.row.isBlocked === "Y"
                            ? "translateX(2px)"
                            : "translateX(0px)",
                      },
                      "& .MuiSwitch-switchBase": {
                        padding: 1,
                        transition: "transform 0.3s",
                        "&.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: ThemeColor.LIGHTPURPLE,
                        },
                      },
                    }}
                    checked={params.row.isBlocked === "Y"}
                    onChange={(e) => handleBlockingStatusChange(e)}
                    color="primary"
                  />
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const OrganizationlistState = useSelector(
    (state) => state.setting?.organization?.data
  );
  const ChatlistState = useSelector(
    (state) => state.setting?.allchat?.data?.data
  );
  useEffect(() => {
    // setOrganizationlistStateData(OrganizationlistState?.organizations);
    if (!isToggled) {
      setOrganizationlist(
        OrganizationlistState?.organizations?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    } else {
      setOrganizationlist(
        OrganizationlistState?.BloackedOrganisations?.map(
          ({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          })
        )
      );
    }

    if (OrganizationlistState != undefined) setTable_load(false);
  }, [OrganizationlistState]);

  useEffect(() => {
    setChatlist(ChatlistState);
  }, [ChatlistState]);

  const getChat = (id) => {
    //(id);
    setChatId(id);
    setChatWindow(true);
    dispatch(GetUserChatByIdAction(id));
  };
  const ChatByIdlistState = useSelector(
    (state) => state.setting?.chat?.data?.data
  );

  useEffect(() => {
    setChatByIdlist(ChatByIdlistState);
  }, [ChatByIdlistState]);

  function generate(array) {
    return array?.map((value) => {
      return (
        <ListItem>
          <ListItemButton
            onClick={() => {
              getChat(value._id);
            }}
          >
            <ListItemIcon>
              <Message />
            </ListItemIcon>
            <ListItemText primary={value.title} />
          </ListItemButton>
        </ListItem>
      );
    });
  }

  const UserlistState = useSelector(
    (state) => state.setting?.user?.data?.activeUsers
  );

  //("user list", UserlistState);

  useEffect(() => {
    setTable_load(true);
    dispatch(GetUserAction());
  }, []);

  useEffect(() => {
    const updatedData = UserlistState?.map(({ firstName, ...rest }) => ({
      title: firstName,
      ...rest,
    }));
    setUserlist(updatedData);
    setTable_load(false);
  }, [UserlistState]);

  // Handle toggle change
  const handleStatusChange = async () => {
    const newToggleState = !isToggled;
    if (newToggleState) {
      setOrganizationlist(
        OrganizationlistState?.BloackedOrganisations?.map(
          ({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          })
        )
      );
    } else {
      setOrganizationlist(
        OrganizationlistState?.organizations?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    }
    setIsToggled(newToggleState);
  };

  const fields = [
    {
      label: "Organization",
      value: "organizationName",
      type: "text",
      option: OrganizationlistStateData?.map(
        ({ organizationName, ...rest }) => ({
          title: organizationName,
          ...rest,
        })
      ),
    },
    {
      label: "Additional Organization Name",
      value: "additionalOrganizationName",
      type: "text",
      option: OrganizationlistStateData?.map(
        ({ additionalOrganizationName, ...rest }) => ({
          title: additionalOrganizationName,
          ...rest,
        })
      ),
    },
    {
      label: "Is Active",
      value: "isActive",
      type: "select",
      option: [
        { _id: "Y", title: "Yes" },
        { _id: "N", title: "No" },
      ],
    },
  ];
  const fetchData = async (filters) => {
    let string = filters.map((data) => `${data.field}=${data.value}`);
    try {
      let response = await axios.get(
        basicurl +
          `/api/organization/get-all-organizations?${string.join("&")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const result = await response.json();
      console.log(response);
      if (isToggled) {
        setOrganizationlist(
          response.data?.BloackedOrganisations?.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          }))
        );
      } else {
        setOrganizationlist(
          response.data?.organizations?.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleApplyFilters = (filters) => {
    fetchData(filters); // Call the API with filters
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Organization List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },

              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              onClick={() => setFilterOpen(true)}
            >
              Filter
            </OutlineCustomButton>
            <div className="px-1 ps-2">
              <OutlineCustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                onClick={() =>
                  handleExportOrg(
                    selectionModel,
                    "Organization",
                    Organizationlist,
                    "isActive",
                    visibleColumns,
                    columns
                  )
                }
              >
                Export
              </OutlineCustomButton>
            </div>
            <Box
              display="flex"
              sx={{ border: `2px solid ${ThemeColor.GREY}`, borderRadius: 25 }}
              alignItems="center"
              className="px-2"
            >
              <Typography>Blocked</Typography>
              <Switch
                sx={{
                  "& .MuiSwitch-track": {
                    // backgroundColor: isToggled ? "white" : "#ccc",
                    transition: "background-color 0.3s",
                    borderRadius: 20,
                  },
                  "& .MuiSwitch-thumb": {
                    width: 24,
                    height: 24,
                    backgroundColor: isToggled
                      ? ThemeColor.PURPLE
                      : ThemeColor.BLACK,
                    transition: "transform 0.3s ease",
                    transform: isToggled
                      ? "translateX(2px)"
                      : "translateX(0px)",
                  },
                  "& .MuiSwitch-switchBase": {
                    padding: 1,
                    transition: "transform 0.3s",
                    "&.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: ThemeColor.LIGHTPURPLE,
                    },
                  },
                }}
                checked={isToggled ? true : false}
                onChange={handleStatusChange}
                color="primary"
              />
            </Box>
            <div className="px-2">
              {(window.sessionStorage.getItem("user_role_type") === "admin" ||
                !window.sessionStorage.getItem("user_role_type") ===
                  "user") && (
                <CustomButton
                  variant="contained"
                  type="submit"
                  backgroundColor={ThemeColor.PURPLE}
                  color={ThemeColor.WHITE}
                  onClick={() => navigate("/CAE/CreateOrganization")}
                >
                  Add <span>Organization</span>
                </CustomButton>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
      <div className="table-container mt-4" style={{ overflowX: "scroll" }}>
        <CustomDataTable
          row={Organizationlist ? Organizationlist : []}
          columns={columns}
          loading={table_load}
          onSelectionChange={(newSelection) => {
            // console.log(newSelection)
            setSelectionModel(newSelection);
          }}
          getRowClassName={(params) =>
            params.row.isActive === "Y" ? "row-white" : "row-dull"
          }
          onVisibleColumnsChange={handleVisibleColumnsChange}
        />
      </div>
      <FilterComponent
        open={isFilterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={handleApplyFilters}
        fields={fields}
      />
      <ConfirmationModal
        msg="Updated"
        btnName="Update"
        handleConfirmationClose={() => {
          setOrganizationlist((prev) =>
            prev.map((data) =>
              data.id === changeBlockStatusOfOrg.id
                ? {
                    ...data,
                    isBlocked:
                      changeBlockStatusOfOrg.isBlocked === "Y" ? "N" : "Y",
                  }
                : data
            )
          );
          setConfirmationOpen(false);
        }}
        deletefunction={async () => {
          try {
            const res = await EditOrgBlockedStatusService(
              changeBlockStatusOfOrg
            );

            if (res.status === 200) {
              dispatch(GetOrganizationAction());
              setIsToggled(!isToggled);
              // handleStatusChange(); // Dispatch the action if success
              return res; // Return the result so the child can access it
            } else {
              return Promise.reject("Unexpected response status"); // Handle unexpected status codes
            }
          } catch (e) {
            dispatch(
              showSnackbar(
                e.response?.data?.message || "Error occurred",
                "error"
              )
            );
            return e;
          }
        }}
        confirmationopen={confirmationopen}
        message={"Want to change the blocking status?"}
      />
    </>
  );
};

function UOrganizationlist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<UOrganizationlistPage />} />
    </div>
  );
}

export default UOrganizationlist;
