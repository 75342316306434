import React, { useCallback, useEffect, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import dayjs from "dayjs";
import CustomButton from "../../component/button/button";
import CustomDataTable from "../../component/datatable/DataTable";
import OutlineCustomButton from "../../component/button/outlineButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetDegreeAction,
  GetStudentAction,
  GetStudentChatByIdAction,
  GetStudentNotesByIdAction,
  GetStudentReminderByIdAction,
} from "../../Redux/action/ApplicationAction";
import StudentChat from "../../Icon/StudentChat";
import Note from "../../Icon/Note";
import Reminder from "../../Icon/Reminder";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  GetAgentAction,
  GetOrganizationAction,
  GetUniversityAction,
} from "../../Redux/action/SettingAction";
import { EmailTwoTone } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "../../component/CopyToClipboard";
import { StudentChatModal } from "../../component/StudentModal/ChatModal.component";
import { StudentReminderModal } from "../../component/StudentModal/ReminderModal.component";
import { StudentNotesModal } from "../../component/StudentModal/NotesModal.component";
import { StudentVisaApproveModal } from "../../component/StudentModal/VisaApproveModal.component";
import FolderIcon from "@mui/icons-material/Folder";
import FilterComponent from "../../component/filterForTable/FilterComponent";
import axios from "axios";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import { StudentTransferModal } from "../../component/StudentModal/StudentTransfer.component";
import { handleExport } from "../ExportFunction/ExportFunction";
import PushPinIcon from "@mui/icons-material/PushPin";
import { StudentPinnedModal } from "../../component/StudentModal/PinnedModal.component";
import MessageIcon from "@mui/icons-material/Message";
import {
  postmarkSeenMessagesAPI,
  postmarkSeenMessagesStdAPI,
} from "../../Redux/service/Application";

const StudentlistPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Studentlist, setStudentlist] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const [chatModal, setChatModal] = useState(false);

  const [NoteModal, setNoteModal] = useState(false);

  const [ReminderModal, setReminderModal] = useState(false);

  const [stdId, setStdId] = useState("");

  const [visibleColumns, setVisibleColumns] = useState([]);

  const [AddPaymentModal, setAddPaymentModal] = useState(false);

  const [selectionModel, setSelectionModel] = useState([]);

  const [Degreelist, setDegreelist] = useState([]);
  const [Organizationlist, setOrganizationlist] = useState([]);
  const [Agentlist, setAgentlist] = useState([]);

  const [Universitylist, setUniversitylist] = useState([]);

  const [data, setData] = useState([]);
  const [isFilterOpen, setFilterOpen] = useState(false);

  const [TransferModal, setTransferModal] = useState(false);

  const [pinnedModal, setPinnedModal] = useState(false);
  const [pinned, setPinned] = useState();
  const [isPinedNotes, setisPinedNotes] = useState();

  const handlePinnedModal = () => {
    setPinnedModal(!pinnedModal);
  };
  const UniversitylistState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );

  const handleVisibleColumnsChange = useCallback((visibleColumns) => {
    console.log("handele visible", visibleColumns);
    // Handle the change in visible columns
    setVisibleColumns(visibleColumns);
  }, []);

  useEffect(() => {
    const updatedData = UniversitylistState?.map(({ name, ...rest }) => ({
      title: name,
      ...rest,
    }));
    setUniversitylist(updatedData);
  }, [UniversitylistState]);

  const DegreelistState = useSelector(
    (state) => state.application?.degree?.data?.degrees
  );

  const OrganizationlistState = useSelector(
    (state) => state.setting?.organization?.data?.organizations
  );

  const AgentlistState = useSelector(
    (state) => state.setting?.agent?.data?.activeAgents
  );
  useEffect(() => {
    dispatch(GetDegreeAction());
    dispatch(GetOrganizationAction());
    dispatch(GetAgentAction());
  }, []);

  useEffect(() => {
    setDegreelist(
      DegreelistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );

    setOrganizationlist(
      OrganizationlistState?.map(({ organizationName, ...rest }) => ({
        title: organizationName,
        ...rest,
      }))
    );

    setAgentlist(
      AgentlistState?.map(({ firstName, ...rest }) => ({
        title: firstName,
        ...rest,
      }))
    );
  }, [DegreelistState, OrganizationlistState, AgentlistState]);

  const columns = [
    {
      field: "pinned",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        const handlePinned = (e) => {
          e.stopPropagation();
          setStdId(params.row.id);
          setPinned(params.row.isPined);
          setisPinedNotes(params.row.isPinedNotes);
          handlePinnedModal();
        };
        return (
          <Box className="d-flex align-items-center h-100">
            {
              <IconButton onClick={handlePinned}>
                <PushPinIcon
                  sx={{
                    fill: `${
                      params.row.isPined ? ThemeColor.RED : ThemeColor.GREY
                    }`,
                    // fill:ThemeColor.LIGHTPURPLE
                  }}
                />
              </IconButton>
            }
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        const handleChatActionClick = async (event) => {
          event.stopPropagation(); // Prevent the row from being selected when clicking the icon
          setStdId(params.row.id);
          setChatModal(true);

          await postmarkSeenMessagesStdAPI(params.row.id)
            .then((res) => {
              if (res.status === 200) {
                dispatch(GetStudentAction());
              }

              return null;
            })
            .catch((err) => {
              console.log(err);
            });
          dispatch(GetStudentChatByIdAction(params.row.id));
        };
        const handleNoteActionClick = (e) => {
          e.stopPropagation();
          //(params.row.id);
          setStdId(params.row.id);
          setNoteModal(true);
          dispatch(GetStudentNotesByIdAction(params.row.id));
        };

        const handleReminderActionClick = (e) => {
          e.stopPropagation();
          setStdId(params.row.id);
          setReminderModal(true);
          dispatch(GetStudentReminderByIdAction(params.row.id));
        };

        const handleEditActionClick = (event) => {
          event.stopPropagation();
          setAddPaymentModal(true);
          setStdId(params.row.id);
        };

        const handleEmailActionClick = (event) => {
          event.stopPropagation();
          navigate(`/CAE/StudentMailTemplate/${params.row.agent.email}`);
        };

        const handleEditApplicantActionClick = (event) => {
          event.stopPropagation();
          navigate(`/CAE/EditApplication/${params.row.id}`);
        };

        const handleFolderActionClick = (event) => {
          event.stopPropagation();
          navigate(`/CAE/FileManager/StdFolder/${params.row.folderId?._id}`);
        };
        return (
          <Box className="d-flex justify-content-center align-items-center">
            <Tooltip title="Chat to student" sx={{ cursor: "cell" }}>
              <IconButton onClick={handleChatActionClick}>
                <Badge badgeContent={params.row.count} color="primary">
                  <MessageIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
                </Badge>
              </IconButton>
            </Tooltip>
            {(window.sessionStorage.getItem("user_role_type") === "user" ||
              window.sessionStorage.getItem("user_role_type") === "admin") && (
              <Tooltip title="Add Notes">
                <IconButton
                  onClick={handleNoteActionClick}
                  sx={{ cursor: "cell" }}
                >
                  <Note />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Add Reminder" followCursor sx={{ cursor: "cell" }}>
              <IconButton onClick={handleReminderActionClick}>
                <Reminder />
              </IconButton>
            </Tooltip>

            <Tooltip title="Approve Visa">
              {" "}
              <IconButton onClick={handleEditActionClick}>
                <PaymentsIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Send Email">
              {" "}
              <IconButton onClick={handleEmailActionClick}>
                <EmailTwoTone sx={{ fill: ThemeColor.LIGHTPURPLE }} />
              </IconButton>
            </Tooltip>

            {window.sessionStorage.getItem("user_role_type") !== "agent" && (
              <Tooltip
                title="Go to Student folder"
                followCursor
                sx={{ cursor: "cell" }}
              >
                <IconButton onClick={handleFolderActionClick}>
                  <FolderIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Edit Student">
              {" "}
              <IconButton onClick={handleEditApplicantActionClick}>
                <ModeEditIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "studentFirstName",
      headerName: "Student Name",
      width: 250,
      renderCell: (params) => {
        return (
          <Box className="d-flex align-items-center h-100">
            <Typography align="" fontSize={fontSize.h3.medium}>
              <div className="text-center" style={{}}>
                {" "}
                {params.row?.studentFirstName +
                  " " +
                  params.row?.studentLastName}
              </div>
              {(window.sessionStorage.getItem("user_role_type") === "admin" ||
                window.sessionStorage.getItem("user_role_type") === "user") && (
                <div style={{ color: ThemeColor.RED }}>
                  {params.row?.isDuplicate ? (
                    <div
                      className="px-4 py-1"
                      style={{
                        backgroundColor: "rgba(221, 32, 37, 0.2)",
                        fontSize: "10px",
                        colo: "red",
                        borderRadius: "15px",
                      }}
                    >
                      Duplicate
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Typography>
          </Box>
        );
      },
    },

    ...(!(
      window.sessionStorage.getItem("user_role_type") === "agent" &&
      window.sessionStorage.getItem("role") === "agent_counsellor"
    )
      ? [
          {
            field: "agent.firstName",
            headerName: "Agent",
            width: 150,
            renderCell: (params) => {
              return (
                <div style={{ textTransform: "lowercase" }}>
                  {params.row?.agent?.firstName}
                </div>
              );
            },
          },
        ]
      : []),

    ...(!(
      window.sessionStorage.getItem("user_role_type") === "agent" &&
      (window.sessionStorage.getItem("role") === "agent_supervisor" ||
        window.sessionStorage.getItem("role") === "agent_counsellor" ||
        window.sessionStorage.getItem("role") === "admin")
    )
      ? [
          {
            field: "organizationId.organizationName",
            headerName: "Organization",
            width: 150,
            renderCell: (params) => {
              return (
                <div>
                  <Tooltip
                    title={params.row.organizationId?.organizationName}
                    arrow
                  >
                    <span>
                      {params.row.organizationId?.organizationName.length > 10
                        ? `${params.row.organizationId?.organizationName.slice(
                            0,
                            15
                          )}...`
                        : params.row.organizationId?.organizationName}
                    </span>
                  </Tooltip>
                </div>
              );
            },
          },
        ]
      : []),

    {
      field: "university.name",
      headerName: "University",
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={params.row.university?.name} arrow>
              <span>
                {params.row.university?.name.length > 5
                  ? `${params.row.university?.name.slice(0, 10)}...`
                  : params.row.university?.name}
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "intake",
      headerName: "Intake",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            {params.row.intake}
            {/* <Tooltip title={params.row.intake} arrow>
              <span>
                {params.row.id.length > 5
                  ? `${params.row.id.slice(0, 10)}...`
                  : params.row.id}
                <IconButton onClick={() => CopyToClipboard(params.row.id)}>
                  <ContentCopyIcon />
                </IconButton>
              </span>
            </Tooltip> */}
          </div>
        );
      },
    },
    {
      field: "universityId",
      headerName: "Student Id",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={params.row.universityId} arrow>
              <span>
                {params.row.universityId.length > 5
                  ? `${params.row.universityId.slice(0, 10)}...`
                  : params.row.universityId}
                {params.row?.universityId && (
                  <IconButton
                    onClick={() => CopyToClipboard(params.row.universityId)}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "visaStatus",
      headerName: "Visa Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ textTransform: "lowercase" }}>
            {params.row?.visaStatus?.split("_").map((data) => {
              let string = data + " ";
              return string;
            })}
          </div>
        );
      },
    },
    {
      field: "commissionStatus",
      headerName: "Commission Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ textTransform: "lowercase" }}>
            {params.row?.commissionStatus?.split("_").map((data) => {
              let string = data + " ";
              return string;
            })}
          </div>
        );
      },
    },

    {
      field: "propgram.program",
      headerName: "Program",
      width: 150,
      renderCell: (params) => {
        return <div>{params.row.program?.program}</div>;
      },
    },
    {
      field: "degreeType.name",
      headerName: "Degree",
      width: 150,
      renderCell: (params) => {
        return <div>{params.row.degreeType?.name}</div>;
      },
    },
    {
      field: "createdOn",
      headerName: "Entry Date",
      width: 150,
      renderCell: (params) => {
        return <div>{dayjs(params.row.createdOn).format("MM-DD-YYYY")}</div>;
      },
    },

    {
      field: "action3",
      headerName: "Add University",
      width: 150,
      renderCell: (params) => {
        const handleEditActionClick = (event) => {
          event.stopPropagation();
          navigate(`/CAE/AddUniversityApplication/${params.row.id}`);
        };
        return (
          <div
            className="d-flex"
            onClick={handleEditActionClick}
            style={{
              textDecoration: "underline",
              color: ThemeColor.LIGHTPURPLE,
              cursor: "grab",
            }}
          >
            Add More University
          </div>
        );
      },
    },
  ];

  const fields = [
    { label: "Agent", value: "agentId", type: "select", option: Agentlist },
    {
      label: "University",
      value: "university",
      type: "select",
      option: Universitylist,
    },
    {
      label: "Degree Type",
      value: "degreeType",
      type: "select",
      option: Degreelist,
    },
    {
      label: "Application Status",
      value: "applicationStatus",
      type: "select",
      option: [
        {
          _id: "application_submitted",
          title: "Application Submitted",
        },
        {
          _id: "application_not_submitted",
          title: "Application Not Submitted",
        },
        {
          _id: "application_incomplete",
          title: "Application Incomplete",
        },
        {
          _id: "decision_awaited",
          title: "Decision Waited",
        },
        { _id: "admitted", title: "Admitted" },
        {
          _id: "admitted_and_finalized",
          title: "Admitted and Finalized",
        },
        {
          _id: "admitted_but_not_finalized",
          title: "Admitted but not finalized",
        },
        { _id: "deferred", title: "Deferred" },
        { _id: "denied", title: "Denied" },
        { _id: "withdrawn", title: "Withdrawn" },
      ],
    },
    {
      label: "Tagging Status",
      value: "taggingStatus",
      type: "select",
      option: [
        { _id: "tagged", title: "Tagged" },
        { _id: "not_tagged", title: "Not Tagged" },
        {
          _id: "awaited",
          title: "Awaited",
        },
      ],
    },
    {
      label: "Visa Status",
      value: "visaStatus",
      type: "select",
      option: [
        {
          _id: "visa_approved",
          title: "Visa Approved",
        },
        {
          _id: "visa_rejected",
          title: "Visa Rejected",
        },
        { _id: "deferred", title: "Deferred" },
        { _id: "awaited", title: "Awaited" },
        {
          _id: "transferred",
          title: "Transferred/Dropped",
        },
        {
          _id: "pending",
          title: "Pending",
        },
      ],
    },
    {
      label: "Organization",
      value: "organizationId",
      type: "select",
      option: Organizationlist,
    },
    { label: "StudentId/UniversityId", value: "universityId", type: "text" },
    { label: "Application Id", value: "referenceId", type: "text" },
    { label: "Intake", value: "intake", type: "text" },

    {
      label: "Commission Status",
      value: "commissionStatus",
      type: "select",
      option: [
        {
          _id: "not_approved",
          title: "Commission Not Approved",
        },
        {
          _id: "approved",
          title: "Commission Approved",
        },
        {
          _id: "pending",
          title: "Commission Pending",
        },
      ],
    },
  ];
  const fetchData = async (filters) => {
    let string = filters.map((data) => `${data.field}=${data.value}`);
    console.log(
      filters,
      `/api/student/get-all-students?${string.join("&")}`,
      "in filter "
    );
    try {
      let response = await axios.get(
        basicurl + `/api/student/get-all-students?${string.join("&")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const result = await response.json();
      console.log(response);
      setData(
        response.data.students?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      ); // Assuming the response structure has a `data` key
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleApplyFilters = (filters) => {
    fetchData(filters); // Call the API with filters
  };

  const StudentlistState = useSelector(
    (state) => state.application?.student?.data?.students
  );
  // console.log(StudentlistState)
  useEffect(() => {
    setTable_load(true);
    dispatch(GetStudentAction());
    dispatch(GetUniversityAction());
  }, []);

  // useEffect(() => {
  //   const updatedData = StudentlistState?.map(({ _id, ...rest }) => ({
  //     id: _id,
  //     ...rest,
  //   }));
  //   setStudentlist(updatedData);
  //   setTable_load(false);
  //   setData(updatedData);
  // }, [StudentlistState]);

  useEffect(() => {
    const fetchChatCounts = async () => {
      const updatedChatList = await Promise.all(
        StudentlistState?.map(async (chat) => {
          try {
            const response = await axios.get(
              `${basicurl}/api/chat-room/getUnseenMessageCount/${chat._id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            console.log(response, "dhdhdhdhdhdh");
            return {
              ...chat,
              id: chat._id,
              count: response.data?.unseenMessageCount,
            }; // Assume the API response has a 'count' field
          } catch (error) {
            // console.error(`Error fetching count for chat ID ${chat.id}`, error);
            return { ...chat, id: chat._id, count: 0 };
          }
        })
      );

      // setChatlist(updatedChatList);
      setStudentlist(updatedChatList);
      setTable_load(false);
      setData(updatedChatList);
    };
    if (StudentlistState && StudentlistState?.length > 0) fetchChatCounts();
  }, [StudentlistState]);

  // handle close of modal
  const handleCloseChatModal = () => setChatModal(!chatModal);
  const handleCloseReminderModal = () => setReminderModal(!ReminderModal);
  const handleCloseNoteModal = () => setNoteModal(!NoteModal);
  const handleCloseTransferModal = () => setTransferModal(!TransferModal);
  const handleCloseVisaApproveModal = () =>
    setAddPaymentModal(!AddPaymentModal);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Student List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },

              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              onClick={() => setFilterOpen(true)}
            >
              Filter
            </OutlineCustomButton>
            <div className="px-1 ps-2">
              <OutlineCustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                onClick={() =>
                  handleExport(
                    selectionModel,
                    "Application",
                    Studentlist,
                    "isDisabled",
                    visibleColumns
                  )
                }
              >
                Export
              </OutlineCustomButton>
            </div>

            {window.sessionStorage.getItem("user_role_type") === "agent" &&
              (window.sessionStorage.getItem("role") === "admin" ||
                window.sessionStorage.getItem("role") ===
                  "application_supervisor") && (
                <div className="px-1">
                  <OutlineCustomButton
                    variant="contained"
                    type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    onClick={() => setTransferModal(true)}
                  >
                    Transfer
                  </OutlineCustomButton>
                </div>
              )}

            <FilterComponent
              open={isFilterOpen}
              onClose={() => setFilterOpen(false)}
              onApply={handleApplyFilters}
              fields={fields}
            />
            <div className="px-1">
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                onClick={() => navigate("/CAE/CreateApplication")}
              >
                Add Student
              </CustomButton>
            </div>
          </Box>
        </Grid>
      </Grid>

      <div className="table-container " style={{ overflow: "scroll" }}>
        <CustomDataTable
          row={data ? data : []}
          columns={columns}
          loading={table_load}
          rowheight={100}
          onSelectionChange={(newSelection) => {
            // console.log(newSelection)
            setSelectionModel(newSelection);
          }}
          getRowClassName={(params) =>
            params.row.isDisabled === true ? "row-dull" : "row-white"
          }
          onVisibleColumnsChange={handleVisibleColumnsChange}
        />
      </div>

      {/* modal for chat window */}
      <StudentChatModal
        handleClose={handleCloseChatModal}
        chatModal={chatModal}
        stdId={stdId}
      />

      {/* modal for notes window */}
      <StudentNotesModal
        NoteModal={NoteModal}
        handleClose={handleCloseNoteModal}
        stdId={stdId}
        Studentlist={Studentlist}
      />

      {/* modal for pinned window */}
      <StudentPinnedModal
        pinned={pinned}
        pinnedModal={pinnedModal}
        handleClose={handlePinnedModal}
        stdId={stdId}
        Studentlist={Studentlist}
        isPinedNotes={isPinedNotes}
      />

      {/* modal for reminder window */}
      <StudentReminderModal
        ReminderModal={ReminderModal}
        handleClose={handleCloseReminderModal}
        stdId={stdId}
        // Studentlist={selectionModel}
        Studentlist={Studentlist}
      />

      {/* modal for payment details adding window */}
      <StudentVisaApproveModal
        AddPaymentModal={AddPaymentModal}
        handleClose={handleCloseVisaApproveModal}
        stdId={stdId}
        Studentlist={Studentlist}
      />

      {/* modal for transfering student*/}
      <StudentTransferModal
        TransferModal={TransferModal}
        handleClose={handleCloseTransferModal}
        Agentlist={Agentlist}
        Studentlist={selectionModel}
      />
    </>
  );
};

function Studentlist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<StudentlistPage />} />
    </div>
  );
}

export default Studentlist;
