import React, { useState } from "react";
import { ThemeColor } from "../../component/theme/color";
import "../login/Login.css";
import {
  Chip,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontSize } from "../../component/theme/fontSize";
import { fontWeight } from "../../component/theme/fontWeight";
import LoginModule from "./Login.module";
import ForgotModule from "./Forgot.module";
import ChangePasswordModule from "./ChangePassword.module";
import SnackbarComponent from "../../component/snackbar/snackbar";

function Login(props) {
  // //(props)
  return (
    <div
      className="login-container"
      style={{ backgroundColor: ThemeColor.WHITE }}
    >
      <div
        className="side-container"
        style={{ backgroundColor: ThemeColor.BLUE }}
      >
        <div className="logo px-3 d-flex align-items-center" style={{}}>
          <img src="/assest/Group.png" alt="logo" />
          <img src="assest/Grouptext.png" alt="logo text" className="px-2" />
        </div>
        <div className="side-container-text">
          <Typography
            fontWeight={fontWeight.W700}
            fontSize={fontSize.h2.medium}
            color={ThemeColor.WHITE}
            fontFamily={FontFamily.Nunito}
          >
            We Make Your Work Smarter, Not Harder
          </Typography>
        </div>

        <div className="side-container-chip px-3" style={{ cursor: "pointer" }}>
          <Chip
            label="Check Our Partner Universities"
            sx={{
              backgroundColor: ThemeColor.WHITE,
              color: ThemeColor.RED,
              textDecoration: "underline",
              p: 2,
            }}
            onClick={() => window.location.href = "/universitylist"}
          />
        </div>
      </div>
      <div className="main-container">
        <div className="contact-admin">
          <Typography
            color={ThemeColor.RED}
            fontWeight={fontWeight.W700}
            fontFamily={FontFamily.Nunito}
            fontSize={fontSize.h3.small}
            sx={{ textDecoration: "underline" }}
          >
            Contact Admin
          </Typography>
        </div>
        {props.children}
        {/* <LoginModule /> */}
        {/* <ForgotModule /> */}
        {/* <ChangePasswordModule /> */}
        <div className="privacy-policy">
          <Typography
            color={ThemeColor.RED}
            fontWeight={fontWeight.W700}
            fontFamily={FontFamily.Nunito}
            fontSize={fontSize.h3.small}
            sx={{ textDecoration: "underline" }}
          >
            Privacy Policy
          </Typography>
        </div>
      </div>
      <div>
        <SnackbarComponent />
      </div>
    </div>
  );
}

export default Login;
