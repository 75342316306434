import axios from "axios";
import { basicurl } from "../ServiceUrl/url";

// const basicurl = basicurl;

const token = window.sessionStorage.getItem("user_token");
//.log(token)
// //(token);

export const GetUniversityListService = async () => {
  let res = await axios.get(basicurl + "/api/university/get-all-university", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetAgentListService = async () => {
  let res = await axios.get(basicurl + "/api/login/get-all-active-agents", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetUserListService = async () => {
  let res = await axios.get(basicurl + "/api/login/get-all-active-users", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetCatalogListService = async () => {
  let res = await axios.get(
    basicurl + "/api/catalog/get-all-catalogs-or-brochures",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetProgramListService = async (id) => {
  const url =
    id !== undefined
      ? `${basicurl}/api/program/get-all-programs?university=${id}`
      : `${basicurl}/api/program/get-all-programs`;
  let res = await axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetProcessListService = async () => {
  let res = await axios.get(
    basicurl + "/api/process-sheet/get-all-process-sheets",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetOrganizationListService = async () => {
  let res = await axios.get(
    basicurl + "/api/organization/get-all-organizations",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetRoleListService = async () => {
  let res = await axios.get(basicurl + "/api/role/get-all-roles", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const CreateUniversityService = async (data) => {
  let res = await axios.post(
    basicurl + "/api/university/add-university",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const CreateOrganizationService = async (data) => {
  let res = await axios.post(
    basicurl + "/api/organization/add-organization",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const CreateAgentService = async (data) => {
  let res = await axios.post(basicurl + "/api/login/create-agent", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const CreateUserService = async (data) => {
  let res = await axios.post(basicurl + "/api/login/create-CAE-user", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const CreateCatalogService = async (data) => {
  let res = await axios.post(
    basicurl + "/api/catalog/add-catalog-or-brochure",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const CreateProgramService = async (data) => {
  let res = await axios.post(
    basicurl + "/api/program/add-multiple-programs",
    [data],
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const CreateProcessService = async (data) => {
  let res = await axios.post(
    basicurl + "/api/process-sheet/add-process-sheet",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const LoginService = async (data) => {
  let res = await axios.post(basicurl + "/api/login/user-login", data);
  return res;
};

export const changePasswordService = async (data) => {
  let res = await axios.post(basicurl + "/api/login/change-password", data, {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem(
        "user_first_token"
      )}`,
    },
  });
  return res;
};
export const AddtochatService = async (data) => {
  let res = await axios.post(basicurl + "/api/chat/add-message-to-chat", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetUserChatService = async () => {
  let res = await axios.get(basicurl + "/api/chat/get-user-chats", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const GetUserChatByIdService = async (data) => {
  //(data, "line 170");
  let res = await axios.get(basicurl + `/api/chat/get-chat-by-id/${data}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const DeleteMsgFromChatService = async (personId, msgId) => {
  let res = await axios.delete(
    basicurl + `/api/chat/delete-message-from-chat/${personId}/${msgId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const EditMsgToChatService = async (personId, msgId, data) => {
  let res = await axios.put(
    basicurl +
      `/api/chat/edit-message-by-chatId-messageId/${personId}/${msgId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetBranchByOrganizationIdService = async (id) => {
  let res = await axios.get(
    basicurl + `/api/branch/get-branches-by-organisation/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
export const GetSettingCountService = async () => {
  let res = await axios.get(basicurl + `/api/login/settings-data-counter/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const CreateBranchByOrganizationIdService = async (data) => {
  let res = await axios.post(basicurl + `/api/branch/create-brach`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const AddPersonChatService = async (data) => {
  //(data, "data of chat person");
  let res = await axios.post(basicurl + "/api/chat/create-new-chat", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const AddGroupChatService = async (data) => {
  let res = await axios.post(basicurl + "/api/chat/create-chat-group", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const AddPersonToGroupChatService = async (chatId, data) => {
  let res = await axios.post(
    basicurl + `/api/chat/add-participants-to-group-chat/${chatId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const RemovePersonToGroupChatService = async (chatId, data) => {
  let res = await axios.post(
    basicurl + `/api/chat/remove-participant-from-chat/${chatId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

// services for edit

export const EditUniversityService = async (data, id) => {
  let res = await axios.put(
    basicurl + `/api/university/update-university/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const EditCatalogService = async (data, id) => {
  let res = await axios.put(
    basicurl + `/api/catalog/update-catalogs-or-brochure/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

// export const EditProgramService = async (data, id) => {
//   let res = await axios.put(
//     basicurl + `/api/catalog/update-catalogs-or-brochure/${id}`,
//     data,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
//   return res;
// };

export const EditOrganizationService = async (data) => {
  let res = await axios.put(
    basicurl + `/api/organization/update-organization`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const EditProcessService = async (data, id) => {
  let res = await axios.put(
    basicurl + `/api/process-sheet/update-process-sheet/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const EditAgentService = async (data, id) => {
  let res = await axios.put(basicurl + `/api/login/update-agent/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const EditUserService = async (data, id) => {
  let res = await axios.put(
    basicurl + `/api/login/update-CAE-user/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetFilteredUserService = async (data) => {
  let res = await axios.get(
    basicurl +
      `/api/chat/filter-user?searchItem=${
        data?.searchItem ? data.searchItem : ""
      }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const EditOrgBlockedStatusService = async (data) => {
  let res = await axios.put(
    basicurl + `/api/organization/change-organisation-block-status/${data.id}`,
    {
      isBlocked: data.isBlocked,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const EditUnivBlockedStatusService = async (data) => {
  let res = await axios.put(
    basicurl + `/api/university/block-or-unblock-university/${data.id}`,
    {
      isBlocked: data.isBlocked,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const EditUserBlockedStatusService = async (data, payload) => {
  let res = await axios.put(
    basicurl + `/api/login/change-cae-user-status/${data.id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const AddDefaultIntakeService = async (data) => {
  let res = await axios.post(
    basicurl + `/api/student/save-default-intake`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const GetDefaultIntakeService = async () => {
  let res = await axios.get(basicurl + `/api/student/get-default-intake`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const EditProgAvailableService = async (data, id) => {
  console.log(data, id);
  let res = await axios.put(
    basicurl + `/api/program/update-program-availablity/${id}`,
    { isAvailable: data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const DeleteProgAvailableService = async (id) => {
  let res = await axios.delete(
    basicurl + `/api/program/delete-program/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};
