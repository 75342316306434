export const GetUniversityListConst = "GetUniversityList";
export const GetAgentListConst = "GetAgentList";
export const GetUserListConst = "GetUserList";
export const GetOrganizationListConst = "GetOrganizationList";
export const GetRoleListConst="GetRoleList"
export const GetCatalogListConst="GetCatalogList"
export const GetProcessListConst="GetProcessList"
export const SaveRolesConst="SaveRoles"
export const GetUserChatConst="GetUserChat"
export const GetUserChatByIdConst="GetUserChatById"
export const GetBranchByOrgIdConst="GetBranchByOrgId"
export const GetFilteredUserConst="GetFilteredUser"
export const GetProgramListConst="GetProgramList"
export const GetSettingCountConst="GetSettingCount"