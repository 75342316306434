import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Badge,
  Box,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../component/button/button";
import OutlineCustomButton from "../../component/button/outlineButton";
import {
  GetFilteredUserAction,
  GetOrganizationAction,
  GetRoleAction,
  GetUserAction,
  GetUserChatAction,
  GetUserChatByIdAction,
} from "../../Redux/action/SettingAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Msg from "../../Icon/Msg";
import { type } from "@testing-library/user-event/dist/type";
import {
  AddtochatService,
  DeleteMsgFromChatService,
  EditMsgToChatService,
} from "../../Redux/service/SettingService";
import moment from "moment/moment";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import AttachmentDropzone from "../../component/dropzone/AttachmentDropzone";
import {
  AddCircleTwoTone,
  DeleteForeverOutlined,
  DeleteForeverRounded,
  RemoveCircleOutline,
} from "@mui/icons-material";
import "../chat/chatlistpage.css";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { CreateChatModal } from "../../component/ChatSectionModal/CreateChat.component";
import { ChatListNav } from "../../component/ChatSectionModal/ChatListSideNav.component";
import { ReceiverSection } from "../../component/ChatSectionModal/ReceiverSection.component";
import { CreateGroupChatModal } from "../../component/ChatSectionModal/AddGroupPersonChatModal.component";
import { AddPersonToChatChatModal } from "../../component/ChatSectionModal/AddPersonToChat";
import Crossicon from "../../Icon/Crossicon";
import { DownCircleFilled, DownCircleOutlined } from "@ant-design/icons";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import FilePreviewer from "./testingpreview";
import { RemovePersonToChatChatModal } from "../../component/ChatSectionModal/RemovePersonToChat";
import axios from "axios";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import {
  markSeenMessagesAPI,
  postmarkSeenMessagesAPI,
} from "../../Redux/service/NotificationService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

const drawerWidth = 240;

const ChatlistPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const childRef = useRef();

  const [open, setOpen] = React.useState(false);

  const [Organizationlist, setOrganizationlist] = useState([]);

  const [Chatlist, setChatlist] = useState([]);

  const [table_load, setTable_load] = useState(false);

  const [ChatByIdlist, setChatByIdlist] = useState([]);

  const [formLoading, setFormLoading] = useState(false);

  const [chatId, setChatId] = useState("");

  const [Userlist, setUserlist] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [filteredUser, setFilteredUser] = useState([]);

  const [Rolelist, setRolelist] = useState([]);

  const [chatTextField, setChatTextField] = useState([]);

  const [ChatEditMsg, setChatEditMsg] = useState("");

  const [visibleIcons, setVisibleIcons] = useState({});

  const [chatModal, setChatModal] = useState(false);

  const [AddGroupChat, setAddGroupChat] = useState(false);

  const [AddPersonChat, setAddPersonChat] = useState(false);

  const [RemovePersonChat, setRemovePersonChat] = useState(false);

  const [AttachedFiles, setAttachedFiles] = useState();

  const toggleDrawer = () => {
    dispatch(GetUserChatAction());

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTable_load(true);
    dispatch(GetOrganizationAction());
  }, []);

  useEffect(() => {
    let data = {
      firstName: null,
      lastName: null,
      roles: null,
      roleType: null,
      organization: null,
      email: null,
    };
    dispatch(GetFilteredUserAction(data));
    dispatch(GetUserChatAction());
  }, []);

  const validationSchema = Yup.object().shape({
    text: Yup.string()
      // .required("Resend date is required")/
      .when("url", {
        is: (url) => !url,
        then: (schema) =>
          schema.test(
            "is-greater",
            "Either text or file is required to send to chat",
            function (value) {
              const { url } = this.parent;

              if (url !== "" && !value) {
                console.log("no file");
                return false;
              }
              return true;
            }
          ),
        otherwise: (schema) => schema.notRequired(),
      }),
    url: Yup.string().notRequired(),
  });

  const OrganizationlistState = useSelector(
    (state) => state.setting?.organization?.data?.organizations
  );
  const FilteredUserlistState = useSelector(
    (state) => state.setting?.filteredUser?.data?.data
  );

  const ChatlistState = useSelector(
    (state) => state.setting?.allchat?.data?.data
  );

  // //(ChatlistState);

  useEffect(() => {
    const updatedData = FilteredUserlistState?.map(
      ({ firstName, ...rest }) => ({
        title: firstName,
        ...rest,
      })
    );
    setFilteredUser(updatedData);
  }, [FilteredUserlistState]);

  useEffect(() => {
    const updatedData = OrganizationlistState?.map(
      ({ organizationName, ...rest }) => ({
        title: organizationName,
        ...rest,
      })
    );
    setOrganizationlist(updatedData);
  }, [OrganizationlistState]);

  // useEffect(() => {
  //   setChatlist(ChatlistState);
  // }, [ChatlistState]);

  const getChat = async (id) => {
    setChatId(id);
    setOpen(false);
    await postmarkSeenMessagesAPI(id)
      .then((res) => {
        if (res.status === 200) {
          toggleDrawer();
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(GetUserChatByIdAction(id));
  };
  const ChatByIdlistState = useSelector(
    (state) => state.setting?.chat?.data?.data
  );

  useEffect(() => {
    setChatByIdlist(ChatByIdlistState);
    ChatByIdlistState?.messages?.forEach((item) => {
      if (item.sender?._id === window.sessionStorage.getItem("user_id")) {
        const creationMoment = moment.unix(item.dateTime);
        const expirationTime = creationMoment.add(2, "minutes");
        const now = moment();
        const timeRemaining = expirationTime.diff(now, "milliseconds");

        if (timeRemaining > 0) {
          // Set timeout to hide the icons after 2 minutes (or less, depending on how much time has passed)
          setVisibleIcons((prev) => ({ ...prev, [item._id]: true }));
          setTimeout(() => {
            setVisibleIcons((prev) => ({ ...prev, [item._id]: false }));
          }, timeRemaining);
        }
      }
    });
  }, [ChatByIdlistState]);

  // //(ChatByIdlistState);

  const RolelistState = useSelector(
    (state) => state.setting?.role?.data?.roles
  );

  useEffect(() => {
    dispatch(GetRoleAction());
  }, []);

  useEffect(() => {
    setRolelist(RolelistState);
  }, [RolelistState]);

  const handleCloseGroupChatModal = () => setAddGroupChat(!AddGroupChat);

  useEffect(() => {
    console.log(Chatlist);

    const fetchChatCounts = async () => {
      const updatedChatList = await Promise.all(
        ChatlistState?.map(async (chat) => {
          try {
            const response = await axios.get(
              `${basicurl}/api/chat/countUnseenMessagesByChatId/${chat._id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            console.log(response, "dhdhdhdhdhdh");
            return { ...chat, count: response.data?.data?.unseenCount }; // Assume the API response has a 'count' field
          } catch (error) {
            console.error(`Error fetching count for chat ID ${chat.id}`, error);
            return { ...chat, count: 0 };
          }
        })
      );

      setChatlist(updatedChatList);
    };
    if (ChatlistState && ChatlistState?.length > 0) fetchChatCounts();
  }, [ChatlistState]);

  function generate(array, icon) {
    console.log(array);
    return array?.map((value) => {
      return (
        <ListItem className="w-100 p-0">
          <ListItemButton
            onClick={() => {
              getChat(value._id);
            }}
          >
            <ListItemIcon>
              {" "}
              <Badge badgeContent={value.count} color="primary">
                {icon}
              </Badge>
            </ListItemIcon>
            <ListItemText
            // primary={value.title}
            // secondary={<AddCircleTwoTone />}
            >
              <div className="d-flex justify-content-center align-items-center">
                <Typography>{value.title}</Typography>
                <IconButton
                  className=""
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClosePersonChat();
                    setChatId(value._id);
                  }}
                >
                  {" "}
                  <AddCircleTwoTone sx={{ fill: "white", fontSize: 25 }} />
                </IconButton>

                <IconButton
                  className=""
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCloseRemovePersonChat();
                    setChatId(value._id);
                  }}
                >
                  {" "}
                  <RemoveCircleOutline sx={{ fill: "white", fontSize: 25 }} />
                </IconButton>
              </div>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      );
    });
  }

  const UserlistState = useSelector(
    (state) => state.setting?.user?.data?.activeUsers
  );

  //("user list", UserlistState);

  useEffect(() => {
    setTable_load(true);
    dispatch(GetUserAction());
  }, []);

  useEffect(() => {
    const updatedData = UserlistState?.map(({ firstName, ...rest }) => ({
      title: firstName,
      ...rest,
    }));
    setUserlist(updatedData);
    setTable_load(false);
  }, [UserlistState]);

  const deleteOnetoOneMsg = async (msgId, personId) => {
    //("msg delete id=>", msgId, "deleting for person id=>", personId);
    await DeleteMsgFromChatService(personId, msgId)
      .then((res) => {
        if (res.status === 200) {
          // //(res);
          dispatch(GetUserChatByIdAction(personId));
          dispatch(showSnackbar(res.data.message, "success"));
        }
      })
      .catch((e) => {
        setFormLoading(false);
        dispatch(showSnackbar(e.response.data.message, "error"));
      });
  };

  const EditOnetoOneMsg = async (msgId, personId, text) => {
    let data = { text: ChatEditMsg };
    if (ChatEditMsg !== "") {
      await EditMsgToChatService(personId, msgId, data)
        .then((res) => {
          if (res.status === 200) {
            // //(res);
            dispatch(GetUserChatByIdAction(personId));
            dispatch(showSnackbar(res.data.message, "success"));
            setChatTextField([]);
            setChatEditMsg("");
          }
        })
        .catch((e) => {
          setFormLoading(false);
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    } else {
      dispatch(showSnackbar("Message is required to edit!", "error"));
    }
  };
  // handle close of modal
  const handleClosePersonChat = () => setAddPersonChat(!AddPersonChat);
  const handleCloseRemovePersonChat = () =>
    setRemovePersonChat(!RemovePersonChat);

  // const downloadFile = async (file) => {
  //   console.log(file,"url of file in chat zone")

  //   const proxyUrl = "https://cors-anywhere.herokuapp.com/";
  //   const link = document.createElement("a");
  //   link.href = file;
  //   link.setAttribute("download", "downloaded-image.png");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   // const response = await fetch(`${proxyUrl}${file}`);
  //   // const blob = await response.blob();
  //   // const blobUrl = URL.createObjectURL(blob);

  //   // const link = document.createElement("a");
  //   // link.href = blobUrl;
  //   // link.setAttribute("download", "Group.png");
  //   // document.body.appendChild(link);
  //   // link.click();
  //   // link.remove();
  //   // URL.revokeObjectURL(blobUrl);
  // };

  const downloadFile = async (fileUrl) => {
    window.open(fileUrl, "_blank", "noopener noreferrer");
    // try {
    //     const response = await fetch(fileUrl, { mode: "cors" });
    //     if (!response.ok) throw new Error("Failed to fetch image");

    //     const blob = await response.blob();
    //     const blobUrl = URL.createObjectURL(blob);

    //     const link = document.createElement("a");
    //     link.href = blobUrl;
    //     link.setAttribute("download", "downloaded-image.png");
    //     link.target = "_blank";  // Opens in new tab
    //     link.rel = "noopener noreferrer"; // Security best practice

    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(blobUrl);
    // } catch (error) {
    //     console.error("Error downloading file:", error);
    // }
  };

  const FileHandler = (data) => {
    if (data?.split(".").pop() === "xlsx") {
      let urlParts = data.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          <div className="filename-icon">
            <img
              src="/assest/folderpng/excel.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div>
          <a
            href={data}
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else if (data?.split(".").pop() === "pdf") {
      let urlParts = data.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          <div className="filename-icon">
            <img
              src="/assest/folderpng/pdf.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div>
          <a
            href={data}
            target="_blank"
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else {
      return (
        <div className="mt-2">
          <img
            onClick={() => downloadFile(data)}
            src={data}
            style={{
              width: "100px",
              height: "100px",
            }}
            alt="chatimg"
          />
        </div>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={7} xs={5}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Chat List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={6} md={5} xs={7}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },
            }}
          >
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              onClick={() => handleCloseGroupChatModal()}
            >
              <Msg stroke={"black"} /> &nbsp;Create Chat
            </OutlineCustomButton>
          </Box>
        </Grid>
      </Grid>

      <div className="table-container" style={{ overflowX: "scroll" }}>
        <div className="h-100 w-100" style={{ position: "relative" }}>
          <ChatListNav
            toggleDrawer={toggleDrawer}
            Chatlist={Chatlist}
            isOpen={isOpen}
            generate={generate}
          />
          {/* Main content */}
          <div
            className={`main-content`}
            style={{ overflowY: "scroll", height: "50vh" }}
          >
            {ChatByIdlist ? (
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  fontFamily={FontFamily.Poppins}
                  fontWeight={fontWeight.W600}
                  fontSize={fontSize.h3.large}
                  color={ThemeColor.BLACK}
                  align="center"
                >
                  Chat for "{ChatByIdlist?.title}"
                </Typography>
                <Box sx={{ height: "100%", overflowY: "scroll" }}>
                  {ChatByIdlist?.messages?.map((data) => {
                    return (
                      <>
                        {data.sender?._id ===
                        window.sessionStorage.getItem("user_id") ? (
                          <div>
                            <div id="sender" className="ms-5 mt-3">
                              <Box className="d-flex justify-content-between">
                                <div>
                                  <Typography
                                    fontFamily={FontFamily.Poppins}
                                    fontWeight={fontWeight.W700}
                                    fontSize={fontSize.h4.small}
                                    color={ThemeColor.BLACK}
                                    align="left"
                                  >
                                    {moment.unix(data.dateTime).format("llll")}

                                    {/* {data.dateTime} */}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    fontFamily={FontFamily.Poppins}
                                    fontWeight={fontWeight.W400}
                                    fontSize={fontSize.h4.small}
                                    color={ThemeColor.RED}
                                    sx={{ textDecoration: "underline" }}
                                    align="left"
                                  >
                                    {data.sender.firstName} &nbsp;
                                    {data.sender.lastName}
                                  </Typography>
                                </div>
                              </Box>

                              {chatTextField.some((id) => id === data._id) ? (
                                <div className="d-flex mt-3">
                                  <OutlinedInputField
                                    label={"enter msg"}
                                    value={ChatEditMsg}
                                    onChange={(e) =>
                                      setChatEditMsg(e.target.value)
                                    }
                                  />
                                  <IconButton
                                    onClick={() => {
                                      EditOnetoOneMsg(
                                        data._id,
                                        ChatByIdlist._id
                                      );
                                    }}
                                  >
                                    <CheckIcon sx={{ fill: ThemeColor.BLUE }} />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => setChatTextField([])}
                                  >
                                    <CloseIcon sx={{ fill: ThemeColor.RED }} />
                                  </IconButton>
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {data?.url && FileHandler(data?.url)}
                                  <Typography
                                    className="mt-3 pe-5"
                                    fontFamily={FontFamily.Poppins}
                                    fontWeight={fontWeight.W400}
                                    fontSize={fontSize.h4.medium}
                                    color={ThemeColor.BLACK}
                                    align="left"
                                  >
                                    {data.text}
                                  </Typography>
                                </>
                              )}
                            </div>
                            {visibleIcons[data._id] && (
                              <div className="d-flex justify-content-end">
                                <IconButton
                                  onClick={() =>
                                    deleteOnetoOneMsg(
                                      data._id,
                                      ChatByIdlist._id
                                    )
                                  }
                                >
                                  <DeleteForeverRounded
                                    sx={{ fill: ThemeColor.RED }}
                                  />
                                </IconButton>

                                <IconButton
                                  onClick={() => {
                                    setChatEditMsg(data.text);
                                    let array = [];
                                    array.push(data._id);
                                    setChatTextField(array);
                                  }}
                                >
                                  <EditIcon sx={{ fill: ThemeColor.BLUE }} />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        ) : (
                          ReceiverSection(data)
                        )}
                      </>
                    );
                  })}
                </Box>
                <Formik
                  initialValues={{
                    text: "",
                    url: "",
                  }}
                  // validationSchema={validationSchema}
                  onSubmit={async (values, { resetForm }) => {
                    setFormLoading(true);
                    values["chatId"] = chatId;
                    //("Form data:", values);

                    await AddtochatService(values)
                      .then((res) => {
                        if (res.status === 200) {
                          dispatch(GetUserChatByIdAction(chatId));
                          setFormLoading(false);
                          setAttachedFiles([]);
                          childRef.current.resetDropzone();

                          resetForm();
                        }
                      })
                      .catch((e) => {
                        setFormLoading(false);
                      });
                  }}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form className="d-flex justify-content-center ">
                      <Box
                        className="addcatalog-container"
                        sx={{
                          width: {
                            sm: "100%",
                            xs: "100%",
                            md: "100%",
                            lg: "100%",
                          },
                          // height:"60%",
                          position: "absolute",
                          bottom: "0%",
                          paddingLeft: {
                            lg: "20px",
                            md: "20px",
                            sm: "10px",
                            xs: "10px",
                          },
                          paddingRight: {
                            lg: "20px",
                            md: "20px",
                            sm: "10px",
                            xs: "10px",
                          },
                        }}
                      >
                        <FormGroup style={{}}>
                          <div className="d-flex justify-content-between">
                            <div className="px-2 w-100">
                              <OutlinedInputField
                                value={values.text}
                                name={"text"}
                                onChange={handleChange}
                                placeholder="Write something...."
                                fullWidth={true}
                                helperText={
                                  <Typography
                                    align="left"
                                    // className="px-3"
                                    color={ThemeColor.RED}
                                    fontSize={fontSize.h4.medium}
                                    fontFamily={FontFamily.Lato}
                                    fontWeight={fontWeight.W500}
                                  >
                                    {errors.text}
                                  </Typography>
                                }
                              />
                              {AttachedFiles && (
                                <div>
                                  {AttachedFiles?.map((data, index) => {
                                    return (
                                      <div className="d-flex align-items-center">
                                        <div className="px-2">
                                          {" "}
                                          <Typography
                                            color={ThemeColor.PURPLE}
                                            fontSize={fontSize.h3.medium}
                                            fontFamily={FontFamily.Lato}
                                          >
                                            Selected file:{" "}
                                            {AttachedFiles[0]?.name}
                                          </Typography>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                            <div>
                              <AttachmentDropzone
                                ref={childRef}
                                handleMydropzoneFile={(updatedFiles, files) => {
                                  // //(updatedFiles, "oooyesah");
                                  console.log(files[0].name);
                                  setAttachedFiles(files);
                                  setFieldValue("url", updatedFiles[0].url);
                                }}
                              />
                            </div>
                            <div className="px-3">
                              <CustomButton
                                variant="contained"
                                type="submit"
                                backgroundColor={ThemeColor.PURPLE}
                                color={ThemeColor.WHITE}
                                loading={formLoading}
                                //   padding={"0px"}
                                // onClick={handleClick}
                              >
                                Send
                              </CustomButton>
                            </div>
                          </div>
                        </FormGroup>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            ) : (
              <Box
                className="d-flex justify-content-center align-items-center h-50"
                sx={{
                  width: {
                    sm: "100%",
                    xs: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                }}
              >
                <Typography
                  fontSize={fontSize.h3.large}
                  FontFamily={FontFamily.Poppins}
                  color={ThemeColor.GREY}
                >
                  Click User To chat
                </Typography>
              </Box>
            )}
          </div>
        </div>
      </div>

      {/* create chat modal */}
      {/* <CreateChatModal
        chatModal={chatModal}
        handleClose={handleCloseChatModal}
        Organizationlist={Organizationlist}
        filteredUser={filteredUser}
        getChat={getChat}
        Rolelist={Rolelist}
        generate={generate}
      /> */}

      <CreateGroupChatModal
        Organizationlist={Organizationlist}
        filteredUser={filteredUser}
        AddGroupChat={AddGroupChat}
        Rolelist={Rolelist}
        handleClose={handleCloseGroupChatModal}
      />

      <AddPersonToChatChatModal
        // Organizationlist={Organizationlist}
        // filteredUser={filteredUser}
        // AddGroupChat={AddGroupChat}
        // Rolelist={Rolelist}
        Participantlist={Chatlist?.filter(
          (data) => data._id === chatId
        )[0]?.participants?.map(({ firstName, ...rest }) => ({
          title: firstName,
          ...rest,
        }))}
        chatId={chatId}
        AddPersonChat={AddPersonChat}
        handleClose={handleClosePersonChat}
      />

      <RemovePersonToChatChatModal
        // Organizationlist={Organizationlist}
        // filteredUser={filteredUser}
        // AddGroupChat={AddGroupChat}
        // Rolelist={Rolelist}
        Participantlist={Chatlist?.filter(
          (data) => data._id === chatId
        )[0]?.participants?.map(({ firstName, ...rest }) => ({
          title: firstName,
          ...rest,
        }))}
        chatId={chatId}
        RemovePersonChat={RemovePersonChat}
        handleClose={handleCloseRemovePersonChat}
      />
    </>
  );
};

function Chatlist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<ChatlistPage />} />
    </div>
  );
}

export default Chatlist;
