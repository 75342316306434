import React, { useEffect, useRef, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";

import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateUniversityService,
  EditUniversityService,
} from "../../Redux/service/SettingService";
import moment from "moment";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import { GetProgramAction } from "../../Redux/action/SettingAction";
import { universityvalidationSchema } from "./ValidationConstant";
import { GetDegreeAction } from "../../Redux/action/ApplicationAction";
import MultiSelect from "../../component/select field/MultiSelectWithAllOption";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const CreateUniversityPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const childRef = useRef(null);
  const datetimepickerRef = useRef(null);

  const { id } = useParams();

  const [Edit, setEdit] = useState(id ? true : false);

  const [prefieldData, setPrefieldData] = useState();

  const [contactPerson, setContactPerson] = useState([]);

  const [CommissionsAndPrograms, setCommissionsAndPrograms] = useState();

  const [reminders, setReminders] = useState([]);

  const [Programlist, setProgramlist] = useState([]);

  const [formLoading, setFormLoading] = useState(false);

  const [Degreelist, setDegreelist] = useState([]);

  const DegreelistState = useSelector(
    (state) => state.application?.degree?.data?.degrees
  );
  useEffect(() => {
    dispatch(GetDegreeAction());
  }, []);

  useEffect(() => {
    setDegreelist(
      DegreelistState?.map(({ name, ...rest }) => ({
        title: name,
        ...rest,
      }))
    );
  }, [DegreelistState]);

  useEffect(() => {
    dispatch(GetProgramAction());
  }, []);

  const ProgramlistState = useSelector(
    (state) => state.setting?.program?.data?.programs
  );
  useEffect(() => {
    const updatedData = ProgramlistState?.map(({ program, ...rest }) => ({
      title: program,
      ...rest,
    }));
    setProgramlist(updatedData);
  }, [ProgramlistState]);

  const EditUniversitydataState = useSelector(
    (state) => state.setting?.university?.data?.universities
  );
  useEffect(() => {
    setPrefieldData(
      EditUniversitydataState?.filter((data) => data._id === id)?.[0]
    );

    setReminders(
      EditUniversitydataState?.filter(
        (data) => data._id === id
      )?.[0]?.reminders?.map((data) => {
        let json = {
          _id: data._id,
          text: data.text,
          reminderDateTime: moment.unix(data.reminderDateTime).format("llll"),
          resendDateTime: moment.unix(data.resendDateTime).format("llll"),
        };
        return json;
      })
    );
    setContactPerson(
      EditUniversitydataState?.filter(
        (data) => data._id === id
      )?.[0]?.contactPerson?.map((data) => {
        let json = {
          designation: data.designation,
          email: data.email,
          name: data.name,
          phone: data.phone,
        };
        return json;
      })
    );

  
 

    setCommissionsAndPrograms(
      EditUniversitydataState?.filter(
        (data) => data._id === id
      )?.[0]?.commissionsAndPrograms?.map((data) => {
        let json = {
          program: data?.program.map((data) => {
            return data?._id;
          }),
          degree: data?.degree?._id,
          commissionType: data.commissionType,
          value: data.value,
          duration: data.duration,
          paymentCycle: data.paymentCycle,
          termsAndCondition: data.termsAndCondition,
          termsAndConditionForOrg: data.termsAndConditionForOrg,
        };
        return json;
      })
    );
  }, [Edit, EditUniversitydataState, id]);

  // handle form submit
  const handleFormSubmit = async (values, formType) => {
    // //(values, formType, formikRef.current);

    setFormLoading(true);

    if (!Edit) {
      await CreateUniversityService(values)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/Universitylist");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          dispatch(showSnackbar(e.response.data.message, "error"));
        });
    } else {
      console.log(values, id);
      await EditUniversityService(values, id)
        .then((res) => {
          if (res.status === 200) {
            //(res);
            setFormLoading(false);

            dispatch(showSnackbar(res.data.message, "success"));
            if (formType === "reset") {
              formikRef.current.resetForm();
            } else {
              navigate("/CAE/Universitylist");
            }
          }
        })
        .catch((e) => {
          setFormLoading(false);
          dispatch(showSnackbar(e.response?.data?.message, "error"));
        });
    }
  };

  if (!prefieldData && !CommissionsAndPrograms && Edit) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" />
      </div>
    ); // You can replace this with a loading spinner
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Settings
            </Typography>
            <Typography
              className="px-2 pt-1"
              fontFamily={FontFamily.Poppins}
              fontSize={fontSize.h4.medium}
              fontWeight={fontWeight.W300}
              color={ThemeColor.GREY}
            >
              {!Edit ? "Create New" : "Edit"} university
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className="d-flex justify-content-center w-100">
            {!Edit && (
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                loading={formLoading}
                onClick={() =>
                  handleFormSubmit(formikRef.current?.values, "reset")
                }
              >
                {!Edit ? "Add" : "Edit"} Another University
              </CustomButton>
            )}
          </div>
        </Grid>
      </Grid>
      <div className="container">
        <Formik
          innerRef={formikRef}
          initialValues={{
            name: Edit ? prefieldData?.name : "",
            country:Edit?prefieldData?.country:"",
            state: Edit ? prefieldData?.state : "",
            city: Edit ? prefieldData?.city : "",
            address: Edit ? prefieldData?.address : "",
            websiteLink: Edit ? prefieldData?.websiteLink : "",
            contractExpireDate: Edit ? prefieldData?.contractExpireDate : null,
            documents: Edit ? prefieldData?.documents : [],
            reminders: Edit
              ? reminders?.map((reminder) => ({
                  ...reminder,
                  isNew: false, // Existing data is not new
                }))
              : [
                  // {
                  //   reminderDateTime: null,
                  //   text: "",
                  //   resendDateTime: null,
                  //   isNew: true,
                  // },
                ],

            contactPerson: Edit
              ? contactPerson
              : [
                  {
                    name: "",
                    email: "",
                    phone: "",
                    designation: "",
                  },
                ],
            commissionsAndPrograms: Edit
              ? CommissionsAndPrograms
              : [
                  {
                    degree: "",
                    program: [],
                    commissionType: "",
                    value: "",
                    duration: "",
                    paymentCycle: "",
                    termsAndCondition: "",
                    termsAndConditionForOrg: "",
                  },
                ],
                notes:prefieldData?.notes
          }}
          validationSchema={universityvalidationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (values?.reminders?.[0]?.reminderDateTime === null) {
              delete values["reminders"];
            }
            //(values);
            handleFormSubmit(values, "submit");
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form className="" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* grid for right basic content */}
                <Grid item lg={8}>
                  <Typography
                    align="center"
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    {!Edit ? "Add" : "Edit"} New university
                  </Typography>

                  <Grid container spacing={2} className="mt-4">
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Name of the university *
                          </Typography>
                          <OutlinedInputField
                            value={values.name}
                            name={"name"}
                            onChange={handleChange}
                            placeholder="Enter name of university"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.name}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Country *
                          </Typography>
                          <CustomSelect
                            value={values?.country}
                            name={"country"}
                            multiple={false}
                            defaultValue={"United States of America (USA)"}
                            option={[
                              {
                                _id: "United States of America (USA)",
                                title: "United States of America (USA)",
                              },
                              { _id: "Canada", title: "Canada" },
                              {
                                _id: "United Arab Emirates (UAE)",
                                title: "United Arab Emirates (UAE)",
                              },
                              { _id: "Singapore", title: "Singapore" },
                              {
                                _id: "United Kingdom",
                                title: "United Kingdom",
                              },
                            ]}
                            onChange={(e) => {
                              setFieldValue("country", e.target.value);
                            }}
                            label={"Country"}
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.country}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            State *
                          </Typography>
                          <CustomSelect
                            value={values?.state}
                            name={"state"}
                            multiple={false}
                            // defaultValue={"United States of America (USA)"}
                            option={[
                              { _id: "Alabama", title: "Alabama" },
                              { _id: "Alaska", title: "Alaska" },
                              { _id: "Arizona", title: "Arizona" },
                              { _id: "Arkansas", title: "Arkansas" },
                              { _id: "California", title: "California" },
                              { _id: "Colorado", title: "Colorado" },
                              { _id: "Connecticut", title: "Connecticut" },
                              { _id: "Delaware", title: "Delaware" },
                              { _id: "Florida", title: "Florida" },
                              { _id: "Georgia", title: "Georgia" },
                              { _id: "Hawaii", title: "Hawaii" },
                              { _id: "Idaho", title: "Idaho" },
                              { _id: "Illinois", title: "Illinois" },
                              { _id: "Indiana", title: "Indiana" },
                              { _id: "Iowa", title: "Iowa" },
                              { _id: "Kansas", title: "Kansas" },
                              { _id: "Kentucky", title: "Kentucky" },
                              { _id: "Louisiana", title: "Louisiana" },
                              { _id: "Maine", title: "Maine" },
                              { _id: "Maryland", title: "Maryland" },
                              { _id: "Massachusetts", title: "Massachusetts" },
                              { _id: "Michigan", title: "Michigan" },
                              { _id: "Minnesota", title: "Minnesota" },
                              { _id: "Mississippi", title: "Mississippi" },
                              { _id: "Missouri", title: "Missouri" },
                              { _id: "Montana", title: "Montana" },
                              { _id: "Nebraska", title: "Nebraska" },
                              { _id: "Nevada", title: "Nevada" },
                              { _id: "New Hampshire", title: "New Hampshire" },
                              { _id: "New Jersey", title: "New Jersey" },
                              { _id: "New Mexico", title: "New Mexico" },
                              { _id: "New York", title: "New York" },
                              {
                                _id: "North Carolina",
                                title: "North Carolina",
                              },
                              { _id: "North Dakota", title: "North Dakota" },
                              { _id: "Ohio", title: "Ohio" },
                              { _id: "Oklahoma", title: "Oklahoma" },
                              { _id: "Oregon", title: "Oregon" },
                              { _id: "Pennsylvania", title: "Pennsylvania" },
                              { _id: "Rhode Island", title: "Rhode Island" },
                              {
                                _id: "South Carolina",
                                title: "South Carolina",
                              },
                              { _id: "South Dakota", title: "South Dakota" },
                              { _id: "Tennessee", title: "Tennessee" },
                              { _id: "Texas", title: "Texas" },
                              { _id: "Utah", title: "Utah" },
                              { _id: "Vermont", title: "Vermont" },
                              { _id: "Virginia", title: "Virginia" },
                              { _id: "Washington", title: "Washington" },
                              { _id: "West Virginia", title: "West Virginia" },
                              { _id: "Wisconsin", title: "Wisconsin" },
                              { _id: "Wyoming", title: "Wyoming" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("state", e.target.value);
                            }}
                            label={"State"}
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.state}
                              </Typography>
                            }
                          />
                          {/* <OutlinedInputField
                            value={values.state}
                            name={"state"}
                            onChange={handleChange}
                            placeholder="Enter your details...."
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.state}
                              </Typography>
                            }
                          /> */}
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={6} md={6} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            City *
                          </Typography>
                          <OutlinedInputField
                            value={values.city}
                            name={"city"}
                            onChange={handleChange}
                            placeholder="Enter your details...."
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.city}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Contract Type *
                          </Typography>
                          <CustomSelect
                            value={values?.contractType}
                            name={"contractType"}
                            multiple={false}
                            // defaultValue={"United States of America (USA)"}
                            option={[
                              { _id: "Direct", title: "Direct" },
                              { _id: "Kaplan", title: "Kaplan" },
                              { _id: "EAG", title: "EAG" },
                              { _id: "EduCo.", title: "EduCo." },
                              { _id: "Shorelight", title: "Shorelight" },
                            ]}
                            onChange={(e) => {
                              setFieldValue("contractType", e.target.value);
                            }}
                            label={"Contract Type"}
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.contractType}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Campus Address
                          </Typography>
                          <OutlinedTextAreaField
                            multiline={true}
                            minRows={4}
                            value={values.address}
                            onChange={handleChange}
                            name={"address"}
                            placeholder="Enter your address"
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.address}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>
                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FormGroup style={{}}>
                        <div className="">
                          <Typography
                            className="mb-2"
                            fontFamily={FontFamily.Lato}
                            fontSize={fontSize.h3.medium}
                            fontWeight={fontWeight.W400}
                          >
                            Website link
                          </Typography>
                          <OutlinedInputField
                            value={values.websiteLink}
                            onChange={handleChange}
                            name={"websiteLink"}
                            placeholder="Enter your details...."
                            fullWidth={true}
                            helperText={
                              <Typography
                                align="left"
                                // className="px-3"
                                color={ThemeColor.RED}
                                fontSize={fontSize.h4.medium}
                                fontFamily={FontFamily.Lato}
                                fontWeight={fontWeight.W500}
                              >
                                {errors.websiteLink}
                              </Typography>
                            }
                          />
                        </div>
                      </FormGroup>
                    </NoPaddingGridItem>

                    <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                      <FieldArray name="reminders">
                        {({ push, remove }) => (
                          <div>
                            {values.reminders?.map((reminder, index) => (
                              <Grid
                                container
                                spacing={2}
                                className="mt-4"
                                key={index}
                              >
                                {index > 0 && (
                                  <NoPaddingGridItem
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="d-flex align-items-center"
                                  >
                                    <div className="w-100 mt-2 mb-3">
                                      <Divider>
                                        <Chip
                                          label={`${
                                            index + 1
                                          } Reminder Details `}
                                          size="small"
                                        />
                                      </Divider>
                                    </div>
                                  </NoPaddingGridItem>
                                )}
                                <NoPaddingGridItem
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Reminder Date & Time
                                      </Typography>
                                      <MyDatePicker
                                        ref={datetimepickerRef}
                                        minDate={dayjs().startOf("day")}
                                        label="Enter your details...."
                                        // value={dayjs(values.contactPerson[index].reminder_date).toDate()}

                                        value={
                                          values.reminders[index]
                                            ?.reminderDateTime
                                            ? dayjs(
                                                values.reminders[index]
                                                  ?.reminderDateTime
                                              ).isValid()
                                              ? dayjs(
                                                  values.reminders[index]
                                                    ?.reminderDateTime
                                                )
                                              : null
                                            : null
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            `reminders[${index}].reminderDateTime`,
                                            dayjs(date).format(
                                              "YYYY-MM-DD HH:MM"
                                            )
                                          );
                                        }}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.reminders?.[index]
                                                ?.reminderDateTime
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Resend Date & Time
                                      </Typography>
                                      <MyDatePicker
                                        ref={datetimepickerRef}
                                        minDate={dayjs().startOf("minute")}
                                        label="Enter your details...."
                                        // value={dayjs(values.contactPerson[index].reminder_date).toDate()}

                                        value={
                                          values.reminders[index]
                                            ?.resendDateTime
                                            ? dayjs(
                                                values.reminders[index]
                                                  ?.resendDateTime
                                              ).isValid()
                                              ? dayjs(
                                                  values.reminders[index]
                                                    ?.resendDateTime
                                                )
                                              : null
                                            : null
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            `reminders[${index}].resendDateTime`,
                                            dayjs(date).format(
                                              "YYYY-MM-DD HH:MM"
                                            )
                                          );
                                        }}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.reminders?.[index]
                                                ?.resendDateTime
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Reminder Note
                                      </Typography>
                                      <OutlinedInputField
                                        value={values.reminders[index].text}
                                        name={`reminders[${index}].text`}
                                        onChange={handleChange}
                                        placeholder="Enter your details...."
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {errors.reminders?.[index]?.text}
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>
                                {index > 0 && (
                                  <NoPaddingGridItem
                                    item
                                    lg={3}
                                    md={3}
                                    sm={12}
                                    xs={12}
                                    className="d-flex align-items-center"
                                  >
                                    <div className="d-flex justify-content-start w-100 mt-3">
                                      <Typography
                                        className="px-2"
                                        fontSize={fontSize.h3.small}
                                        fontFamily={FontFamily.Lato}
                                        color={ThemeColor.BLACK}
                                        fontWeight={fontWeight.W600}
                                      >
                                        Delete Reminder
                                      </Typography>
                                      <IconButton
                                        className="form-icon"
                                        sx={{
                                          background: ThemeColor.RED,
                                          color: ThemeColor.WHITE,
                                        }}
                                        variant="contained"
                                        onClick={() => remove(index)}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </div>
                                  </NoPaddingGridItem>
                                )}
                              </Grid>
                            ))}
                            <div className="d-flex justify-content-start w-100 mt-2 mb-3">
                              <Typography
                                className="px-2"
                                fontSize={fontSize.h3.small}
                                fontFamily={FontFamily.Lato}
                                color={ThemeColor.BLACK}
                                fontWeight={fontWeight.W600}
                              >
                                Add More Reminder
                              </Typography>
                              <IconButton
                                className="form-icon"
                                sx={{
                                  background: ThemeColor.BLUE,
                                  color: ThemeColor.WHITE,
                                }}
                                onClick={() =>
                                  push({
                                    text: "",
                                    reminderDateTime: null,
                                    resendDateTime: null,
                                    isNew: true,
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </NoPaddingGridItem>
                  </Grid>
                </Grid>

                {/* grid for left docs uploading content */}
                <Grid item lg={4} xs={12}>
                  <div className="w-100 d-flex justify-content-center mt-4">
                    <Previews
                      ref={childRef}
                      initialValue={values?.documents}
                      handleMydropzoneFile={(updatedFiles) => {
                        //(updatedFiles);
                        setFieldValue("documents", updatedFiles);
                      }}
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.documents}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-center mt-4">
                    <Mylogodropzone
                      handleMyLogodropzoneFile={(updatedFiles) => {
                        //(updatedFiles, "logo");
                        setFieldValue("logo", updatedFiles);
                      }}
                    />
                  </div>

                  <div className="w-100">
                    <Typography
                      className="mb-2"
                      fontFamily={FontFamily.Lato}
                      fontSize={fontSize.h3.medium}
                      fontWeight={fontWeight.W400}
                    >
                      Contract Expire Date
                    </Typography>
                    <MyDatePicker
                      label="Enter your details...."
                      // value={dayjs(values.contactPerson[index].reminder_date).toDate()}
                      onChange={(date) => {
                        setFieldValue(
                          `contractExpireDate`,
                          dayjs(date).format("YYYY-MM-DD HH:MM")
                        );
                      }}
                      value={
                        values.contractExpireDate
                          ? dayjs(values.contractExpireDate).isValid()
                            ? dayjs(values.contractExpireDate)
                            : null
                          : null
                      }
                      helperText={
                        <Typography
                          align="left"
                          // className="px-3"
                          color={ThemeColor.RED}
                          fontSize={fontSize.h4.medium}
                          fontFamily={FontFamily.Lato}
                          fontWeight={fontWeight.W500}
                        >
                          {errors.contractExpireDate}
                        </Typography>
                      }
                    />
                  </div>
                </Grid>

                {/* grid for bottom contact person content */}
                <Grid item lg={12}>
                  <Typography
                    fontFamily={FontFamily.Poppins}
                    fontWeight={fontWeight.W600}
                    fontSize={fontSize.h3.medium}
                    color={ThemeColor.PURPLE}
                  >
                    Contact person
                  </Typography>
                  <FieldArray name="contactPerson">
                    {({ push, remove }) => (
                      <div>
                        {values.contactPerson.map((contact, index) => (
                          <Grid
                            container
                            spacing={2}
                            className="mt-4"
                            key={index}
                          >
                            {index > 0 && (
                              <NoPaddingGridItem
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="d-flex align-items-center"
                              >
                                <div className="w-100 mt-2 mb-3">
                                  <Divider>
                                    <Chip
                                      label={`${
                                        index + 1
                                      } Contact Person Details `}
                                      size="small"
                                    />
                                  </Divider>
                                </div>
                              </NoPaddingGridItem>
                            )}
                            <NoPaddingGridItem
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="">
                                  <Typography
                                    className="mb-2"
                                    fontFamily={FontFamily.Lato}
                                    fontSize={fontSize.h3.medium}
                                    fontWeight={fontWeight.W400}
                                  >
                                    Name
                                  </Typography>
                                  <OutlinedInputField
                                    value={values.contactPerson[index].name}
                                    name={`contactPerson[${index}].name`}
                                    onChange={handleChange}
                                    placeholder="Enter your details..."
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {errors.contactPerson?.[index]?.name}
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>

                            <NoPaddingGridItem
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="">
                                  <Typography
                                    className="mb-2"
                                    fontFamily={FontFamily.Lato}
                                    fontSize={fontSize.h3.medium}
                                    fontWeight={fontWeight.W400}
                                  >
                                    Email
                                  </Typography>
                                  <OutlinedInputField
                                    value={values.contactPerson[index].email}
                                    name={`contactPerson[${index}].email`}
                                    onChange={handleChange}
                                    placeholder="Enter your details...."
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {errors.contactPerson?.[index]?.email}
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>
                            <NoPaddingGridItem
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="">
                                  <Typography
                                    className="mb-2"
                                    fontFamily={FontFamily.Lato}
                                    fontSize={fontSize.h3.medium}
                                    fontWeight={fontWeight.W400}
                                  >
                                    Phone No.
                                  </Typography>
                                  <OutlinedInputField
                                    value={values.contactPerson[index]?.phone}
                                    name={`contactPerson[${index}].phone`}
                                    onChange={handleChange}
                                    placeholder="Enter your details...."
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {errors.contactPerson?.[index]?.phone}
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>
                            <NoPaddingGridItem
                              item
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                            >
                              <FormGroup style={{}}>
                                <div className="">
                                  <Typography
                                    className="mb-2"
                                    fontFamily={FontFamily.Lato}
                                    fontSize={fontSize.h3.medium}
                                    fontWeight={fontWeight.W400}
                                  >
                                    Designation
                                  </Typography>
                                  <OutlinedInputField
                                    value={
                                      values.contactPerson[index].designation
                                    }
                                    name={`contactPerson[${index}].designation`}
                                    onChange={handleChange}
                                    placeholder="Enter your details...."
                                    fullWidth={true}
                                    helperText={
                                      <Typography
                                        align="left"
                                        // className="px-3"
                                        color={ThemeColor.RED}
                                        fontSize={fontSize.h4.medium}
                                        fontFamily={FontFamily.Lato}
                                        fontWeight={fontWeight.W500}
                                      >
                                        {
                                          errors.contactPerson?.[index]
                                            ?.designation
                                        }
                                      </Typography>
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </NoPaddingGridItem>

                            {index > 0 && (
                              <NoPaddingGridItem
                                item
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}
                                className="d-flex align-items-center"
                              >
                                <div className="d-flex justify-content-start w-100 mt-3">
                                  <Typography
                                    className="px-2"
                                    fontSize={fontSize.h3.small}
                                    fontFamily={FontFamily.Lato}
                                    color={ThemeColor.BLACK}
                                    fontWeight={fontWeight.W600}
                                  >
                                    Delete Contact Person
                                  </Typography>
                                  <IconButton
                                    className="form-icon"
                                    sx={{
                                      background: ThemeColor.RED,
                                      color: ThemeColor.WHITE,
                                    }}
                                    variant="contained"
                                    onClick={() => remove(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </div>
                              </NoPaddingGridItem>
                            )}
                          </Grid>
                        ))}
                        <div className="d-flex justify-content-end w-100 mt-3">
                          <Typography
                            className="px-2"
                            fontSize={fontSize.h3.small}
                            fontFamily={FontFamily.Lato}
                            color={ThemeColor.BLACK}
                            fontWeight={fontWeight.W600}
                          >
                            Add More Contact Person
                          </Typography>
                          <IconButton
                            className="form-icon"
                            sx={{
                              background: ThemeColor.BLUE,
                              color: ThemeColor.WHITE,
                            }}
                            onClick={() =>
                              push({
                                name: "",
                                email: "",
                                phone: "",
                                designation: "",
                              })
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Grid>

                <Grid item lg={12}>
                  <NoPaddingGridItem item lg={12} md={12} sm={12} xs={12}>
                    <FormGroup style={{}} className="w-100">
                      <div className="w-100">
                        <Typography
                          className="mb-2"
                          fontFamily={FontFamily.Lato}
                          fontSize={fontSize.h3.medium}
                          fontWeight={fontWeight.W400}
                        >
                          Notes (Admin only)
                        </Typography>
                        <OutlinedTextAreaField
                          multiline={true}
                          minRows={4}
                          value={values.notes}
                          name={"notes"}
                          onChange={handleChange}
                          placeholder="Enter your Notes"
                          fullWidth={true}
                        />
                      </div>
                    </FormGroup>
                  </NoPaddingGridItem>
                </Grid>

                {/* grid for bottom commision prog content */}
                {Edit && (
                  <Grid item lg={12}>
                    <Typography
                      fontFamily={FontFamily.Poppins}
                      fontWeight={fontWeight.W600}
                      fontSize={fontSize.h3.medium}
                      color={ThemeColor.PURPLE}
                    >
                      Commission And Program
                    </Typography>
                    <FieldArray name="commissionsAndPrograms">
                      {({ push, remove }) => (
                        <div>
                          {values?.commissionsAndPrograms?.map(
                            (contact, index) => (
                              <Grid
                                container
                                spacing={2}
                                className="mt-4"
                                key={index}
                              >
                                {index > 0 && (
                                  <NoPaddingGridItem
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="d-flex align-items-center"
                                  >
                                    <div className="w-100 mt-2 mb-3">
                                      <Divider>
                                        <Chip
                                          label={`${
                                            index + 1
                                          } commissionsAndPrograms Details `}
                                          size="small"
                                        />
                                      </Divider>
                                    </div>
                                  </NoPaddingGridItem>
                                )}

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <Typography
                                      className="mb-1"
                                      fontFamily={FontFamily.Lato}
                                      fontSize={fontSize.h3.medium}
                                      fontWeight={fontWeight.W400}
                                    >
                                      Degree Type
                                    </Typography>
                                    <div className="">
                                      <CustomSelect
                                        value={
                                          Degreelist &&
                                          values?.commissionsAndPrograms[index]
                                            ?.degree
                                        }
                                        name={`commissionsAndPrograms[${index}].degree`}
                                        multiple={false}
                                        option={Degreelist}
                                        onChange={(e) => {
                                          console.log(e.target.value);
                                          setFieldValue(
                                            `commissionsAndPrograms[${index}].degree`,
                                            e.target.value
                                          );
                                        }}
                                        fullWidth={true}
                                        label={"Degree"}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.degree
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                {/* <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}} className="">
                                    <div className="w-100">
                                      <Typography
                                        className="mb-1"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Program
                                      </Typography>
                                      <CustomSelect
                                        value={
                                          Programlist
                                            ? values.commissionsAndPrograms[
                                                index
                                              ]?.program
                                            : []
                                        }
                                        name={"program"}
                                        multiple={true}
                                        option={
                                          Programlist &&
                                          Programlist?.filter(
                                            (prog) =>
                                              id === prog.university._id &&
                                              values?.commissionsAndPrograms[
                                                index
                                              ]?.degree === prog.degree._id
                                          )?.map(({ email, ...rest }) => ({
                                            title: email,
                                            ...rest,
                                          }))
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `commissionsAndPrograms[${index}].program`,
                                            e.target.value
                                          );
                                        }}
                                        label={"Program"}
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.program
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem> */}

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}} className="">
                                    <div className="w-100">
                                      <Typography
                                        className="mb-1"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Program
                                      </Typography>
                                      <MultiSelect
                                        value={
                                          Programlist
                                            ? values.commissionsAndPrograms[
                                                index
                                              ]?.program
                                            : []
                                        }
                                        onChange={(selectedOptions) =>
                                          setFieldValue(
                                            `commissionsAndPrograms[${index}].program`,
                                            selectedOptions
                                          )
                                        }
                                        name={"program"}
                                        options={
                                          Programlist &&
                                          Programlist?.filter(
                                            (prog) =>
                                              id === prog.university._id &&
                                              values?.commissionsAndPrograms[
                                                index
                                              ]?.degree === prog.degree._id
                                          )?.map(({ email, ...rest }) => ({
                                            title: email,
                                            ...rest,
                                          }))
                                        }
                                        // onChange={(e) => {
                                        //   setFieldValue(
                                        //     `commissionsAndPrograms[${index}].program`,
                                        //     e.target.value
                                        //   );
                                        // }}
                                        // label={"Program"}
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.program
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="mt-2">
                                      <Typography
                                        className="mb-1"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Commision Type
                                      </Typography>
                                      <CustomSelect
                                        value={
                                          values.commissionsAndPrograms[index]
                                            ?.commissionType
                                        }
                                        name={"commissionType"}
                                        multiple={false}
                                        option={[
                                          { _id: "F", title: "Fixed" },
                                          { _id: "P", title: "Percentage" },
                                        ]}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `commissionsAndPrograms[${index}].commissionType`,
                                            e.target.value
                                          );
                                        }}
                                        label={"Commission Type"}
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.commissionType
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Value
                                      </Typography>
                                      <OutlinedInputField
                                        value={
                                          values.commissionsAndPrograms[index]
                                            .value
                                        }
                                        name={`commissionsAndPrograms[${index}].value`}
                                        onChange={handleChange}
                                        placeholder="Enter your details...."
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.value
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-1"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Duration
                                      </Typography>
                                      <CustomSelect
                                        value={
                                          values.commissionsAndPrograms[index]
                                            ?.duration
                                        }
                                        name={"duration"}
                                        multiple={false}
                                        option={[
                                          { _id: "S", title: "Semester-wise" },
                                          { _id: "Y", title: "Year-wise" },
                                        ]}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `commissionsAndPrograms[${index}].duration`,
                                            e.target.value
                                          );
                                        }}
                                        label={"Duration"}
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.duration
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Payment Cycle
                                      </Typography>
                                      <OutlinedInputField
                                        value={
                                          values.commissionsAndPrograms[index]
                                            ?.paymentCycle
                                        }
                                        name={`commissionsAndPrograms[${index}].paymentCycle`}
                                        onChange={handleChange}
                                        placeholder="Enter your payment cycle details...."
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.paymentCycle
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Terms And Conditions for university
                                      </Typography>
                                      <OutlinedInputField
                                        value={
                                          values.commissionsAndPrograms[index]
                                            .termsAndCondition
                                        }
                                        name={`commissionsAndPrograms[${index}].termsAndCondition`}
                                        onChange={handleChange}
                                        placeholder="Enter your terms and condition details...."
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.termsAndCondition
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                <NoPaddingGridItem
                                  item
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <FormGroup style={{}}>
                                    <div className="">
                                      <Typography
                                        className="mb-2"
                                        fontFamily={FontFamily.Lato}
                                        fontSize={fontSize.h3.medium}
                                        fontWeight={fontWeight.W400}
                                      >
                                        Terms And Conditions for organization
                                      </Typography>
                                      <OutlinedInputField
                                        value={
                                          values.commissionsAndPrograms[index]
                                            .termsAndConditionForOrg
                                        }
                                        name={`commissionsAndPrograms[${index}].termsAndConditionForOrg`}
                                        onChange={handleChange}
                                        placeholder="Enter your terms and condition details...."
                                        fullWidth={true}
                                        helperText={
                                          <Typography
                                            align="left"
                                            // className="px-3"
                                            color={ThemeColor.RED}
                                            fontSize={fontSize.h4.medium}
                                            fontFamily={FontFamily.Lato}
                                            fontWeight={fontWeight.W500}
                                          >
                                            {
                                              errors.commissionsAndPrograms?.[
                                                index
                                              ]?.termsAndConditionForOrg
                                            }
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </NoPaddingGridItem>

                                {index > 0 && (
                                  <NoPaddingGridItem
                                    item
                                    lg={3}
                                    md={3}
                                    sm={12}
                                    xs={12}
                                    className="d-flex align-items-center"
                                  >
                                    <div className="d-flex justify-content-start w-100 mt-3">
                                      <Typography
                                        className="px-2"
                                        fontSize={fontSize.h3.small}
                                        fontFamily={FontFamily.Lato}
                                        color={ThemeColor.BLACK}
                                        fontWeight={fontWeight.W600}
                                      >
                                        Delete Commission and Program
                                      </Typography>
                                      <IconButton
                                        className="form-icon"
                                        sx={{
                                          background: ThemeColor.RED,
                                          color: ThemeColor.WHITE,
                                        }}
                                        variant="contained"
                                        onClick={() => remove(index)}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </div>
                                  </NoPaddingGridItem>
                                )}
                              </Grid>
                            )
                          )}
                          <div className="d-flex justify-content-end w-100 mt-3">
                            <Typography
                              className="px-2"
                              fontSize={fontSize.h3.small}
                              fontFamily={FontFamily.Lato}
                              color={ThemeColor.BLACK}
                              fontWeight={fontWeight.W600}
                            >
                              Add Commission and Program
                            </Typography>
                            <IconButton
                              className="form-icon"
                              sx={{
                                background: ThemeColor.BLUE,
                                color: ThemeColor.WHITE,
                              }}
                              onClick={() =>
                                push({
                                  degree: "",
                                  program: [],
                                  commissionType: "",
                                  value: "",
                                  duration: "",
                                  termsAndCondition: "",
                                  paymentCycle: "",
                                  termsAndConditionForOrg: "",
                                  isNew: true,
                                })
                              }
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </Grid>
                )}
              </Grid>

              {(!window.sessionStorage.getItem("user_role_type") === "agent" ||
                window.sessionStorage.getItem("user_role_type") === "admin" ||
                !window.sessionStorage.getItem("user_role_type") ===
                  "user") && (
                <Box
                  sx={{
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                  }}
                  className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
                >
                  {" "}
                  <CustomButton
                    variant="contained"
                    type="submit"
                    backgroundColor={ThemeColor.PURPLE}
                    color={ThemeColor.WHITE}
                    loading={formLoading}
                    //   padding={"0px"}
                    // onClick={handleClick}
                  >
                    {!Edit ? "Save University" : "Save Changes"}
                  </CustomButton>
                  {Edit && (
                    <Box
                      className="px-2"
                      sx={{
                        paddingTop: {
                          lg: "0px",
                          md: "0px",
                          sm: "5px",
                          xs: "5px",
                        },
                      }}
                    >
                      <CustomButton
                        variant="contained"
                        // type="submit"
                        backgroundColor={ThemeColor.PURPLE}
                        color={ThemeColor.WHITE}
                        loading={formLoading}
                        //   padding={"0px"}
                        onClick={() => {
                          // setFieldValue('birthday', null);
                          // if (childRef.current) {
                          //   childRef.current.someFunction();
                          // }
                          // setFieldValue("reminders",[{reminderDateTime:null,text:""}])
                          // setFieldValue("contractExpireDate",null)
                          // setFieldValue("documents", []);
                          // //(values)
                          if (Edit) {
                            if (childRef.current) {
                              childRef.current.someFunction(
                                prefieldData?.documents
                              );
                            }
                          } else {
                            if (childRef.current) {
                              childRef.current.someEditFunction();
                            }
                          }
                          resetForm();
                        }}
                      >
                        Cancel Changes
                      </CustomButton>
                    </Box>
                  )}
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

function CreateUniversity() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<CreateUniversityPage />} />
    </div>
  );
}

export default CreateUniversity;
