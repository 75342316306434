import React, { useState } from "react";

import InputField from "../../component/inputfiled/Inputfiled";
import { Form, Formik } from "formik";
import CustomButton from "../../component/button/button";
import {
  Chip,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { fontSize } from "../../component/theme/fontSize";
import { fontWeight } from "../../component/theme/fontWeight";
import { FontFamily } from "../../component/theme/fontFamily";
import { ThemeColor } from "../../component/theme/color";
import OutlineCustomButton from "../../component/button/outlineButton";
import Login from "./Login.page";
import { changePasswordService } from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

function ChangePasswordModule() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleClick = () => {
    alert("Button clicked!");
  };

  const loginSchema = Yup.object({
    oldPassword: Yup.string()
      .required("Old Password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New Password is required"),
  });
  return (
    <Login>
      {" "}
      <div className="main-container-content d-flex flex-column justify-content-center">
        <Typography
          fontSize={fontSize.h2.medium}
          fontWeight={fontWeight.W700}
          fontFamily={FontFamily.Nunito}
        >
          Change Password
        </Typography>
        <Typography
          fontSize={fontSize.h3.small}
          fontWeight={fontWeight.W500}
          fontFamily={FontFamily.Nunito}
          className="pb-4"
        >
          CAE Portal
        </Typography>
        <div className="changepassword-note">
          <Typography
            fontFamily={FontFamily.Nunito}
            fontSize={fontSize.h3.small}
            fontWeight={fontWeight.W400}
          >
            As a valuable member of CAE, we respect your Privacy and taking this
            under consideration we request you to change you password as this is
            your first login
          </Typography>
        </div>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await changePasswordService(values)
              .then((res) => {
                if (res.status === 200) {
                  // //("res of login", res);
                  dispatch(showSnackbar(res.data.message, "success"));
                  // window.sessionStorage.setItem("user_token", res.data.token);
                  // window.sessionStorage.setItem("user_token", res.data.token);
                  // window.sessionStorage.setItem(
                  //   "user_role_type",
                  //   res.data?.user?.roleType
                  // );

                  navigate("/");
                  navigate(0);
                }
              })
              .catch((e) => {
                dispatch(showSnackbar(e.response.data.message, "error"));

                // //("error", e.response.data.message);
              });
          }}
          validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
          }) => (
            <Form className="mt-4" onSubmit={handleSubmit}>
              <FormGroup style={{}}>
                <InputField
                  name={"oldPassword"}
                  placeholder="Enter your old password"
                  fullWidth={true}
                  value={values.oldPassword}
                  onChange={handleChange}
                  type="password"
                  helperText={
                    <Typography
                      align="left"
                      // className="px-3"
                      color={ThemeColor.RED}
                      fontSize={fontSize.h4.medium}
                      fontFamily={FontFamily.Lato}
                      fontWeight={fontWeight.W500}
                    >
                      {errors.oldPassword}
                    </Typography>
                  }
                />
                <InputField
                  value={values.newPassword}
                  onChange={handleChange}
                  name="newPassword"
                  type="password"
                  placeholder="Enter your new Password"
                  fullWidth={true}
                  helperText={
                    <Typography
                      align="left"
                      // className="px-3"
                      color={ThemeColor.RED}
                      fontSize={fontSize.h4.medium}
                      fontFamily={FontFamily.Lato}
                      fontWeight={fontWeight.W500}
                    >
                      {errors.newPassword}
                    </Typography>
                  }
                />
                <div className="d-flex justify-content-center mt-5">
                  <OutlineCustomButton variant="outlined" type="submit">
                    Update
                  </OutlineCustomButton>
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </Login>
  );
}

export default ChangePasswordModule;
