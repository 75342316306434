import React, { useState } from "react";
import {
  FormHelperText,
  IconButton,
  TextField,
  createSvgIcon,
} from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeColor } from "../theme/color";
import Calendar from "../../Icon/Calendar";
import dayjs from "dayjs";

const OnlyDatePicker = ({
  label,
  value,
  onChange,
  minDate,
  helperText,
  format,
  openTo,
  views,
  ...otherProps
  // field,
  // form,
  // ...props
}) => {
  const icon = createSvgIcon(
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6096 3.5H17.6475V2.625C17.6475 2.39294 17.5441 2.17038 17.3602 2.00628C17.1762 1.84219 16.9266 1.75 16.6664 1.75C16.4063 1.75 16.1567 1.84219 15.9727 2.00628C15.7887 2.17038 15.6854 2.39294 15.6854 2.625V3.5H9.79904V2.625C9.79904 2.39294 9.69568 2.17038 9.5117 2.00628C9.32771 1.84219 9.07818 1.75 8.81798 1.75C8.55779 1.75 8.30826 1.84219 8.12427 2.00628C7.94029 2.17038 7.83693 2.39294 7.83693 2.625V3.5H5.87481C5.09423 3.5 4.34563 3.77656 3.79368 4.26884C3.24172 4.76113 2.93164 5.42881 2.93164 6.125V16.625C2.93164 17.3212 3.24172 17.9889 3.79368 18.4812C4.34563 18.9734 5.09423 19.25 5.87481 19.25H19.6096C20.3902 19.25 21.1388 18.9734 21.6908 18.4812C22.2427 17.9889 22.5528 17.3212 22.5528 16.625V6.125C22.5528 5.42881 22.2427 4.76113 21.6908 4.26884C21.1388 3.77656 20.3902 3.5 19.6096 3.5ZM20.5907 16.625C20.5907 16.8571 20.4873 17.0796 20.3033 17.2437C20.1193 17.4078 19.8698 17.5 19.6096 17.5H5.87481C5.61462 17.5 5.36508 17.4078 5.1811 17.2437C4.99712 17.0796 4.89376 16.8571 4.89376 16.625V10.5H20.5907V16.625ZM20.5907 8.75H4.89376V6.125C4.89376 5.89294 4.99712 5.67038 5.1811 5.50628C5.36508 5.34219 5.61462 5.25 5.87481 5.25H7.83693V6.125C7.83693 6.35706 7.94029 6.57962 8.12427 6.74372C8.30826 6.90781 8.55779 7 8.81798 7C9.07818 7 9.32771 6.90781 9.5117 6.74372C9.69568 6.57962 9.79904 6.35706 9.79904 6.125V5.25H15.6854V6.125C15.6854 6.35706 15.7887 6.57962 15.9727 6.74372C16.1567 6.90781 16.4063 7 16.6664 7C16.9266 7 17.1762 6.90781 17.3602 6.74372C17.5441 6.57962 17.6475 6.35706 17.6475 6.125V5.25H19.6096C19.8698 5.25 20.1193 5.34219 20.3033 5.50628C20.4873 5.67038 20.5907 5.89294 20.5907 6.125V8.75Z"
        fill="black"
      />
    </svg>
  );
  const [selectedDate, setSelectedDate] = useState(null);

  // const { name } = field;
  // const { setFieldValue } = form;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            border: `0.2px solid ${ThemeColor.WhHITESMOKE}`, // Adjust the color as needed
            padding: "0",
            width: "100%", // Adjust the padding inside the input
          },
          "& .MuiInputBase-input": {
            padding: "12px 12px",
            width: "100%", // Adjust the padding inside the input
          },
          "& .MuiFormLabel-root": {
            fontSize: "14px",
            // Adjust the label font size
          },
        }}
        slots={{
          openPickerButton: icon,
        }}
        label={label}
        openTo={openTo}
        format={format}
        value={value ? (dayjs(value).isValid() ? dayjs(value) : null) : null}
        minDate={minDate}
        onChange={onChange}
        views={views}
        // views={['year', 'month', 'day']}
        renderInput={(params) => <TextField {...params} fullWidth />}
        {...otherProps}
        // {...props}
        // value={
        //   field.value
        //     ? dayjs(field.value).isValid()
        //       ? dayjs(field.value)
        //       : null
        //     : null
        // }
        // onChange={(date) => {
        //   setFieldValue(name, date ? dayjs(date).format("YYYY-MM-DD") : null);
        // }}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </LocalizationProvider>
  );
};

export default OnlyDatePicker;
