import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { FontFamily } from "../theme/fontFamily";
import { fontWeight } from "../theme/fontWeight";
import { fontSize } from "../theme/fontSize";
import { ThemeColor } from "../theme/color";
import { DownCircleOutlined } from "@ant-design/icons";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export const ReceiverSection = (data) => {
  const downloadFile = async (file) => {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    const response = await fetch(`${proxyUrl}${file}`);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", "Group.png");
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(blobUrl);
  };

  const FileHandler = (data) => {
    if (data?.split(".").pop() === "xlsx") {
      let urlParts = data.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          <div className="filename-icon">
            <img
              src="/assest/folderpng/excel.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div>
          <a
            href={data}
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else if (data?.split(".").pop() === "pdf") {
      let urlParts = data.split("/");
      let fullFileName = urlParts[urlParts.length - 1];
      return (
        <div className="mt-2 d-flex align-items-center">
          <div className="filename-icon">
            <img
              src="/assest/folderpng/pdf.png"
              alt="file icon"
              className="w-100 h-100"
            ></img>
          </div>
          <a
            href={data}
            download={fullFileName}
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Typography
              className="px-2"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W400}
              fontSize={fontSize.h4.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              {fullFileName}
            </Typography>
          </a>
        </div>
      );
    } else {
      return (
        <div className="mt-2">
          <img
            onClick={() => downloadFile(data)}
            src={data}
            style={{
              width: "100px",
              height: "100px",
            }}
            alt="chatimg"
          />
        </div>
      );
    }
  };

  return (
    <div id="receiver" className="me-5 mt-3">
      <Box className="d-flex justify-content-between">
        <div>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W400}
            fontSize={fontSize.h4.small}
            color={ThemeColor.RED}
            sx={{ textDecoration: "underline" }}
            align="left"
          >
            {data.sender.firstName} &nbsp;
            {data.sender.lastName}
          </Typography>
        </div>
        <div>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W700}
            fontSize={fontSize.h4.small}
            color={ThemeColor.BLACK}
            align="left"
          >
            {moment.unix(data.dateTime).format("llll")}
          </Typography>
        </div>
      </Box>{" "}
      {data?.url && FileHandler(data?.url)}
      <Typography
        className="mt-3 pe-5"
        fontFamily={FontFamily.Poppins}
        fontWeight={fontWeight.W400}
        fontSize={fontSize.h4.medium}
        color={ThemeColor.BLACK}
        align="left"
      >
        {data.text}
      </Typography>
    </div>
  );
};
