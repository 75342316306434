import { AllCountTotalUnseenMessagesConst, GET_ALL_NOTIFICATION, GetUnseenNotificationCountConst } from "../constant/NotificationConstant";
import { GetUnseenNotificationCountAPI, getAllCountTotalUnseenMessagesAPI, getAllNotificationAPI } from "../service/NotificationService";

export const GetAllNotification = () => async (dispatch) => {
  try {
    const data = await getAllNotificationAPI();

    dispatch({ type: GET_ALL_NOTIFICATION, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_NOTIFICATION, payload: error });
  }
};

export const GetAllCountTotalUnseenMessages = () => async (dispatch) => {
  try {
    const data = await getAllCountTotalUnseenMessagesAPI();

    dispatch({ type: AllCountTotalUnseenMessagesConst, payload: data });
  } catch (error) {
    dispatch({ type: AllCountTotalUnseenMessagesConst, payload: error });
  }
};
export const GetUnseenNotificationCount = () => async (dispatch) => {
  try {
    const data = await GetUnseenNotificationCountAPI();

    dispatch({ type: GetUnseenNotificationCountConst, payload: data });
  } catch (error) {
    dispatch({ type: GetUnseenNotificationCountConst, payload: error });
  }
};
