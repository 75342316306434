import React from "react";

function Filter() {
  return (
    <div>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5378 5.12722C14.5673 5.12722 15.4019 4.24435 15.4019 3.15528C15.4019 2.06621 14.5673 1.18335 13.5378 1.18335C12.5084 1.18335 11.6738 2.06621 11.6738 3.15528C11.6738 4.24435 12.5084 5.12722 13.5378 5.12722Z"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.3926 3.15649H18.5025"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 3.15649H11.6739"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.19995 11.7293C7.22942 11.7293 8.06397 10.8464 8.06397 9.75733C8.06397 8.66827 7.22942 7.7854 6.19995 7.7854C5.17049 7.7854 4.33594 8.66827 4.33594 9.75733C4.33594 10.8464 5.17049 11.7293 6.19995 11.7293Z"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.06445 9.75732H18.5029"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 9.75732H4.34542"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5378 18.3301C14.5673 18.3301 15.4019 17.4472 15.4019 16.3582C15.4019 15.2691 14.5673 14.3862 13.5378 14.3862C12.5084 14.3862 11.6738 15.2691 11.6738 16.3582C11.6738 17.4472 12.5084 18.3301 13.5378 18.3301Z"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.3926 16.3582H18.5025"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 16.3582H11.6739"
          stroke="#ADADAD"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default Filter;
