import {
  Box,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import Crossicon from "../../Icon/Crossicon";
import { fontSize } from "../theme/fontSize";
import { fontWeight } from "../theme/fontWeight";
import { FontFamily } from "../theme/fontFamily";
import { ThemeColor } from "../theme/color";
import { Formik, Form } from "formik";
import CustomButton from "../button/button";
import OutlinedInputField from "../inputfiled/OutlinedInput";
import moment from "moment";
import * as Yup from "yup";
import AttachmentDropzone from "../dropzone/AttachmentDropzone";
import { GetStudentChatByIdAction } from "../../Redux/action/ApplicationAction";
import { AddStudentChatService } from "../../Redux/service/Application";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import { GetUserChatByIdAction } from "../../Redux/action/SettingAction";
import { CreatePersonChatModal } from "./AddPersonChatModal.component";
import { CreateGroupChatModal } from "./AddGroupPersonChatModal.component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "100%", xs: "100%", md: "50%", lg: "50%" },
  height: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid grey",
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  message: Yup.string().required("Text is required"),
});

export const CreateChatModal = ({
  chatModal,
  handleClose,
  Organizationlist,
  filteredUser,
  Rolelist,
  getChat,
  generate
}) => {
  const dispatch = useDispatch();

  const [Chatlist, setChatlist] = useState([]);

  const [AddPersonChat, setAddPersonChat] = useState(false);

  const [AddGroupChat, setAddGroupChat] = useState(false);

  const [chatId, setChatId] = useState("");

  const [chatWithOwner, setChatWithOwner] = useState(false);

  const ChatlistState = useSelector(
    (state) => state.setting?.allchat?.data?.data
  );

  useEffect(() => {
    setChatlist(ChatlistState);
  }, [ChatlistState]);

  const handleClosePersonChat = () => setAddPersonChat(!AddPersonChat);
  const handleCloseGroupChatModal = () => setAddGroupChat(!AddGroupChat);

  return (
    <>
      <Modal
        open={chatModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography align="right" onClick={() => handleClose()}>
            <Crossicon width={"20"} height={"20"} />
          </Typography>
          <Typography
            fontFamily={FontFamily.Poppins}
            fontWeight={fontWeight.W600}
            fontSize={fontSize.h3.large}
            color={ThemeColor.PURPLE}
            align="center"
          >
            {chatWithOwner ? "Chat With Owners" : "Chat List"}
          </Typography>

          {/* <Box className="mt-3">
            <Typography
              className="d-flex"
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h3.medium}
              color={ThemeColor.BLACK}
              align="left"
            >
              Person to Person Chat List{" "}
              <span
                className="d-flex align-items-center mx-2"
                style={{
                  background: ThemeColor.LIGHTPURPLE,
                  width: 25,
                  height: 25,
                  borderRadius: "50%",
                }}
              >
                <div
                  className=""
                  onClick={() => {
                    setAddPersonChat(true);
                    // handleCloseGroupChatModal();
                  }}
                >
                  <AddIcon />
                </div>
              </span>
            </Typography>
            <List>
              {generate(
                Chatlist?.filter((data) => data.isGroupChat === false),
                <ChatIcon />
              )}
            </List>
          </Box> */}
          <Box className="mt-3">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h3.medium}
              color={ThemeColor.BLACK}
              align="left"
              className="d-flex"
            >
              Chat List
              <span
                className="d-flex align-items-center mx-2"
                style={{
                  background: ThemeColor.LIGHTPURPLE,
                  width: 25,
                  height: 25,
                  borderRadius: "50%",
                }}
              >
                <div
                  className=""
                  onClick={() => {
                    // handleClosePersonChat();
                    setAddGroupChat(true);
                  }}
                >
                  <AddIcon />
                </div>
              </span>
            </Typography>
            <List>
              {generate(
                Chatlist?.filter((data) => data.isGroupChat === true),
                <ChatIcon />
              )}
            </List>
          </Box>
        </Box>
      </Modal>

      <CreatePersonChatModal
        Organizationlist={Organizationlist}
        filteredUser={filteredUser}
        AddPersonChat={AddPersonChat}
        Rolelist={Rolelist}
        handleClose={handleClosePersonChat}
      />

      <CreateGroupChatModal
        Organizationlist={Organizationlist}
        filteredUser={filteredUser}
        AddGroupChat={AddGroupChat}
        Rolelist={Rolelist}
        handleClose={handleCloseGroupChatModal}
      />
    </>
  );
};
