import React, { useState } from "react";

import InputField from "../../component/inputfiled/Inputfiled";
import { Form, Formik } from "formik";
import CustomButton from "../../component/button/button";
import {
  Chip,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { fontSize } from "../../component/theme/fontSize";
import { fontWeight } from "../../component/theme/fontWeight";
import { FontFamily } from "../../component/theme/fontFamily";
import { ThemeColor } from "../../component/theme/color";
import * as Yup from "yup";
import { LoginService } from "../../Redux/service/SettingService";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Login from "./Login.page";
import { saveRoles } from "../../Redux/action/SettingAction";
import { GetMicrosoftAuthCodeService } from "../../Redux/service/MailService";

function LoginModule() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleClick = () => {
    alert("Button clicked!");
  };
  const loginSchema = Yup.object({
    email: Yup.string()
      .strict()
      // .lowercase("Email should be in lowercase.")
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });
  return (
    <Login>
      {" "}
      <div className="main-container-content d-flex flex-column justify-content-center">
        <Typography
          fontSize={fontSize.h2.medium}
          fontWeight={fontWeight.W700}
          fontFamily={FontFamily.Nunito}
        >
          Login
        </Typography>
        <Typography
          fontSize={fontSize.h3.medium}
          fontWeight={fontWeight.W500}
          fontFamily={FontFamily.Nunito}
          className="pb-4"
        >
          Welcome back to Apply CAE 
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            LoginService(values)
              .then((res) => {
                if (res.status === 200) {
                  // //("res of login", res);
                  //(res);
                  dispatch(showSnackbar(res.data.message, "success"));

                  if (res.data?.user?.isPasswordChanged) {
                    window.sessionStorage.setItem("user_token", res.data.token);
                    window.sessionStorage.setItem("user_id", res.data.user._id);
                    window.sessionStorage.setItem(
                      "user_name",
                      res.data.user.firstName
                    );

                    window.sessionStorage.setItem(
                      "user_role_type",
                      res.data?.user?.roleType
                    );

                    if (res.data?.user?.roleType === "agent" || res.data?.user?.roleType === "user") {
                      window.sessionStorage.setItem(
                        "role",
                        res.data?.user?.roles?.role
                      );
                    }

                    //(window.sessionStorage.getItem("user_token"));

                    if (res.data?.user?.roles) {
                      //(res.data?.user?.roles, "role roles");

                      dispatch(saveRoles(res.data?.user?.roles));
                    }

                    if (window.sessionStorage.getItem("user_token")) {
                      GetMicrosoftAuthCodeService().then((authres) => {
                        if (authres.status === 200) {
                          window.open(authres.data.AuthorizationURL);
                          navigate("/CAE/SettingDashboard");
                          navigate(0);

                          // //(authres);
                        }
                      });
                    }

                    // navigate("/CAE/SettingDashboard");
                    // navigate(0);
                  } else {
                    window.sessionStorage.setItem(
                      "user_first_token",
                      res.data.token
                    );

                    navigate("/changepassword");
                  }
                }
              })
              .catch((e) => {
                dispatch(showSnackbar(e.response?.data?.message, "error"));

                // //("error", e.response.data.message);
              });
          }}
          validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            handleSubmit,
          }) => (
            <Form className="">
              <FormGroup style={{}}>
                <InputField
                  // label="Your Name"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Enter your username / email"
                  fullWidth={true}
                  name={"email"}
                  helperText={
                    <Typography
                      className="px-3"
                      color={ThemeColor.RED}
                      fontSize={fontSize.h4.medium}
                      fontFamily={FontFamily.Lato}
                      fontWeight={fontWeight.W500}
                    >
                      {errors.email}
                    </Typography>
                  }
                />
                <InputField
                  // label="Your Name"
                  value={values.password}
                  onChange={handleChange}
                  type="password"
                  placeholder="Password"
                  fullWidth={true}
                  name={"password"}
                  helperText={
                    <Typography
                      className="px-3"
                      color={ThemeColor.RED}
                      fontSize={fontSize.h4.medium}
                      fontFamily={FontFamily.Lato}
                      fontWeight={fontWeight.W500}
                    >
                      {errors.password}
                    </Typography>
                  }
                />
                <div className="loginform-password-conten">
                  {/* <div>
               <RadioGroup
                 row
                 aria-labelledby="demo-form-control-label-placement"
                 name="position"
                 defaultValue="top"
               >
                 <FormControlLabel
                   value="end"
                   control={<Radio />}
                   label="Remember me"
                   sx={{
                     "& .MuiFormControlLabel-label": {
                       fontSize: fontSize.h3.small,
                       fontWeight: fontWeight.W600,
                       fontFamily: FontFamily.Nunito,
                     },
                   }}
                 />
               </RadioGroup>
             </div> */}
                  <Typography
                    sx={{ textDecoration: "underline", pt: 1 }}
                    fontFamily={FontFamily.Nunito}
                    fontSize={fontSize.h3.small}
                    fontWeight={fontWeight.W600}
                  >
                    Forgot Password?
                  </Typography>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <CustomButton
                    variant="contained"
                    backgroundColor={ThemeColor.LIGHTBLUE}
                    color={ThemeColor.NAVYBLUE}
                    type={"submit"}
                  >
                    Login
                  </CustomButton>
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </Login>
  );
}

export default LoginModule;
