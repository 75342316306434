import { Typography } from "@mui/material";
import React from "react";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontSize } from "../../component/theme/fontSize";
import { fontWeight } from "../../component/theme/fontWeight";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";

function NotFoundPage() {
  return (
    <div>
      <Typography
        fontFamily={FontFamily.Poppins}
        fontSize={fontSize.h3.medium}
        fontWeight={fontWeight.W600}
        align="center"
      >
        You Not Allowed To access this page
      </Typography>
    </div>
  );
}



function NotFound() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<NotFoundPage />} />
    </div>
  );
}

export default NotFound;
