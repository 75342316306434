import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  organizationId: Yup.string().required("You must select an organization"),

  agent: Yup.string()
    .required("Agent is required!")
    .test("organization-selected", "First select an organization", function () {
      const { organizationId } = this.parent;
      return !!organizationId; // Returns true if organizationId is present
    }),

  agentname: Yup.string().test(
    "organization-selected",
    "First select an organization",
    function () {
      const { organizationId } = this.parent;
      return !!organizationId;
    }
  ),

  branch: Yup.string()
    .required("Branch is required!")
    .test("organization-selected", "First select an organization", function () {
      const { organizationId } = this.parent;
      return !!organizationId;
    }),

  studentFirstName: Yup.string().required("Student First name is required!"),
  studentLastName: Yup.string().required("Student Last name is required!"),
  gender: Yup.string().required("Gender is required!"),
  dateOfBirth: Yup.string().required("Birth date is required!"),

  // studentEmailId: Yup.string()
  //   .email("Invalid Email format")
  //   .required("Student Email is required!"), // Fixed the error message

  countryOfCitizenship: Yup.string().required("Country is required!"),
  university: Yup.string().required("You must select university and type of degree for program!"),
  degreeType: Yup.string().required("You must select university and type of degree for program!"),
  program: Yup.string()
    .required("Program is required!")
    .test("university-degree-selected", "First select an university and degree type", function () {
      const { university,degreeType } = this.parent;
      console.log(university,degreeType)
      return (!!university && !!degreeType); // Returns true if organizationId is present
    }),

  // visaStatus: Yup.string().required("Visa Status is required!"),

  visaintake: Yup.string().when("visaStatus", {
    is: (value) => value === "deferred",
    then: (schema) => schema.required("Visa Intake is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  visaintakeyear: Yup.string().when("visaStatus", {
    is: (value) => value === "deferred",
    then: (schema) => schema.required("Visa Intake year is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  visaStatusNotes: Yup.string().when("visaStatus", {
    is: (value) => value === "transferred",
    then: (schema) => schema.required("Visa status notes is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  //   commentsFromUniversity: Yup.string().required("CAE Notes are required!"),

  // caeNotes: Yup.string()
  //      .when([], {
  //        is: () => window.sessionStorage.getItem("user_role_type") === "admin" || window.sessionStorage.getItem("user_role_type") === "user",
  //        then: (schema) => schema.required("CAE Notes are required!"),
  //        otherwise: (schema) => schema,
  //      }),

  // taggingStatus: Yup.string().required("Tagging Status is required!"),
  taggingStatusNotes: Yup.string().when("taggingStatus", {
    is: (value) => value === "not_tagged" || value === "awaited",
    then: (schema) => schema.required("Tagging Status Notes is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  commissionStatusNotes: Yup.string().when("commissionStatus", {
    is: (value) => value === "not_approved",
    then: (schema) => schema.required("Commission Status Notes is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  applicationStatus: Yup.string().required("Application status is required!"),

  applicationintake: Yup.string().when("applicationStatus", {
    is: (value) => value === "deferred",
    then: (schema) => schema.required("Application Intake is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  applicationintakeyear: Yup.string().when("applicationStatus", {
    is: (value) => value === "deferred",
    then: (schema) => schema.required("Application Intake year is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  // taggingRequirement: Yup.string().required("Tagging requirement is required!"),
  // applicationSubmissionDate: Yup.string().required(
  //   "Application submission date required!"
  // ),
  // referenceId: Yup.string().required("Application ID is required!"),
  intake: Yup.string().required("Intake is required!"),
  intakeyear: Yup.string().required("Intake Year is required!"),
  studentEmailId: Yup.string()
    .strict()
    // .lowercase("Email should be in lowercase.")
    .email("Invalid email address"),
});
