import React, { useCallback, useEffect, useState } from "react";
import ResponsiveDrawer from "../../component/sidebar/sidebar.component";
import { FontFamily } from "../../component/theme/fontFamily";
import { fontWeight } from "../../component/theme/fontWeight";
import { fontSize } from "../../component/theme/fontSize";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  createSvgIcon,
  styled,
} from "@mui/material";
import { ThemeColor } from "../../component/theme/color";
import OutlinedInputField from "../../component/inputfiled/OutlinedInput";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import OutlinedTextAreaField from "../../component/inputfiled/OutlinedTextedArea";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import MyDatePicker from "../../component/datepicker/OutlinedDatepicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "../../Icon/Calendar";
import CustomButton from "../../component/button/button";
import Previews from "../../component/dropzone/Mydropzone";
import MyLogoDropzone from "../../component/dropzone/LogoDropzone";
import CustomOutlinedSelect from "../../component/select field/OutlinedSelectField";
import CustomDataTable from "../../component/datatable/DataTable";
import Mylogodropzone from "../../component/dropzone/Mylogodropzone";
import CustomSelect from "../../component/select field/OutlinedSelectField";
import Setting from "../../Icon/Setting";
import OutlineCustomButton from "../../component/button/outlineButton";
import Settinglist from "../../Icon/Settinglist";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAgentAction,
  GetOrganizationAction,
  GetRoleAction,
} from "../../Redux/action/SettingAction";
import Message from "../../Icon/Message";
import { showSnackbar } from "../../Redux/action/SnackbarAction";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { handleExport } from "../ExportFunction/ExportFunction";
import FilterComponent from "../../component/filterForTable/FilterComponent";
import { basicurl, token } from "../../Redux/ServiceUrl/url";
import axios from "axios";

const NoPaddingGridItem = styled(Grid)({
  paddingTop: "0px !important",
});

const selectOptions = [
  { value: 10, label: "Option 1" },
  { value: 20, label: "Option 2" },
  { value: 30, label: "Option 3" },
];
const AgentlistPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [Agentlist, setAgentlist] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [table_load, setTable_load] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [data, setData] = useState([]);
  const [Organizationlist, setOrganizationlist] = useState([]);


  const [Rolelist, setRolelist] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState([]);


  const handleVisibleColumnsChange = useCallback((visibleColumns) => {
    // Handle the change in visible columns
    setVisibleColumns(visibleColumns);
  }, []);


  const RolelistState = useSelector(
    (state) => state.setting?.role?.data?.roles
  );
  const OrganizationlistState = useSelector(
    (state) => state.setting?.organization?.data
  );
  useEffect(() => {
    dispatch(GetRoleAction());
    dispatch(GetOrganizationAction());

  }, []);

  useEffect(() => {
    // //("role list==>",RolelistState)
    const updated = RolelistState?.filter(
      (role) =>
        role.role === "agent_counsellor" ||
        role.role === "agent_accountant" ||
        role.role === "application_supervisor"
    );
    setRolelist(updated);
    setOrganizationlist(
      OrganizationlistState?.organizations?.map(({ organizationName, ...rest }) => ({
        title: organizationName,
        ...rest,
      }))
    );
  }, [RolelistState,OrganizationlistState]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const handleEditActionClick = (event) => {
          event.stopPropagation();
          navigate(`/CAE/EditAgent/${params.row.id}`);
        };
        return (
          <Tooltip title="Edit Agent">
            {" "}
            <IconButton onClick={handleEditActionClick}>
              <ModeEditIcon sx={{ fill: ThemeColor.LIGHTPURPLE }} />
            </IconButton>
          </Tooltip>
        );
      },
    },
    ...(!(window.sessionStorage.getItem("user_role_type")==="agent")
      ? [
          {
            field: "organization",
            headerName: "Organization",
            width: 150,
            renderCell: (params) => {
              return (
                <div
                  style={{
                    textDecoration: "underline",
                    textTransform: "lowercase",
                  }}
                >
                  {params.row?.organization?.organizationName || "N/A"}
                </div>
              );
            },
          },
        ]
      :[]),
    // {
    //   field: "organization",
    //   headerName: "Organization",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         style={{ textDecoration: "underline", textTransform: "lowercase" }}
    //       >
    //         {params.row?.organization?.organizationName}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "firstName",
      headerName: "Agent Name",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="d-flex" style={{ color: ThemeColor.BLUE }}>
            {params.row?.roles.role}
            {/* {params.row.roles?.map((data, index) => {
              return (
                <div>
                  {data.title}
                  {index + 1 === params.row.roles?.length ? "" : ", "}
                </div>
              );
            })} */}
          </div>
        );
      },
    },
    {
      field: "end_date",
      headerName: "Entry Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div>{dayjs(params.row.createdOn).format("YYYY-MM-DD HH:MM")}</div>
        );
      },
    },

    {
      field: "isActive",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            {params.row?.isActive === "Y" ? "Active" : "In-active"}
          </div>
        );
      },
    },
    
  ];

  const AgentlistState = useSelector((state) => state.setting?.agent?.data);
  //(AgentlistState, "agent data");

  useEffect(() => {
    setTable_load(true);
    dispatch(GetAgentAction());
  }, []);

  useEffect(() => {
    const updatedData = AgentlistState?.activeAgents?.map(
      ({ _id, ...rest }) => ({
        id: _id,
        ...rest,
      })
    );
    setAgentlist(updatedData);
    setTable_load(false);
  }, [AgentlistState]);

  // Handle toggle change
  const handleStatusChange = async () => {
    const newToggleState = !isToggled;
    if (newToggleState) {
      setAgentlist(
        AgentlistState?.blockedAgents?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    } else {
      setAgentlist(
        AgentlistState?.activeAgents?.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        }))
      );
    }
    setIsToggled(newToggleState);
  };

  const fields = [
    // { label: "Agent ID", value: "agentId", type: "select", option: Agentlist },
    {
      label: "Organization",
      value: "organization",
      type: "select",
      option: Organizationlist,
    },
    {
      label: "Agent Name",
      value: "firstName",
      type: "text",
      // option: Universitylist,
    },
    {
      label: "Email",
      value: "email",
      type: "text",
      // option: Degreelist,
    },
    {
      label: "Roles",
      value: "roles",
      type: "select",
      option: Rolelist,
    },
  ];
  const fetchData = async (filters) => {
    let string = filters.map((data) => `${data.field}=${data.value}`);
    try {
      let response = await axios.get(
        basicurl + `/api/login/get-all-active-agents?${string.join("&")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const result = await response.json();
      console.log(response);
      if (isToggled) {
        setAgentlist(
          response.data?.blockedAgents?.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          }))
        );
      } else {
        setAgentlist(
          response.data?.activeAgents?.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleApplyFilters = (filters) => {
    fetchData(filters); // Call the API with filters
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12}>
          <div className="d-flex align-items-center">
            <Typography
              fontFamily={FontFamily.Poppins}
              fontWeight={fontWeight.W600}
              fontSize={fontSize.h2.small}
            >
              Agent List
            </Typography>
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Box
            className="d-flex align-items-center w-100"
            sx={{
              justifyContent: {
                sm: "space-between",
                xs: "space-between",
                lg: "end",
                md: "end",
              },

              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
          >
            {/* <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              //   color={ThemeColor.WHITE}
              //   padding={"0px"}
              // onClick={handleClick}
            >
              Action
            </OutlineCustomButton> */}
            <OutlineCustomButton
              variant="contained"
              type="submit"
              backgroundColor={ThemeColor.PURPLE}
              onClick={() => setFilterOpen(true)}
            >
              Filter
            </OutlineCustomButton>
            <div className="px-1 ps-2">
              <OutlineCustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                onClick={() =>
                  handleExport(selectionModel, "Agent", Agentlist, "isActive",visibleColumns)
                }
              >
                Export
              </OutlineCustomButton>
            </div>
            <Box
              display="flex"
              sx={{ border: `2px solid ${ThemeColor.GREY}`, borderRadius: 25 }}
              alignItems="center"
              className="px-2"
            >
              <Typography>Blocked</Typography>
              <Switch
                sx={{
                  "& .MuiSwitch-track": {
                    // backgroundColor: isToggled ? "white" : "#ccc",
                    transition: "background-color 0.3s",
                    borderRadius: 20,
                  },
                  "& .MuiSwitch-thumb": {
                    width: 24,
                    height: 24,
                    backgroundColor: isToggled
                      ? ThemeColor.PURPLE
                      : ThemeColor.BLACK,
                    transition: "transform 0.3s ease",
                    transform: isToggled
                      ? "translateX(2px)"
                      : "translateX(0px)",
                  },
                  "& .MuiSwitch-switchBase": {
                    padding: 1,
                    transition: "transform 0.3s",
                    "&.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: ThemeColor.LIGHTPURPLE,
                    },
                  },
                }}
                checked={isToggled ? true : false}
                onChange={handleStatusChange}
                color="primary"
              />
            </Box>
            <div className="px-2">
              <CustomButton
                variant="contained"
                type="submit"
                backgroundColor={ThemeColor.PURPLE}
                color={ThemeColor.WHITE}
                onClick={() => navigate("/CAE/AddAgent")}

                //   padding={"0px"}
                // onClick={handleClick}
              >
                Add Agent
              </CustomButton>
            </div>
          </Box>
        </Grid>
      </Grid>
      <FilterComponent
        open={isFilterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={handleApplyFilters}
        fields={fields}
      />
      <div className="table-container mt-4" style={{ overflowX: "scroll" }}>
        <CustomDataTable
          row={Agentlist ? Agentlist : []}
          columns={columns}
          loading={table_load}
          onSelectionChange={(newSelection) => {
            // console.log(newSelection)
            setSelectionModel(newSelection);
          }}
          getRowClassName={(params) =>
            params.row.isActive === "Y" ? "row-white" : "row-dull"
          }
          onVisibleColumnsChange={handleVisibleColumnsChange}

        />
      </div>
    </>
  );
};

function Agentlist() {
  return (
    <div>
      <ResponsiveDrawer maincontent={<AgentlistPage />} />
    </div>
  );
}

export default Agentlist;
